export class Beneficiary{
    bE_ID: string;
    externaL_BE_ID?: string;
    companyName: string;
    responsiblePerson: string;
    address: string;
    zipCode: string;
    email: string;
    city: string;
    vaT_ID: string;
    ccEmail: string;
    phoneNumber: string;
    active: boolean;
    iatA_airlineId?: string;
    iatA_airlineName: string;
    iatA_code: string;
    iR_ID?: string;
    invoiceRecipientName: string;
    invoicingCycleId?: string;
    invoicingCycleName: string;
    countryId?: string;
    countryName: string;
    isRecipient : boolean;
    colliSize: number;

    /**
     * Constructor
     * @param beneficiary
     */
    constructor(beneficiary?){
        beneficiary = beneficiary || {};
        this.bE_ID = beneficiary.bE_ID || '';
        this.externaL_BE_ID = beneficiary.externaL_BE_ID || '';
        this.companyName = beneficiary.companyName || '';
        this.responsiblePerson = beneficiary.responsiblePerson || '';
        this.address = beneficiary.address || '';
        this.zipCode = beneficiary.zipCode || '';
        this.email = beneficiary.email || '';
        this.city = beneficiary.city || '';
        this.vaT_ID = beneficiary.vaT_ID || '';
        this.ccEmail = beneficiary.ccEmail || '';
        this.phoneNumber = beneficiary.phoneNumber || '';
        this.active = beneficiary.active;
        this.iatA_airlineId = beneficiary.iatA_airlineId || null;
        this.iatA_airlineName = beneficiary.iatA_airlineName || '';
        this.iatA_code = beneficiary.iatA_code || '';
        this.iR_ID = beneficiary.iR_ID || '';
        this.invoiceRecipientName = beneficiary.invoiceRecipientName;
        this.invoicingCycleId = beneficiary.invoicingCycleId || null;
        this.invoicingCycleName = beneficiary.invoicingCycleName;
        this.countryId = beneficiary.countryId || null;
        this.countryName = beneficiary.countryName;
        this.isRecipient = beneficiary.isRecipient || false;
        this.colliSize = beneficiary.colliSize || 0;
    }
}
