import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

import { Mail } from 'app/mail/mail.model';
import { MailService } from 'app/mail/mail.service';
import { MatPaginator } from '@angular/material';

@Component({
    selector     : 'mail-list',
    templateUrl  : './mail-list.component.html',
    styleUrls    : ['./mail-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class MailListComponent implements OnInit, OnDestroy
{
    mails: Mail[];
    currentMail: Mail;
    totalPages: number;
    totalCount: number;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;
    
    private _unsubscribeAll: Subject<any>;



    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {MailService} _mailService
     * @param {Location} _location
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _mailService: MailService,
        private _location: Location,

    )
    {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void
    {
        this._mailService.onMailsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(mailsDetails => {
                this.mails = mailsDetails.mails;
                this.totalPages = mailsDetails && mailsDetails.totalPages;
                this.totalCount = mailsDetails && mailsDetails.totalCount;
            });

            this.paginator.page.subscribe(() => {
                const pageNumber = this.paginator.pageIndex + 1;
                const pageSize = this.paginator.pageSize;
                this._mailService.onPageChanged({pageNumber, pageSize});
            });

        this._mailService.onCurrentMailChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(currentMail => {
                if ( !currentMail )
                {
                    this.currentMail = null;

                    const labelHandle  = this._activatedRoute.snapshot.params.labelHandle,
                          filterHandle = this._activatedRoute.snapshot.params.filterHandle,
                          folderHandle = this._activatedRoute.snapshot.params.folderHandle;

                    if ( labelHandle )
                    {
                        this._location.go('mail/label/' + labelHandle);
                    }
                    else if ( filterHandle )
                    {
                        this._location.go('mail/filter/' + filterHandle);
                    }
                    else
                    {
                        this._location.go('mail/' + folderHandle);
                    }
                }
                else
                {
                    this.currentMail = currentMail;
                }
            });
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Read mail
     *
     * @param mailId
     */
    readMail(mailId): void
    {
        const labelHandle  = this._activatedRoute.snapshot.params.labelHandle,
              filterHandle = this._activatedRoute.snapshot.params.filterHandle,
              folderHandle = this._activatedRoute.snapshot.params.folderHandle;

        if ( labelHandle )
        {
            this._location.go('apps/mail/label/' + labelHandle + '/' + mailId);
        }
        else if ( filterHandle )
        {
            this._location.go('apps/mail/filter/' + filterHandle + '/' + mailId);
        }
        else
        {
            this._location.go('apps/mail/' + folderHandle + '/' + mailId);
        }

        this._mailService.setCurrentMail(mailId);
    }
}
