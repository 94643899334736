import { FeatureCollection } from "geojson";
import { MapboxFeature } from "./MapboxFeature";

export class MapboxFeatureCollection implements FeatureCollection {
    type: "FeatureCollection" = "FeatureCollection";
    features: MapboxFeature[];

    constructor(features: MapboxFeature[]) {
        this.features = features;
    }
}