import { GeoJSONSourceRaw, Source } from "mapbox-gl";
import { MapboxFeatureCollection } from "./MapboxFeatureCollection";

export class RouteData implements GeoJSONSourceRaw {
    public type: 'geojson' = "geojson";
    public data: MapboxFeatureCollection;

    constructor(data: MapboxFeatureCollection) {
        this.data = data;
    }
}