import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { InvoicingDraftService } from './invoicing-draft.service';
import { fuseAnimations } from '@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'invoicing-draft-dialog',
  templateUrl: './invoicing-draft.component.html',
  styleUrls: ['./invoicing-draft.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class InvoicingDraftComponent {

  action: string;
  dialogTitle: string;
  invoicingDraftFindOrdersForm: FormGroup;
  dataSource: any;
  dataSourceOrder: any;
  displayedColumns = ['select', 'bE_ID', 'companyName', 'iatA_code'];
  detailFlag = false;
  displayedColumnsOrder = ['select', 'ahlName', 'tourName', 'deliveryDate', 'numberOfBags'];
  selection = new SelectionModel<any>(true, []);
  loader = false;
  invoiceOrdersDetails: any;
  invoiceOrdersSelected: any;
  filteredOrders: any;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<InvoicingDraftComponent>,
    private _invoicingDraftService: InvoicingDraftService,
    private _formBuilder: FormBuilder
  ) {
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Draft' : 'Create Draft';
    this._unsubscribeAll = new Subject();

    this.invoicingDraftFindOrdersForm = this._formBuilder.group({
      //invoicingDate: new FormControl(),
      dateFrom: [new FormControl(), [Validators.required]],
      dateTo: [new FormControl(), [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  findOrders(): void {
    /* Reset flag / selected values */
    this.loader = true;
    this.detailFlag = false;
    this.selection.clear();
    this.invoiceOrdersDetails = [];
    /* Reset flag / selected values */

    let fromDate = new Date(this.invoicingDraftFindOrdersForm.get('dateFrom').value);
    const offset = fromDate.getTimezoneOffset();
    fromDate = new Date(fromDate.getTime() - (offset * 60 * 1000));
    sessionStorage.setItem('invoice-draft-from-date', fromDate.toISOString().split('T')[0]);

    let toDate = new Date(this.invoicingDraftFindOrdersForm.get('dateTo').value);
    toDate = new Date(toDate.getTime() - (offset * 60 * 1000));
    sessionStorage.setItem('invoice-draft-to-date', toDate.toISOString().split('T')[0]);

    this._invoicingDraftService.getBeneficiaries(fromDate.toISOString().split('T')[0], toDate.toISOString().split('T')[0]).then((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.loader = false;
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.fetchInvoiceOrders();
  }

  beneficiarySelection(): void {
    this.fetchInvoiceOrders();
  }

  fetchInvoiceOrders(): void {
    this.loader = true;
    if (this.selection.selected.length > 0) {
      let beIds = this.selection.selected.map(a => a.beirveid).join(',');
      this._invoicingDraftService.getOrders(beIds, sessionStorage.getItem('invoice-draft-from-date'), sessionStorage.getItem('invoice-draft-to-date')).then((response) => {
        this.invoiceOrdersDetails = response;

        //By default select first belst and its orders
        this.invoiceOrdersSelected = this.invoiceOrdersDetails && this.invoiceOrdersDetails.belst ? this.invoiceOrdersDetails.belst[0] : [];

        if (this.invoiceOrdersSelected) {
          this.filteredOrders = this.invoiceOrdersDetails.orderLst.filter(function (item) {
            return item.beirveid == this.invoiceOrdersSelected.beirveid;
          }, this);

          this.dataSourceOrder = new MatTableDataSource(this.filteredOrders);
        }

        this.loader = false;
        this.detailFlag = this.invoiceOrdersDetails && this.invoiceOrdersDetails.belst ? true : false;
      });
    } else {
      this.invoiceOrdersDetails = [];
      this.loader = false;
      this.detailFlag = false;
    }
  }

  changeBlest(event): void {
    if (event.target.value) {
      this.invoiceOrdersSelected = this.invoiceOrdersDetails.belst.find(item => item.beirveid == event.target.value);

      if (this.invoiceOrdersSelected) {
        this.filteredOrders = this.invoiceOrdersDetails.orderLst.filter(function (item) {
          return item.beirveid == this.invoiceOrdersSelected.beirveid;
        }, this);

        this.dataSourceOrder = new MatTableDataSource(this.filteredOrders);
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isOrderAllSelected() {
    const numSelected = this.filteredOrders.filter(function (item) {
      return item.isSelected == true;
    }).length;
    const numRows = this.filteredOrders.length;
    return numSelected === numRows;
  }

  isIndeterminate() {
    const numSelected = this.filteredOrders.filter(function (item) {
      return item.isSelected == true;
    }).length;
    return numSelected ? true : false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleOrder() {
    if (this.isOrderAllSelected()) {
      this.filteredOrders.map(item => {
        item.isSelected = false;
        return item;
      });
    } else {
      this.filteredOrders.map(item => {
        item.isSelected = true;
        return item;
      });
    }
  }
  
  visibleCreateDraft() {
    const orderSelected = (this.invoiceOrdersDetails && this.invoiceOrdersDetails.orderLst && this.invoiceOrdersDetails.orderLst.filter(function (item) {
      return item.isSelected == true;
    })) || [];
    return orderSelected.length ? true : false;
  }

  createDraft(): void {
    const draftOrders = this.invoiceOrdersDetails.orderLst.filter(function (item) {
      return item.isSelected == true;
    });
    
    this.matDialogRef.close({
      actionType: 'new',
      orders: draftOrders
    });
  }
}
