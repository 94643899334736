import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VendorDraftsService } from '../vendor-drafts.service';

@Component({
  selector: 'draft-details-popup-dialog',
  templateUrl: './draft-details-popup.component.html',
  styleUrls: ['./draft-details-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DraftDetailsPopupComponent {
  action: string;
  dialogTitle: string;
  dialogRef: any;
  vendorId: number;
  vendorDraftDetails: any;
  
  /**
   * Constructor
   *
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<DraftDetailsPopupComponent>,
    public _matDialog: MatDialog,
    private _vendorDraftService: VendorDraftsService

  ) {
    this.action = _data.action;
    this.dialogTitle = "Draft Details";
    this.vendorId = _data.vendorId;
  }

  ngOnInit(): void {
    this._vendorDraftService.getInvoiceDraftDetails(this.vendorId).then(response => {
      this.vendorDraftDetails = response;
      console.log(response);
    });
  }
}