import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { TourTemplate } from '../fixed-tour.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSelectModule } from '@angular/material';
import { FixedToursService } from '../fixed-tours.service';
import { FixedTourService } from './fixed-tour.service';
import { FuseUtils } from '@fuse/utils';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LoginService } from 'app/main/pages/authentication/login/login.service';
import { OwnersService } from '../../owners/owners.service';
import { Zone } from 'app/main/zones/zone.model';
import { ZonesService } from 'app/main/zones/zones.service';
import { map, startWith } from 'rxjs/operators';
import { UnitsService } from 'app/main/units/units.service';
import { PriceItemsService } from 'app/main/price-item/price-item.service';

@Component({
    selector: 'fixed-tours-fixed-tour-form-dialog',
    templateUrl: './fix-tour.component.html',
    styleUrls: ['./fix-tour.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FixTourComponent {
    baseAdd = [];
    action: string;
    fixedTour: TourTemplate;
    dialogTitle: string;
    pageType: string;
    fixedTourForm: FormGroup;
    myControl = new FormControl();
    options: { id: number, value: string }[] = [{ id: 1, value: 'One' }, { id: 2, value: 'O444e' }, { id: 3, value: 'O222ne' }];
    filteredOptions: Observable<string[]>;
    zonesCtrl = new FormControl();
    filteredZones: Observable<Zone[]>;
    zones: Zone[] = [];
    allZones: Zone[];
    selectedUnitId: any;
    selectedExtraUnitId: any;
    units: any;
    filteredPriceItems: any;
    filteredExtraPriceItems: any;
    priceItems: any;

    private _unsubscribeAll: Subject<any>;
    _matSnackBar: any;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<FixTourComponent>,
        private _fixedToursService: FixedToursService,
        private _fixedTourService: FixedTourService,
        private _formBuilder: FormBuilder,
        public _matDialog: MatDialog,
        private _loginService: LoginService,
        private _ownersService: OwnersService,
        private _zonesService: ZonesService,
        private _unitsService: UnitsService,
        private _priceItemsService: PriceItemsService
    ) {
        this._unitsService.getUnits().then(data => {
            this.units = data;
        });

        this._priceItemsService.getPriceItems().then(data => {
            this.priceItems = data;

            //To get select unit and filter price items for edit
            if (this.action == "edit") {
                if (this.fixedTour.priceItemId) {
                    let selectedUnit = this.priceItems.find(item => item.id === this.fixedTour.priceItemId);

                    this.selectedUnitId = selectedUnit ? selectedUnit.unitId : null;

                    if(this.selectedUnitId)
                        this.filteredPriceItems = this.priceItems.filter(item => item.unitId === this.selectedUnitId);
                }

                if (this.fixedTour.extraPriceItemId) {
                    let selectedUnit = this.priceItems.find(item => item.id === this.fixedTour.extraPriceItemId);

                    this.selectedExtraUnitId = selectedUnit ? selectedUnit.unitId : null;

                    if(this.selectedExtraUnitId)
                        this.filteredExtraPriceItems = this.priceItems.filter(item => item.unitId === this.selectedExtraUnitId);
                }
            }
        });

        this._ownersService.getOwnerDetails(this._loginService.getUserDetails().ownerId).subscribe(data => {
            this.baseAdd.push(data.baseAddress1);
            this.baseAdd.push(data.baseAddress2);
        });


        this.fixedTour = new TourTemplate(_data.fixedTour);
        this.action = _data.action;
        this.dialogTitle = this.action === 'edit' ? 'Edit Fixed Tour' : 'New Fixed Tour';
        this.fixedTourForm = this.createFixedTourForm();
        this._unsubscribeAll = new Subject();
        this.setupAutocompleteDataSourceZone();        
    }

    /**
     * Create driver form
     *
     * @returns {FormGroup}
     */
    createFixedTourForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.fixedTour.id],
            name: [this.fixedTour.name, [Validators.required]],
            tourTime: [this.fixedTour.tourTime, [Validators.required]],
            isActive: [this.fixedTour.isActive],
            monday: [this.fixedTour.monday],
            tuesday: [this.fixedTour.tuesday],
            wednesday: [this.fixedTour.wednesday],
            thursday: [this.fixedTour.thursday],
            friday: [this.fixedTour.friday],
            saturday: [this.fixedTour.saturday],
            sunday: [this.fixedTour.sunday],
            baseAddress: [this.fixedTour.baseAddress, [Validators.required]],
            priceItemId: [this.fixedTour.priceItemId, [Validators.required]],
            extraPriceItemId: [this.fixedTour.extraPriceItemId],
            zoneId: [this.fixedTour.zoneId, [Validators.required]],
            zonesCtrl: [this.fixedTour.zoneName]
        });
    }

    saveFixedTour(): void {
        const data = this.fixedTourForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._fixedTourService.saveFixedTour(data)
            .then(() => {
                this._fixedTourService.onFixedTourChanged.next(data);

                this._matSnackBar.open('Tour saved', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    zoneSelected(zoneId): void {
        this.fixedTourForm.patchValue({
            zoneId: zoneId ? ((this.zones && this.zones[0] && this.zones[0].id) || null) : null
        });
    }

    private setupAutocompleteDataSourceZone() {
        this._zonesService.getZones().then(zones => {
            this.allZones = zones;
            this.filteredZones = this.zonesCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterZone(name) : this._clearZoneSelection()));
        });
        this.zonesCtrl.setValue(this.fixedTour.zoneName);
    }

    public _clearZoneSelection() {
        if (!this.zonesCtrl.value)
            this.zoneSelected(null);
        return this.allZones.slice();
    }

    private _filterZone(name: string): Zone[] {
        const filterValue = name.toLowerCase();

        this.zones = this.allZones.filter(zone => zone.name.toLowerCase().indexOf(filterValue) === 0);
        return this.zones;
    }

    unitSelection(unitId): void {
        //Reset priceItemId on unit change
        this.fixedTourForm.patchValue({
            priceItemId : null
        });

        if (unitId) {
            this.filteredPriceItems = this.priceItems.filter(item => item.unitId === unitId);
        } else {
            this.filteredPriceItems = [];
        }
    }

    extraUnitSelection(unitId): void {
        //Reset priceItemId on unit change
        this.fixedTourForm.patchValue({
            extraPriceItemId : null
        });

        if (unitId) {
            this.filteredExtraPriceItems = this.priceItems.filter(item => item.unitId === unitId);
        } else {
            this.filteredExtraPriceItems = [];
        }
    }


}