import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { LtsDropItemType } from '../dispatcher-screen.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material';
import { DispatcherScreenService } from '../dispatcher-screen.service';
import { Order } from '../orders/order.model';
import { DispatcherDriversService } from '../drivers/drivers.service';
import { MatDialogRef, MatDialog } from '@angular/material';
export interface OrderDroppedToTour {
  order: Order;
  tour: any;
}
import { Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TourDateComponent } from '../tour-date/tour-date.component';
import { TourStatusPopupComponent } from './tour-status-popup/tour-status-popup.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderComponent } from '../orders/order/order.component';
import { DispatcherOrdersComponent } from '../orders/orders.component';
import * as moment from 'moment';

@Component({
  selector: 'dispatcher-tours-widget',
  templateUrl: './tours-widget.component.html',
  styleUrls: ['./tours-widget.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ToursWidgetComponent implements OnInit {

  @Input() ltsConnectedDroplists: string[];
  @Input() ltsConnectedDroplistsTour: string[];
  @Input() ltsDropItemType: LtsDropItemType;

  @Output() tourDropListsChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() orderDroppedToTour: EventEmitter<OrderDroppedToTour> = new EventEmitter<OrderDroppedToTour>();

  @Input() sourceComponent: string = "dispatcher";

  listOfTargetDropListsIds = [];
  dialogRef: any;
  tours: any = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  tourConfirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  dispatcherTourForm: FormGroup;
  tourDetails: any;
  loader: boolean = false;
  notDeliveredToursWithOrders: any;
  unreadTourStatusCount: number = 0;
  tourStatusDateRange: any = { begin: new Date(), end: new Date() };

  constructor(private _matSnackBar: MatSnackBar,
    private dispatcherScreenService: DispatcherScreenService,
    private _driversService: DispatcherDriversService,
    public _matDialog: MatDialog,
    private router: Router,
    private _dispatcherTourFormBuilder: FormBuilder,
    private _dispatcherOrder: DispatcherOrdersComponent) { }

  ngOnInit(): void {
    this.loader = true;
    let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();

    //Get tours by date
    this.getInitialTours(defaultDispatcherTourDate);

    //Dispatcher tour date init
    this.dispatcherTourForm = this._dispatcherTourFormBuilder.group({
      dispatcherTourDate: defaultDispatcherTourDate
    });

    this.dispatcherScreenService.tourWidgetTours.subscribe(tours => {
      this.tours = tours;
      this.listOfTargetDropListsIds = this.tours.map(tour => uuidv4());
      this.tours.forEach(tour => generateOrderString(tour));
      this.tourDropListsChanged.emit(this.listOfTargetDropListsIds);
    });

    if (this.sourceComponent == "dispatcher") {
      this.dispatcherScreenService.getNotDeliveredOrders().then(data => {
        this.notDeliveredToursWithOrders = data;
        this.unreadTourStatusCount = 0;
        this.loader = false;
      });
    } else {
      this.dispatcherScreenService.getNotDeliveredOrdersByFilter({}).then((response: any) => {
        this.notDeliveredToursWithOrders = response.tourDetail;
        this.unreadTourStatusCount = response.count;
        this.loader = false;
      });
    }
  }

  onDispatcherTourDateSelected(event): void {
    let selectedDate = new Date(event.value);
    sessionStorage.setItem('dispatcher-tour-date', selectedDate.toString());
    this.getInitialTours(selectedDate);
  }

  getInitialTours(defaultTourDate) {
    const offset = defaultTourDate.getTimezoneOffset();
    defaultTourDate = new Date(defaultTourDate.getTime() - (offset * 60 * 1000));
    let dateString = defaultTourDate.toISOString().split('T')[0];

    this.dispatcherScreenService.getToursForToday(dateString).then(tour => {
      this.tours = tour;
      this.listOfTargetDropListsIds = this.tours.map(tour => uuidv4());
      this.tours.forEach(tour => generateOrderString(tour));
      this.tourDropListsChanged.emit(this.listOfTargetDropListsIds);
    });
  }


  itemDropped(event: CdkDragDrop<any[]>): void {

    if (event.previousContainer === event.container) {
      return;
    }

    const droppedItemData = event && event.item && event.item.data;
    if (droppedItemData.ltsItemType === LtsDropItemType.Order) {
      this.onOrderDropped({ tour: event.container.data, order: droppedItemData.ltsData, event });
      return;
    }
    if (droppedItemData.ltsItemType === LtsDropItemType.TourTemplate) {
      this.onTourDropped({ tour: droppedItemData.ltsData, order: [], event });
      return;
    }
    if (droppedItemData.ltsItemType === LtsDropItemType.Driver) {
      this.onDriverDropped({ driver: droppedItemData.ltsData, order: [], event });
      return;
    }

  }

  driverDropped(event: CdkDragDrop<any[]>): void {

    if (event.previousContainer === event.container) {
      return;
    }

    const droppedItemData = event && event.item && event.item.data;

    if (droppedItemData.ltsItemType === LtsDropItemType.TourTemplate) {
      this.onTourDropped({ tour: droppedItemData.ltsData, order: [], event });
      return;
    }

  }

  private onDriverDropped(ltsDropItemData): void {
    const { driver, order, event } = ltsDropItemData;
    if (event.container.data && event.container.data.driver) {
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure to replace driver?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.addTourDriver(driver, event.container.data);
        }
        this.confirmDialogRef = null;
      });
    } else {
      this.addTourDriver(driver, event.container.data);
    }
  }

  addTourDriver(session, tour) {
    this._driversService.createTourDriver(tour, session).then(() => {
      let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();
      this.getInitialTours(defaultDispatcherTourDate);
      this._matSnackBar.open(`Driver ${session.driverName} added to tour ${tour.name}`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
    });
  }
  private onTourDropped(ltsDropItemData): void {
    const { tour, order, event } = ltsDropItemData;
    //If tour Lat / Long exists then save it or find Lat / Long and save it.
    var promise = new Promise((resolve, reject) => {
      let response;
      if (tour.Longitude && tour.Latitude) {
        response = { "error": false, "lat": tour.Latitude, "long": tour.Longitude };
        resolve(response);
      } else {
        if (tour.baseAddress) {
          this.dispatcherScreenService.geocodeAddress(tour.baseAddress)
            .subscribe((location: Location) => {
              if (location && location[0] && location[0].geometry && location[0].geometry.location) {
                response = { "error": false, "lat": location[0].geometry.location.lat(), "long": location[0].geometry.location.lng() };
                resolve(response);
              } else {
                response = { "error": true };
                resolve(response);
              }
            });
        } else {
          response = { "error": true };
          resolve(response);
        }
      }
    })

    promise.then((response: any) => {
      if (response.error) {
        this._matSnackBar.open(`Tour base address is not valid`, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
        return;
      } else {
        let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();

        const offset = defaultDispatcherTourDate.getTimezoneOffset();
        let defaultTourDate = new Date(defaultDispatcherTourDate.getTime() - (offset * 60 * 1000));
        let defaultTourDateString = defaultTourDate.toISOString().split('T')[0];

        const tourTable = {
          name: tour.name,
          timeOfTheTour: tour.tourTime,
          dateOfCreation: defaultTourDateString + 'T' + tour.tourTime,
          status: 1,
          baseAddress: tour.baseAddress,
          Longitude: response.long, // if this not found then make call google api for lat/long for base address
          Latitude: response.lat,
          PriceItemId: tour.priceItemId,
          ExtraPriceItemId: tour.extraPriceItemId
        };

        this.dispatcherScreenService.createTour(tourTable).subscribe(data => {
          this.getInitialTours(defaultDispatcherTourDate);
        });
      }
    });
  }

  private onOrderDropped(ltsDropItemData): void {

    const { tour, order, event } = ltsDropItemData;
    if (order.numberOfBags <= 0) {
      this._matSnackBar.open(`Order should have at least one bag`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      return;
    }
    if (!order.address) {
      this._matSnackBar.open(`Order address is not defined`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      return;
    }
    //generateOrderString(tour);
    this.orderDroppedToTour.emit({ order, tour });
  }

  public orderDetails(tour): void {

  }

  removeDriverFromTour(_id): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dispatcherScreenService.removeDriverFromTour(_id).subscribe(() => {
          let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();
          this.getInitialTours(defaultDispatcherTourDate);
        });
      }
      this.confirmDialogRef = null;
    });

  }

  removeTour(_id): void {
    this.tourConfirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.tourConfirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    this.tourConfirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dispatcherScreenService.removeTour(_id).subscribe(() => {
          let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();
          this.getInitialTours(defaultDispatcherTourDate);
        });
      }
      this.tourConfirmDialogRef = null;
    });

  }

  changeTourStatus(tour, status): void {
    if (!tour.orders || tour.orders.length <= 0) {
      this._matSnackBar.open(`No orders in tour`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      return;
    }

    if (!tour.driver) {
      this._matSnackBar.open(`No driver assigned in tour`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      return;
    }

    const tourStatusData = {
      "Status": status,
      "Id": tour.id
    };

    this.dispatcherScreenService.changeTourStatus(tourStatusData).subscribe(() => {
      let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();
      this.getInitialTours(defaultDispatcherTourDate);

      /*Update tour distances, priorities */
      this.dispatcherScreenService.getTourDetails(tour.id).then(tourDetailsResponse => {
        this.tourDetails = tourDetailsResponse;

        //Check if any order's priority is NULL
        let isOrderPriorityNull = false;
        for (let i = 0; i < this.tourDetails.tour_Orders.length; i++) {
          if (!this.tourDetails.tour_Orders[i].priority)
            isOrderPriorityNull = true;
        }

        //If atleast one order has priority NULL then find and set priorities for all the orders
        if (isOrderPriorityNull) {
          this.setOptimizedOrderPriorities();
        }
      });
      /*Update tour distances, priorities */
    });
  }

  editOrder(order, tour): void {
    this._dispatcherOrder.editOrder(order, tour);
  }

  setOptimizedOrderPriorities = () => {
    //set priorities null for all the orders
    let orderLatLongs = [];
    for (let i = 0; i < this.tourDetails.tour_Orders.length; i++) {
      orderLatLongs.push({ lat: this.tourDetails.tour_Orders[i].orderInfo.latitude, lng: this.tourDetails.tour_Orders[i].orderInfo.longitude });
      this.tourDetails.tour_Orders[i].priority = null;
      this.tourDetails.tour_Orders[i].kilometers = 0;
    }

    //Find nearest order from tour base address
    this.dispatcherScreenService.distanceForMultipleDestianations({ lat: this.tourDetails.latitude, lng: this.tourDetails.longitude }, orderLatLongs)
      .subscribe((result: any) => {
        if (result && result.rows[0] && result.rows[0].elements) {
          let minDistance = Math.min.apply(Math, result.rows[0].elements.map(function (item) { return (item.distance ? item.distance.value : 9999999999999); }));
          let nearestPointIndex = result.rows[0].elements.findIndex(item => (item.distance ? item.distance.value : 9999999999999) == minDistance);

          //Update priority for order
          this.tourDetails.tour_Orders[nearestPointIndex].priority = 1;
          this.tourDetails.tour_Orders[nearestPointIndex].kilometers = ((minDistance == 9999999999999) ? 0 : (minDistance / 1000));

          //Find and set priorities for nearest orders
          // if (this.tourDetails.tour_Orders.length > 1)
            this.findOrdersPriorities();
        }
      }
      );
  }

  findOrdersPriorities = async () => {
    for (let i = 1; i < this.tourDetails.tour_Orders.length; i++) {
      await this.findOrdersPrioritiesPromise(i);
    }

    //sort orders by priority
    this.tourDetails.tour_Orders.sort((a, b) => {
      return (a.priority ? parseInt(a.priority) : 999999) - (b.priority ? parseInt(b.priority) : 999999)
    });

    //Update order priorities and distance
    this.dispatcherScreenService.updateTourOrderWithPriority(this.tourDetails.tour_Orders).subscribe();
  }

  findOrdersPrioritiesPromise = (index) => {
    return new Promise(resolve => {
      //lat-long of all orders
      let orderLatLongsInternal = [];
      let mapTourOrders = [];
      let nextOrigin;

      //sort orders by priority
      this.tourDetails.tour_Orders.sort((a, b) => {
        return (a.priority ? parseInt(a.priority) : 999999) - (b.priority ? parseInt(b.priority) : 999999)
      });

      for (let i = 0; i < this.tourDetails.tour_Orders.length; i++) {
        if (!this.tourDetails.tour_Orders[i].priority) {
          orderLatLongsInternal.push({ lat: this.tourDetails.tour_Orders[i].orderInfo.latitude, lng: this.tourDetails.tour_Orders[i].orderInfo.longitude });
          mapTourOrders.push(this.tourDetails.tour_Orders[i].id);
        } else {
          nextOrigin = { lat: this.tourDetails.tour_Orders[i].orderInfo.latitude, lng: this.tourDetails.tour_Orders[i].orderInfo.longitude }
        }
      }

      this.dispatcherScreenService.distanceForMultipleDestianations(nextOrigin, orderLatLongsInternal)
        .subscribe((result: any) => {
          if (result && result.rows[0] && result.rows[0].elements) {
            //find minimum distance
            let minDistanceInternal = Math.min.apply(Math, result.rows[0].elements.map(function (item) { return item.distance ? item.distance.value : 9999999999999; }));

            //find index of nearest point
            let nearestPointIndexInternal = result.rows[0].elements.findIndex(item => (item.distance ? item.distance.value : 9999999999999) == minDistanceInternal);

            //find nearest orderId
            let tourOrderId = mapTourOrders[nearestPointIndexInternal];
            let tourOrderIndex = this.tourDetails.tour_Orders.findIndex(item => item.id == tourOrderId);

            //Update priority for order
            this.tourDetails.tour_Orders[tourOrderIndex].priority = (index + 1);
            this.tourDetails.tour_Orders[tourOrderIndex].kilometers = (minDistanceInternal == 9999999999999) ? 0 : (minDistanceInternal / 1000);
            resolve();
          } else {
            resolve();
          }
        }
        );
    });
  }

  /**
       * change tour date-time
       *
       * @param contact
       */
  changeTourDate(tour): void {
    this.dialogRef = this._matDialog.open(TourDateComponent, {
      panelClass: 'tour-date-form-dialog',
      data: {
        tour,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }

        switch (response.actionType) {

          case 'save':
            var localTime = moment(response.formData.dateOfCreation).format("YYYY-MM-DD")
            const updateTourData = {
              TimeOfTheTour: response.formData.timeOfTheTour,
              DateOfCreation: localTime,
              Id: tour.id
            };
            this.dispatcherScreenService.changeTourDateTime(updateTourData).subscribe(() => {
              let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();
              this.getInitialTours(defaultDispatcherTourDate);
            });
            break;
        }
      });
  }

  goToTourDetails(tour): void {
    if (!tour.orders || tour.orders.length <= 0) {
      this._matSnackBar.open(`No orders in tour`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      return;
    }

    if (!tour.driver) {
      this._matSnackBar.open(`No driver assigned in tour`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      return;
    }

    this.router.navigate(['dispatcher/tour/' + tour.id + '/tour-with-orders']);
  }

  openTourStatusPopup(): void {
    this.dialogRef = this._matDialog.open(TourStatusPopupComponent, {
      panelClass: 'tour-status-popup-dialog',
      data: {
        notDeliveredToursWithOrders: this.notDeliveredToursWithOrders,
        unreadTourStatusCount: this.unreadTourStatusCount,
        dateRange: this.tourStatusDateRange,
        sourceComponent: this.sourceComponent,
        action: 'view'
      },
      disableClose: true
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (this.sourceComponent == "dispatcher-tour") {
          this.unreadTourStatusCount = response.unreadTourStatusCount;
          this.notDeliveredToursWithOrders = response.notDeliveredToursWithOrders;
          this.tourStatusDateRange = response.dateRange;
        }
        if (!response) {
          return;
        }
      });
  }
}

function uuidv4() {
  return 'tour_xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function generateOrderString(tour) {
  tour.ordersString = tour.orders.map(order => order.ahlName).join(", ");
}

