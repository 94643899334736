import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MatSort, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { OrderComponent, OrderDialogResult } from './order/order.component';
import { Order } from './order.model';
import { OrderService } from './order/order.service';
import { OrdersService } from './orders.service';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { Mail } from 'app/mail/mail.model';
import { IATA_airline } from '../airlines/airlines.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { isNumber } from 'lodash';
import { ImportSitaComponent } from './import-sita/import-sita.component';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { CustomerSignaturePopupComponent } from './customer-signature-popup/customer-signature-popup.component';
import { AdditinoalInformationComponent } from './additinoal-information/additinoal-information.component';

@Component({
    selector: 'main-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    date = new Date();

    order: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['ahlName', 'iataName', 'numberOfBags', 'address', 'cellPhone01', 'tourName', 'deliveryDate',
        'deliveryTime', 'statusName', 'driverName', 'luggageassigndate', 'luggagepickupdate', 'deliverDate','action'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    form: FormGroup;

    private _unsubscribeAll: Subject<any>;
    constructor(
        private _ordersService: OrdersService,
        private _orderService: OrderService,
        public _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private _fb: FormBuilder,
        private dateAdapter: DateAdapter<Date>,
        private datepipe: DatePipe
    ) {
        this._unsubscribeAll = new Subject();
        this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }


    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._ordersService, this.paginator, this.sort);
        this.sort.disableClear = true;

        let startDate = new Date();
        let endDate = new Date();
        startDate.setMonth(startDate.getMonth() - 3);

        this.form = this._fb.group({
            dateRange: [{ begin: startDate, end: endDate }]
        });


        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onRangeSelected(event) {
        const offset = event.value.begin.getTimezoneOffset();

        let startDateTime = new Date(event.value.begin.getTime() - (offset * 60 * 1000));
        let startDateTimeString = startDateTime.toISOString().split('T')[0];
        startDateTimeString = startDateTimeString + 'T00:00:00.001Z';

        let endDateTime = new Date(event.value.end.getTime() - (offset * 60 * 1000));
        let endDateTimeString = endDateTime.toISOString().split('T')[0];
        endDateTimeString = endDateTimeString + 'T23:59:59.999Z';

        this._ordersService.getOrdersByDates(startDateTimeString, endDateTimeString).then((response)=>{
            // console.log("response", response);
        });
    }


    /**
      * Edit contact
      *
      * @param contact
      */
    editOrder(order): void {
        this.dialogRef = this._matDialog.open(OrderComponent, {
            panelClass: 'order-form-dialog',
            data: {
                order,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: OrderDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                switch (response.actionType) {
                    case 'save':
                        if (response.formData.deliveryDate) {
                            let deliveryDate = new Date(response.formData.deliveryDate);
                            const offset = deliveryDate.getTimezoneOffset();
                            let orderDeliveryDate = new Date(deliveryDate.getTime() - (offset * 60 * 1000));
                            response.formData.deliveryDate = orderDeliveryDate.toISOString().split('T')[0];
                        }

                        this._orderService.saveOrder(response.formData, response.iata_airline).subscribe(() => {
                            let dateRanege = this.form.getRawValue().dateRange;
                            const offset2 = dateRanege.begin.getTimezoneOffset();

                            let startDateTime = new Date(dateRanege.begin.getTime() - (offset2 * 60 * 1000));
                            let startDateTimeString = startDateTime.toISOString().split('T')[0];
                            startDateTimeString = startDateTimeString + 'T00:00:00.001Z';

                            let endDateTime = new Date(dateRanege.end.getTime() - (offset2 * 60 * 1000));
                            let endDateTimeString =     endDateTime.toISOString().split('T')[0];
                            endDateTimeString = endDateTimeString + 'T23:59:59.999Z';

                            this._ordersService.getOrdersByDates(startDateTimeString, endDateTimeString).then();

                            this._matSnackBar.open('Order Updated', 'OK', {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        });
                        break;
                    case 'delete':
                        this.deleteOrder(response.formData);
                        break;
                }
            });
    }

    customerSignature(order): void {
        this.dialogRef = this._matDialog.open(CustomerSignaturePopupComponent, {
            panelClass: 'customer-signature-popup-dialog',
            data: {
                order
            }
        });
    }

    
    additionalInformation(order): void {
        this.dialogRef = this._matDialog.open(AdditinoalInformationComponent, {
            panelClass: 'app-additinoal-information',
            data: {
                order
            }
        });
    }

    ImportSita(): void {
        this.dialogRef = this._matDialog.open(ImportSitaComponent, {
            panelClass: 'import-sita-dialog'
        });
    }

    exportOrders(): void {
        //Filter orders with Status => DELIVERED and NOT DELIVERED
        let exportOrderRecords = this.dataSource.filteredData.filter(item => ['IN_PROGRESS', 'DELIVERED', 'NOT_DELIVERED', 'DELIVERED_WITH_DAMAGE'].includes(item.statusName));

        let csvRowsData = [];
        exportOrderRecords.forEach(element => {
            // let orderStatus = "";
            // switch (element.statusName) {
            //     case 'IN_PROGRESS': {
            //         orderStatus = "UP";
            //         break;
            //     }
            //     case 'DELIVERED': {
            //         orderStatus = "OD";
            //         break;
            //     }
            //     case 'NOT_DELIVERED': {
            //         orderStatus = "UN";
            //         break;
            //     }
            //     case 6: {
            //         orderStatus = "OD";
            //         break;
            //     }
            // }
            csvRowsData.push({
                "orderNumber": element.ahlName,
                "bagNumber": element.numberOfBags,
                "recordType": "",
                "stationCode": element.ahlName.slice(0, 3),
                "airlineCode": element.ahlName.slice(3, 5),
                "recordId": element.ahlName.slice(5),
                "deliveryDate": this.datepipe.transform(element.deliveryDate, 'dd.MM.yyyy'),
                "deliveryTime": element.deliveryTime,
                "status": element.statusName,
                "comments": "",
                "applicationId": "",
                "agentId": element.agent ? element.agent : "",
            });
        });
        if (csvRowsData.length > 0) {
            const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
            const header = Object.keys(csvRowsData[0]);
            let csv = csvRowsData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
            csv.unshift(header.join(','));
            let csvArray = csv.join('\r\n');

            var blob = new Blob([csvArray], { type: 'text/csv' });
            saveAs(blob, "orders.csv");
        } else {
            this._matSnackBar.open('No orders to export!', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        }
    }

    newContact(): void {
        this.dialogRef = this._matDialog.open(OrderComponent, {
            panelClass: 'order-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: OrderDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                this.addOrder(response.formData, response.iata_airline);

            });
    }

    addOrder(order: any, iata_airline: IATA_airline) {

        if (order.deliveryDate) {
            let deliveryDate = new Date(order.deliveryDate);
            const offset = deliveryDate.getTimezoneOffset();
            let orderDeliveryDate = new Date(deliveryDate.getTime() - (offset * 60 * 1000));
            order.deliveryDate = orderDeliveryDate.toISOString().split('T')[0];
        }

        this._orderService.createOrder(order, iata_airline).subscribe(() => {
            let dateRanege = this.form.getRawValue().dateRange;
            const offset2 = dateRanege.begin.getTimezoneOffset();

            let startDateTime = new Date(dateRanege.begin.getTime() - (offset2 * 60 * 1000));
            let startDateTimeString = startDateTime.toISOString().split('T')[0];
            startDateTimeString = startDateTimeString + 'T00:00:00.001Z';

            let endDateTime = new Date(dateRanege.end.getTime() - (offset2 * 60 * 1000));
            let endDateTimeString = endDateTime.toISOString().split('T')[0];
            endDateTimeString = endDateTimeString + 'T23:59:59.999Z';

            this._ordersService.getOrdersByDates(startDateTimeString, endDateTimeString).then();
            this._matSnackBar.open('Order Created', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    saveOrder(order: any, email: Mail, iata_airline: IATA_airline) {
        if (email) {
            order.emailId = email.id;
        }
        if (iata_airline) {
            order.iatA_airlineId = iata_airline.id;
        }

        if (order.deliveryDate) {
            let deliveryDate = new Date(order.deliveryDate);
            const offset = deliveryDate.getTimezoneOffset();
            let orderDeliveryDate = new Date(deliveryDate.getTime() - (offset * 60 * 1000));
            order.deliveryDate = orderDeliveryDate.toISOString().split('T')[0];
        }

        this._orderService.saveOrder(order, iata_airline).subscribe(() => {
            let dateRanege = this.form.getRawValue().dateRange;
            const offset2 = dateRanege.begin.getTimezoneOffset();

            let startDateTime = new Date(dateRanege.begin.getTime() - (offset2 * 60 * 1000));
            let startDateTimeString = startDateTime.toISOString().split('T')[0];
            startDateTimeString = startDateTimeString + 'T00:00:00.001Z';

            let endDateTime = new Date(dateRanege.end.getTime() - (offset2 * 60 * 1000));
            let endDateTimeString = endDateTime.toISOString().split('T')[0];
            endDateTimeString = endDateTimeString + 'T23:59:59.999Z';

            this._ordersService.getOrdersByDates(startDateTimeString, endDateTimeString).then();

            this._matSnackBar.open('Order Updated', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    deleteOrder(order): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this._orderService.deleteOrder(order.id).subscribe(() => {
                let dateRanege = this.form.getRawValue().dateRange;
                const offset = dateRanege.begin.getTimezoneOffset();

                let startDateTime = new Date(dateRanege.begin.getTime() - (offset * 60 * 1000));
                let startDateTimeString = startDateTime.toISOString().split('T')[0];
                startDateTimeString = startDateTimeString + 'T00:00:00.001Z';

                let endDateTime = new Date(dateRanege.end.getTime() - (offset * 60 * 1000));
                let endDateTimeString = endDateTime.toISOString().split('T')[0];
                endDateTimeString = endDateTimeString + 'T23:59:59.999Z';

                this._ordersService.getOrdersByDates(startDateTimeString, endDateTimeString).then();
            });
            this.confirmDialogRef = null;
        });

    }

}
export class FilesDataSource extends DataSource<any> {
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {BeneficiaryService} _beneficiaryService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _ordersService: OrdersService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._ordersService.orders;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._ordersService.onOrdersChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._ordersService.orders.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}


