import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatDatepickerModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ErrorHandlerInterceptor } from './blocks/interceptor/errorhandler.interceptor';
import { AuthInterceptor } from 'app/blocks/interceptor/auth.interceptor';
import { WildcardModule } from 'app/wildcard-routing.module';
import { PagesModule } from './main/pages/pages.module';
import { LtsMainModule } from './main/main.module';

import { AuthenticationGuard } from './blocks/guards/authentication.guards';
import { LtsMailModule } from './mail/mail.module';
import { LtsCalendarModule } from './calendar/calendar.module';
import { LtsMapModule } from './map/map.module';
import { LtsDispatcherModule } from './dispatcher/dispatcher.module';
import { CdkTableModule } from '@angular/cdk/table';
import { MapBoxModule } from '@mapbox/mapbox.module';
import { environment } from 'environments/environment';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        CdkTableModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot([], {useHash: true}),
        TranslateModule.forRoot(),
        MatMomentDateModule,
        MatDatepickerModule,
        MatButtonModule,
        MatIconModule,

        MapBoxModule,

        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        LayoutModule,
        PagesModule,
        LtsMapModule,
        LtsMailModule,
        LtsCalendarModule,
        LtsMainModule,
        LtsDispatcherModule,
        WildcardModule
    ],
    providers: [
        AuthenticationGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        }],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
