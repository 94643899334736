import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { DriverSessionService } from './driver-session.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Vendor } from 'app/main/vendors/vendor.model';
import { startWith, map } from 'rxjs/operators';
import { Location } from '../location.model';

import { DriverSession } from './driver-session.model';
import { isString } from 'lodash';
import { Vehicle } from 'app/main/vehicles/vehicle.model';
import { Driver } from '../drivers.model';

export interface DriverDialogResult {
  actionType: string;
  formData: any;
  vendor: Vendor;
  location: Location;
}
@Component({
  selector: 'driver-session-form-dialog',
  templateUrl: './driver-session.component.html',
  styleUrls: ['./driver-session.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DriverSessionComponent {
  action: string;
  driver: Driver;
  dialogRef: any;
  dialogTitle: string;
  driverSessionForm: FormGroup;
  locationsCtrl = new FormControl();
  filteredLocations: Observable<Location[]>;
  locations: Location[] = [];
  allLocations: Location[];
  vehiclesCtrl = new FormControl();
  filteredVehicles: Observable<Location[]>;
  vehicles: Vehicle[] = [];
  allVehicles: Vehicle[];

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DriverSessionService} _driverSessionService
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<DriverSessionComponent>,
    private _driverSessionService: DriverSessionService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
  ) {
    this.action = _data.action;
    this.driver = _data.driver;
    this.dialogTitle = 'Create Session';
    this.driverSessionForm = this.createDriverSessionForm();
    this._unsubscribeAll = new Subject();
    this.setupAutocompleteDataSourceLocation();
  }

  /**
   * Create driver form
   *
   * @returns {FormGroup}
   */
  createDriverSessionForm(): FormGroup {
    return this._formBuilder.group({
      driverId: [this.driver.id, Validators.required],
      locationId: ['', Validators.required],
      locationsCtrl: [''],
      vehicleId: ['', Validators.required],
      vehiclesCtrl: ['']
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.driverSessionForm.getRawValue()
    });
  }

  locationSelected(locationId): void {
    this.driverSessionForm.patchValue({
      locationId
    });
    if (locationId) {
      this.setupAutocompleteDataSourceVehicles(locationId);
      let selectedLocation = this.allLocations.find(item => item.locationId == locationId);
      this.locationsCtrl.setValue(selectedLocation && selectedLocation.name || '');
    } else {
      this.allVehicles = [];
      this.driverSessionForm.patchValue({
        vehicleId: ''
      });
    }
  }

  private setupAutocompleteDataSourceLocation() {
    this._driverSessionService.getLocations().then(locations => {
      this.allLocations = locations;
      this.filteredLocations = this.locationsCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterLocation(name) : this._clearLocationFilterSelection()));
    });
  }

  public _clearLocationFilterSelection() {
    if (!this.locationsCtrl.value)
      this.locationSelected(null);
    return this.allLocations.slice();
  }

  private _filterLocation(name: string): Location[] {
    if (isString(name)) {
      const filterValue = name.toLowerCase();
      this.locations = this.allLocations.filter(location => location.name.toLowerCase().indexOf(filterValue) === 0);
      return this.locations;
    } else {
      return this.allLocations;
    }
  }

  vehicleSelected(vehicleId): void {
    this.driverSessionForm.patchValue({
      vehicleId
    });
    if (vehicleId) {
      let selectedVehicle = this.allVehicles.find(item => item.id == vehicleId);
      this.vehiclesCtrl.setValue(selectedVehicle && selectedVehicle.name || '');
    }
  }

  private setupAutocompleteDataSourceVehicles(locationId) {
    this._driverSessionService.getVehicles(locationId).then(vehicles => {
      this.allVehicles = vehicles;
      this.filteredVehicles = this.vehiclesCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterVehicle(name) : this._clearVehicleFilterSelection()));
    });
  }

  public _clearVehicleFilterSelection() {
    if (!this.vehiclesCtrl.value)
      this.vehicleSelected(null);
    return this.allVehicles.slice();
  }

  private _filterVehicle(name: string): Vehicle[] {
    if (isString(name)) {
      const filterValue = name.toLowerCase();

      this.vehicles = this.allVehicles.filter(vehicle => vehicle.name.toLowerCase().indexOf(filterValue) === 0);
      return this.vehicles;
    } else {
      return this.allVehicles;
    }
  }
}
