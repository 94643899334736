import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from 'app/main/pages/authentication/login/login.service';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _loginService: LoginService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this._loginService.getUserDetails() && this._loginService.getUserDetails().token;

        if (!authToken) {
            return next.handle(req);
        }

        const cloned = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${authToken}`)
        });

        return next.handle(cloned);
    }

}
