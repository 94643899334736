export const RoleType: {name: string, value: number}[] = [
    {name: "ADMINISTRATOR", value:1},
    {name: "DISPATCHER", value:2},
    {name: "CONTROLLER", value:3},
    {name: "SUPERUSER", value:4},
    {name: "ACCOUNTANT", value:5}
];
export class User{
    name: string;
    lastName: string;
    imagePath: string;
    address: string;
    username: string;
    password: string;
    email: string;
    phoneNumber: string;
    role?: number;
    roleType: number;
    active: boolean;
    ownerId?: string;
    ownerName: string;
    countryId?: string;
    countryName: string;
    id?: number;

    /**
     * Constructor
     * @param user
     */
    constructor(user?){
        user = user || {};
        this.id = user.id || 0;
        this.name = user.name;
        this.lastName = user.lastName;
        this.imagePath = user.imagePath;
        this.address = user.address;
        this.phoneNumber = user.phoneNumber;
        this.username = user.username;
        this.password = user.password;
        this.active = user.active;
        this.email = user.email;
        this.ownerName = user.ownerName;
        this.role = user.RoleType || 0;
        this.roleType = user.role || 0;
        this.ownerId = user.ownerId || null;
        this.countryId = user.countryId || null;
        this.countryName = user.countryName;
    }
}
