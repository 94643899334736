import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { InvoiceRecipient } from './invoice-recipient.model';
import { Country } from '../country/country.model';
import { InvoicingCycle } from '../invoice-cycles/invoice-cycle.model';
import { IATA_airline } from '../airlines/airlines.model';


@Injectable({ providedIn: "root" })
export class InvoiceRecipientsService implements Resolve<InvoiceRecipient[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    invoiceRecipients: InvoiceRecipient[];
    onInvoiceRecipientsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onInvoiceRecipientsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getInvoiceRecipients()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getInvoiceRecipients(): Promise<InvoiceRecipient[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/invoicerecipients')
                .subscribe((response: any) => {
                    this.invoiceRecipients = response;
                    this.onInvoiceRecipientsChanged.next(this.invoiceRecipients);
                    resolve(response);
                }, reject);
        });
    }

    getRecipientDetails(iR_ID: string) : Observable<InvoiceRecipient> {
        const  apiUrl: string = `${environment.urlAddress}/api/invoicerecipients/${iR_ID}`;
        return this.http.get<InvoiceRecipient>(apiUrl);
    }

   createRecipient(recipient: InvoiceRecipient, country: Country, invoiceCycle: InvoicingCycle, iataCodeAirline: IATA_airline) : Observable<InvoiceRecipient> {

    if(country){
        recipient.countryId = country.id;
    }
    if(invoiceCycle){
        recipient.invoicingCycleId = invoiceCycle.id;
    }
    if(recipient.active == null){
        recipient.active = false;
    }
    if(iataCodeAirline){
        recipient.iatA_codeAirlineId = iataCodeAirline.id;
    }
        const apiUrl: string = `${environment.urlAddress}/api/invoicerecipients`;
        return this.http.post<InvoiceRecipient>(apiUrl, recipient);
    }


    deleteRecipient(iR_ID: string) : Observable<InvoiceRecipient>{
        const apiUrl: string = `${environment.urlAddress}/api/invoicerecipients/${iR_ID}`;
        return this.http.delete<InvoiceRecipient>(apiUrl);
    }

    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onInvoiceRecipientsChanged.next(this.selectedContacts);

                return;
            }
        }

        this.selectedContacts.push(id);

        this.onSelectedContactsChanged.next(this.selectedContacts);
    }
}
