import { NgModule, ModuleWithProviders } from '@angular/core';
import { MapBoxService } from "./mapbox.service";
import { MapboxInfoPanel } from "./components/InfoPanel/mapbox-info-panel.component";

@NgModule({
    declarations: [ MapboxInfoPanel ],
    exports: [ MapboxInfoPanel ],
    providers: [
        MapBoxService
    ]
})
export class MapBoxModule { }