import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { TourTable, StatusType } from '../tour.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DriversService } from 'app/main/drivers/drivers.service';
import { startWith, map } from 'rxjs/operators';
import { Driver } from 'app/main/drivers/drivers.model';

export interface TourDialogResult {
  actionType: string;
  formData: any;
  driver: Driver;
}

@Component({
  selector: 'tours-tour-form-dialog',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TourComponent {

  action: string;
  tour: TourTable;
  dialogTitle: string;
  pageType: string;
  statusTypes = StatusType;
  tourForm: FormGroup;
  myControl = new FormControl();
  options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
  filteredOptions: Observable<string[]>;
  
  driverCtrl = new FormControl();

  filteredDrivers: Observable<Driver[]>;
  drivers: Driver[] = [];
  allDrivers: Driver[];

private _unsubscribeAll: Subject<any>;

  /**
  * Constructor
  *
  * @param {FormBuilder} _formBuilder
  * @param {MatSnackBar} _matSnackBar
  */
 constructor(
  @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
  public matDialogRef: MatDialogRef<TourComponent>,
  private _driversService: DriversService,
  private _formBuilder: FormBuilder,
  public _matDialog: MatDialog
)
{
  this.tour = new TourTable(_data.tour);
  this.action = _data.action;
  this.dialogTitle = this.action === 'edit' ? 'Edit Tour' : 'New Tour';
  this.tourForm = this.createTourForm();
  this._unsubscribeAll = new Subject();
  this.setupAutocompleteDataSourceDrivers();

}

createTourForm(): FormGroup
  {
    const selectedEnum = this.statusTypes.find(status => status.value == this.tour.statusName);
      return this._formBuilder.group({
        id                 : [this.tour.id],
        name               : [this.tour.name, [Validators.required]],
        dateOfCreation     : [this.tour.dateOfCreation],
        timeOfTheTour      : [this.tour.timeOfTheTour, [Validators.required]],
        description        : [this.tour.description],
        driverId           : [this.tour.driverId],
        driverCtrl         : [this.tour.driverName],
        status             : [this.tour.statusName  || 2]
      });
    }

    onSaveClick() {
      this.matDialogRef.close({
        actionType: 'save',
        formData: this.tourForm.getRawValue(),
        driver: this.drivers[0]
      });
    }
  
    onAddClick() {
      this.matDialogRef.close({
        actionType: 'add',
        formData: this.tourForm.getRawValue(),
        driver: this.drivers[0]
      });
    }
   
    onDeleteClick (){
       this.matDialogRef.close({
        actionType: 'delete',
        formData: this.tourForm.getRawValue(),
        driver: this.drivers[0]
      }); 
    } 

    private setupAutocompleteDataSourceDrivers() {
      this._driversService.getDrivers().then(drivers => {
        this.allDrivers = drivers;
        this.filteredDrivers = this.driverCtrl.valueChanges.pipe(
          startWith(null),
          map((name: string | null) => name ? this._filterDrivers(name) : this.allDrivers.slice()));
      });
      this.driverCtrl.setValue(this.tour.driverName);
    }

    private _filterDrivers(driverName: string): Driver[] {
      const filterValue = driverName.toLowerCase();
  
      this.drivers = this.allDrivers.filter(driver => driver.firstName.toLowerCase().indexOf(filterValue) === 0);
      return this.drivers;
    }

}
