import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import { FuseUtils } from '@fuse/utils';
import { merge, Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialogRef, MatPaginator, MatSort, MatDialog, MatSnackBar, MatMenuTrigger } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { DispatcherToursService } from './tours.service';
import { DataSource } from '@angular/cdk/table';
import { fuseAnimations } from '@fuse/animations';
import { TourTable } from './tour.model';
import { Router } from '@angular/router';
import { TourComponent } from 'app/main/tours/tour/tour.component';
import { Driver } from 'app/main/drivers/drivers.model';
import { DispatcherScreenService } from '../dispatcher-screen.service';
import { LtsDropItemType } from '../dispatcher-screen.component';
import { CdkDropList, CdkDragExit } from '@angular/cdk/drag-drop';
import { FixTourComponent } from 'app/main/fixed-tours/fix-tour/fix-tour.component';
import { FormGroup } from '@angular/forms';
import { FixedToursService } from 'app/main/fixed-tours/fixed-tours.service';
import { isNumber } from 'lodash';
export interface TourDialogResult {
    actionType: string;
    formData: any;
    driver: Driver;
}

@Component({
    selector: 'dispatcher-tour-templates',
    templateUrl: './tours.component.html',
    styleUrls: ['./tours.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DispatcherToursComponent implements OnInit, OnDestroy {

    @Input() ltsConnectedDroplistsTour: string[];
    @Input() ltsDroplist: string;
    @Input() ltsDropItemType: LtsDropItemType;

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    tour: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['name', 'tourTime'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    @ViewChild(MatMenuTrigger)
    contextMenu: MatMenuTrigger;

    private _unsubscribeAll: Subject<any>;
    constructor(
        private _toursService: DispatcherToursService,
        private _dispatcherService: DispatcherScreenService,
        public _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private router: Router,
        private _fixedToursService: FixedToursService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this._toursService.getTours().then(orders => {
            this.dataSource = new FilesDataSource(this._toursService, this.paginator, this.sort);
            this.sort.disableClear = true;
        });
    }

    contextMenuPosition = { x: '0px', y: '0px' };

    onContextMenu(event: MouseEvent, tour: TourTable) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'tour': tour };
        this.contextMenu.openMenu();
    }

    navigate(tour) {
        //this.router.navigateByUrl(`dispatcher/tour/${tour.id}/tour-screen`);
        this.router.navigateByUrl(`dispatcher/tour/1/tour-screen`);
    }

    addTour(tour: TourTable, driver: Driver) {
        this._toursService.createTour(tour, driver).subscribe(() => {
            this._toursService.getTours();
            this._matSnackBar.open('Tour Created', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    newTour(): void {
        this.dialogRef = this._matDialog.open(FixTourComponent, {
            panelClass: 'tour-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                this._toursService.saveFixedTour(response.getRawValue());

            });


    }

    deleteTour(): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._fixedToursService.deleteFixedTour(this.contextMenu.menuData.tour.id).subscribe( () => {
                    this._toursService.getTours().then(response => {
                        this.dataSource = new FilesDataSource(this._toursService, this.paginator, this.sort);
                        this.sort.disableClear = true;
                    });
                });
            }
            this.confirmDialogRef = null;
        });

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onOrderAddedToTourViaDragAndDrop(event: CdkDragExit): void {

        const droppedItemData = event && event.item && event.item.data;
        if (!droppedItemData) {
            return;
        }

    }

    mustBePlacedInsideTourWidget(drop: CdkDropList): boolean {

        return true;

    }
}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {BeneficiaryService} _beneficiaryService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _toursService: DispatcherToursService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._toursService.tours;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._toursService.onToursChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._toursService.tours.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());
                    
                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}




