export const logType: { name: string, value: number }[] = [
    { name: "Add", value: 1 },
    { name: "Update", value: 2 },
    { name: "Delete", value: 3 }
];

export class Log {
    userName: string;
    description: string;
    createdDate: string;
    id?: number;
    userID: number;
    action: number;
    actionName?: string;

    /**
    * Constructor
    * @param log
    */
    constructor(log?) {
        log = log || {};
        this.id = log.id || 0;
        this.userID = log.userID || 0;
        this.userName = log.userName || '';
        this.createdDate = log.createdDate || '';
        this.description = log.description || '';
    }
}