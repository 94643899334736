import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { ReportsInvoicesTable } from './reports-invoices.model';
import { IATA_airline } from "../airlines/airlines.model";
import { DriverVehicleSession } from "app/dispatcher/dispatcher-screen/drivers/driver-vehicle-session.model";
import { Vendor } from "../vendors/vendor.model";


@Injectable({ providedIn: "root" })
export class ReportsInvoicesService implements Resolve<ReportsInvoicesTable[]> {

    records: any = [];
    onInvoiceReportsChanged: BehaviorSubject<any>;
    constructor(private http: HttpClient) {
        this.onInvoiceReportsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            // let fromDateTimeString = "";
            // fromDateTimeString = new Date().toISOString().split('T')[0];
            // fromDateTimeString = fromDateTimeString + 'T00:00:00.001Z';

            // let toDateTimeString = "";
            // toDateTimeString = new Date().toISOString().split('T')[0];
            // toDateTimeString = toDateTimeString + 'T23:59:59.999Z';

            Promise.all([
                // this.getInvoiceReport({ "StartDate": fromDateTimeString, "EndDate": toDateTimeString })
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getInvoiceReport(searchPayload): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(environment.urlAddress + '/api/report/GetInvoiceReport', searchPayload)
                .subscribe((response: any) => {
                    this.records = response;
                    this.onInvoiceReportsChanged.next(this.records);
                    resolve(response);
                }, reject);
        });
    }

    getTours(): Observable<any> {
        return this.http.get<any>(environment.urlAddress + '/api/dispatcher-screen/tour-templates-for-today');
    }

    getAirlines(): Observable<IATA_airline[]> {
        return this.http.get<IATA_airline[]>(environment.urlAddress + '/api/iata_airlines');
    }

    getDrivers(): Observable<DriverVehicleSession[]> {
        return this.http.get<DriverVehicleSession[]>(environment.urlAddress + '/api/sessions');
    }

    getVendors(): Observable<Vendor[]> {
        return this.http.get<Vendor[]>(environment.urlAddress + '/api/vendors');
    }
}