import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TourDetails } from '../tour-order.model';



@Injectable({ providedIn: "root" })
export class DispatcherTourOrderService implements Resolve<TourDetails>  {

    tourDetails: TourDetails;
    onTourDetailsChanged: BehaviorSubject<any>;
    routeParams: any;

    constructor(private http: HttpClient) {
        this.onTourDetailsChanged = new BehaviorSubject({});
    }

    getTourDetails(id: number): Promise<TourDetails> {
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.urlAddress}/api/tours/${id}/details`)
                .subscribe((response: any) => {
                    this.tourDetails = response;
                    this.onTourDetailsChanged.next(this.tourDetails);
                    resolve(response);
                }, reject);
        });
    }


    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<TourDetails> {
        this.routeParams = route.params;
        return await this.getTourDetails(this.routeParams.id);
    }

}
