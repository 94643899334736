import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { IATA_airline } from '../airlines.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AirlinesService } from '../airlines.service';
import { AirlineService } from './airline.service';
import { FuseUtils } from '@fuse/utils';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'airlines-airline-form-dialog',
    templateUrl: './airline.component.html',
    styleUrls: ['./airline.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AirlineComponent {

    action: string;
    airline: IATA_airline;
    dialogTitle: string;
    pageType: string;
    airlineForm: FormGroup;
    myControl = new FormControl();
    options: { id: number, value: string }[] = [{ id: 1, value: 'One' }, { id: 2, value: 'O444e' }, { id: 3, value: 'O222ne' }];
    filteredOptions: Observable<string[]>;

    priceGroupCtrl = new FormControl();
    filteredPriceGroups: Observable<any>;
    priceGroups: any;
    allPriceGroups: any = [
        {id : "LFK", name : "LFK"},
        {id : "LHGROUP", name : "LHGROUP"},
        {id : "OSHANDLING", name : "OSHANDLING"},
        {id : "VENDOR", name : "VENDOR"}
    ];

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<AirlineComponent>,
        private _airlinesService: AirlinesService,
        private _airlineService: AirlineService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar
    ) {
        this.airline = new IATA_airline(_data.airline);
        this.action = _data.action;
        this.dialogTitle = this.action === 'edit' ? 'Edit Airline' : 'New Airline';
        this.airlineForm = this.createAirlineForm();
        this._unsubscribeAll = new Subject();
        this.setupAutocompleteDataSourcePriceGroup();
    }

    /**
     * Create company form
     *
     * @returns {FormGroup}
     */
    createAirlineForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.airline.id],
            airlineName: [this.airline.airlineName, [Validators.required, Validators.maxLength(40)]],
            iatA_code: [this.airline.iatA_code, [Validators.required, Validators.maxLength(2)]],
            country: [this.airline.country],
            interface: [this.airline.interface],
            costSharing: [this.airline.costSharing],
            priceGroups: [this.airline.priceGroups],
            priceGroupCtrl: [this.airline.priceGroups]
        });
    }

    saveAirline(): void {
        const data = this.airlineForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._airlineService.saveAirline(data)
            .then(() => {

                this._airlineService.onAirlineChanged.next(data);
                this._matSnackBar.open('Airline saved', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    private setupAutocompleteDataSourcePriceGroup() {
        this.filteredPriceGroups = this.priceGroupCtrl.valueChanges.pipe(
            startWith(null),
            map((name: string | null) => name ? this._filterPriceGroup(name) : this._cleaPriceGroupSelection()));
        this.priceGroupCtrl.setValue(this.airline.priceGroups);
    }

    public _cleaPriceGroupSelection() {
        if (!this.priceGroupCtrl.value)
            this.priceGroupSelected(null);
        return this.allPriceGroups.slice();
    }

    private _filterPriceGroup(name: string): any {
        const filterValue = name.toLowerCase();

        this.priceGroups = this.allPriceGroups.filter(priceGroup => priceGroup.name.toLowerCase().indexOf(filterValue) === 0);
        return this.priceGroups;
    }

    priceGroupSelected(priceGroups): void {
        this.airlineForm.patchValue({
            priceGroups
        });
    }

}