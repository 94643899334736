import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { Driver } from '../drivers.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { DriverService } from './driver.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { DriversService } from '../drivers.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { VendorsService } from 'app/main/vendors/vendors.service';
import { Vendor } from 'app/main/vendors/vendor.model';
import { startWith, map } from 'rxjs/operators';
import { Location } from '../location.model';
import { ChangeDriverPasswordDialogResult, DriverPasswordComponent } from '../driver-password/driver-password.component';

export interface DriverDialogResult {
  actionType: string;
  formData: any;
  vendor: Vendor;
  location: Location;
}
@Component({
  selector: 'drivers-driver-form-dialog',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DriverComponent {

  action: string;
  show: boolean = true;
  dialogRef: any;
  driver: Driver;
  dialogTitle: string;
  pageType: string;
  driverForm: FormGroup;
  myControl = new FormControl();
  options: { id: number, value: string }[] = [{ id: 1, value: 'One' }, { id: 2, value: 'O444e' }, { id: 3, value: 'O222ne' }];
  filteredOptions: Observable<string[]>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  vendorsCtrl = new FormControl();

  filteredVendors: Observable<Vendor[]>;
  vendors: Vendor[] = [];
  allVendors: Vendor[];

  locationsCtrl = new FormControl();

  filteredLocations: Observable<Location[]>;
  locations: Location[] = [];
  allLocations: Location[];


  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DriverService} _driverService
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<DriverComponent>,
    private _driverService: DriverService,
    private _driversService: DriversService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private _vendoersService: VendorsService,
    private _matSnackBar: MatSnackBar
  ) {
    this.driver = new Driver(_data.driver);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Driver' : 'New Driver';
    this.driverForm = this.createDriverForm();
    this._unsubscribeAll = new Subject();
    this.setupAutocompleteDataSourceVendor();
    this.setupAutocompleteDataSourceLocation();
  }

  /**
   * Create driver form
   *
   * @returns {FormGroup}
   */
  createDriverForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.driver.id],
      dR_ID: [this.driver.dR_ID],
      firstName: [this.driver.firstName, [Validators.required, Validators.maxLength(140)]],
      lastName: [this.driver.lastName, [Validators.required, Validators.maxLength(140)]],
      arrivalTime: [this.driver.arrivalTime],
      licenseNumber: [this.driver.licenseNumber, [Validators.required]],
      iD_Card: [this.driver.iD_Card],
      phoneNumber: [this.driver.phoneNumber],
      username: [this.driver.username, [Validators.required]],
      password: [this.driver.password, [Validators.required]],
      active: [this.driver.active],
      comment: [this.driver.comment],
      email: [this.driver.email, [Validators.required, Validators.email]],
      vendorId: [this.driver.vendorId],
      locationId: [this.driver.locationId],
      vendorsCtrl: [this.driver.vendorName],
      locationsCtrl: [this.driver.locationName]
    });
  }

  changePassword(driver): void {
    this.dialogRef = this._matDialog.open(DriverPasswordComponent, {
      panelClass: 'change-driver-password-form-dialog',
      data: {
        driver,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: ChangeDriverPasswordDialogResult) => {
        if (!response || !response.formData) {
          return;
        }
        this.saveDriverPassword(response.driver, response.formData.password);
      });

  }

  saveDriverPassword(driver: Driver, password: string) {
    driver.password = password;
    this._driversService.changePassword(driver).then(() => {
      this._matSnackBar.open(`Password updated successfully`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      this.matDialogRef.close();
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.driverForm.getRawValue(),
      vendor: this.vendors[0],
      location: this.locations[0]
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.driverForm.getRawValue(),
      vendor: this.vendors[0],
      location: this.locations[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.driverForm.getRawValue(),
      vendor: this.vendors[0],
      location: this.locations[0]
    });
  }

  private setupAutocompleteDataSourceVendor() {
    this._vendoersService.getVendors().then(vendors => {
      this.allVendors = vendors;
      this.filteredVendors = this.vendorsCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterVendor(name) : this.allVendors.slice()));
    });
    this.vendorsCtrl.setValue(this.driver.vendorName);
  }

  private _filterVendor(vendorName: string): Vendor[] {
    const filterValue = vendorName.toLowerCase();

    this.vendors = this.allVendors.filter(vendor => vendor.companyName.toLowerCase().indexOf(filterValue) === 0);
    return this.vendors;
  }

  private setupAutocompleteDataSourceLocation() {
    this._driversService.getLocations().then(locations => {
      this.allLocations = locations;
      this.filteredLocations = this.locationsCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterLocation(name) : this.allLocations.slice()));
    });
    this.locationsCtrl.setValue(this.driver.locationName);
  }

  private _filterLocation(name: string): Location[] {
    const filterValue = name.toLowerCase();

    this.locations = this.allLocations.filter(location => location.name.toLowerCase().indexOf(filterValue) === 0);
    return this.locations;
  }
}
