import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Error404Component } from 'app/main/pages/errors/404/error-404.component';


/**
 * This is a centralized wildcard route, i.e. for a site-wide 'Page not found' component. 
 * It had to be extracted into a separate routing module, which gets included after all the other modules containing child routes.
 * Therefore, the wildcard route is truly in last position and doesn't hide the routes from any other module.
 */
@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '**',
                component: Error404Component
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class WildcardModule { }
