export class Country{
    name: string;
    id?: string;

    /**
     * Constructor
     * @param country
     */
    constructor(country?){
        country = country || {};
        this.id = country.id || 0;
        this.name = country.name || '';
    }
}