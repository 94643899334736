
export const StatusType: {name: string, value: number}[] = [
    {name: "ASSIGNE", value:1},
    {name: "UNASSIGNED", value:2},
    {name: "IN PROGRESS", value:4}
];
export class TourTable{
    name: string;
    dateOfCreation: Date;
    timeOfTheTour: Date;
    numberOfOrders?: number;
    description: string;
    status?: number;
    statusName: number;
    driverId?: number;
    driverName: string;
    id?: string;
    priceItemId?: number;
    extraPriceItemId?: number

    /**
     * Constructor
     * @param tour
     */
    constructor(tour?){
        tour = tour || {};
        this.id = tour.id || 0;
        this.name = tour.name || '';
        this.dateOfCreation = tour.dateOfCreation || Date.now;
        this.timeOfTheTour = tour.timeOfTheTour;
        this.description = tour.description || '';
        this.status = tour.StatusType || 2;
        this.statusName = tour.status;
        this.numberOfOrders = tour.numberOfOrders || 0;
        this.driverId = tour.driverId || null;
        this.driverName = tour.driverName;
        this.priceItemId= tour.priceItemId || 0;
        this.extraPriceItemId= tour.extraPriceItemId || 0;
    }
}