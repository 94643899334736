import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {


    constructor(private router: Router, private _matSnackBar: MatSnackBar) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).do(() => { }, (err: any) => {
            if (err instanceof HttpErrorResponse && navigator.onLine) {
                if (err.status === 401 && request.url.indexOf(`auth/login`) !== -1) {
                    return;
                }

                if (err.status === 400) {
                    if (err.error && err.error.Message) {
                        this._matSnackBar.open(err.error.Message, 'OK', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                    return;
                }

                if (err.status === 401) {
                    this.router.navigate(['auth/login']);
                    return;
                }

                if (err.status === 403) {
                    this.router.navigate(['error/forbidden']);
                    return;
                }

                if (err.status === 404) {
                    this.router.navigate(['error/not-found']);
                    return;
                }


                this.router.navigate(['error/server-error']);
                return;
            }

        });
    }


}
