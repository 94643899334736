import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { VendorsService } from '../vendors.service';


@Injectable({ providedIn: "root" })
export class VendorService implements Resolve<any>
{
    routeParams: any;
    vendor: any;
    onVendorChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _vendorsService: VendorsService
    )
    {
        this.onVendorChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getVendor()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getVendor(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onVendorChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/vendors/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.vendor = response;
                    this.onVendorChanged.next(this.vendor);
                    resolve(response);
                }, reject);
 
        });
    }

    /**
     * Save vendor
     *
     * @param vendor
     * @returns {Promise<any>}
     */
    saveVendor(vendor, country): Promise<any>
    {
        if(country){
            vendor.countryId = country.id;
        }
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/vendors/' + vendor.vE_ID, vendor)
                .subscribe((response: any) => {
                    resolve(response);
                    this._vendorsService.getVendors();
                }, reject);
        });
    }

   
}