import { Component, OnInit } from '@angular/core';
import { DispatcherToursService } from '../tours.service';
import { TourDetails } from './tour-order.model';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'dispatcher-tour-screen',
  templateUrl: './tour-screen.component.html',
  styleUrls: ['./tour-screen.component.scss']
})
export class TourScreenComponent implements OnInit {

  tourDetails: TourDetails;
  tourDetailsForm: any;

  constructor(private toursService: DispatcherToursService, private _formBuilder: FormBuilder) { }

  ngOnInit() {
      
  }

}