export class Zone{
    name: string;
    zoneId: string;
    description: string;
    id?: number;

     /**
     * Constructor
     * @param zone
     */
    constructor(zone?){
        zone = zone || {};
        this.id = zone.id || 0;
        this.name = zone.name || '';
        this.zoneId = zone.zoneId || '';
        this.description = zone.description || '';
    }
}