import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatAutocompleteModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import {MatToolbarModule} from '@angular/material/toolbar';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { DistrictsComponent } from './districts.component';
import { DistrictsService } from './districts.service';
import { DistrictComponent } from './district/district.component';
import { DistrictService } from './district/district.service';

const routes = [
    {
        path: '',
        component: DistrictsComponent,
        resolve: {
            data: DistrictsComponent
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'districts',
        component: DistrictsComponent,
        resolve: {
            data: DistrictsService
        },
        canActivate: [AuthenticationGuard]
    },
     {
        path: 'districts/:id',
        component: DistrictComponent,
        resolve: {
            data: DistrictService
        },
        canActivate: [AuthenticationGuard]
    } 
];

@NgModule({
    entryComponents: [
    ],
    declarations: [
        DistrictsComponent,
        DistrictComponent
        //VehicleComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatToolbarModule,
        MatDialogModule,
        MatAutocompleteModule,

        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    exports: [
        DistrictsComponent,
        DistrictComponent
    ],
    providers:[
        DistrictsComponent,
        DistrictService
    ]
})

export class DistrictsModule {
}
