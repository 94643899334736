import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { OrdersService } from '../orders.service';
import { Order } from '../order.model';
import { IATA_airline } from 'app/main/airlines/airlines.model';


@Injectable({ providedIn: "root" })
export class OrderService implements Resolve<any>
{
    routeParams: any;
    order: any;
    onOrderChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _ordersService: OrdersService
    )
    {
        this.onOrderChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getOrder()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getOrder(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onOrderChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/orders/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.order = response;
                    this.onOrderChanged.next(this.order);
                    resolve(response);
                }, reject);
 
        });
    }
    getSITAOrder(): Promise<any>
    {
        return new Promise((resolve, reject) => { 

            this._httpClient.get(environment.urlAddress + '/api/orders/FetchSitaOrder')
            .subscribe((response: any) => {
               resolve(response);
            }, reject);
        });
    }

    getOrderHistoryById(id:bigint): Promise<any> {
        return new Promise((resolve, reject) => {
            const apiUrl = environment.urlAddress + '/api/orderhistory/' + id;
            this._httpClient.get(apiUrl)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    createOrder(order: Order, iata_airline:IATA_airline) : Observable<Order> {
        if(iata_airline){
            order.iatA_airlineId = iata_airline.id;
        }
        const apiUrl: string = `${environment.urlAddress}/api/orders`;
        return this._httpClient.post<Order>(apiUrl, order);
    }

    saveOrder(order, iata_airline): Observable<any>
    {
        if(iata_airline){
            order.iatA_airlineId = iata_airline.id;
        }
        return this._httpClient.put(environment.urlAddress + '/api/orders/' + order.id, order);
    }
    
    deleteOrder(id: string) : Observable<Order>{
        const apiUrl: string = `${environment.urlAddress}/api/orders/${id}`;
        return this._httpClient.delete<Order>(apiUrl);
    }

}