import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, TemplateRef, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { EmployeeRoleService } from './employee-role.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { isNumber } from 'lodash';

@Component({
    selector: 'main-employee-role',
    templateUrl: './employee-role.component.html',
    styleUrls: ['./employee-role.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class EmployeeRoleComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    user: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['name', 'lastName', 'address', 'country', 'username', 'email', 'phoneNumber', 'ownerName', 'active'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UserService} _userService
     */
    constructor(
        private _usersService: EmployeeRoleService,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._usersService, this.paginator, this.sort);
        this.sort.disableClear = true;
        
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _usersService: EmployeeRoleService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._usersService.users;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._usersService.onUsersChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._usersService.users.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}