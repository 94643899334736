import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { BeneficiariesService } from '../beneficiaries.service';
import { Beneficiary } from '../beneficiary.model';
import { InvoiceRecipient } from 'app/main/invoice-recipients/invoice-recipient.model';
import { Country } from 'app/main/country/country.model';
import { InvoicingCycle } from 'app/main/invoice-cycles/invoice-cycle.model';
import { IATA_airline } from 'app/main/airlines/airlines.model';


@Injectable({ providedIn: "root" })
export class BeneficiaryService implements Resolve<any>
{
    routeParams: any;
    beneficiary: any;
    onBeneficiaryChanged: BehaviorSubject<any>;
    list: string[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _beneficiariesService: BeneficiariesService
    )
    {
        this.onBeneficiaryChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getBeneficiary()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getBeneficiary(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onBeneficiaryChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/beneficiaries/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.beneficiary = response;
                    this.onBeneficiaryChanged.next(this.beneficiary);
                    resolve(response);
                }, reject);
 
        });
    }

    createBeneficiary(beneficary: Beneficiary, invoiceRecipient: InvoiceRecipient, iata: IATA_airline,
        invoicingCycle: InvoicingCycle, country: Country) : Observable<Beneficiary> {
        if(invoiceRecipient) {
            this.list = invoiceRecipient.iR_ID.split('_');
            beneficary.iR_ID = this.list[1];
        }
        if(iata){
            beneficary.iatA_airlineId = iata.id;
        }
        if(invoicingCycle){
            beneficary.invoicingCycleId = invoicingCycle.id;
        }
        if(country){
            beneficary.countryId = country.id;
        }
        if(beneficary.active == null){
            beneficary.active = false;
        }
        const apiUrl: string = `${environment.urlAddress}/api/beneficiaries`;
        return this._httpClient.post<Beneficiary>(apiUrl, beneficary);
    }
    
    saveBeneficiary(beneficary, invoiceRecipient, iata, invoicingCycle, country): Promise<any>
    {
        if(invoiceRecipient){
            this.list = invoiceRecipient.iR_ID.split('_');
            beneficary.iR_ID = this.list[1];
        }
        if(iata){
            beneficary.iatA_airlineId = iata.id;
        }
        if(invoicingCycle){
            beneficary.invoicingCycleId = invoicingCycle.id;
        }
        if(country){
            beneficary.countryId = country.id;
        }
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/beneficiaries/' + beneficary.bE_ID, beneficary)
                .subscribe((response: any) => {
                    resolve(response);
                    this._beneficiariesService.getBeneficiaries();
                }, reject);
        });
    }

    deleteBeneficiary(id: string) : Observable<Beneficiary>{
        const apiUrl: string = `${environment.urlAddress}/api/beneficiaries/${id}`;
        return this._httpClient.delete<Beneficiary>(apiUrl);
    }
   
   
}