export class Vehicle{
    name: string;
    luggageCapacity: string;
    licensePlateNumber: string;
    model: string;
    maxPayload: string;
    description: string;
    parkingNumber: string;
    vendorId?: string;
    vendorName: string;
    locationId?: number;
    locationName: string;
    vehicleTypeId?: number;
    vehicleTypeName: string;
    id?: string;

    /**
     * Constructor
     * @param vehicle
     */
    constructor(vehicle?){
        vehicle = vehicle || {};
        this.id = vehicle.id || 0;
        this.name = vehicle.name || '';
        this.luggageCapacity = vehicle.luggageCapacity || '';
        this.licensePlateNumber = vehicle.licensePlateNumber || '';
        this.parkingNumber = vehicle.parkingNumber || '';
        this.model = vehicle.model || '';
        this.maxPayload = vehicle.maxPayload || '';
        this.description = vehicle.description || '';
        this.vendorId = vehicle.vendorId;
        this.vendorName = vehicle.vendorName;
        this.locationId = vehicle.locationId || null;
        this.locationName = vehicle.locationName;
        this.vehicleTypeId = vehicle.vehicleTypeId || null;
        this.vehicleTypeName = vehicle.vehicleTypeName;
    }
}