import * as mapboxgl from "mapbox-gl";

export class Route {
    public coordinates: mapboxgl.LngLatLike[];
    public waypoints: mapboxgl.LngLatLike[];
    public duration: number;
    public distance: number;

    constructor(coordinates: mapboxgl.LngLatLike[], waypoints: mapboxgl.LngLatLike[], duration: number, distance: number) {
        this.coordinates = coordinates;
        this.waypoints = waypoints;
        this.duration = duration;
        this.distance = distance;
    }
}