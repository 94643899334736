export const locale = {
    lang: 'de',
    data: {

        /* LEFT MENU navigation section */

        'NAV': {
            'APPLICATIONS': '',
            'COMPANIES' : 'Kompanie',
            'VENDOR' : 'Verkäufer',
            'PROFILE' : 'Firmenliste',
            'VEHICLES.TITLE' : 'Fahrzeuge',
            'DRIVERS.TITLE' : 'Autofahrer',
            'ENTITIES' : 'Entitäten',
            'INVOICE-RECIPIENTS.TITLE' : 'Rechnungsempfänger',
            'BENEFICIARIES.TITLE' : 'Begünstigte',
            'VENDORS.TITLE' : 'Verkäufer',
            'SERVICES' : 'Dienstleistungen',
            'ORDERS.TITLE' : 'Bestellung',
            'TOURS.TITLE' : 'Touren',
            'FIXED-TOURS.TITLE' : 'Feste Touren',
            'SERVICE-TYPES.TITLE' : 'Servicetypen',
            'INVOICING' : 'Fakturierung',
            'INVOICE-CYCLE.TITLE' : 'Rechnungszyklus',
            'CLIENTDATA' : 'Kundendaten',
            'AIRLINES.TITLE' : 'Airlines',
            'AIRPORTS.TITLE' : 'Flughäfen',
            'VEHICLE-TYPE.TITLE' : 'Fahrzeugtyp',
            'ZONES.TITLE' : 'Zonen',
            'LOGS.TITLE' : 'Logs',
            'COUNTRY.TITLE' : 'Land',
            'USERMANAGEMENT' : 'Benutzerverwaltung',
            'USERS.TITLE' : 'Benutzer',
        },

        /*  COMPANY - OWNER component */

        'OWNER': {

            /* HEADER SECTION */

            'COMPANIES.TITLE' : 'Firmenliste',
            'SEARCH.BENEFICIARY.TITLE' : 'Suche nach Begünstigten',
            'ADD.NEW.COMPANY' : 'NEUE FIRMA HINZUFÜGEN',

            /* TABLE SECTION */

            'TABLE.COMPANY' : 'Kompanie',
            'TABLE.ADDRESS' : 'Adresse',
            'TABLE.COUNTRY' : 'Land',
            'TABLE.ZIP' : 'Postleitzahl',
            'TABLE.CITY' : 'Stadt',
            'TABLE.EMAIL' : 'Email',
            'TABLE.PHONE' : 'Telefonnummer',

            /* MODALS SECTION */

            'FORM.COMPANY.NAME' : 'Firmenname',
            'FORM.COMPANY.EMAIL' : 'Email',
            'FORM.COMPANY.ADDRESS' : 'Adresse',
            'FORM.COMPANY.CITY' : 'Stadt',
            'FORM.COMPANY.ZIP.CODE' : 'Postleitzahl',
            'FORM.COMPANY.COUNTRY' : 'Land',
            'FORM.COMPANY.ASSIGN.COUNTRY' : 'Land zuweisen',
            'FORM.COMPANY.PHONE.NUMBER' : 'Telefonnummer',
            'FORM.COMPANY.FAX' : 'Fax',
            'FORM.COMPANY.WEBSITE' : 'Webseite',
            'FORM.COMPANY.REGISTER.NUMBER' : 'Registriernummer',
            'FORM.COMPANY.VAT' : 'MwSt',
            'FORM.COMPANY.BANK' : 'Bank',
            'FORM.COMPANY.IBAN' : 'IBAN',
            'FORM.COMPANY.BIC' : 'BIC',
            'FORM.COMPANY.NOTES' : 'Anmerkungen',
        }
    }
};
