import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { VendorDraftService } from './vendor-draft.service';
import { fuseAnimations } from '@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'vendor-draft-dialog',
  templateUrl: './vendor-draft.component.html',
  styleUrls: ['./vendor-draft.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class VendorDraftComponent {

  action: string;
  dialogTitle: string;
  vendorDraftFindOrdersForm: FormGroup;
  dataSource: any;
  dataSourceOrder: any;
  displayedColumns = ['select', 'beirveid', 'driverName', 'companyName'];
  detailFlag = false;
  displayedColumnsOrder = ['select', 'ahlName', 'tourName', 'deliveryDate', 'numberOfBags'];
  selection = new SelectionModel<any>(true, []);
  loader = false;
  vendorOrdersDetails: any;
  vendorOrdersSelected: any;
  filteredOrders: any;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<VendorDraftComponent>,
    private _vendorDraftService: VendorDraftService,
    private _formBuilder: FormBuilder
  ) {
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Draft' : 'Create Draft';
    this._unsubscribeAll = new Subject();

    this.vendorDraftFindOrdersForm = this._formBuilder.group({
      //invoicingDate: new FormControl(),
      dateFrom: [new FormControl(), [Validators.required]],
      dateTo: [new FormControl(), [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  findOrders(): void {
    /* Reset flag / selected values */
    this.loader = true;
    this.detailFlag = false;
    this.selection.clear();
    this.vendorOrdersDetails = [];
    /* Reset flag / selected values */

    let fromDate = new Date(this.vendorDraftFindOrdersForm.get('dateFrom').value);
    const offset = fromDate.getTimezoneOffset();
    fromDate = new Date(fromDate.getTime() - (offset * 60 * 1000));
    sessionStorage.setItem('vendor-draft-from-date', fromDate.toISOString().split('T')[0]);

    let toDate = new Date(this.vendorDraftFindOrdersForm.get('dateTo').value);
    toDate = new Date(toDate.getTime() - (offset * 60 * 1000));
    sessionStorage.setItem('vendor-draft-to-date', toDate.toISOString().split('T')[0]);

    this._vendorDraftService.getBeneficiaries(fromDate.toISOString().split('T')[0], toDate.toISOString().split('T')[0]).then((response) => {
      this.dataSource = new MatTableDataSource(response);
      this.loader = false;
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.fetchVendorOrders();
  }

  beneficiarySelection(): void {
    this.fetchVendorOrders();
  }

  fetchVendorOrders(): void {
    this.loader = true;
    if (this.selection.selected.length > 0) {
      let beIds = this.selection.selected.map(a => a.beirveid).join(',');
      this._vendorDraftService.getOrders(beIds, sessionStorage.getItem('vendor-draft-from-date'), sessionStorage.getItem('vendor-draft-to-date')).then((response) => {
        this.vendorOrdersDetails = response;

        //By default select first belst and its orders
        this.vendorOrdersSelected = this.vendorOrdersDetails && this.vendorOrdersDetails.driverInfo ? this.vendorOrdersDetails.driverInfo[0] : [];

        if (this.vendorOrdersSelected) {
          this.filteredOrders = this.vendorOrdersDetails.ordersInfo.filter(function (item) {
            return item.beirveid == this.vendorOrdersSelected.beirveid;
          }, this);

          this.dataSourceOrder = new MatTableDataSource(this.filteredOrders);
        }

        this.loader = false;
        this.detailFlag = this.vendorOrdersDetails && this.vendorOrdersDetails.driverInfo ? true : false;
      });
    } else {
      this.vendorOrdersDetails = [];
      this.loader = false;
      this.detailFlag = false;
    }
  }

  changeBlest(event): void {
    if (event.target.value) {
      this.vendorOrdersSelected = this.vendorOrdersDetails.driverInfo.find(item => item.beirveid == event.target.value);

      if (this.vendorOrdersSelected) {
        this.filteredOrders = this.vendorOrdersDetails.ordersInfo.filter(function (item) {
          return item.beirveid == this.vendorOrdersSelected.beirveid;
        }, this);

        this.dataSourceOrder = new MatTableDataSource(this.filteredOrders);
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isOrderAllSelected() {
    const numSelected = this.filteredOrders.filter(function (item) {
      return item.isSelected == true;
    }).length;
    const numRows = this.filteredOrders.length;
    return numSelected === numRows;
  }

  isIndeterminate() {
    const numSelected = this.filteredOrders.filter(function (item) {
      return item.isSelected == true;
    }).length;
    return numSelected ? true : false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleOrder() {
    if (this.isOrderAllSelected()) {
      this.filteredOrders.map(item => {
        item.isSelected = false;
        return item;
      });
    } else {
      this.filteredOrders.map(item => {
        item.isSelected = true;
        return item;
      });
    }
  }
  
  visibleCreateDraft() {
    const orderSelected = (this.vendorOrdersDetails && this.vendorOrdersDetails.ordersInfo && this.vendorOrdersDetails.ordersInfo.filter(function (item) {
      return item.isSelected == true;
    })) || [];
    return orderSelected.length ? true : false;
  }

  createDraft(): void {
    const draftOrders = this.vendorOrdersDetails.ordersInfo.filter(function (item) {
      return item.isSelected == true;
    });
    
    this.matDialogRef.close({
      actionType: 'new',
      orders: draftOrders
    });
  }
}
