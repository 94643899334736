import { Feature, GeoJsonProperties } from "geojson";
import { Geometry } from "./Geometry";

export class MapboxFeature implements Feature {
    public type: "Feature"= "Feature";
    public geometry: Geometry;
    public properties: GeoJsonProperties;

    constructor(geometry: Geometry) {
        this.geometry = geometry;
    }
} 