import { Component, OnInit, Optional, Inject } from '@angular/core';
import { User } from 'app/main/user-management/users/users.model';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { Owner } from 'app/main/owners/owner.model';
import { Country } from 'app/main/country/country.model';

export interface ChangeUserDetailsDialogResult {
  actionType: string;
  formData: any;
  user: User;
  owner: Owner;
  country: Country;
}

@Component({
  selector: 'user-details-form-dialog',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent {

  user: User;
  dialogRef: any;
  ownersCtrl = new FormControl();

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1.value == f2.value && f1.viewValue == f2.viewValue;
  }
  // ////////////////////////////////////
  action: string;
  dialogTitle: string;
  pageType: string;
  userForm: FormGroup;

  //Private
  private _unsubscribeAll: Subject<any>;

  /**
    * Constructor
    *
    * @param {FormBuilder} _formBuilder
    * @param {Location} _location
    * @param {MatSnackBar} _matSnackBar
    */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<UserDetailsComponent>,
    private _formBuilder: FormBuilder
  ) {


      this.user = _data.user;
      // Set the defaults
      this.action = _data.action;

      this.dialogTitle = 'Edit User Profile';

      this.userForm = this.createUserForm();
  }

  /**
     * Create user form
     *
     * @returns {FormGroup}
     */
  createUserForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.user.id],
      name: [this.user.name],
      lastName: [this.user.lastName],
      address: [this.user.address],
      username: [this.user.username],
      email: [this.user.email],
      phoneNumber: [this.user.phoneNumber] 
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.userForm.getRawValue()
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.userForm.getRawValue()
    });
  }

  onDeleteClick (){
    this.matDialogRef.close({
     actionType: 'delete',
     formData: this.userForm.getRawValue()
   }); 
 }

}
