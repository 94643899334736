export class ServiceType{
    name: string;
    active: boolean;
    id?: string;

    /**
     * Constructor
     * @param owner
     */
    constructor(serviceType?){
        serviceType = serviceType || {};
        this.id = serviceType.id || 0;
        this.name = serviceType.name || '';
        this.active = serviceType.active || false;
    }
}