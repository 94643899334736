import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { ToursComponent } from './tours.component';
import { ToursService } from './tours.service';
import { TourComponent } from './tour/tour.component';
import { TourService } from './tour/tour.service';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

const routes = [
    {
        path: '',
        component: ToursComponent,
        resolve: {
            data: ToursService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'tours',
        component: ToursComponent,
        resolve: {
            data: ToursService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'tours/:id',
        component: TourComponent,
        resolve: {
            data: TourService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        ToursComponent,
        TourComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatDatepickerModule,
        SatDatepickerModule, 
        SatNativeDateModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatDatepickerModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCheckboxModule, 
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatCheckboxModule
    ],
    providers: [
        ToursService
    ],
    exports: [
        ToursComponent, 
        TourComponent
    ]
})

export class TourModule {
}
