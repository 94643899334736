import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { VehiclesService } from '../vehicles.service';
import { Vehicle } from '../vehicle.model';
import { Vendor } from 'app/main/vendors/vendor.model';
import { Location } from './locaton.model';
import { VehicleType } from 'app/main/vehicle-types/vehicle-type.model';

@Injectable({ providedIn: "root" })
export class VehicleService implements Resolve<any>
{
    routeParams: any;
    vehicle: any;
    onVehicleChanged: BehaviorSubject<any>;
    list: string[];
     /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _vehiclesService: VehiclesService
    )
    {
        this.onVehicleChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getVehicle()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

     /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getVehicle(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onVehicleChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/vehicles/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.vehicle = response;
                    this.onVehicleChanged.next(this.vehicle);
                    resolve(response);
                }, reject);
 
        });
    }

    createVehicle(vehicle: Vehicle, vendor: Vendor, location: Location, vehicleType: VehicleType) : Observable<Vehicle> {
        if(vendor) {
            this.list = vendor.vE_ID.split('_');
            vehicle.vendorId = this.list[1];
        }
        if(location){
            vehicle.locationId = location.locationId;
        }
        if(vehicleType){
            vehicle.vehicleTypeId = vehicleType.id;
        }
        const apiUrl: string = `${environment.urlAddress}/api/vehicles`;
        return this._httpClient.post<Vehicle>(apiUrl, vehicle);
    }

      /**
     * Save owner
     *
     * @param vehicle
     * @returns {Promise<any>}
     */
    saveVehicle(vehicle, vendor, location, vehicleType): Promise<any>
    {
        if(vendor){
            this.list = vendor.vE_ID.split('_');
            vehicle.vendorId = this.list[1];
        }
        if(location){
            vehicle.locationId = location.locationId;
        }
        if(vehicleType){
            vehicle.vehicleTypeId = vehicleType.id;
        }
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/vehicles/' + vehicle.id, vehicle)
                .subscribe((response: any) => {
                    resolve(response);
                    this._vehiclesService.getVehicles();
                }, reject);
        });
    }
    
    deleteVehicle(id: number) : Observable<Vehicle>{
        const apiUrl: string = `${environment.urlAddress}/api/vehicles/${id}`;
        return this._httpClient.delete<Vehicle>(apiUrl);
    }
}