import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { InvoicingCycle } from '../invoice-cycle.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { InvoiceCycleService } from './invoice-cycle.service';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'invoice-cycles-invoice-cycle-form-dialog',
  templateUrl: './invoice-cycle.component.html',
  styleUrls: ['./invoice-cycle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceCycleComponent {

  action: string;
  cycle: InvoicingCycle;
  dialogTitle: string;
  pageType: string;
  cycleForm: FormGroup;
  myControl = new FormControl();
  options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
  filteredOptions: Observable<string[]>;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
      @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
      public matDialogRef: MatDialogRef<InvoiceCycleComponent>,
      private _cycleService: InvoiceCycleService,
      private _formBuilder: FormBuilder,
      private _matSnackBar: MatSnackBar
  )
  {
      this.cycle = new InvoicingCycle(_data.cycle);
      this.action = _data.action;
      this.dialogTitle = this.action === 'edit' ? 'Edit Invoice Cycle' : 'New Invoice Cycle';
      this.cycleForm = this.createCycleForm();
      this._unsubscribeAll = new Subject();
  }

  /**
   * Create company form
   *
   * @returns {FormGroup}
   */
  createCycleForm(): FormGroup
  {
      return this._formBuilder.group({
          id              : [this.cycle.id],
          name            : [this.cycle.name, [Validators.required, Validators.maxLength(40)]],
      });
  }

  saveCycle(): void
  {
      const data = this.cycleForm.getRawValue();
      data.handle = FuseUtils.handleize(data.name);

      this._cycleService.saveCycle(data)
          .then(() => {
              this._cycleService.onCycleChanged.next(data);

              this._matSnackBar.open('Invoice Cycle saved', 'OK', {
                  verticalPosition: 'top',
                  duration        : 2000
              });
          });
  }

}
