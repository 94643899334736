import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DispatcherToursService } from '../tours.service';

@Injectable()
export class DispatcherTourScreenService implements Resolve<any> {
    routeParams: any;

    constructor(private toursService: DispatcherToursService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return this.toursService.getTourDetails(+this.routeParams.id);
    } 
}
