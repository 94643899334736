import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, fromEvent, merge, Observable, BehaviorSubject } from 'rxjs';
import { ReportsInvoicesService } from './reports-invoices.service';
import { takeUntil, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { DataSource } from '@angular/cdk/table';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumber } from 'lodash';
import * as moment from 'moment';
import { TourTable } from '../tours/tour.model';
import { IATA_airline } from '../airlines/airlines.model';
import { DriverVehicleSession } from 'app/dispatcher/dispatcher-screen/drivers/driver-vehicle-session.model';
import { Vendor } from '../vendors/vendor.model';
import { SitaPopupComponent } from './sita-popup/sita-popup.component';
import { PricePopupComponent } from './price-popup/price-popup.component';
import { saveAs } from 'file-saver';

@Component({
    selector: 'main-reports-invoices',
    templateUrl: './reports-invoices.component.html',
    styleUrls: ['./reports-invoices.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})

export class ReportsInvoicesComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    dataSource: FilesDataSource | null;
    displayedColumns = ['invoiceStatus', 'dateOfCreation', 'tourName', 'timeOfTheTour', 'ahlName', 'airlineName', 'vendor', 'driverName', 'deliveryAddress', 'zipCode', 'city', 'numberOfBags', 'passengerName', 'telephone', 'emailId', 'netInvoicePrice', 'invoiceTax', 'totalInvoiceProice', 'statusTypeName', 'action'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    reportsInvoicesSearchForm: FormGroup;
    findReportsInvoices: boolean;
    tours: TourTable[] = [];
    filteredTours: Observable<TourTable[]>;
    airlines: IATA_airline[] = [];
    filteredAirlines: Observable<IATA_airline[]>;
    vendors: any[] = [];
    filteredVendors: Observable<any>;
    drivers: any[] = [];
    filteredDrivers: Observable<any>;
    tourNameCtrl = new FormControl();
    airlineIdCtrl = new FormControl();
    vendorNameCtrl = new FormControl();
    driverIdCtrl = new FormControl();
    loader: boolean = false;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {OwnersService} _ownerService
     */
    constructor(
        private _reportsInvoicesService: ReportsInvoicesService,
        public _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private router: Router,
        private _matSnackBar: MatSnackBar,
    ) {
        this._unsubscribeAll = new Subject();
        this.reportsInvoicesSearchForm = this._formBuilder.group({
            StartDate: new FormControl(new Date()),
            EndDate: new FormControl(new Date()),
            AHLNumber: new FormControl(),
            tourNameCtrl: new FormControl(),
            TourTime: new FormControl(),
            airlineIdCtrl: new FormControl(),
            vendorNameCtrl: new FormControl(),
            driverIdCtrl: new FormControl(),
            OrderStatus: new FormControl()
        });
        this.findReportsInvoices = false;
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._reportsInvoicesService, this.paginator, this.sort);
        this.sort.disableClear = true;
        this.setupAutocompleteTourName();
        this.setupAutocompleteAirlineId();
        this.setupAutocompleteVendorName();
        this.setupAutocompleteDriverId();
        this.loader = true;
        let fromDateTimeString = "";
        fromDateTimeString = new Date().toISOString().split('T')[0];
        fromDateTimeString = fromDateTimeString + 'T00:00:00.001Z';

        let toDateTimeString = "";
        toDateTimeString = new Date().toISOString().split('T')[0];
        toDateTimeString = toDateTimeString + 'T23:59:59.999Z';

        this._reportsInvoicesService.getInvoiceReport({ "StartDate": fromDateTimeString, "EndDate": toDateTimeString }).then(() => {
            this.loader = false;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleFindForm(): void {
        this.findReportsInvoices = !this.findReportsInvoices;
    }

    exportReport(type=","): void {
        let exportOrderRecords = this.dataSource.filteredData;
        if (exportOrderRecords.length > 0) {
            const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
            const header = ['dateOfCreation', 'tourName', 'timeOfTheTour', 'ahlName', 'airlineName', 'vendor', 'driverName', 'deliveryAddress', 'zipCode', 'city', 'numberOfBags', 'passengerName', 'telephone', 'emailId', 'netInvoicePrice', 'invoiceTax', 'totalInvoiceProice', 'netVendorPrice', 'vendorTax', 'totalVendorProice', 'statusTypeName'];
            // console.log("header", header);
            let csv = exportOrderRecords.map(row => header.map(fieldName => {
                let csvFields = ['dateOfCreation', 'tourName', 'timeOfTheTour', 'ahlName', 'airlineName', 'vendor', 'driverName', 'deliveryAddress', 'zipCode', 'city', 'numberOfBags', 'passengerName', 'telephone', 'emailId', 'netInvoicePrice', 'invoiceTax', 'totalInvoiceProice', 'netVendorPrice', 'vendorTax', 'totalVendorProice', 'statusTypeName'];
                if (csvFields.indexOf(fieldName) !== -1) {
                    return JSON.stringify(row[fieldName], replacer);
                }
                // console.log("fieldName", fieldName, row[fieldName]);
                // //Handle json values
                // if (fieldName == "sitaODRequest" || fieldName == "sitaODResponse" || fieldName == "sitaOFRequest" || fieldName == "sitaOFResponse" || fieldName == "sitaUNRequest" || fieldName == "sitaUNResponse" || fieldName == "sitaUPRequest" || fieldName == "sitaUPResponse")
                //     return '"' + JSON.stringify(JSON.parse(row[fieldName] || '{}')).replace(/\"/g, '""') + '"';
                // else
                //     return JSON.stringify(row[fieldName], replacer);
            }).join(type));
            csv.unshift(header.join(type));
            let csvArray = csv.join('\r\n');

            var blob = new Blob([csvArray], { type: 'text/csv' });
            saveAs(blob, "invoice-report.csv");
        } else {
            this._matSnackBar.open('No records to export!', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        }
    }

    private setupAutocompleteTourName() {
        this._reportsInvoicesService.getTours().subscribe((response) => {
            this.tours = response.fixedTours || [];
            this.filteredTours = this.tourNameCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterTour(name) : this.tours.slice()));
        });
    }

    private _filterTour(tourName: string): TourTable[] {
        const filterValue = tourName.toLowerCase();
        return this.tours.filter(tour => tour.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private setupAutocompleteAirlineId() {
        this._reportsInvoicesService.getAirlines().subscribe((response) => {
            this.airlines = response || [];
            this.filteredAirlines = this.airlineIdCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterAirline(name) : this.airlines.slice()));
        });
    }

    private _filterAirline(airlineName: string): IATA_airline[] {
        const filterValue = airlineName.toLowerCase();
        return this.airlines.filter(airline => airline.airlineName.toLowerCase().indexOf(filterValue) === 0);
    }

    private setupAutocompleteVendorName() {
        this._reportsInvoicesService.getVendors().subscribe((response) => {
            this.vendors = response || [];
            this.filteredVendors = this.vendorNameCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterVendor(name) : this.vendors.slice()));
        });
    }

    private _filterVendor(vendorName: string): Vendor[] {
        const filterValue = vendorName.toLowerCase();
        return this.vendors.filter(vendor => vendor.companyName.toLowerCase().indexOf(filterValue) === 0);
    }

    private setupAutocompleteDriverId() {
        this._reportsInvoicesService.getDrivers().subscribe((response) => {
            this.drivers = response || [];
            this.filteredDrivers = this.driverIdCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterDriver(name) : this.drivers.slice()));
        });
    }

    private _filterDriver(driverName: string): DriverVehicleSession[] {
        const filterValue = driverName.toLowerCase();
        return this.drivers.filter(driver => driver.driverName.toLowerCase().indexOf(filterValue) === 0);
    }

    searchInvoices(): void {
        this.loader = true;
        let search = this.reportsInvoicesSearchForm.getRawValue();
        let selectedTourName = this.tourNameCtrl.value;
        let selectedAirlineName = this.airlineIdCtrl.value;
        let selectedVendorName = this.vendorNameCtrl.value;
        let selectedDriverName = this.driverIdCtrl.value;

        let airlineId: string = '0';
        if (selectedAirlineName) {
            airlineId = this.airlines.find(item => item.airlineName === selectedAirlineName).id || '0';
        }

        let driverId: string = '0';
        if (selectedDriverName) {
            driverId = this.drivers.find(item => item.driverName === selectedDriverName).driverId || '0';
        }

        let fromDateTimeString = "";
        if (search.StartDate) {
            let offset = search.StartDate.getTimezoneOffset();

            let fromDateTime = new Date(search.StartDate.getTime() - (offset * 60 * 1000));
            fromDateTimeString = fromDateTime.toISOString().split('T')[0];
            fromDateTimeString = fromDateTimeString + 'T00:00:00.001Z';
        }

        let toDateTimeString = "";
        if (search.EndDate) {
            let offset = search.EndDate.getTimezoneOffset();

            let toDateTime = new Date(search.EndDate.getTime() - (offset * 60 * 1000));
            toDateTimeString = toDateTime.toISOString().split('T')[0];
            toDateTimeString = toDateTimeString + 'T23:59:59.999Z';
        }
        let searchPayload = {
            "StartDate": fromDateTimeString,
            "EndDate": toDateTimeString,
            "AHLNumber": search.AHLNumber,
            "TourName": selectedTourName,
            "TourTime": search.TourTime,
            "AirlineId": airlineId,
            "Vendor": selectedVendorName,
            "DriverId": driverId,
            "OrderStatus": search.OrderStatus
        }
        // let searchPayload = {
        //     "StartDate": "",
        //     "EndDate": "",
        //     "AHLNumber": "",
        //     "TourName": "",
        //     "TourTime": "",
        //     "AirlineId": 0,
        //     "Vendor": "",
        //     "DriverId": 0
        // }
        this._reportsInvoicesService.getInvoiceReport(searchPayload).then(() => {
            this.loader = false;
        });
    }

    clearSearch(): void {
        this.reportsInvoicesSearchForm.reset();
        this.airlineIdCtrl.reset();
        this.tourNameCtrl.reset();
        this.vendorNameCtrl.reset();
        this.driverIdCtrl.reset();
    }

    sitaPopup(record: any, type: string): void {
        this.dialogRef = this._matDialog.open(SitaPopupComponent, {
            panelClass: 'sita-popup-dialog',
            data: {
                record,
                type
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }

    priceDetails(record: any): void {
        this.dialogRef = this._matDialog.open(PricePopupComponent, {
            panelClass: 'price-popup-dialog',
            data: {
                record
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }

}

export class FilesDataSource extends DataSource<any> {
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {OwnersService} _ownerService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _reportsInvoicesService: ReportsInvoicesService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();
        this.filteredData = _reportsInvoicesService.records;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._reportsInvoicesService.onInvoiceReportsChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {
            let data = this._reportsInvoicesService.records.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            // Grab the page's slice of data.
            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}

