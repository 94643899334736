import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { InvoicingOverviewTable } from './invoicing-overview.model';


@Injectable({ providedIn: "root" })
export class InvoicingOverviewService implements Resolve<InvoicingOverviewTable[]> {

    overview: InvoicingOverviewTable[];
    onOverviewChanged: BehaviorSubject<any>;

    tourStatusTypes = [
        { name: "Created", value: 1 },
        { name: "Ready", value: 2 },
        { name: "In progress", value: 3 },
        { name: "Finished", value: 4 }
    ];

    orderStatusTypes = [
        { name: "ASSIGNED", value: 1 },
        { name: "NOT ASSIGNED", value: 2 },
        { name: "IN PROGRESS", value: 3 },
        { name: "DELIVERED", value: 4 },
        { name: "NOT DELIVERED", value: 5 },
        { name: "DELIVERED WITH DAMAGE", value: 6 }
      ]

    constructor(private http: HttpClient) 
    {
        this.onOverviewChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getOverview()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getOverview(): Promise<InvoicingOverviewTable[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/invoice/GetVendorReport')
                .subscribe((response: any) => {
                    response.map(item => {
                        let tourStatusName = this.tourStatusTypes.find(i => i.value == item.statusTour);
                        item.tourStatusName = tourStatusName ? tourStatusName.name : '';

                        let orderStatusName = this.orderStatusTypes.find(i => i.value == item.statusOrder);
                        item.orderStatusName = orderStatusName ? orderStatusName.name : '';
                    });
                    this.overview = response;
                    this.onOverviewChanged.next(this.overview);
                    resolve(response);
                }, reject);
        });
    }

    // getToursByDates( beginIsoDate1: string,  endIsoDate: string): Promise<InvoicingOverviewTable[]>{
    //     return new Promise((resolve, reject) => {
    //         this.http.get(`${environment.urlAddress}/api/tours/params?fromDate=${beginIsoDate1}&toDate=${endIsoDate}`)
    //             .subscribe((response: any) => {
    //                 response.map(item => {
    //                     let tourStatusName = this.statusTypes.find(i => i.value === item.status);
    //                     item.statusName = tourStatusName ? tourStatusName.name : item.statusName;
    //                 });
    //                 this.tours = response;
    //                 this.onToursChanged.next(this.tours);
    //                 resolve(response);
    //             }, reject);
    //     });
    // }

    // getTourDetails(id: number) : Observable<InvoicingOverviewTable> {
    //     const  apiUrl: string = `${environment.urlAddress}/api/tours/${id}`;
    //     return this.http.get<InvoicingOverviewTable>(apiUrl);
    // }

}