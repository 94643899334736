import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { VehicleType } from '../vehicle-type.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { VehicleTypesService } from '../vehicle-types.service';
import { VehicleTypeService } from './vehicle-type.service';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'vehicle-types-vehicle-type-form-dialog',
  templateUrl: './vehicle-type.component.html',
  styleUrls: ['./vehicle-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehicleTypeComponent {

  action: string;
    vehicleType: VehicleType;
    dialogTitle: string;
    pageType: string;
    typeForm: FormGroup;
    myControl = new FormControl();
    options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
    filteredOptions: Observable<string[]>;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<VehicleTypeComponent>,
        private _vehicleTypesService: VehicleTypesService,
        private _vehicleTypeService: VehicleTypeService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar
    )
    {
        this.vehicleType = new VehicleType(_data.vehicleType);
        this.action = _data.action;
        this.dialogTitle = this.action === 'edit' ? 'Edit Vehicle Type' : 'New Vehicle Type';
        this.typeForm = this.createVehicleTypeForm();
        this._unsubscribeAll = new Subject();
    }


    /**
     * Create company form
     *
     * @returns {FormGroup}
     */
    createVehicleTypeForm(): FormGroup
    {
        return this._formBuilder.group({
            id                : [this.vehicleType.id],
            vehicleTypeName   : [this.vehicleType.vehicleTypeName, [Validators.required, Validators.maxLength(40)]],
        });
    }

    saveVehicleType(): void
    {
        const data = this.typeForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._vehicleTypeService.saveVehicleType(data)
            .then(() => {
                this._vehicleTypeService.onVehicleTypeChanged.next(data);

                this._matSnackBar.open('Vehicle type saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

   

    private _filter(value: any): any[] {
        const filterValue = value.toLowerCase();
    
        return this.options.filter(option => option.value.toLowerCase().includes(filterValue));
      }

}

