import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Unit } from 'app/main/units/unit.model';
import { ServiceType } from 'app/main/service-types/serviceType.model';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { UnitsService } from 'app/main/units/units.service';
import { startWith, map } from 'rxjs/operators';
import { PriceTablePriceItem } from '../price-table-items.model';
import { FuseUtils } from '@fuse/utils';
import { ServiceTypesService } from 'app/main/service-types/serviceTypes.service';


export interface PriceItemDialogResult {
  actionType: string;
  formData: any;
  unit: Unit;
  serviceType: ServiceType
}

@Component({
  selector: 'app-itm-tbl',
  templateUrl: './itm-tbl.component.html',
  styleUrls: ['./itm-tbl.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class ItmTblComponent {
  action: string;
  pItem: PriceTablePriceItem;
  dialogTitle: string;
  pageType: string;

  itemForm: FormGroup;

  UnitCtrl = new FormControl();
  ServiceCtrl = new FormControl();

  filteredUnits: Observable<Unit[]>;
  units: Unit[] = [];
  allUnits: Unit[];

  filteredServices: Observable<ServiceType[]>;
  service: ServiceType[] = [];
  allService: ServiceType[];

  /**
  * Constructor
  *
  * @param {FormBuilder} _formBuilder
  * @param {MatSnackBar} _matSnackBar
  */

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<ItmTblComponent>,
    private _unitsService: UnitsService,
    private _serviceTypesService: ServiceTypesService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog) {
    console.log(_data);
    this.pItem = new PriceTablePriceItem(_data.pItm);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Price Items' : 'New Price Items';
    this.itemForm = this.createItemForm();
    // this._unsubscribeAll = new Subject();
    this.setupAutocompleteDataSourceUnits();
    this.setupAutocompleteDataSourceServiceType();

  }

  createItemForm(): FormGroup {

    return this._formBuilder.group({
      id: [this.pItem.id],
      name: [this.pItem.name, [Validators.required]],
      serviceTypeId: [this.pItem.serviceTypeId],
      unitId: [this.pItem.unitId],
      costSharing: [this.pItem.costSharing],
      extra: [this.pItem.extra],
      UnitCtrl: [this.pItem.unitType],
      ServiceCtrl: [this.pItem.serviceTypeName],
      changeKG: [this.pItem.changeKG],
      lfk: [this.pItem.lfk],
      lhgroup: [this.pItem.lhgroup],
      oshandling: [this.pItem.oshandling],
      vendor: [this.pItem.vendor],
      vendorlfk: [this.pItem.vendorlfk],
      vendorlhgroup: [this.pItem.vendorlhgroup]
    });
  }


  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.itemForm.getRawValue(),
      unit: this.units[0],
      serviceType: this.service[0]
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.itemForm.getRawValue(),
      unit: this.units[0],
      serviceType: this.service[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.itemForm.getRawValue(),
      unit: this.units[0],
      serviceType: this.service[0]
    });
  }

  private setupAutocompleteDataSourceUnits() {
    this._unitsService.getUnits().then(data => {

      this.allUnits = data;
      this.filteredUnits = this.UnitCtrl.valueChanges.pipe(
        startWith(null),
        map((description: string | null) => description ? this._filterUnits(description) : this.allUnits.slice()));
    });
    this.UnitCtrl.setValue(this.pItem.unitName);
  }
  private _filterUnits(unitName: string): Unit[] {
    const filterValue = unitName.toLowerCase();

    this.units = this.allUnits.filter(d => d.description.toLowerCase().indexOf(filterValue) === 0);
    console.log(this.units);
    return this.units;
  }

  private setupAutocompleteDataSourceServiceType() {
    this._serviceTypesService.getServiceTypes().then(data => {

      this.allService = data;
      this.filteredServices = this.ServiceCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterServiceType(name) : this.allService.slice()));
    });
    this.ServiceCtrl.setValue(this.pItem.serviceTypeName);
    this.UnitCtrl.setValue(this.pItem.unitType);
  }
  private _filterServiceType(name: string): ServiceType[] {
    const filterValue = name.toLowerCase();

    this.service = this.allService.filter(d => d.name.toLowerCase().indexOf(filterValue) === 0);
    console.log(this.service);
    return this.service;
  }
}
