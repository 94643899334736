
export class Order{
    id?: number;
    ahlName: string;
    name01: string;
    name02: string;
    name03: string;
    deliveryService: string;
    permanentAddress01: string;
    permanentAddress02: string;
    city: string;
    zipCode: string;
    deliveryDate: Date;
    deliveryTime: Date;
    cellPhone01: string;
    cellPhone02: string;
    localDelivery01: string;
    localDelivery02: string;
    datePickedupForDelivery01: string;
    datePickedupForDelivery02: string;
    deliveryAddress01: string;
    deliveryAddress02: string;
    sendEmail: string;
    routing: string;
    agent: string;
    permanentPhoneNumber01: string;
    permanentPhoneNumber02: string;
    teletypeAddress: string;
    temporaryAddress01: string;
    temporaryAddress02: string;
    temporaryZipCode: string;
    numberOfBags: number;
    tourId?: string;
    tourName: string;
    emailId?: string;
    emailSubject: string;
    iatA_airlineId?: string;
    iataName: string;
    codeName: string;
    StatusType :string;
    OriginType :string;
    statusId? :number;
    /**
     * Constructor
     * @param order
     */
    constructor(order?){
        order = order || {};
        this.id = order.id || 0;
        this.ahlName = order.ahlName || '';
        this.name01 = order.name01 || '';
        this.name02 = order.name02 || '';
        this.name03 = order.name03 || '';
        this.deliveryService = order.deliveryService || '';
        this.permanentAddress01 = order.permanentAddress01 || '';
        this.permanentAddress02 = order.permanentAddress024 || '';
        this.city = order.city || '';
        this.zipCode = order.zipCode || '';
        this.deliveryDate = order.deliveryDate || Date.now;
        this.deliveryTime = order.deliveryTime;
        this.cellPhone01 = order.cellPhone01 || '';
        this.cellPhone02 = order.cellPhone02 || '';
        this.localDelivery01 = order.localDelivery01 || '';
        this.localDelivery02 = order.localDelivery02 || '';
        this.datePickedupForDelivery01 = order.datePickedupForDelivery01 || '';
        this.datePickedupForDelivery02 = order.datePickedupForDelivery02 || '';
        this.deliveryAddress01 = order.deliveryAddress01 || '';
        this.deliveryAddress02 = order.deliveryAddress02 || '';
        this.sendEmail = order.sendEmail || '';
        this.routing = order.routing || '';
        this.agent = order.agent || '';
        this.tourId = order.tourId || 0;
        this.permanentPhoneNumber01 = order.permanentPhoneNumber01 || '';
        this.permanentPhoneNumber02 = order.permanentPhoneNumber02 || '';
        this.temporaryAddress01 = order.temporaryAddress01 || '';
        this.temporaryAddress02 = order.temporaryAddress02 || '';
        this.temporaryZipCode = order.temporaryZipCode || '';
        this.numberOfBags = order.numberOfBags || 0;
        this.tourName = order.tourName || '';
        this.iatA_airlineId = order.iatA_airlineId || 0;
        this.iataName = order.iataName || '';
        this.emailId = order.emailId || 0;
        this.emailSubject = order.emailSubject || '';
        this.codeName = order.codeName || '';
        this.StatusType = order.StatusType || 2;
        this.statusId = order.statusId || 2;
        this.OriginType = order.OriginType || 1;
    }
}