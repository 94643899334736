import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MatMenuTrigger, MatSnackBar, MatDialog } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { Order } from 'app/main/orders/order.model';
import { DispatcherTourOrderService } from './tour-order.service';

@Component({
    selector: 'tour-orders',
    templateUrl: './tour-order-table.component.html',
    styleUrls: ['./tour-order-table.component.scss']
})
export class TourOrderTableComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    order: any;
    dataSource:  null;
    displayedColumns = ['ahlName', 'iataName', 'numberOfBags', 'temporaryAddress01', 'tourName', 'deliveryDate',
        'deliveryTime'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;


    @ViewChild(MatMenuTrigger)
    contextMenu: MatMenuTrigger;

    private _unsubscribeAll: Subject<any>;
    constructor(
        private _DispatcherTourOrderService: DispatcherTourOrderService,
        public _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {


    }

    contextMenuPosition = { x: '0px', y: '0px' };

    onContextMenu(event: MouseEvent, order: Order) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'order': order };
        this.contextMenu.openMenu();
    }

 
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
