import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, TemplateRef, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MatDialog, MatSort, MatPaginator, MatMenuTrigger, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { DispatcherOrdersService } from './orders.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TourTable } from '../tour/tour.model';
import { Order } from './order.model';
import { OrderComponent } from './order/order.component';
import { LtsDropItemType } from '../dispatcher-screen.component';
import { CdkDropList, CdkDragExit } from '@angular/cdk/drag-drop';
import { DispatcherOrdersDataSource } from './orders.data-source';
import { DispatcherScreenService } from '../dispatcher-screen.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { SelectionModel } from '@angular/cdk/collections';
import { DispatcherToursService } from '../tour/tours.service';

@Component({
    selector: 'dispatcher-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DispatcherOrdersComponent implements OnInit, OnDestroy {
    @Input() ltsConnectedDroplists: string[];
    @Input() ltsDroplist: string;
    @Input() ltsDropItemType: LtsDropItemType;
    @Input() sourceComponent: string = "dispatcher";

    @Output() orderAssignedViaContextMenu = new EventEmitter();

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filterDispatcher')
    filterDispatcher: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    @ViewChild(MatMenuTrigger)
    contextMenu: MatMenuTrigger;
    contextMenuMultiple: MatMenuTrigger;

    contextMenuPosition = { x: '0px', y: '0px' };

    clickedOrder: any;

    date = new Date();
    order: any;
    tours: TourTable[];
    screenTours: TourTable[];
    dataSource: DispatcherOrdersDataSource | null;
    displayedColumns = ['select', 'ahlName', 'iataName', 'numberOfBags', 'address', 'zipCode', 'city', 'deliveryDate', 'deliveryTime'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    form: FormGroup;
    selection = new SelectionModel<any>(true, []);

    private _unsubscribeAll: Subject<any>;
    constructor(
        private _dispatcherScreenService: DispatcherScreenService,
        private _DispatcherOrdersService: DispatcherOrdersService,
        private _toursService: DispatcherToursService,
        public _matDialog: MatDialog,
        private _fb: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private dateAdapter: DateAdapter<Date>) {
        this._unsubscribeAll = new Subject();
        this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

    ngAfterViewInit() {
        fromEvent(this.filterDispatcher.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filterDispatcher.nativeElement.value;
            });
    }


    ngOnInit(): void {
        let defaultOrderDate = sessionStorage.getItem('dispatcher-order-date') ? new Date(sessionStorage.getItem('dispatcher-order-date')) : new Date();

        let defaultOrderTime = sessionStorage.getItem('dispatcher-order-time') ? sessionStorage.getItem('dispatcher-order-time') : "00:00";

        this.getOrdersByDates(defaultOrderDate, defaultOrderTime);

        this._dispatcherScreenService.tourWidgetTours.subscribe(screenTours => {
            this.screenTours = screenTours;
        });

        this._toursService.onToursChanged.subscribe(todayToursTemplate => {
            //console.log("todayTourTemplate", this._toursService.tours);
            this.tours = todayToursTemplate;
        });

        this.form = this._fb.group({
            orderDate: defaultOrderDate,
            orderTime: defaultOrderTime
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // onDateSelected(event): void {
    //     let orderDate = this.form.value.orderDate;
    //     let orderTime = this.form.value.orderTime;
    //     const offset = orderDate.getTimezoneOffset();

    //     let orderDateTime = new Date(orderDate.getTime() - (offset * 60 * 1000));
    //     let orderDateTimeString = orderDateTime.toISOString().split('T')[0];
    //     orderDateTimeString = orderDateTimeString + ' 00:00';

    //     this.getOrdersByDates(orderDateTimeString);
    //     sessionStorage.setItem('dispatcher-order-date', orderDateTimeString);
    // }

    filterByDateTime(): void {
        let orderDate = this.form.value.orderDate || new Date();
        let orderTime = this.form.value.orderTime || "00:00";
        // const offset = orderDate.getTimezoneOffset();

        // let orderDateTime = new Date(orderDate.getTime() - (offset * 60 * 1000));
        // let orderDateTimeString = orderDateTime.toISOString().split('T')[0];
        // orderDateTimeString = orderDateTimeString + ' ' + orderTime;

        // console.log("orderDateTimeString", orderDateTimeString);
        this.getOrdersByDates(orderDate, orderTime);
        sessionStorage.setItem('dispatcher-order-date', orderDate);
        sessionStorage.setItem('dispatcher-order-time', orderTime);
    }

    getOrdersByDates(orderDate, orderTime): void {
        const offset = orderDate.getTimezoneOffset();

        let orderDateTime = new Date(orderDate.getTime() - (offset * 60 * 1000));
        let orderDateTimeString = orderDateTime.toISOString().split('T')[0];
        orderDateTimeString = orderDateTimeString + ' ' + orderTime;

        this._DispatcherOrdersService.getOrdersByDates(orderDateTimeString).then(() => {
            this.dataSource = new DispatcherOrdersDataSource(this._DispatcherOrdersService, this.paginator, this.sort);
            if (this.sort)
                this.sort.disableClear = true;
        });
    }

    preventBrowserContextMenu(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onContextMenu(event: MouseEvent, order: Order): void {
        event.preventDefault();
        if (order.numberOfBags <= 0) {
            this._matSnackBar.open(`Order should have at least one bag`, 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
            return;
        }

        if (!order.address) {
            this._matSnackBar.open(`Order address is not defined`, 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
            return;
        }

        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'order': order };
        this.contextMenu.openMenu();
        event.stopPropagation();
    }


    addTourOrder(tour: TourTable, order: any): void {
        //To add multiple orders in tour
        if (order == "multiple") {
            if (this.selection.selected.length > 0) {
                this.orderAssignedViaContextMenu.emit({ tour, order: this.selection.selected });
                this.selection.clear();
            } else {
                this._matSnackBar.open(`Please select order(s) to add in tour`, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
                return;
            }
        } else {
            let allOrders = Object.assign([], this.selection.selected);
            allOrders.push(order);
            allOrders = Array.from(new Set(allOrders));
            this.orderAssignedViaContextMenu.emit({ tour, order: allOrders });
            this.selection.clear();
        }
    }

    addFixedTourOrder(tour: any, order: any): void {
        //If tour Lat / Long exists then save it or find Lat / Long and save it.
        var promise = new Promise((resolve, reject) => {
            let response;
            if (tour.Longitude && tour.Latitude) {
                response = { "error": false, "lat": tour.Latitude, "long": tour.Longitude };
                resolve(response);
            } else {
                if (tour.baseAddress) {
                    this._dispatcherScreenService.geocodeAddress(tour.baseAddress)
                        .subscribe((location: Location) => {
                            if (location && location[0] && location[0].geometry && location[0].geometry.location) {
                                response = { "error": false, "lat": location[0].geometry.location.lat(), "long": location[0].geometry.location.lng() };
                                resolve(response);
                            } else {
                                response = { "error": true };
                                resolve(response);
                            }
                        });
                } else {
                    response = { "error": true };
                    resolve(response);
                }
            }
        })

        promise.then((response: any) => {
            if (response.error) {
                this._matSnackBar.open(`Tour base address is not valid`, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
                return;
            } else {
                let defaultDispatcherTourDate = sessionStorage.getItem('dispatcher-tour-date') ? new Date(sessionStorage.getItem('dispatcher-tour-date')) : new Date();

                const offset = defaultDispatcherTourDate.getTimezoneOffset();
                let defaultTourDate = new Date(defaultDispatcherTourDate.getTime() - (offset * 60 * 1000));
                let defaultTourDateString = defaultTourDate.toISOString().split('T')[0];

                const tourTable = {
                    name: tour.name,
                    timeOfTheTour: tour.tourTime,
                    dateOfCreation: defaultTourDateString + 'T' + tour.tourTime,
                    status: 1,
                    baseAddress: tour.baseAddress,
                    Longitude: response.long, // if this not found then make call google api for lat/long for base address
                    Latitude: response.lat,
                    PriceItemId: tour.priceItemId,
                    ExtraPriceItemId: tour.extraPriceItemId
                };

                this._dispatcherScreenService.createTour(tourTable).subscribe(data => {
                    this.getInitialTours(defaultDispatcherTourDate);
                    this.addTourOrder(data, order);
                });
            }
        });
    }

    getInitialTours(defaultTourDate) {
        const offset = defaultTourDate.getTimezoneOffset();
        defaultTourDate = new Date(defaultTourDate.getTime() - (offset * 60 * 1000));
        let dateString = defaultTourDate.toISOString().split('T')[0];

        this._dispatcherScreenService.getToursForToday(dateString).then((tour: any) => { });
    }

    newOrder(): void {
        this.dialogRef = this._matDialog.open(OrderComponent, {
            panelClass: 'order-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response) => {
                if (!response || !response.formData) {
                    return;
                }
                switch (response.actionType) {
                    case 'add':
                        this._DispatcherOrdersService.createOrder(response.formData, response.iata_airline).subscribe(() => {
                            let defaultOrderDate = sessionStorage.getItem('dispatcher-order-date') ? new Date(sessionStorage.getItem('dispatcher-order-date')) : new Date();

                            let defaultOrderTime = sessionStorage.getItem('dispatcher-order-time') ? sessionStorage.getItem('dispatcher-order-time') : "00:00";

                            this.getOrdersByDates(defaultOrderDate, defaultOrderTime);
                        });
                        break;
                    case 'delete':
                        this.deleteOrder(response.formData);
                        break;
                }
            });
    }

    editOrder(order, tour?): void {
        this.dialogRef = this._matDialog.open(OrderComponent, {
            panelClass: 'order-form-dialog',
            data: {
                order,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                switch (response.actionType) {
                    case 'save':
                        this._DispatcherOrdersService.saveOrder(response.formData, response.iata_airline).subscribe(() => {
                            if (tour) {
                                tour.orders.map(item => {
                                    if (item.id == response.formData.id) {
                                        for (var key in item) {
                                            item[key] = response.formData[key];
                                        }
                                    }
                                    return item;
                                });
                            }
                            let defaultOrderDate = sessionStorage.getItem('dispatcher-order-date') ? new Date(sessionStorage.getItem('dispatcher-order-date')) : new Date();

                            let defaultOrderTime = sessionStorage.getItem('dispatcher-order-time') ? sessionStorage.getItem('dispatcher-order-time') : "00:00";

                            this.getOrdersByDates(defaultOrderDate, defaultOrderTime);
                        });
                        break;
                    case 'delete':
                        this.deleteOrder(response.formData);
                        break;
                }
            });
    }

    deleteOrder(order): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this._DispatcherOrdersService.deleteOrder(order.id).subscribe(() => {
                let defaultOrderDate = sessionStorage.getItem('dispatcher-order-date') ? new Date(sessionStorage.getItem('dispatcher-order-date')) : new Date();

                let defaultOrderTime = sessionStorage.getItem('dispatcher-order-time') ? sessionStorage.getItem('dispatcher-order-time') : "00:00";

                this.getOrdersByDates(defaultOrderDate, defaultOrderTime);
            });
            this.confirmDialogRef = null;
        });

    }

    onOrderAddedToTourViaDragAndDrop(event: CdkDragExit): void {
        const droppedItemData = event && event.item && event.item.data;
        if (!droppedItemData) {
            return;
        }

    }

    mustBePlacedInsideTourWidget(drop: CdkDropList): boolean {
        return true;
        return drop.id.startsWith('tour_');
    }

}