import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { District } from "./District.model";


@Injectable({ providedIn: "root" })
export class DistrictsService implements Resolve<District[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    districts: District[];
    onDistrictsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onDistrictsChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDistricts()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getDistricts(): Promise<District[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/districts')
                .subscribe((response: any) => {
                    this.districts = response;
                    this.onDistrictsChanged.next(this.districts);
                    resolve(response);
                }, reject);
        });
    }

   createDistrict(district: District) : Observable<District> {
        const apiUrl: string = `${environment.urlAddress}/api/districts`;
        return this.http.post<District>(apiUrl, district);
    }


    deleteDistrict(id: number) : Observable<District>{
        const apiUrl: string = `${environment.urlAddress}/api/districts/${id}`;
        return this.http.delete<District>(apiUrl);
    }

    


    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onDistrictsChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }
}