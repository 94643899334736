export class Owner{
    name: string;
    logo: string;
    address: string;
    description: string;
    zipCode: string;
    city: string;
    email: string;
    phoneNumber: string;
    fax: string;
    website: string;
    companyRegister: string;
    vaT_ID: string;
    bank: string;
    ibaN_account: string;
    biC_account: string;
    countryId?: string;
    countryName: string;
    baseAddress1:string;
    baseAddress2:string;
    id?: string;

    /**
     * Constructor
     * @param owner
     */
    constructor(owner?){
        owner = owner || {};
        this.id = owner.id || 0;
        this.name = owner.name || '';
        this.logo = owner.logo || '';
        this.address = owner.address || '';
        this.description = owner.description || '';
        this.zipCode = owner.zipCode || '';
        this.city = owner.city || '';
        this.email = owner.email || '';
        this.phoneNumber = owner.phoneNumber || '';
        this.fax = owner.fax || '';
        this.website = owner.website || '';
        this.companyRegister = owner.companyRegister || '';
        this.vaT_ID = owner.vaT_ID || '';
        this.bank = owner.bank || '';
        this.ibaN_account = owner.ibaN_account || '';
        this.biC_account = owner.biC_account || '';
        this.countryId = owner.countryId || null;
        this.countryName = owner.countryName;
        this.baseAddress1 = owner.baseAddress1;
        this.baseAddress2 = owner.baseAddress2;
    }
}