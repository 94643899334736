import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Driver } from './drivers.model';
import { Location } from './location.model';
import { DriverSession } from "./driver-session/driver-session.model";


@Injectable({ providedIn: "root" })
export class DriversService implements Resolve<Driver[]> {

    drivers: Driver[];
    locations: Location[];
    onDriversChanged: BehaviorSubject<any>;
    onLocationsChanged: BehaviorSubject<any>
    
    constructor(private http: HttpClient) 
    {
        this.onDriversChanged = new BehaviorSubject({});
        this.onLocationsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDrivers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getDrivers(): Promise<Driver[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/drivers')
                .subscribe((response: any) => {
                    this.drivers = response;
                    this.onDriversChanged.next(this.drivers);
                    resolve(response);
                }, reject);
        });
    }

    getLocations(): Promise<Location[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/locations')
                .subscribe((response: any) => {
                    this.locations = response;
                    this.onLocationsChanged.next(this.locations);
                    resolve(response);
                }, reject);
        });
    }

    getDriverDetails(id: number) : Observable<Driver> {
        const  apiUrl: string = `${environment.urlAddress}/api/drivers/${id}`;
        return this.http.get<Driver>(apiUrl);
    }

    changePassword(driver: Driver) : Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.patch(environment.urlAddress + '/api/drivers/' + driver.id, driver)
                .subscribe((response: any) => {
                    this.getDrivers();
                    resolve(response);
                }, reject);
        });
    }

    deleteDriverSession(sessionId: string) : Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(environment.urlAddress + '/driver-api/session/close?sessionId='+sessionId,{})
                .subscribe((response: any) => {
                    this.getDrivers();
                    resolve(response);
                }, reject);
        });
    }

    createDriverSession(driverSession: DriverSession) : Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(environment.urlAddress + '/driver-api/session/open',driverSession)
                .subscribe((response: any) => {
                    this.getDrivers();
                    resolve(response);
                }, reject);
        });
    }


}