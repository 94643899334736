import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dispatcher-tour-details',
    templateUrl: './tour-details.component.html'
})
export class DispatcherTourDetailsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
