import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LoginService } from 'app/main/pages/authentication/login/login.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router,
        private http: HttpClient,
        private _fuseConfigService: FuseConfigService,
        private loginService: LoginService) {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        const url = this.getConfiguredUrl(route);
        if (!this.loginService.getUserDetails() && url.indexOf("auth/login") === -1 && url.indexOf("error") === -1) {
            this.hideNavBars();
            return this.router.navigate(['auth/login']);
        }

        return true;

    }

    private getConfiguredUrl(route: ActivatedRouteSnapshot): string {
        return '/' + route.pathFromRoot
            .filter(v => v.routeConfig)
            .map(v => v.routeConfig!.path)
            .join('/');
    }
    
    hideNavBars() {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
}