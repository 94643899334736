import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoicingDraftsService } from '../invoicing-drafts/invoicing-drafts.service';

@Component({
    selector: 'main-invoicing-draft-details',
    templateUrl: './invoicing-draft-details.component.html',
    styleUrls: ['./invoicing-draft-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InvoicingDraftDetailsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {OwnersService} _ownerService
     */
    public invoiceDraftDetails: any;
    public backUrl: string;
    public type: string = "draft";
    constructor(
        public router: Router,
        private _invoicingDraftService: InvoicingDraftsService,
        private route: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
        this.type = this.route.snapshot.paramMap.get('type');

        this.backUrl = this.route.snapshot.paramMap.get('type') == 'draft' ? 'invoicing/invoicing-module/drafts' : 'invoicing/invoicing-module/reports';
        this._invoicingDraftService.getInvoiceDraftDetails(this.route.snapshot.paramMap.get('id'), this.type).then(response => {
            this.invoiceDraftDetails = response;
            this.invoiceDraftDetails.grandToalPrice = this.invoiceDraftDetails.grandToalPricePercentage = this.invoiceDraftDetails.grandToalAll = 0;
            this.invoiceDraftDetails.tours.map(item => {
                item.tourTotalPricePercentage = (item.tourTotalPrice * item.ust) / 100;
                this.invoiceDraftDetails.grandToalPrice += item.tourTotalPrice;
                this.invoiceDraftDetails.grandToalPricePercentage += item.tourTotalPricePercentage;
            });
            this.invoiceDraftDetails.grandToalAll = this.invoiceDraftDetails.grandToalPrice + this.invoiceDraftDetails.grandToalPricePercentage;
        });
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}


