import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Console } from 'console';

@Component({
  selector: 'order-statuses-popup-dialog',
  templateUrl: './order-statuses-popup.component.html',
  styleUrls: ['./order-statuses-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderStatusesPopupComponent {
  action: string;
  dialogTitle: string;
  displayedColumns: string[];
  dataSource: any;
  dialogRef: any;

  /**
   * Constructor
   *
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<OrderStatusesPopupComponent>,
    public _matDialog: MatDialog
  ) {
    this.action = _data.action;
    this.dialogTitle = "Order Statuses";
    this.displayedColumns = ['ahlName', 'statusName', 'action'];
    this.dataSource = [_data.order];
  }
  public updateOrderStatus(orderId, status): void {

    let orderstatusData = {
      "status": status,
      "OrderId": orderId,
      "TourTableId" : this._data.TourTableId
    };
    this.matDialogRef.close({
      actionType: 'change-status',
      orderstatusData
    });
  }
}