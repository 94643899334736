import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, 
    MatChipsModule, MatAutocompleteModule, MatCheckboxModule, MatDialogRef, MatDialogModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { UsersComponent } from './users.component';
import { UsersService } from './users.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import { UserService } from '../user/user.service';
import { UserComponent } from '../user/user.component';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { AgmCoreModule } from '@agm/core';

const routes = [
    {
        path: '',
        component: UsersComponent,
        resolve: {
            data: UsersComponent
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'user-management/users',
        component: UsersComponent,
        resolve: {
            data: UsersService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'user-management/users/:id',
        component: UserComponent,
        resolve: {
            data: UserService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'auth/:id',
        component: ChangePasswordComponent,
        resolve: {
            data: UserService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    entryComponents: [
    ],
    declarations: [
        UsersComponent,
        UserComponent,
        ChangePasswordComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatToolbarModule,
        MatAutocompleteModule,
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
            libraries: ["places"]
        })
    ],
    exports: [
        UsersComponent,
        UserComponent,
        ChangePasswordComponent
    ],
    providers: [
        UsersService,
        UserService
    ]
})

export class UserManagementUsersModule {
}
