import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { PriceTableService } from './table/table.service';
import { PriceTablesService } from './price-tables.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { TableComponent } from './table/table.component';
import { isNumber } from 'lodash';

@Component({
  selector: 'main-price-tables',
  templateUrl: './price-tables.component.html',
  styleUrls: ['./price-tables.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PriceTablesComponent implements OnInit, OnDestroy {

  @ViewChild('dialogContent')
  dialogContent: TemplateRef<any>;

  date = new Date();
  priceTable: any;
  dataSource: FilesDataSource | null;
  displayedColumns = ['name', 'dateOfCreation', 'beginDate', 'endDate'];
  dialogRef: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('filter')
  filter: ElementRef;

  @ViewChild(MatSort)
  sort: MatSort;

  form: FormGroup;

  private _unsubscribeAll: Subject<any>;

  constructor(
      private _priceTablesService: PriceTablesService,
      private _priceTableService: PriceTableService,
      public _matDialog: MatDialog, 
      private _fb: FormBuilder,
      private _matSnackBar: MatSnackBar
  )
  {
      this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void
  {
      this.dataSource = new FilesDataSource(this._priceTablesService, this.paginator, this.sort);
      this.sort.disableClear = true;

      this.form = this._fb.group({
        dateRange: [{begin: new Date(), end: new Date()}]
      });
      fromEvent(this.filter.nativeElement, 'keyup')
          .pipe(
              takeUntil(this._unsubscribeAll),
              debounceTime(150),
              distinctUntilChanged()
          )
          .subscribe(() => {
              if ( !this.dataSource )
              {
                  return;
              }
              this.dataSource.filter = this.filter.nativeElement.value;
          });
  }

  
  ngOnDestroy(): void
  {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  onRangeSelected(event) {
      if(!event.value){
          return;
      }
      console.log(`wtf is this shiet i dont even shoet shetttt : ${event && event.value && event.value.begin && event.value.end && `${event.value.begin + '' +  event.value.end}`}`)
      this._priceTablesService.getPriceTablesByDates(event.value.begin.toISOString(),  event.value.end.toISOString()).then( kek => {
        console.log("kek")
      });
  }

   /**
     * Edit contact
     *
     * @param contact
     */
    editPriceTable(priceTable): void
    {
        this.dialogRef = this._matDialog.open(TableComponent, {
            panelClass: 'price-form-dialog',
            data      : {
                priceTable: priceTable,
                action : 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    case 'save':

                        this._priceTableService.savePriceTable(formData.getRawValue()).subscribe((result) => {
                            if (result.status === 0) {
                                this._priceTablesService.getPriceTables();
            
                                this._matSnackBar.open('Price table updated', 'OK', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            } else {
                                this._matSnackBar.open(result.error, 'OK', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                        });
                        break;
                   
                    case 'delete':
                        this.deletePriceTable(priceTable);
                        break;
                }
            });
    }

    newContact(): void
    {
        this.dialogRef = this._matDialog.open(TableComponent, {
            panelClass: 'price-form-dialog',
            data      : {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if ( !response )
                {
                    return;
                }

                this._priceTablesService.createPriceTable(response.getRawValue()).subscribe( (result) => {
                    if (result.status === 0) {
                        this._priceTablesService.getPriceTables();
    
                        this._matSnackBar.open('Price table saved', 'OK', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    } else {
                        this._matSnackBar.open(result.error, 'OK', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                });
            });
    }

    deletePriceTable(priceTable): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._priceTablesService.deletePriceTable(priceTable.id).subscribe( () => {
                    this._priceTablesService.getPriceTables();
                });
            }
            this.confirmDialogRef = null;
        });

    }
}



export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  constructor(
      private _priceTablesService: PriceTablesService,
      private _matPaginator: MatPaginator,
      private _matSort: MatSort
  )
  {
      super();

      this.filteredData = this._priceTablesService.priceTables;
  }

  get filteredData(): any
  {
      return this._filteredDataChange.value;
  }

  set filteredData(value: any)
  {
      this._filteredDataChange.next(value);
  }

  get filter(): string
  {
      return this._filterChange.value;
  }

  set filter(filter: string)
  {
      this._filterChange.next(filter);
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
      const displayDataChanges = [
          this._priceTablesService.onPriceTableChanged,
          this._matPaginator.page,
          this._filterChange,
          this._matSort.sortChange
      ];

      return merge(...displayDataChanges).pipe(map(() => {

              let data = this._priceTablesService.priceTables.slice();

              data = this.filterData(data);

              this.filteredData = [...data];

              data = this.sortData(data);

              // Grab the page's slice of data.
              const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
              return data.splice(startIndex, this._matPaginator.pageSize);
          })
      );

  }

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any
  {
      if ( !this.filter )
      {
          return data;
      }
      return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
      if (!this._matSort.active || this._matSort.direction === '') {
          return data;
      }

      let sortColumn = this._matSort.active;
      let direction = this._matSort.direction;
      if (direction == 'asc') {
          return data.sort(function (a, b) {
              var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                  vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());
                  
              return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
          });
      } else {
          return data.sort(function (a, b) {
              var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                  vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());

              return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
          });
      }
  }

  disconnect(): void
  {
  }
}


