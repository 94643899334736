import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class MapService {
    constructor(private http: HttpClient){

    }
    getActiveDrivers() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/sessions/active')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}