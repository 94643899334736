import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { User } from './users.model';



@Injectable({ providedIn: "root" })
export class UsersService implements Resolve<User[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    users: User[];
    onUsersChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onUsersChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUsers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getUsers(): Promise<User[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/users')
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUsersChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    getUserDetails(id: number) : Observable<User> {
        const  apiUrl: string = `${environment.urlAddress}/api/users/${id}`;
        return this.http.get<User>(apiUrl);
    }

}