import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tour-order-details',
  templateUrl: './tour-order-details.component.html',
  styleUrls: ['./tour-order-details.component.scss']
})
export class TourOrderDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
