import { Component, Inject, OnInit,Optional, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Order } from '../order.model';
import { OrderService } from '../order/order.service';


@Component({
  selector: 'app-additinoal-information',
  templateUrl: './additinoal-information.component.html',
  styleUrls: ['./additinoal-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditinoalInformationComponent implements OnInit {

  dataSource: [];
  displayedColumns: string[];
  public orderHistoryInfo: any;
  
  constructor(
    private _orderService: OrderService,
    public _matDialog: MatDialog,
    public dialogRef: MatDialogRef<AdditinoalInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any) { 
      
      this.displayedColumns = ['orderId','description','date'];

      this._orderService.getOrderHistoryById(this._data.order.id).then(response => {
        console.log(this.dataSource);
        this.dataSource = response;
        
      });

    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void
  {
     
  }
}
