import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatSort, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { VehiclesService } from './vehicles.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { VehicleService } from './vehicle/vehicle.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { VehicleComponent, VehicleDialogResult } from './vehicle/vehicle.component';
import { Vehicle } from './vehicle.model';
import { Vendor } from '../vendors/vendor.model';
import { Location } from '../drivers/location.model';
import { VehicleType } from '../vehicle-types/vehicle-type.model';
import { isNumber } from 'lodash';

@Component({
    selector: 'main-vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class VehiclesComponent implements OnInit {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    vehicle: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['name', 'model', 'licensePlateNumber', 'vehicleTypeName', 'luggageCapacity', 'parkingNumber', 'maxPayload', 'vendorName', 'locationName'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    list: string[];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {VehicleService} _vehicleService
     */
    constructor(
        private _vehiclesService: VehiclesService,
        private _vehicleService: VehicleService,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._vehiclesService, this.paginator, this.sort);
        this.sort.disableClear = true;

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    editVehicle(vehicle): void {
        this.dialogRef = this._matDialog.open(VehicleComponent, {
            panelClass: 'vehicle-form-dialog',
            data: {
                vehicle,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: VehicleDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                switch (response.actionType) {
                    case 'save':
                        this._vehicleService.saveVehicle(response.formData, response.vendor, response.location, response.vehicleType);
                        break;
                    case 'delete':
                        this.deleteVehicle(response.formData);
                        break;
                }
            });
    }

    newContact(): void {
        this.dialogRef = this._matDialog.open(VehicleComponent, {
            panelClass: 'vehicle-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: VehicleDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                this.addVehicle(response.formData, response.vendor, response.location, response.vehicleType);

            });
    }

    addVehicle(vehicle: Vehicle, vendor: Vendor, location: Location, vehicleType: VehicleType) {
        this._vehicleService.createVehicle(vehicle, vendor, location, vehicleType).subscribe(() => {
            this._vehiclesService.getVehicles();

            this._matSnackBar.open('Vehicle Created', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    saveVehicle(vehicle: Vehicle, vendor: Vendor, location: Location, vehicleType: VehicleType) {
        if (vendor) {
            this.list = vendor.vE_ID.split('_');
            vehicle.vendorId = this.list[1];
        }
        if (location) {
            vehicle.locationId = location.locationId;
        }
        if (vehicleType) {
            vehicle.vehicleTypeId = vehicle.vehicleTypeId;
        }
        this._vehicleService.saveVehicle(vehicle, vendor, location, vehicleType).then(() => {
            this._vehiclesService.getVehicles();

            this._matSnackBar.open('Vehicle Updated', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    deleteVehicle(vehicle): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this._vehicleService.deleteVehicle(vehicle.id).subscribe(() => {
                this._vehiclesService.getVehicles();
            });
            this.confirmDialogRef = null;
        });

    }

    /**
    * On selected change
    *
    * @param contactId
    */
    onSelectedChange(contactId): void {
        this._vehiclesService.toggleSelectedContact(contactId);
    }


}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {VehicleService} _vehicleService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _vehicleService: VehiclesService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._vehicleService.vehicles;
    }


    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._vehicleService.onVehiclesChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._vehicleService.vehicles.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            // Grab the page's slice of data.
            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());
                    
                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}
