import { NgModule } from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    MatButtonModule, MatChipsModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatRippleModule, MatSelectModule, MatSnackBarModule,
    MatSortModule,
    MatTableModule, MatTabsModule, MatCheckboxModule, MatDialogModule, MatMenuModule, MatDatepickerModule, MatToolbarModule, MatToolbarRow, MatAutocompleteModule, MatTooltipModule, MatCardModule, MatProgressSpinnerModule
} from '@angular/material';
import { AgmDirectionModule } from 'agm-direction';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { DispatcherScreenComponent } from './dispatcher-screen/dispatcher-screen.component';
import { DispatcherToursComponent } from './dispatcher-screen/tour/tours.component';
import { DispatcherTourDetailsComponent } from './dispatcher-screen/tour/tour-details/tour-details.component';
import { RouterModule, Routes } from '@angular/router';
import { DispatcherScreenService } from './dispatcher-screen/dispatcher-screen.service';
import { DispatcherOrdersComponent } from './dispatcher-screen/orders/orders.component';
import { DispatcherMapComponent } from './dispatcher-screen/map/map.component';
import { DispatcherOrdersService } from './dispatcher-screen/orders/orders.service';
import { DispatcherToursService } from './dispatcher-screen/tour/tours.service';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { TourScreenComponent } from './dispatcher-screen/tour/tour-screen/tour-screen.component';
import { TourOrderDetailsComponent } from './dispatcher-screen/tour/tour-screen/tour-order-details/tour-order-details.component';
import { TourOrderTableComponent } from './dispatcher-screen/tour/tour-screen/tour-order-table/tour-order-table.component';
import { DispatcherTourOrderService } from './dispatcher-screen/tour/tour-screen/tour-order-table/tour-order.service';
import { DispatcherTourScreenService } from './dispatcher-screen/tour/tour-screen/tour-screen.service';
import { NewTourComponent } from './dispatcher-screen/tour/new-tour/new-tour.component';
import { DriversComponent } from './dispatcher-screen/drivers/drivers.component';
import { OrderComponent } from './dispatcher-screen/orders/order/order.component';
import { DispatcherDriversService } from './dispatcher-screen/drivers/drivers.service';
import { ToursWidgetComponent } from './dispatcher-screen/tours-widget/tours-widget.component';
import { EmailDetailsComponent } from './dispatcher-screen/orders/order/email-details/email-details.component';
import { TourWithOrdersComponent } from './dispatcher-screen/tours-widget/tour-with-orders/tour-with-orders.component';
import { AgmCoreModule } from '@agm/core';
import { TourDateComponent } from './dispatcher-screen/tour-date/tour-date.component';
import { TourDetailsPopupComponent } from './dispatcher-screen/map/tour-details-popup/tour-details-popup.component';
import { OrderStatusesPopupComponent } from './dispatcher-screen/map/tour-details-popup/order-statuses-popup/order-statuses-popup.component';
import { TourStatusPopupComponent } from './dispatcher-screen/tours-widget/tour-status-popup/tour-status-popup.component';
import { ExtraPriceItemComponent } from './dispatcher-screen/tours-widget/tour-with-orders/extra-price-item/extra-price-item.component';
import { DispatcherTourScreenComponent } from './dispatcher-tour-screen/dispatcher-tour-screen.component';

const routes: Routes = [
    {
        path: 'dispatcher',
        component: DispatcherScreenComponent,
        pathMatch: 'full',
        resolve: {
            mail: DispatcherScreenService
        }
    },
    {
        path: 'dispatcher-tour',
        component: DispatcherTourScreenComponent,
        pathMatch: 'full',
        resolve: {
            mail: DispatcherScreenService
        }
    },
    {
        path: 'dispatcher/tour/:id/tour-screen',
        pathMatch: 'full',
        component: TourScreenComponent,
        resolve: {
            data: DispatcherTourScreenService
        }
    },
    {
        path: 'dispatcher/map',
        pathMatch: 'full',
        component: DispatcherMapComponent,
       
    },
    {
        path: 'dispatcher/tour/:id/tour-with-orders',
        pathMatch: 'full',
        component: TourWithOrdersComponent       
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        MatTooltipModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatToolbarModule,
        MatDatepickerModule,
        SatDatepickerModule, 
        SatNativeDateModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        FuseWidgetModule,
        FuseSharedModule,
        MatMenuModule,
        DragDropModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
            libraries: ["places"]
        }),
        AgmDirectionModule
    ],
    declarations: [
        TourDateComponent,
        DispatcherScreenComponent,
        DispatcherTourScreenComponent,
        DispatcherToursComponent,
        DispatcherTourDetailsComponent,
        DispatcherOrdersComponent,
        DispatcherMapComponent,
        TourScreenComponent,
        TourOrderTableComponent,
        TourOrderDetailsComponent,
        NewTourComponent,
        DriversComponent,
        OrderComponent,
        ToursWidgetComponent,
        EmailDetailsComponent,
        TourWithOrdersComponent,
        TourDetailsPopupComponent,
        OrderStatusesPopupComponent,
        TourStatusPopupComponent,
        ExtraPriceItemComponent
    ],
    providers: [
        DispatcherScreenService,
        DispatcherOrdersService,
        DispatcherToursService,
        DispatcherTourOrderService,
        DispatcherTourScreenService,
        DispatcherDriversService,
        DispatcherOrdersComponent,
        TourStatusPopupComponent,
        ExtraPriceItemComponent
    ],
    exports : [
        TourDateComponent,
        OrderComponent,
        EmailDetailsComponent,
        TourWithOrdersComponent,
        TourDetailsPopupComponent,
        OrderStatusesPopupComponent,
        TourStatusPopupComponent,
        ExtraPriceItemComponent
    ], 
    entryComponents: [
        TourDateComponent,
        OrderComponent,
        EmailDetailsComponent,
        TourWithOrdersComponent,
        TourDetailsPopupComponent,
        OrderStatusesPopupComponent,
        TourStatusPopupComponent,
        ExtraPriceItemComponent
    ]
})
export class LtsDispatcherModule { }
