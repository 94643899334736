import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Order } from './order.model';


@Injectable({ providedIn: "root" })
export class OrdersService implements Resolve<Order[]> {

    orders: Order[];
    onOrdersChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onOrdersChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            let currentDate = new Date();
            const offset = currentDate.getTimezoneOffset();

            let startDateTime = new Date(currentDate.getTime() - (offset * 60 * 1000));
            startDateTime.setMonth(startDateTime.getMonth() - 3);
            let startDateTimeString = startDateTime.toISOString().split('T')[0];
            startDateTimeString = startDateTimeString+'T00:00:00.001Z';

            let endDateTime = new Date(currentDate.getTime() - (offset * 60 * 1000));
            let endDateTimeString = endDateTime.toISOString().split('T')[0];
            endDateTimeString = endDateTimeString+'T23:59:59.999Z';

            Promise.all([
                this.getOrdersByDates(startDateTimeString, endDateTimeString)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getOrders(): Promise<Order[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/orders')
                .subscribe((response: any) => {
                    this.orders = response;
                    this.onOrdersChanged.next(this.orders);
                    resolve(response);
                }, reject);
        });
    }

    getOrdersByDates( beginIsoDate1: string,  endIsoDate: string): Promise<Order[]>{
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.urlAddress}/api/orders/params?fromDate=${beginIsoDate1}&toDate=${endIsoDate}`)
                .subscribe((response: any) => {
                    this.orders = response;
                    this.onOrdersChanged.next(this.orders);
                    resolve(response);
                }, reject);
        });
    }

    getOrderDetails(id: number) : Observable<Order> {
        const  apiUrl: string = `${environment.urlAddress}/api/orders/${id}`;
        return this.http.get<Order>(apiUrl);
    }

    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);

                this.onOrdersChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);

        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

}