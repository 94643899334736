import { LngLatLike, LngLat } from "mapbox-gl";
import { Position } from "geojson";

export const position = (coordinate: LngLatLike): Position => {
    if(Array.isArray(coordinate)) {
        return coordinate;
    }

    if(coordinate instanceof LngLat) {
        coordinate = coordinate as LngLat;
        return [coordinate.lng, coordinate.lat];
    }

    if(coordinate.hasOwnProperty("lng")) {
        coordinate = coordinate as { lng: number; lat: number; };
        return [coordinate.lng, coordinate.lat];
    }

    if(coordinate.hasOwnProperty("lon")) {
        coordinate = coordinate as { lon: number; lat: number; };
        return [coordinate.lon, coordinate.lat];
    }

    return null;
}

export const positions = (coordinates: LngLatLike[]): Position[] => {
    return coordinates.map(coordinate => position(coordinate));
}