import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { InvoiceRecipientsService } from './invoice-recipients.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { InvoiceRecipientService } from './invoice-recipient/invoice-recipient.service';
import { InvoiceRecipientComponent, RecipientDialogResult } from './invoice-recipient/invoice-recipient.component';
import { isNumber } from 'lodash';

@Component({
    selector: 'main-invoice-recipients',
    templateUrl: './invoice-recipients.component.html',
    styleUrls: ['./invoice-recipients.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InvoiceRecipientsComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    recipient: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['iR_ID', 'external_IR_ID', 'companyName', 'responsiblePerson', 'countryName', 'city', 'vaT_ID', 'languageName', 'invoicingCycleName', 'active'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DriverService} _driverService
     */
    constructor(
        private _invoiceRecipientsService: InvoiceRecipientsService,
        private _invoiceRecipientService: InvoiceRecipientService,
        private _matDialog: MatDialog
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._invoiceRecipientsService, this.paginator, this.sort);
        this.sort.disableClear = true;

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    editRecipient(recipient): void {
        this.dialogRef = this._matDialog.open(InvoiceRecipientComponent, {
            panelClass: 'recipient-form-dialog',
            data: {
                recipient,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: RecipientDialogResult) => {
                if (!response) {
                    return;
                }

                switch (response.actionType) {
                    case 'save':
                        this._invoiceRecipientService.saveRecipient(response.formData, response.country, response.invoiceCycle, response.iataCodeAirline);
                        break;

                    case 'delete':
                        this.deleteRecipient(recipient);
                        break;
                }
            });
    }

    newContact(): void {
        this.dialogRef = this._matDialog.open(InvoiceRecipientComponent, {
            panelClass: 'recipient-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: RecipientDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }

                this._invoiceRecipientsService.createRecipient(response.formData, response.country, response.invoiceCycle, response.iataCodeAirline).subscribe(() => {
                    this._invoiceRecipientsService.getInvoiceRecipients();
                });
            });
    }

    deleteRecipient(recipient): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._invoiceRecipientsService.deleteRecipient(recipient.iR_ID).subscribe(() => {
                    this._invoiceRecipientsService.getInvoiceRecipients();
                });
            }
            this.confirmDialogRef = null;
        });

    }

    /**
   * On selected change
   *
   * @param contactId
   */
    onSelectedChange(contactId): void {
        this._invoiceRecipientsService.toggleSelectedContact(contactId);
    }

}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {DriverService} _driverService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _invoiceRecipientsService: InvoiceRecipientsService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._invoiceRecipientsService.invoiceRecipients;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._invoiceRecipientsService.onInvoiceRecipientsChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._invoiceRecipientsService.invoiceRecipients.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());
                    
                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}
