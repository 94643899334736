import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UsersService } from '../users/users.service';
import { User } from '../users/users.model';
import { Owner } from 'app/main/owners/owner.model';
import { Country } from 'app/main/country/country.model';

@Injectable({ providedIn: "root" })
export class UserService implements Resolve<any>
{
    routeParams: any;
    user: any;
    onUserChanged: BehaviorSubject<any>;

     /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _usersService: UsersService
    )
    {
        this.onUserChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getUser()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onUserChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/users/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.user = response;
                    this.onUserChanged.next(this.user);
                    resolve(response);
                }, reject);
 
        });
    }

    createUser(user: User, owner: Owner, country:Country) : Observable<User> {
        if(owner) {
            user.ownerId = owner.id;
        }
        if(country) {
            user.countryId = country.id;
        }
        if(user.active == null){
            user.active = false;
        }
        const apiUrl: string = `${environment.urlAddress}/api/users`;
        return this._httpClient.post<User>(apiUrl, user);
    }
      /**
     * Save user
     *
     * @param user
     * @returns {Promise<any>}
     */
    saveUser(user, owner, country): Promise<any>
    {
        if(owner) {
            user.ownerId = owner.id;
        }
        if(country){
            user.countryId = country.id;
        }
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/users/' + user.id, user)
                .subscribe((response: any) => {
                    resolve(response);
                    this._usersService.getUsers();
                }, reject);
        });
    }

    
    changePassword(user: User) : Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.patch(`${environment.urlAddress}/api/users/${user.id}/password`, user)
            .subscribe((response: any) => {
                this._usersService.getUsers();
                resolve(response);
                }, reject);
        });
    }

    deleteUser(id: number) : Observable<User>{
        const apiUrl: string = `${environment.urlAddress}/api/users/${id}`;
        return this._httpClient.delete<User>(apiUrl);
    }
}