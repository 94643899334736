import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Order } from 'app/main/orders/order.model';
import { environment } from 'environments/environment';
import { TourTable } from 'app/main/tours/tour.model';
import { HttpClient } from '@angular/common/http';
import { DispatcherOrdersService } from './orders/orders.service';
import { TourDetails } from './tour/tour-screen/tour-order.model';
import { MapsAPILoader } from '@agm/core';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { of } from 'rxjs/observable/of';
import { tap, map, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { Driver } from 'app/main/drivers/drivers.model';

declare var google: any;
@Injectable({ providedIn: "root" })
export class DispatcherScreenService implements Resolve<any> {
    initialTours: any = [];
    private geocoder: any;
    private distanceMatrix: any;

    constructor(private http: HttpClient, private dispatcherOrdersService: DispatcherOrdersService, private mapLoader: MapsAPILoader, private _matSnackBar: MatSnackBar) {

    }

    tourWidgetTours: BehaviorSubject<any> = new BehaviorSubject(this.initialTours);
    onOrderAssignedViaContextMenu: Subject<{ tour: any, order: any }> = new Subject();

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            resolve();
        });
    }

    getToursForToday(tourDate) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/dispatcher-screen/today-tours?filterDate=' + tourDate)
                .subscribe((response: any) => {
                    this.tourWidgetTours.next(response);
                    resolve(response);
                }, reject);
        });
    }

    getInProgressTours(tourDate) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/dispatcher-screen/getprogresstours?filterDate=' + tourDate)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getOrders(): Promise<Order[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/orders')
                .subscribe((response: any) => {

                    resolve(response);
                }, reject);
        });
    }

    getTours(): Promise<TourTable[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/tours')
                .subscribe((response: any) => {

                    resolve(response);
                }, reject);
        });
    }

    getTodayTour(): Promise<TourTable[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/tours/gettodaytour')
                .subscribe((response: any) => {

                    resolve(response);
                }, reject);
        });
    }

    addOrderToTour(params: { order: any, tour: any }) {
        const { tour, order } = params;
        if (order.length > 0)
            this.addMultipleOrdersToTour(tour, order);
        else
            this.addSingleOrderToTour(tour, order);
    }

    addSingleOrderToTour(tour, order) {
        var orderPromise = this.processToAddOrderToTour(tour, order);

        orderPromise.then((response: any) => {
            if (response.error) {
                this._matSnackBar.open(response.message, 'OK', {
                    verticalPosition: 'top',
                    duration: undefined
                });
            } else {
                this._matSnackBar.open(`Order ${order.ahlName} added to tour ${tour.name}`, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        });
    }

    addMultipleOrdersToTour(tour, orders) {
        let allPromises = [];
        for (let i = 0; i < orders.length; i++) {

            var data = this.processToAddOrderToTour(tour, orders[i])
            allPromises.push(data);
            debugger
        }
        Promise.all(allPromises).then(result => {
            let successOrders = 0, failedOrders = 0;

            for (let i = 0; i < result.length; i++) {
                if (result[i].error) {
                    failedOrders++;
                } else {
                    successOrders++;
                }
            }
            if (result.length === 1 && result[0].message) {
                this._matSnackBar.open(result[0].message, 'OK', {
                    verticalPosition: 'top',
                    duration: result[0].error ? undefined : 2000
                });
            } else {
                this._matSnackBar.open(successOrders + " orders(s) added successfully and " + failedOrders + " orders(s) failed to add.", 'OK', {
                    verticalPosition: 'top',
                    duration: failedOrders > 0 ? undefined : 2000
                });
            }
        });
    }

    processToAddOrderToTour(tour, order) {
        let orderAddress = order.city ? order.address + ', ' + order.city : order.address;
        orderAddress = order.zipCode ? orderAddress + ', ' + order.zipCode : orderAddress;
        return new Promise((resolve, reject) => {
            let response;
            if (order.Longitude && order.Latitude) {
                response = { error: false, "lat": order.Latitude, "long": order.Longitude };
                resolve(response);
            } else {
                if (order.address && order.city && order.zipCode) {
                    //Find location details
                    this.geocodeAddress(orderAddress)
                        .subscribe((location: any) => {
                            /* Location must be of either Austria or Slovakia */
                            if (location) {
                                let finalLocation;
                                loop:
                                for (let i = 0; i < location.length; i++) {
                                    for (let j = 0; j < location[i].address_components.length; j++) {
                                        if (location[i].address_components[j].types.includes("country") && (location[i].address_components[j].short_name.toLowerCase() == "at", "si" || location[i].address_components[j].short_name.toLowerCase() == "sk" || location[i].address_components[j].short_name.toLowerCase() == "hu" || location[i].address_components[j].short_name.toLowerCase() == "cz")) {
                                            finalLocation = location[i];
                                            break loop;
                                        }
                                    }
                                }

                                if (finalLocation && finalLocation.geometry && finalLocation.geometry.location) {
                                    response = { error: false, "lat": finalLocation.geometry.location.lat(), "long": finalLocation.geometry.location.lng() };

                                    /* Add Order into tour */
                                    const tourOrder = {
                                        tourTableId: tour.id,
                                        orderId: order.id,
                                        Longitude: response.long,
                                        Latitude: response.lat
                                    };
                                    const apiUrl: string = `${environment.urlAddress}/api/tour_order`;
                                    /* Add Order into tour */
                                    this.http.post<any>(apiUrl, tourOrder).toPromise().then((res) => {
                                        if (!res.status) {
                                            response = { error: true, message: res.message };
                                        } else {
                                            const orders = this.dispatcherOrdersService.onOrdersChanged.getValue();
                                            const filtered = orders.filter(shownOrder => shownOrder.id !== order.id);
                                            this.dispatcherOrdersService.orders = filtered;
                                            this.dispatcherOrdersService.onOrdersChanged.next(filtered);

                                            // this._matSnackBar.open(`Order ${order.ahlName} added to tour ${tour.name}`, 'OK', {
                                            //     verticalPosition: 'top',
                                            //     duration: 2000
                                            // });

                                            //Add new order in tour
                                            const shownTours = this.tourWidgetTours.getValue();
                                            const foundTour = shownTours.find(tourWidget => tourWidget.id === tour.id);
                                            if (foundTour) {
                                                foundTour.orders.push(order);
                                                foundTour.ordersString = foundTour.orders.map(order => order.ahlName).join(", ");
                                            } else {
                                                tour.ordersString = tour.orders.map(order => order.ahlName).join(", ");
                                                tour.orders = [order];
                                                shownTours.push(tour);
                                                this.tourWidgetTours.next(shownTours);
                                            }
                                        }
                                        resolve(response);
                                    });

                                    //resolve(response);

                                } else {
                                    response = { error: true, message: "Location not found." };
                                    resolve(response);
                                }
                            } else {
                                response = { error: true, message: "Location not found." };
                                resolve(response);
                            }
                        });
                } else {
                    response = { error: true, message: "Please enter Address, City and Zip Code." };
                    resolve(response);
                }
            }
        });
    }

    createTour(tourTemplate: any) {
        const apiUrl: string = `${environment.urlAddress}/api/tours/add_tour`;
        return this.http.post<any>(apiUrl, tourTemplate)

    }

    removeDriverFromTour(id: number) {
        const apiUrl: string = `${environment.urlAddress}/api/tours/removediverfromtour/${id}`;

        return this.http.post<any>(apiUrl, null);

    }

    removeTour(id: number) {
        const apiUrl: string = `${environment.urlAddress}/api/tours/${id}`;
        return this.http.delete<any>(apiUrl);
    }

    changeTourStatus(tourStatusData: any) {
        const apiUrl: string = `${environment.urlAddress}/api/tours/changetourstatus`;
        return this.http.post<any>(apiUrl, tourStatusData)
    }

    changeTourDateTime(tourTemplate: any) {
        const apiUrl: string = `${environment.urlAddress}/api/tours/changetourdate`;
        return this.http.post<any>(apiUrl, tourTemplate)

    }

    getTourDetails(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.urlAddress}/api/tours/${id}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    removeOrderFromTour(tourTemplate: any) {
        const apiUrl: string = `${environment.urlAddress}/api/tour_order/removeorderfromtour`;
        return this.http.post<any>(apiUrl, tourTemplate)
    }

    private initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }

    private waitForMapsToLoad(): Observable<boolean> {
        if (!this.geocoder) {
            return fromPromise(this.mapLoader.load())
                .pipe(
                    tap(() => this.initGeocoder()),
                    map(() => true)
                );
        }
        return of(true);
    }

    geocodeAddress(location: string): Observable<Location> {
        return this.waitForMapsToLoad().pipe(
            switchMap(() => {
                return new Observable(observer => {
                    this.geocoder.geocode({ 'address': location }, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            observer.next(results);
                        } else {
                            observer.next(results);
                        }
                        observer.complete();
                    });
                })
            })
        )
    }

    updateTourOrderWithPriority(tourTemplate: any) {
        const apiUrl: string = `${environment.urlAddress}/api/tour_order/UpdateTourOrderWithPriority`;
        return this.http.post<any>(apiUrl, tourTemplate)
    }

    private initDistanceMatrix() {
        this.distanceMatrix = new google.maps.DistanceMatrixService();
    }


    private waitForDistanceMatrixMapsToLoad(): Observable<boolean> {
        if (!this.distanceMatrix) {
            return fromPromise(this.mapLoader.load())
                .pipe(
                    tap(() => this.initDistanceMatrix()),
                    map(() => true)
                );
        }
        return of(true);
    }

    distanceMatrixFromAddress(origin: object, destination: object): Observable<Location> {
        return this.waitForDistanceMatrixMapsToLoad().pipe(
            switchMap(() => {
                return new Observable(observer => {
                    this.distanceMatrix.getDistanceMatrix({ 'origins': [origin], 'destinations': [destination], 'travelMode': 'DRIVING' }, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            observer.next(results);
                        } else {
                            observer.next(results);
                        }
                        observer.complete();
                    });
                })
            })
        )
    }

    distanceForMultipleDestianations(origin: object, destinations: object[]): Observable<Location> {
        return this.waitForDistanceMatrixMapsToLoad().pipe(
            switchMap(() => {
                return new Observable(observer => {
                    this.distanceMatrix.getDistanceMatrix({ 'origins': [origin], 'destinations': destinations, 'travelMode': 'DRIVING' }, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            observer.next(results);
                        } else {
                            observer.next(results);
                        }
                        observer.complete();
                    });
                })
            })
        )
    }

    updateOrdersStatus(tourTemplate: any) {
        const apiUrl: string = `${environment.urlAddress}/api/orders/UpdateOrdersStatus`;
        return this.http.post<any>(apiUrl, tourTemplate)
    }

    addExtraPriceIdInOrder(data: any) {
        const apiUrl: string = `${environment.urlAddress}/api/tour_order/addextrapriceid`;
        return this.http.post<any>(apiUrl, data)
    }

    getNotDeliveredOrders() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/driver-api/GetNotDeliveredOrders')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getNotDeliveredOrdersByFilter(data?) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.urlAddress + '/driver-api/GetNotDeliveredOrdersByFilter', data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
