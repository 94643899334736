import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Vehicle } from './vehicle.model';


@Injectable({ providedIn: "root" })
export class VehiclesService implements Resolve<Vehicle[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    vehicles: Vehicle[];
    onVehiclesChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onVehiclesChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getVehicles()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getVehicles(): Promise<Vehicle[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/vehicles')
                .subscribe((response: any) => {
                    this.vehicles = response;
                    this.onVehiclesChanged.next(this.vehicles);
                    resolve(response);
                }, reject);
        });
    }

    getVehicleDetails(id: number) : Observable<Vehicle> {
        const  apiUrl: string = `${environment.urlAddress}/api/vehicles/${id}`;
        return this.http.get<Vehicle>(apiUrl);
    }

  
    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onVehiclesChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }
}