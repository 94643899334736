import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule, MatDatepickerModule, MatCheckboxModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { FixedToursComponent } from './fixed-tours.component';
import { FixedToursService } from './fixed-tours.service';
import { FixTourComponent } from './fix-tour/fix-tour.component';
import { FixedTourService } from './fix-tour/fixed-tour.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
const routes = [
    {
        path: '',
        component: FixedToursComponent,
        resolve: {
            data: FixedToursService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'fixedTours',
        component: FixedToursComponent,
        resolve: {
            data: FixedToursService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'fixedTours/:id',
        component: FixTourComponent,
        resolve: {
            data: FixedTourService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        FixedToursComponent,
        FixTourComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        FixedToursService
    ],
    exports: [
        FixedToursComponent, 
        FixTourComponent
    ]
})

export class FixedTourServiceModule {
}
