import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatToolbarModule, MatAutocompleteModule, MatCheckboxModule
} from '@angular/material';

import { FuseWidgetModule } from '@fuse/components';
import { InvoiceRecipientsComponent } from './invoice-recipients.component';
import { InvoiceRecipientsService } from './invoice-recipients.service';
import { InvoiceRecipientComponent } from './invoice-recipient/invoice-recipient.component';
import { InvoiceRecipientService } from './invoice-recipient/invoice-recipient.service';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { AgmCoreModule } from '@agm/core';

const routes = [
    {
        path: '',
        component: InvoiceRecipientsComponent,
        resolve: {
            data: InvoiceRecipientsComponent
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'invoicerecipients',
        component: InvoiceRecipientsComponent,
        resolve: {
            data: InvoiceRecipientsService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    entryComponents: [
        InvoiceRecipientComponent
    ],
    declarations: [
        InvoiceRecipientsComponent,
        InvoiceRecipientComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatAutocompleteModule,
        FuseSharedModule,
        FuseWidgetModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
            libraries: ["places"]
        })
    ],
    exports: [
        InvoiceRecipientsComponent,
        InvoiceRecipientComponent
    ],
    providers:[
        InvoiceRecipientsService,
        InvoiceRecipientService
    ]
})

export class InvoiceRecipientsModule {
}
