import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorDraftsService } from '../vendor-drafts/vendor-drafts.service';

@Component({
    selector: 'main-vendor-draft-details',
    templateUrl: './vendor-draft-details.component.html',
    styleUrls: ['./vendor-draft-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class VendorDraftDetailsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {OwnersService} _ownerService
     */
    public invoiceDraftDetails : any;
    public backUrl : string;
    public type: string = "draft";
    constructor(
        public router: Router,
        private _vendorDraftService: VendorDraftsService,
        private route: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
        this.type = this.route.snapshot.paramMap.get('type');
        
        this.backUrl = this.route.snapshot.paramMap.get('type') == 'draft' ? 'invoicing/vendor-module/drafts': 'invoicing/vendor-module/creditNotes';
        this._vendorDraftService.getInvoiceDraftDetails(this.route.snapshot.paramMap.get('id'), this.type).then(response => {
            this.invoiceDraftDetails = response;
            this.invoiceDraftDetails.grandToalPrice = this.invoiceDraftDetails.grandToalPricePercentage = this.invoiceDraftDetails.grandToalAll = 0;
            this.invoiceDraftDetails.vendorTour.map(item => {
                item.tourTotalPricePercentage = (item.total * 20)/100;
                this.invoiceDraftDetails.grandToalPrice += item.total;
                this.invoiceDraftDetails.grandToalPricePercentage += item.tourTotalPricePercentage;
            });
            this.invoiceDraftDetails.grandToalAll = this.invoiceDraftDetails.grandToalPrice + this.invoiceDraftDetails.grandToalPricePercentage;
        });
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}


