import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, 
    MatToolbarModule, MatAutocompleteModule, MatCheckboxModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { BeneficiariesComponent } from './beneficiaries.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { BeneficiariesService } from './beneficiaries.service';
import { BeneficiaryService } from './beneficiary/beneficiary.service';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { AgmCoreModule } from '@agm/core';

const routes = [
    {
        path: '',
        component: BeneficiariesComponent,
        resolve: {
            data: BeneficiariesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'beneficiaries',
        component: BeneficiariesComponent,
        resolve: {
            data: BeneficiariesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'beneficiaries/:bE_ID',
        component: BeneficiaryComponent,
        resolve: {
            data: BeneficiaryService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        BeneficiariesComponent,
        BeneficiaryComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatToolbarModule,
        MatTabsModule,
        NgxChartsModule,
        MatAutocompleteModule,

        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
            libraries: ["places"]
        })
    ],
    exports: [
        BeneficiariesComponent, 
        BeneficiaryComponent
    ]
})

export class BeneficiariesModule {
}
