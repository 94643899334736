import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderDroppedToTour } from './tours-widget/tours-widget.component';
import { DispatcherScreenService } from './dispatcher-screen.service';
import { MatSnackBar } from '@angular/material';

export enum LtsDropItemType {
    Order = 'OrderDropItem',
    TourTemplate = 'TourTemplateDropItem',
    Driver = 'DriverDropItem'
}

@Component({
    selector: 'dispatcher-dispacher-screen',
    templateUrl: './dispatcher-screen.component.html',
    styleUrls: ['./dispatcher-screen.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DispatcherScreenComponent implements OnInit {

    sourceDropLists = {
        ordersDropList: 'orders-drop-list',
        tourTemplatesDropList: 'tour-templates-drop-list',
        driversDropList: 'drivers-drop-list'
    };
    sourceDropListsIds = Object.values(this.sourceDropLists);

    destinationDropListsIds;
    dropItemTypes = LtsDropItemType;
    yOffset: number = 0;
    firstDivHeight: string = "50vh";
    secondDivHeight: string = "50vh";
    resizeStart: boolean = false;

    constructor(private _dispatcherScreenService: DispatcherScreenService, private _matSnackBar: MatSnackBar) { }

    ngOnInit(): void {


    }

    onTourDropListsChanged(dropListsIds): void {
        this.destinationDropListsIds = dropListsIds;
    }

    preventBrowserContextMenu(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    onOrderDroppedToTour(event: OrderDroppedToTour) {
        this._dispatcherScreenService.addOrderToTour(event);
    }

    onOrderAssignedViaContextMenu(event: OrderDroppedToTour) {
        this._dispatcherScreenService.addOrderToTour(event)
    }

    @HostListener('window:dragOrderPanel', ['$event'])
    dragOrderPanel(event): void {
        if (event.offsetY <= 3) {
            this.resizeStart = true;
            this.resizeOrderPanel(event)
        }
    }
    @HostListener('window:resizeOrderPanel', ['$event'])
    resizeOrderPanel(event): void {
        if (this.resizeStart) {
            this.firstDivHeight = (event.y - 58) + "px";
            let secondDivDeduction = event.y;
            this.secondDivHeight = 'calc(100vh - ' + secondDivDeduction + 'px)';
        }
    }
    @HostListener('window:resizeStop', ['$event'])
    //mouseUp(event){}
    resizeStop(event): void {
        if (this.resizeStart) {
            this.resizeStart = false;
        }
    }
}
