import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule, MatMenuModule, MatProgressSpinnerModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { ReportsInvoicesComponent } from './reports-invoices.component';
import { ReportsInvoicesService } from './reports-invoices.service';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { SitaPopupComponent } from './sita-popup/sita-popup.component';
import { PricePopupComponent } from './price-popup/price-popup.component';

const routes = [
    {
        path: '',
        component: ReportsInvoicesComponent,
        resolve: {
            data: ReportsInvoicesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'reports/invoices',
        component: ReportsInvoicesComponent,
        resolve: {
            data: ReportsInvoicesService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        ReportsInvoicesComponent,
        SitaPopupComponent,
        PricePopupComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatDatepickerModule,
        SatDatepickerModule, 
        SatNativeDateModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatDatepickerModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCheckboxModule, 
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatCheckboxModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    providers: [
        ReportsInvoicesService
    ],
    exports: [
        ReportsInvoicesComponent,
        SitaPopupComponent,
        PricePopupComponent
    ],
    entryComponents: [
        SitaPopupComponent,
        PricePopupComponent
    ]
})

export class ReportsInvoicesModule {
}
