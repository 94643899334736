import { Component, ViewEncapsulation, Optional, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LanguageType, InvoicingType, InvoiceRecipient } from '../invoice-recipient.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { InvoiceRecipientService } from './invoice-recipient.service';
import { Country } from 'app/main/country/country.model';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CountriesService } from 'app/main/country/country.service';
import { startWith, map } from 'rxjs/operators';
import { InvoicingCycle } from 'app/main/invoice-cycles/invoice-cycle.model';
import { InvoiceCyclesService } from 'app/main/invoice-cycles/invoice-cycles.service';
import { IATA_airline } from 'app/main/airlines/airlines.model';
import { AirlinesService } from 'app/main/airlines/airlines.service';
import { MapsAPILoader } from '@agm/core';

export interface RecipientDialogResult {
  actionType: string;
  formData: any;
  country: Country;
  invoiceCycle: InvoicingCycle;
  iataCodeAirline: IATA_airline;
}

@Component({
  selector: 'recipients-recipient-form-dialog',
  templateUrl: './invoice-recipient.component.html',
  styleUrls: ['./invoice-recipient.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceRecipientComponent {

  dialogRef: any;
  languages = LanguageType;
  invoicingTypes = InvoicingType;
  action: string;
  active: false;
  recipient: InvoiceRecipient;
  dialogTitle: string;
  pageType: string;
  recipientForm: FormGroup;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  countriesCtrl = new FormControl();

  filteredCountries: Observable<Country[]>;
  countries: Country[] = [];
  allCountries: Country[];

  cyclesCtrl = new FormControl();

  filteredCycles: Observable<InvoicingCycle[]>;
  cycles: InvoicingCycle[] = [];
  allCycles: InvoicingCycle[];

  iataCodesCtrl = new FormControl();

  filteredIataCodes: Observable<IATA_airline[]>;
  iataCodes: IATA_airline[] = [];
  allIataCodes: IATA_airline[];
  @ViewChild('addressAutoComplete') addressAutoCompleteRef: ElementRef;

  private _unsubscribeAll: Subject<any>;
  USTArray:any = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<InvoiceRecipientComponent>,
    private _invoiceRecipientService: InvoiceRecipientService,
    private _countryService: CountriesService,
    private _cycleService: InvoiceCyclesService,
    private _iataCodesService: AirlinesService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    public _matDialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.recipient = new InvoiceRecipient(_data.recipient);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Recipient' : 'New Recipient';
    this.recipientForm = this.createRecipientForm();

    this._unsubscribeAll = new Subject();
    this.setupAutocompleteDataSourceCountry();
    this.setupAutocompleteDataSourceCycle();
    this.setupAutocompleteDataSourceIATA();
  }

  /**
   * Create user form
   *
   * @returns {FormGroup}
   */
  createRecipientForm(): FormGroup {
    const selectedEnum = this.languages.find(language => language.value == this.recipient.languageType);
    
    return this._formBuilder.group({
      iR_ID: [this.recipient.iR_ID],
      external_IR_ID: [this.recipient.external_IR_ID],
      companyName: [this.recipient.companyName, [Validators.required, Validators.maxLength(80)]],
      responsiblePerson: [this.recipient.responsiblePerson],
      address: [this.recipient.address, [Validators.required]],
      zipCode: [this.recipient.zipCode],
      city: [this.recipient.city],
      vaT_ID: [this.recipient.vaT_ID],
      ccEmail: [this.recipient.ccEmail],
      active: [this.recipient.active],
      phoneNumber: [this.recipient.phoneNumber],
      email: [this.recipient.email, [Validators.email, Validators.required]],
      ibaN_account: [this.recipient.ibaN_account],
      biC_account: [this.recipient.biC_account],
      ust: [this.recipient.ust.toString()] ,
      paymentTerms: [this.recipient.paymentTerms],
      language: [this.recipient.languageType || 1],
      countryId: [this.recipient.countryId],
      countriesCtrl: [this.recipient.countryName],
      invoicingCycleId: [this.recipient.invoicingCycleId],
      iatA_codeAirlineId: [this.recipient.iatA_codeAirlineId],
      iataCodesCtrl: [this.recipient.iataName],
      cyclesCtrl: [this.recipient.invoicingCycleName],
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.recipientForm.getRawValue(),
      country: this.countries[0],
      invoiceCycle: this.cycles[0],
      iataCodeAirline: this.iataCodes[0]
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.recipientForm.getRawValue(),
      country: this.countries[0],
      invoiceCycle: this.cycles[0],
      iataCodeAirline: this.iataCodes[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.recipientForm.getRawValue(),
      country: this.countries[0],
      invoiceCycle: this.cycles[0],
      iataCodeAirline: this.iataCodes[0]
    });
  }

  private setupAutocompleteDataSourceCountry() {
    this._countryService.getCountries().then(countries => {
      this.allCountries = countries;
      this.filteredCountries = this.countriesCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterCountry(name) : this.allCountries.slice()));
    });
    this.countriesCtrl.setValue(this.recipient.countryName);
  }

  private _filterCountry(name: string): Country[] {
    const filterValue = name.toLowerCase();

    this.countries = this.allCountries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
    return this.countries;
  }


  private setupAutocompleteDataSourceCycle() {
    this._cycleService.getCycles().then(cycles => {
      this.allCycles = cycles;
      this.filteredCycles = this.cyclesCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterCycles(name) : this.allCycles.slice()));
    });
    this.cyclesCtrl.setValue(this.recipient.invoicingCycleName);
  }

  private _filterCycles(name: string): InvoicingCycle[] {
    const filterValue = name.toLowerCase();

    this.cycles = this.allCycles.filter(cycle => cycle.name.toLowerCase().indexOf(filterValue) === 0);
    return this.cycles;
  }

  private setupAutocompleteDataSourceIATA() {
    this._iataCodesService.getAirlines().then(iataCodes => {
      this.allIataCodes = iataCodes;
      this.filteredIataCodes = this.iataCodesCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterIATA(name) : this.allIataCodes.slice()));
    });
    this.iataCodesCtrl.setValue(this.recipient.iataName);
  }

  private _filterIATA(name: string): IATA_airline[] {
    const filterValue = name.toLowerCase();

    this.iataCodes = this.allIataCodes.filter(iataCode => iataCode.airlineName.toLowerCase().indexOf(filterValue) === 0);
    return this.iataCodes;
  }

  ngAfterViewInit(): void {
    this.googlePlaceAutoComplete();
    this.getUST();
  }

  googlePlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      const options = {
        componentRestrictions: { country: ["at", "si", "sk", "hu", "cz"] }
      };

      let addressAutocomplete = new google.maps.places.Autocomplete(this.addressAutoCompleteRef.nativeElement, options);
      addressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let addressPlace: google.maps.places.PlaceResult = addressAutocomplete.getPlace();
          if (addressPlace && addressPlace.name && addressPlace.formatted_address) {
            this.recipientForm.patchValue({
              address: (addressPlace.name && !(addressPlace.formatted_address.toLowerCase()).includes(addressPlace.name.toLowerCase())) ? addressPlace.name + ", " + addressPlace.formatted_address : addressPlace.formatted_address
            });
          }
        });
      });
    });
  }

  getUST() {
    this._invoiceRecipientService.getUST().subscribe(res => {
      this.USTArray = res;
      this.USTArray.filter(item => item.key === this.recipient.ust.toString());
    });
  }
}
