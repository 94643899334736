import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { ServiceType } from './serviceType.model';


@Injectable({ providedIn: "root" })
export class ServiceTypesService implements Resolve<ServiceType[]> {

    services: ServiceType[];
    onServiceTypesChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onServiceTypesChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getServiceTypes()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getServiceTypes(): Promise<ServiceType[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/servicetypes')
                .subscribe((response: any) => {
                    this.services = response;
                    this.onServiceTypesChanged.next(this.services);
                    resolve(response);
                }, reject);
        });
    }

    getServiceTypeDetails(id: number) : Observable<ServiceType> {
        const  apiUrl: string = `${environment.urlAddress}/api/servicetypes/${id}`;
        return this.http.get<ServiceType>(apiUrl);
    }

   createServiceType(serviceType: ServiceType) : Observable<ServiceType> {
        const apiUrl: string = `${environment.urlAddress}/api/servicetypes`;
        return this.http.post<ServiceType>(apiUrl, serviceType);
    }


    deleteServiceType(id: number) : Observable<ServiceType>{
        const apiUrl: string = `${environment.urlAddress}/api/servicetypes/${id}`;
        return this.http.delete<ServiceType>(apiUrl);
    }

    


    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onServiceTypesChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);

       
    }
}