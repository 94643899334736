import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { InvoicingInvoicesService } from './invoicing-invoices.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isNumber } from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'main-invoicing-invoices',
    templateUrl: './invoicing-invoices.component.html',
    styleUrls: ['./invoicing-invoices.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InvoicingInvoicesComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    dataSource: FilesDataSource | null;
    displayedColumns = ['invoiceId', 'invoiceDate', 'invoiceRecipient', 'invoiceDownload'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    invoicingInvoicesSearchForm: FormGroup;
    findInvoices: boolean;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {OwnersService} _ownerService
     */
    constructor(
        private _invoicingInvoicesService: InvoicingInvoicesService,
        public _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private router: Router
    ) {
        this._unsubscribeAll = new Subject();
        this.invoicingInvoicesSearchForm = this._formBuilder.group({
            invoiceRecipient: new FormControl(),
            dateFrom: new FormControl(),
            dateTo: new FormControl()
        });
        this.findInvoices = false;
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._invoicingInvoicesService.invoices, this._invoicingInvoicesService, this.paginator, this.sort);
        this.sort.disableClear = true;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleFindForm(): void {
        this.findInvoices = !this.findInvoices;
    }

    searchInvoices(): void {
        let search = this.invoicingInvoicesSearchForm.getRawValue();
        let invoiceRecords = this._invoicingInvoicesService.invoices;
        if(search.invoiceRecipient){
            invoiceRecords = invoiceRecords.filter(item => item.beName.toLowerCase().includes(search.invoiceRecipient.toLowerCase()));            
        }
        if(search.dateFrom){   
            let offset = search.dateFrom.getTimezoneOffset();

            let fromDateTime = new Date(search.dateFrom.getTime() - (offset * 60 * 1000));
            let fromDateTimeString = fromDateTime.toISOString().split('T')[0];
            fromDateTimeString = fromDateTimeString + 'T00:00:00.001Z';     

            invoiceRecords = invoiceRecords.filter(item => (new Date(item.invoiceDate).getTime() - (offset * 60 * 1000) >= new Date(fromDateTimeString).getTime()));
        }

        if(search.dateTo){   
            let offset = search.dateTo.getTimezoneOffset();

            let toDateTime = new Date(search.dateTo.getTime() - (offset * 60 * 1000));
            let toDateTimeString = toDateTime.toISOString().split('T')[0];
            toDateTimeString = toDateTimeString + 'T23:59:59.999Z';         

            invoiceRecords = invoiceRecords.filter(item => (new Date(item.invoiceDate).getTime() - (offset * 60 * 1000) <= new Date(toDateTimeString).getTime()));
        }

        this.dataSource = new FilesDataSource(invoiceRecords, this._invoicingInvoicesService, this.paginator, this.sort);
        this.sort.disableClear = true;
    }

    clearSearch(): void{
        this.dataSource = new FilesDataSource(this._invoicingInvoicesService.invoices, this._invoicingInvoicesService, this.paginator, this.sort);
        this.sort.disableClear = true;
        this.invoicingInvoicesSearchForm.reset();
    }
}



export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {OwnersService} _ownerService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private data,
        private _invoicingInvoicesService: InvoicingInvoicesService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this.data;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._invoicingInvoicesService.onInvoiceInvoicesChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this.data.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            // Grab the page's slice of data.
            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}


