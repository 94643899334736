export const UnitType: {name: string, value: number}[] = [
    {name: "km", value:1},
    {name: "PAU", value:2}
];

export class Unit{
    description: string;
    unitType?: number;
    unitName: number;
    id?: string;

    /**
     * Constructor
     * @param unit
     */
    constructor(unit?){
        unit = unit || {};
        this.id = unit.id || 0;
        this.description = unit.description || '';
        this.unitType = unit.UnitType;
        this.unitName = unit.unitType;
    }
}