import { LngLatLike, Marker } from 'mapbox-gl';
import { MapboxMap, MapPointOptions } from '.';

export class MapPoint {
    public data: any;
    public readonly position: LngLatLike;
    private clickHandler: Function|null;
    private map: MapboxMap;
    private element: HTMLElement;
    private innerElement: HTMLElement;
    private tooltipElement: HTMLElement;
    private marker: Marker;
    private getTooltipHTML: Function;

    constructor(options: MapPointOptions) {
        this.data = options.data;
        this.element = options.element;
        this.tooltipElement = options.tooltipElement;
        this.marker = options.marker;
        this.map = options.map;
        this.position = options.position;
        this.clickHandler = options.clickHandler; 
        this.getTooltipHTML = options.getTooltipHTML;
        this.innerElement = options.innerElement;

        this.element.addEventListener("mouseover", this.onMouseOverHandler);
        this.element.addEventListener("mouseout", this.onMouseOutHandler);
        this.innerElement.addEventListener("click", this.onClickHandler);
    }

    private onMouseOverHandler = () => {
        this.tooltipElement.innerHTML = this.getTooltipHTML(this.data);
        this.tooltipElement.classList.add("showing");
    }

    private onMouseOutHandler = () => {
        this.tooltipElement.classList.remove("showing");
    }

    private onClickHandler = () => {
        if(this.clickHandler !== null) {
            this.clickHandler(this);
        }
    }

    set pointColor(color: string) {
        this.innerElement.style.backgroundColor = color;
    }

    set title(text: string) {
        this.innerElement.innerText = text;
    }

    set onClick(handler: Function|null) {
        this.clickHandler = handler;
    }

    public remove() {
        this.element.removeEventListener("mouseover", this.onMouseOverHandler);
        this.element.removeEventListener("mouseout", this.onMouseOutHandler);
        this.innerElement.removeEventListener("click", this.onClickHandler);
        this.marker.remove();
        this.map.removeMapPoint(this);
    }
}