import { Component, ViewEncapsulation, Inject, Optional, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Owner } from '../owner.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { OwnerService } from './owner.service';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Location } from '@angular/common';
import { OwnersService } from '../owners.service';
import { Country } from 'app/main/country/country.model';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CountriesService } from 'app/main/country/country.service';
import { startWith, map } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';

export interface OwnerDialogResult {
  actionType: string;
  formData: any;
  country: Country
}

@Component({
  selector: 'owners-owner-form-dialog',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OwnerComponent {

  action: string;
  country: Country;
  dialogRef: any;
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1.value == f2.value && f1.viewValue == f2.viewValue;
  }
  owner: Owner;
  dialogTitle: string;
  pageType: string;
  ownerForm: FormGroup;
  myControl = new FormControl();
  options: { id: number, value: string }[] = [{ id: 1, value: 'One' }, { id: 2, value: 'O444e' }, { id: 3, value: 'O222ne' }];
  filteredOptions: Observable<string[]>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  countriesCtrl = new FormControl();

  filteredCountries: Observable<Country[]>;
  countries: Country[] = [];
  allCountries: Country[];

  @ViewChild('addressAutoComplete') addressAutoCompleteRef: ElementRef;
  @ViewChild('baseAddress1AutoComplete') baseAddress1AutoCompleteRef: ElementRef;
  @ViewChild('baseAddress2AutoComplete') baseAddress2AutoCompleteRef: ElementRef;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CompanyServicee} _companyService
   * @param {FormBuilder} _formBuilder
   * @param {Location} _location
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<OwnerComponent>,
    private _ownerService: OwnerService,
    private _ownerrService: OwnersService,
    private _countryService: CountriesService,
    private _formBuilder: FormBuilder,
    private _location: Location,
    private _matSnackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.owner = new Owner(_data.owner);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Company' : 'New Company';
    this.ownerForm = this.createOwnerForm();
    this._unsubscribeAll = new Subject();
    this.setupAutocompleteDataSourceCountry();
  }

  /**
   * Create company form
   *
   * @returns {FormGroup}
   */
  createOwnerForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.owner.id],
      name: [this.owner.name, [Validators.required, Validators.maxLength(40)]],
      logo: [this.owner.logo],
      address: [this.owner.address],
      description: [this.owner.description],
      zipCode: [this.owner.zipCode],
      city: [this.owner.city],
      email: [this.owner.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber: [this.owner.phoneNumber],
      fax: [this.owner.fax],
      website: [this.owner.website],
      companyRegister: [this.owner.companyRegister],
      vaT_ID: [this.owner.vaT_ID],
      bank: [this.owner.bank],
      ibaN_account: [this.owner.ibaN_account],
      biC_account: [this.owner.biC_account],
      countryId: [this.owner.countryId],
      countriesCtrl: [this.owner.countryName],
      baseAddress1: [this.owner.baseAddress1, [Validators.required, Validators.maxLength(250)]],
      baseAddress2: [this.owner.baseAddress2],
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.ownerForm.getRawValue(),
      country: this.countries[0]
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.ownerForm.getRawValue(),
      country: this.countries[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.ownerForm.getRawValue(),
      country: this.countries[0]
    });
  }

  private setupAutocompleteDataSourceCountry() {
    this._countryService.getCountries().then(countries => {
      this.allCountries = countries;
      this.filteredCountries = this.countriesCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterCountry(name) : this.allCountries.slice()));
    });
    this.countriesCtrl.setValue(this.owner.countryName);
  }

  private _filterCountry(name: string): Country[] {
    const filterValue = name.toLowerCase();

    this.countries = this.allCountries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
    return this.countries;
  }

  ngAfterViewInit(): void {
    this.googlePlaceAutoComplete();
  }

  googlePlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      const options = {
        componentRestrictions: { country: ["at", "si", "sk", "hu", "cz"] }
      };

      let addressAutocomplete = new google.maps.places.Autocomplete(this.addressAutoCompleteRef.nativeElement, options);
      let baseAddress1Autocomplete = new google.maps.places.Autocomplete(this.baseAddress1AutoCompleteRef.nativeElement, options);
      let baseAddress2Autocomplete = new google.maps.places.Autocomplete(this.baseAddress2AutoCompleteRef.nativeElement, options);
      addressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let addressPlace: google.maps.places.PlaceResult = addressAutocomplete.getPlace();
          if (addressPlace && addressPlace.name && addressPlace.formatted_address) {
            this.ownerForm.patchValue({
              address: (addressPlace.name && !(addressPlace.formatted_address.toLowerCase()).includes(addressPlace.name.toLowerCase())) ? addressPlace.name + ", " + addressPlace.formatted_address : addressPlace.formatted_address
            });
          }
        });
      });

      baseAddress1Autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let baseAddress1Place: google.maps.places.PlaceResult = baseAddress1Autocomplete.getPlace();
          if (baseAddress1Place && baseAddress1Place.name && baseAddress1Place.formatted_address) {
            this.ownerForm.patchValue({
              baseAddress1: (baseAddress1Place.name && !(baseAddress1Place.formatted_address.toLowerCase()).includes(baseAddress1Place.name.toLowerCase())) ? baseAddress1Place.name + ", " + baseAddress1Place.formatted_address : baseAddress1Place.formatted_address
            });
          }
        });
      });

      baseAddress2Autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let baseAddress2Place: google.maps.places.PlaceResult = baseAddress2Autocomplete.getPlace();
          if (baseAddress2Place && baseAddress2Place.formatted_address) {
            this.ownerForm.patchValue({
              baseAddress2: (baseAddress2Place.name && !(baseAddress2Place.formatted_address.toLowerCase()).includes(baseAddress2Place.name.toLowerCase())) ? baseAddress2Place.name + ", " + baseAddress2Place.formatted_address : baseAddress2Place.formatted_address
            });
          }
        });
      });
    });
  }
}

