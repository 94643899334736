import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { OrdersService } from './orders.service';
import { OrdersComponent } from './orders.component';
import { OrderComponent } from './order/order.component';
import { OrderService } from './order/order.service';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { AgmCoreModule } from '@agm/core';
import { ImportSitaComponent } from './import-sita/import-sita.component';
import { DatePipe } from '@angular/common';
import { CustomerSignaturePopupComponent } from './customer-signature-popup/customer-signature-popup.component';
import { AdditinoalInformationComponent } from './additinoal-information/additinoal-information.component';


const routes = [
    {
        path: '',
        component: OrdersComponent,
        resolve: {
            data: OrdersService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'orders',
        component: OrdersComponent,
        resolve: {
            data: OrdersService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'orders/:id',
        component: OrderComponent,
        resolve: {
            data: OrderService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        OrdersComponent,
        OrderComponent,
        ImportSitaComponent,
        CustomerSignaturePopupComponent,
        AdditinoalInformationComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatDatepickerModule,
        SatDatepickerModule, 
        SatNativeDateModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
            libraries: ["places"]
        })
    ],
    providers: [
        OrdersService,
        DatePipe
    ],
    exports: [
        OrdersComponent, 
        OrderComponent,
        ImportSitaComponent,
        CustomerSignaturePopupComponent,
        AdditinoalInformationComponent
    ],
    entryComponents: [
        ImportSitaComponent,
        CustomerSignaturePopupComponent,
        AdditinoalInformationComponent
    ]
})

export class OrderModule {
}
