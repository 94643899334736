import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({ providedIn: "root" })
export class ImportSitaService {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) { }

    getSITAOrder(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.get(environment.urlAddress + '/api/orders/FetchSitaOrder')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}