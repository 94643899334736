import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { Order } from './order.model';
import { TourTable } from '../tour/tour.model';
import { TourOrder } from './tour-order.model';
import { Email } from './order/email-details/email.model';



@Injectable({ providedIn: 'root' })
export class DispatcherOrdersService {

    tourOrder: TourOrder;
    tours: TourTable[];
    orders: Order[];
    onOrdersChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];

    constructor(private http: HttpClient) {
        this.onOrdersChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    getOrders(): Promise<Order[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/orders/nonAssigned')
                .subscribe((response: any) => {
                    this.orders = response;
                    this.onOrdersChanged.next(this.orders);
                    resolve(response);
                }, reject);
        });
    }

    getOrderDetails(id: number): Observable<Order> {
        const apiUrl = `${environment.urlAddress}/api/orders/${id}`;
        return this.http.get<Order>(apiUrl);
    }

    getOrderByEmailId(emailId: number): Observable<Email> {
        const apiUrl = `${environment.urlAddress}/api/orders/${emailId}/email`;
        return this.http.get<Email>(apiUrl);
    }

    getTours(): Promise<TourTable[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/tours/with-orders')
                .subscribe((response: any) => {
                    this.tours = response;
                    this.onOrdersChanged.next(this.tours);
                    resolve(response);
                }, reject);
        });
    }

    createTourOrder(tour: TourTable, order: Order): Promise<TourOrder> {
        const tourOrder = {
            tourTableId: tour.id,
            orderId: order.id
        };
        const apiUrl = `${environment.urlAddress}/api/tour_order`;
        return this.http.post<TourOrder>(apiUrl, tourOrder).toPromise();

    }

    getOrdersByDates(date: string): Promise<Order[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.urlAddress}/api/orders/nonAssigned?filterDate=${date}`)
                .subscribe((response: any) => {
                    this.orders = response;
                    this.onOrdersChanged.next(this.orders);
                    resolve(response);
                }, reject);
        });
    }

    createOrder(order, iata_airline) : Observable<Order> {
        if(iata_airline){
            order.iatA_airlineId = iata_airline.id;
        }
        if(order.deliveryDate){
            let deliveryDate = order.deliveryDate;
            const offset = deliveryDate.getTimezoneOffset();

            let deliveryDateTime = new Date(deliveryDate.getTime() - (offset * 60 * 1000));
            let deliveryDateTimeString = deliveryDateTime.toISOString().split('T')[0]; 
            order.deliveryDate = deliveryDateTimeString;
        }
        const apiUrl: string = `${environment.urlAddress}/api/orders`;
        return this.http.post<Order>(apiUrl, order);
    }

    saveOrder(order, iata_airline): Observable<any> {
        if (iata_airline) {
            order.iatA_airlineId = iata_airline.id;
        }
        if(order.deliveryDate && typeof order.deliveryDate.getMonth === 'function'){
            let deliveryDate = order.deliveryDate;
            const offset = deliveryDate.getTimezoneOffset();

            let deliveryDateTime = new Date(deliveryDate.getTime() - (offset * 60 * 1000));
            let deliveryDateTimeString = deliveryDateTime.toISOString().split('T')[0]; 
            order.deliveryDate = deliveryDateTimeString;
        }
        return this.http.put(environment.urlAddress + '/api/orders/' + order.id, order);
    }

    deleteOrder(id: string): Observable<Order> {
        const apiUrl = `${environment.urlAddress}/api/orders/${id}`;
        return this.http.delete<Order>(apiUrl);
    }

}
