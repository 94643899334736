import { LineString, Position } from "geojson";
import { LngLatLike } from "mapbox-gl";
import { positions } from "../helpers/coordinates";

export class Geometry implements LineString {
    public type: "LineString" = "LineString";
    public coordinates: Position[];

    constructor(coordinates: LngLatLike[]) {
        this.coordinates = positions(coordinates);
    }
}