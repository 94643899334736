import { Component, Inject, Optional } from '@angular/core';
import { PriceTable } from '../price-table.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { PriceTablesService } from '../price-tables.service';
import { PriceTableService } from './table.service';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'price-tables-price-table-form-dialog',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  action: string;
  priceTable: PriceTable;
  dialogTitle: string;
  pageType: string;
  priceTableForm: FormGroup;
  myControl = new FormControl();
  options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
  filteredOptions: Observable<string[]>;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
      @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
      public matDialogRef: MatDialogRef<TableComponent>,
      private _priceTableService: PriceTableService,
      private _formBuilder: FormBuilder,
      private _matSnackBar: MatSnackBar
  )
  {
      this.priceTable = new PriceTable(_data.priceTable);
      this.action = _data.action;
      this.dialogTitle = this.action === 'edit' ? 'Edit Pice table' : 'New Price table';
      this.priceTableForm = this.action === 'edit' ? this.EditPriceTableForm() : this.createPriceTableForm();
      this._unsubscribeAll = new Subject();
  }

  EditPriceTableForm(): FormGroup
  {
      return this._formBuilder.group({
          id              : [this.priceTable.id],
          name            : [this.priceTable.name, [Validators.required]],
          beginDate       : [this.priceTable.beginDate],
          endDate         : [this.priceTable.endDate],
          dateOfCreation  : [this.priceTable.dateOfCreation]
      });
  }

  createPriceTableForm(): FormGroup
  {
      return this._formBuilder.group({
          id              : [this.priceTable.id],
          name            : [this.priceTable.name, [Validators.required]],
          beginDate       : [this.priceTable.beginDate],
          endDate         : [this.priceTable.endDate],
          dateOfCreation  : [this.priceTable.dateOfCreation],
          IsCopy          : [this.priceTable.IsCopy]
      });
  }

  /*savePriceTable(): void
    {
        const data = this.priceTableForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._priceTableService.savePriceTable(data)
            .then(() => {

                this._priceTableService.onPriceTableChanged.next(data);

                this._matSnackBar.open('Price table saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }*/
}
