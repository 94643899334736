export class District{
    name: string;
    zoneId: string;
    zoneName?: string;
    postalCode: string;
    id?: number;

     /**
     * Constructor
     * @param District
     */
    constructor(district?){
        district = district || {};
        this.id = district.id || 0;
        this.name = district.name || '';
        this.zoneId = district.zoneId || '';
        this.zoneName = district.zoneName || '';
        this.postalCode = district.postalCode || '';
    }
}