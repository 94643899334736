import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatCheckboxModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { ServiceTypesComponent } from './service-types.component';
import { ServiceTypeComponent } from './service-type/service-type.component';
import { ServiceTypesService } from './serviceTypes.service';
import { ServiceTypeService } from './service-type/serviceType.service';

const routes = [
    {
        path: '',
        component: ServiceTypesComponent,
        resolve: {
            data: ServiceTypesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'servicetypes',
        component: ServiceTypesComponent,
        resolve: {
            data: ServiceTypesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'servicetypes/:id',
        component: ServiceTypeComponent,
        resolve: {
            data: ServiceTypeService
        },
        canActivate: [AuthenticationGuard]
    } 
];

@NgModule({
    declarations: [
        ServiceTypesComponent,
        ServiceTypeComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,

        
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        ServiceTypesService
    ],
    exports: [
        ServiceTypesComponent,
        ServiceTypeComponent
    ]
})

export class ServiceTypesModule {
}
