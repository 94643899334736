import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ZonesService } from '../zones.service';


@Injectable({ providedIn: "root" })
export class ZoneService implements Resolve<any>
{
    routeParams: any;
    zone: any;
    onZoneChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _zonesService: ZonesService
    )
    {
        this.onZoneChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getZone()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getZone(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onZoneChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/zones/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.zone = response;
                    this.onZoneChanged.next(this.zone);
                    resolve(response);
                }, reject);
 
        });
    }

    /**
     * Save owner
     *
     * @param owner
     * @returns {Promise<any>}
     */
    saveZone(zone): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/zones/' + zone.id, zone)
                .subscribe((response: any) => {
                    resolve(response);
                    this._zonesService.getZones();
                }, reject);
        });
    }

    /**
     * Add owner
     *
     * @param zone
     * @returns {Promise<any>}
     */
   
}