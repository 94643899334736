import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatAutocompleteModule, MatDatepickerModule, MatCheckboxModule, MatProgressSpinnerModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import {MatToolbarModule} from '@angular/material/toolbar';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { InvoicingDraftDetailsComponent } from './invoicing-draft-details.component';
import { NgxPrintModule } from 'ngx-print';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

const routes = [
    {
        path: 'invoicing/invoicing-module/form-details/:id/:type',
        component: InvoicingDraftDetailsComponent,
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    entryComponents: [
    ],
    declarations: [
        InvoicingDraftDetailsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatToolbarModule,
        MatDialogModule,
        MatAutocompleteModule,
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        NgxPrintModule
    ],
    exports: [
        InvoicingDraftDetailsComponent
    ],
    providers:[
        InvoicingDraftDetailsComponent
    ]
})

export class InvoicingDraftDetailsModule {
}
