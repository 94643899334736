import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { VendorDraft } from "./vendor-drafts.model";


@Injectable({ providedIn: "root" })
export class VendorDraftsService implements Resolve<VendorDraft[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    invoiceDrafts: VendorDraft[];
    onInvoiceDraftsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];

    constructor(private http: HttpClient) {
        this.onInvoiceDraftsChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getInvoiceDrafts()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getInvoiceDrafts(): Promise<VendorDraft[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/invoice/VendorDraftList')
                .subscribe((response: any) => {
                    this.invoiceDrafts = response;
                    this.onInvoiceDraftsChanged.next(this.invoiceDrafts);
                    resolve(response);
                }, reject);
        });
    }

    getInvoiceDraftDetails(invoiceId, type = "draft"): Promise<any> {
        return new Promise((resolve, reject) => {
            const apiUrl = environment.urlAddress + '/api/invoice/' + (type == "draft" ? ('getvendorinvoicebyid?invoiceId=' + invoiceId) : 'getvendorcreditnotesbyid?id=' + invoiceId);
            this.http.get(apiUrl)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    createDraft(tourVendorPriceIds): Observable<any> {
        let loginUserDetails = JSON.parse(localStorage.getItem('loginDetails'));
        const apiUrl: string = `${environment.urlAddress}/api/invoice/createvendorinvoice?tourVendorPriceIds=` + tourVendorPriceIds + '&user_id=' + loginUserDetails.id;
        return this.http.get<any>(apiUrl);
    }

    createInvoice(vendorInvoiceIds): Observable<any> {
        let loginUserDetails = JSON.parse(localStorage.getItem('loginDetails'));
        const apiUrl: string = `${environment.urlAddress}/api/invoice/createcreditnote?VendorInvoiceIds=` + vendorInvoiceIds + '&userId=' + loginUserDetails.id;
        return this.http.get<any>(apiUrl);
    }

    deleteDraft(invoiceIds): Observable<any> {
        const apiUrl: string = `${environment.urlAddress}/api/invoice/deletedraft`;
        return this.http.post<any>(apiUrl, { "Ids": invoiceIds });
    }

    deleteVendorInvoiceDraft(invoiceIds): Observable<any> {
        const apiUrl: string = `${environment.urlAddress}/api/invoice/DeleteVendorInvoiceDraft`;
        return this.http.post<any>(apiUrl, { "Ids": invoiceIds });
    }
}