import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'entities',
                title: 'Entities',
                translate: 'NAV.ENTITIES',
                type: 'collapsable',
                icon: 'portrait',
                children: [
                    {
                        id: 'invoicerecipients',
                        title: 'Invoice Recipients',
                        translate: 'NAV.INVOICE-RECIPIENTS.TITLE',
                        type: 'item',
                        icon: 'business_center',
                        url: '/invoicerecipients',
                    },
                    {
                        id: 'beneficiaries',
                        title: 'Beneficiaries',
                        translate: 'NAV.BENEFICIARIES.TITLE',
                        type: 'item',
                        icon: 'person_pin',
                        url: '/beneficiaries',
                    },
                    {
                        id: 'vendors',
                        title: 'Vendors',
                        translate: 'NAV.VENDOR',
                        type: 'collapsable',
                        icon: 'business',
                        children: [
                            {
                                id: 'vendors',
                                title: 'Vendors',
                                translate: 'NAV.VENDORS.TITLE',
                                type: 'item',
                                icon: 'business',
                                url: '/vendors',
                            },
                            {
                                id: 'vehicles',
                                title: 'Vehicles',
                                translate: 'NAV.VEHICLES.TITLE',
                                type: 'item',
                                icon: 'airport_shuttle',
                                url: '/vehicles',
                            },
                            {
                                id: 'drivers',
                                title: 'Drivers',
                                translate: 'NAV.DRIVERS.TITLE',
                                type: 'item',
                                icon: 'airline_seat_recline_extra',
                                url: '/drivers',
                            },
                            {
                                id: 'vehicleTypes',
                                title: 'Vehicle Type',
                                translate: 'NAV.VEHICLE-TYPE.TITLE',
                                type: 'item',
                                icon: 'airport_shuttle',
                                url: '/vehicleTypes',
                            }
                        ]
                    }
                ]
            },
            {
                id: 'dispatcher',
                title: 'Dispatcher',
                translate: 'NAV.DISPATCHER.TITLE',
                type: 'collapsable',
                icon: 'directions_car',
                children: [
                    {
                        id: 'tableItems',
                        title: 'Price tables',
                        translate: 'NAV.PRICE-TABLES.TITLE',
                        type: 'item',
                        icon: 'markunread_mailbox',
                        url: '/tableItems',
                    },
                    {
                        id: 'pricetables',
                        title: 'Tables',
                        translate: 'NAV.TABLES.TITLE',
                        type: 'item',
                        icon: 'markunread_mailbox',
                        url: '/pricetables',
                    },
                    {
                        id: 'servicetypes',
                        title: 'Service Types',
                        translate: 'NAV.SERVICE-TYPES.TITLE',
                        type: 'item',
                        icon: 'add_to_photos',
                        url: '/servicetypes',
                    },
                    {
                        id: 'orders',
                        title: 'Orders',
                        translate: 'NAV.ORDERS.TITLE',
                        type: 'item',
                        icon: 'markunread_mailbox',
                        url: '/orders',
                    },
                    {
                        id: 'tours',
                        title: 'Tours',
                        translate: 'NAV.TOURS.TITLE',
                        type: 'collapsable',
                        icon: 'directions_bus',
                        children: [
                            {
                                id: 'tours',
                                title: 'Tour Items',
                                translate: 'NAV.TOURS.TITLE',
                                type: 'item',
                                icon: 'directions_bus',
                                url: '/tours',
                            },
                            {
                                id: 'fixedTours',
                                title: 'Fixed Tours',
                                translate: 'NAV.FIXED-TOURS.TITLE',
                                type: 'item',
                                icon: 'departure_board',
                                url: '/fixedTours',
                            }
                        ]
                    }
                ]
            },
            {
                id: 'client-data',
                title: 'Basic data',
                translate: 'NAV.BASICDATA.TITLE',
                type: 'collapsable',
                icon: 'insert_drive_file',
                children: [
                    {
                        id: 'profile',
                        title: 'Company',
                        translate: 'NAV.PROFILE',
                        type: 'item',
                        icon: 'list',
                        url: '/owners'
                    },
                    {
                        id: 'districts',
                        title: 'Districts',
                        translate: 'NAV.DISTRICTS',
                        type: 'item',
                        icon: 'list',
                        url: '/districts'
                    },
                    {
                        id: 'iata_airlines',
                        title: 'IATA airline',
                        translate: 'NAV.AIRLINES.TITLE',
                        type: 'item',
                        icon: 'flight',
                        url: '/iata_airlines',
                    },
                    {
                        id: 'iata_airports',
                        title: 'IATA airport',
                        translate: 'NAV.AIRPORTS.TITLE',
                        type: 'item',
                        icon: 'flight_takeoff',
                        url: '/iata_airports',
                    },
                    {
                        id: 'countries',
                        title: 'Country',
                        translate: 'NAV.COUNTRY.TITLE',
                        type: 'item',
                        icon: 'map',
                        url: '/countries',
                    },
                    {
                        id: 'zones',
                        title: 'Zones',
                        translate: 'NAV.ZONES.TITLE',
                        type: 'item',
                        icon: 'place',
                        url: '/zones',
                    },
                    {
                        id: 'logs',
                        title: 'Logs',
                        translate: 'NAV.LOGS.TITLE',
                        type: 'item',
                        icon: 'ballot',
                        url: '/logs',
                    }

                ]
            },
            {
                id: 'invoicing',
                title: 'Invoicing',
                translate: 'NAV.INVOICING',
                type: 'collapsable',
                icon: 'business_center',
                children: [
                    {
                        id: 'invoiceCycles',
                        title: 'Invoice Cycle',
                        translate: 'NAV.INVOICE-CYCLE.TITLE',
                        type: 'item',
                        icon: 'autorenew',
                        url: '/invoiceCycles',
                    },
                    {
                        id: 'invocing_module',
                        title: 'Invoicing module',
                        translate: 'NAV.INVOICING_MODULE.TITLE',
                        type: 'collapsable',
                        icon: 'business',
                        children: [
                            {
                                id: 'invoices',
                                title: 'Invoices',
                                translate: 'NAV.INVOICES.TITLE',
                                type: 'item',
                                icon: 'business',
                                url: '/invoicing/invoicing-module/reports',
                            },
                            {
                                id: 'drafts',
                                title: 'Drafts',
                                translate: 'NAV.DRAFTS.TITLE',
                                type: 'item',
                                icon: 'markunread_mailbox',
                                url: '/invoicing/invoicing-module/drafts',
                            }
                        ]
                    },
                    {
                        id: 'vendors',
                        title: 'Vendors',
                        translate: 'NAV.VENDOR',
                        type: 'collapsable',
                        icon: 'business',
                        children: [
                            {
                                id: 'overview',
                                title: 'Overview',
                                translate: 'NAV.OVERVIEW.TITLE',
                                type: 'item',
                                icon: 'info_outline',
                                url: 'invoicing/vendor-module/overview',
                            },
                            {
                                id: 'drafts',
                                title: 'Drafts',
                                translate: 'NAV.DRAFTS.TITLE',
                                type: 'item',
                                icon: 'markunread_mailbox',
                                url: 'invoicing/vendor-module/drafts',
                            },
                            {
                                id: 'credit_notes',
                                title: 'Credit Notes',
                                translate: 'NAV.CREDIT_NOTES.TITLE',
                                type: 'item',
                                icon: 'note',
                                url: 'invoicing/vendor-module/creditNotes',
                            }
                        ]
                    }
                ]
            },
            {
                id: 'user-management',
                title: 'User management',
                translate: 'NAV.USERMANAGEMENT',
                type: 'collapsable',
                icon: 'group',
                children: [
                    {
                        id: 'users',
                        title: 'Users',
                        translate: 'NAV.USERS.TITLE',
                        type: 'item',
                        icon: 'person',
                        url: '/user-management/users',
                    },
                    {
                        id: 'vendorRole',
                        title: 'Vendor Companies',
                        translate: 'NAV.VENDORS_COMPANIES.TITLE',
                        type: 'item',
                        icon: 'person',
                        url: '/user-management/vendorRole',
                    },
                    {
                        id: 'dispatcherRole',
                        title: 'Dispatchers',
                        translate: 'NAV.DISPATCHERS.TITLE',
                        type: 'item',
                        icon: 'person',
                        url: '/user-management/dispatcherRole',
                    },
                    {
                        id: 'beneficiaryRole',
                        title: 'Beneficiaries',
                        translate: 'NAV.BENEFICIARIESS.TITLE',
                        type: 'item',
                        icon: 'person',
                        url: '/user-management/beneficiaryRole',
                    },
                    {
                        id: 'employeeRole',
                        title: 'Employees',
                        translate: 'NAV.EMPLOYEE.TITLE',
                        type: 'item',
                        icon: 'person',
                        url: '/user-management/employeeRole',
                    },
                    {
                        id: 'employeeRole',
                        title: 'Roles',
                        translate: 'NAV.ROLES.TITLE',
                        type: 'item',
                        icon: 'person',
                        url: '/user-management/roles',
                    }
                ]
            },
            {
                id: 'reports',
                title: 'Reports',
                translate: 'NAV.REPORTS',
                type: 'collapsable',
                icon: 'assignment',
                children: [
                    {
                        id: 'invoice-reports',
                        title: 'Invoice',
                        translate: 'NAV.INVOICE.TITLE',
                        type: 'item',
                        icon: 'business_center',
                        url: '/reports/invoices',
                    }
                ]
            }
        ]
    }
];
