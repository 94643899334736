import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { TourTable } from '../tour/tour.model';
import { DriverVehicleSession } from './driver-vehicle-session.model';

@Injectable({ providedIn: "root" })
export class DispatcherDriversService  {

   
    sessions: DriverVehicleSession[];
    tours: TourTable[];
    onSessionsChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onSessionsChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    getDrivers(): Promise<DriverVehicleSession[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/sessions')
                .subscribe((response: any) => {
                    this.sessions = response;
                    this.onSessionsChanged.next(this.sessions);
                    resolve(response);
                }, reject);
        });
    }

    getTours(): Promise<TourTable[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/tours/with-orders')
                .subscribe((response: any) => {
                    this.tours = response;
                    this.onSessionsChanged.next(this.tours);
                    resolve(response);
                }, reject);
        });
    }

  
    createTourDriver(tour: TourTable, session: DriverVehicleSession) : Promise<TourTable> {  
        const tourTable = {
            tourTableId: tour.id,
            driverId: session.driverId
        };
        const apiUrl: string = `${environment.urlAddress}/api/drivers/${session.driverId}/tour`;
        return this.http.patch<TourTable>(apiUrl, tourTable).toPromise();

    } 

}
