import { Component,  Optional, Inject } from '@angular/core';
import { Driver } from '../drivers.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ChangeDriverPasswordDialogResult {
  actionType: string;
  formData: any;
  driver: Driver;
}

@Component({
  selector: 'change-driver-password-form-dialog',
  templateUrl: './driver-password.component.html',
  styleUrls: ['./driver-password.component.scss']
})
export class DriverPasswordComponent {

  driver: Driver;
  dialogRef: any;
  action: string;
  dialogTitle: string;
  pageType: string;
  driverForm: FormGroup;

 private _unsubscribeAll: Subject<any>;

 /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   * @param {Location} _location
   * @param {MatSnackBar} _matSnackBar
   */
 constructor(
   @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
   public matDialogRef: MatDialogRef<DriverPasswordComponent>,
   private _formBuilder: FormBuilder
 ) {
   this.driver = new Driver(_data.driver);
   this.action = _data.action;
   this.dialogTitle = this.action === 'edit' ? 'Edit Password' : 'New Password';
   this.driverForm = this.createChangePasswordForm();
   this._unsubscribeAll = new Subject();
 }

 /**
    * Create user form
    *
    * @returns {FormGroup}
    */
   createChangePasswordForm(): FormGroup {
   return this._formBuilder.group({
     id: [this.driver.id],
     password: ['', [Validators.required]]
   });
 }

 onSaveClick() {
   this.matDialogRef.close({
     actionType: 'save',
     driver: this.driver,
     formData: this.driverForm.getRawValue()
   });
 }
}