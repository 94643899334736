export class IATA_airline{
    airlineName: string;
    iatA_code: string;
    country: string;
    codeName: string;
    interface: boolean;
    costSharing: boolean;
    priceGroups: string;
    id?: string;

    /**
     * Constructor
     * @param airline
     */
    constructor(airline?){
        airline = airline || {};
        this.id = airline.id || 0;
        this.airlineName = airline.airlineName || '';
        this.iatA_code = airline.iatA_code || '';
        this.country = airline.country || '';
        this.codeName = airline.codeName || '';
        this.interface = airline.interface || false;
        this.costSharing = airline.costSharing || false;
        this.priceGroups = airline.priceGroups || '';
    }
}