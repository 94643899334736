import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatSort, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { debounceTime, takeUntil, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { PriceTablePriceItemService } from './price-table-items.service';
import { ItmTblComponent, PriceItemDialogResult } from './itm-tbl/itm-tbl.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PriceTablePriceItem } from './price-table-items.model';
import { Unit } from 'app/main/units/unit.model';
import { ServiceType } from 'app/main/service-types/serviceType.model';
import { isNumber } from 'lodash';
import { PriceTablesService } from '../price-tables/price-tables.service';
import { PriceTable } from '../price-tables/price-table.model';
import { ImportPricingComponent } from './import-pricing/import-pricing.component';



@Component({
    selector: 'main-price-table-items',
    templateUrl: './price-table-items.component.html',
    styleUrls: ['./price-table-items.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PriceTableItemsComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    priceTablePriceItems: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['name', 'unitType', 'zones', 'lfk', 'lhgroup', 'vendorlfk', 'vendorlhgroup', 'costSharing', 'extra', 'changeKG'];
    columns: any;
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;
    priceTableItems: PriceTable[];
    selectedPriceTable: string;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {PriceTablePriceItemService} _priceTablePriceItemService
     */
    constructor(
        private _priceTablePriceItemService: PriceTablePriceItemService,
        private _priceTablesService: PriceTablesService,
        public _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._priceTablesService.getPriceTables().then(response => {
            this.priceTableItems = response;
            if (response.length > 0) {
                this.selectedPriceTable = response[0].id;
                this._priceTablePriceItemService.getPriceItemsById(this.selectedPriceTable);
            }
        });
        this.columns = this.displayedColumns.map(columnName => ({
            columnDef: columnName,
            header: columnName.toUpperCase(),
            cell: (element: any) => {
                if (columnName === 'costSharing') {
                    return element.costSharing ? `YES` : `NO`;
                }
                if (columnName === 'extra') {
                    return element.extra ? `YES` : `NO`;
                }
                if (columnName === 'changeKG') {
                    return element.changeKG ? `YES` : `NO`;
                }
                return `${element[columnName]}`;
            }
        }));

        this.dataSource = new FilesDataSource(this._priceTablePriceItemService, this.paginator, this.sort);
        this.sort.disableClear = true;

        this.constructDynamicColumns();

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    changePriceTable(event) {
        this.selectedPriceTable = event.value;
        this._priceTablePriceItemService.getPriceItemsById(this.selectedPriceTable);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    downloadPricing() {
        this._priceTablePriceItemService.downloadPricing().subscribe((response) => {
            if (response) {
                var downloadURL = window.URL.createObjectURL(response);
                var link = document.createElement('a');
                link.href = downloadURL;
                link.download = "price.xlsx";
                link.click();
            }
        });
    }

    private constructDynamicColumns() {
        for (const priceItem of this.dataSource.filteredData) {
            this.processDynamicColumnField(priceItem, 'irPriceValues');
            this.processDynamicColumnField(priceItem, 'vendorPriceValues');
        }
    }

    private processDynamicColumnField(priceItem, collectionKey) {
        const collection = priceItem[collectionKey];
        for (const subItem of collection) {
            const newColumn = this.getMatColumnFromModel(subItem, collectionKey);
            if (this.displayedColumns.indexOf(newColumn.header) !== -1) {
                continue;
            }
            this.displayedColumns.push(newColumn.header);
            this.columns.push(newColumn);
        }

    }

    private getMatColumnFromModel(columnSubItem, collectionKey) {
        return {
            columnDef: columnSubItem.table,
            header: columnSubItem.table,
            cell: (priceItem: any) => {
                const ourSubItem = priceItem[collectionKey].find(subItem =>
                    subItem.table === columnSubItem.table);
                return `${(ourSubItem && ourSubItem.price) || 'NA'}`;
            }
        };
    }
    editPriceItem(pItm): void {
        this.dialogRef = this._matDialog.open(ItmTblComponent, {
            panelClass: 'tour-form-dialog',
            data: {
                pItm,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: PriceItemDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                switch (response.actionType) {
                    case 'save':
                        if (response.unit) { response.formData.unitId = response.unit.id }
                        if (response.serviceType) { response.formData.serviceTypeId = response.serviceType.id }
                        this._priceTablePriceItemService.savePriceTable(response.formData);
                        break;
                    case 'delete':
                        this.deletePriceItem(response.formData);
                        break;
                }
            });
    }

    deletePriceItem(priceItem): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this._priceTablePriceItemService.deleteItemsTable(priceItem.id).subscribe(() => {
                this._priceTablePriceItemService.getPriceItems();
            });
            this.confirmDialogRef = null;
        });

    }
    newContact(): void {
        this.dialogRef = this._matDialog.open(ItmTblComponent, {
            panelClass: 'price-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: PriceItemDialogResult) => {
                if (!response) {
                    return;
                }

                this.addPriceItem(response.formData, response.unit, response.serviceType);
            });
    }
    importPricing(): void {
        this.dialogRef = this._matDialog.open(ImportPricingComponent, {
            panelClass: 'import-pricing-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: any) => {
                if (!response) {
                    return;
                }
                let loginUserDetails = JSON.parse(localStorage.getItem('loginDetails'));
                const requestPayload: FormData = new FormData();
                requestPayload.append('File', response.selectedFile);
                requestPayload.append('UserId', loginUserDetails.id);

                this._priceTablePriceItemService.importPricingFile(requestPayload).subscribe(() => {
                    this._matSnackBar.open(`Import pricing successfully.`, 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                });
            });
    }
    addPriceItem(pItm: PriceTablePriceItem, unit: Unit, serviceType: ServiceType) {
        if (unit) { pItm.unitId = unit.id }
        if (serviceType) { pItm.serviceTypeId = serviceType.id }

        this._priceTablePriceItemService.createItemsTable(pItm).subscribe(() => {
            this._priceTablePriceItemService.getPriceItems();

            // this._matSnackBar.open('Order Created', 'OK', {
            //     verticalPosition: 'top',
            //     duration        : 2000
            // });
        });
    }
}

export class FilesDataSource extends DataSource<any> {
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {DriversService} _driversService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _priceTablePriceItemService: PriceTablePriceItemService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._priceTablePriceItemService.tableItems;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._priceTablePriceItemService.onItemsChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._priceTablePriceItemService.tableItems.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            // Grab the page's slice of data.
            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}


