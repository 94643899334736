import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { User } from '../users/users.model';



@Injectable({ providedIn: "root" })
export class BeneficaryRoleService implements Resolve<User[]> {

    users: User[];
    onUsersChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onUsersChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUserBeneficiaryRole()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getUserBeneficiaryRole(): Promise<User[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/users/beneficiaryRole')
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUsersChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }
}