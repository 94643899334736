import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Unit } from './unit.model';



@Injectable({ providedIn: "root" })
export class UnitsService implements Resolve<Unit[]> {

    units: Unit[];
    onUnitsChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onUnitsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUnits()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getUnits(): Promise<Unit[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/units')
                .subscribe((response: any) => {
                    this.units = response;
                    this.onUnitsChanged.next(this.units);
                    resolve(response);
                }, reject);
        });
    }

    getUnitDetails(id: number) : Observable<Unit> {
        const  apiUrl: string = `${environment.urlAddress}/api/units/${id}`;
        return this.http.get<Unit>(apiUrl);
    }

}