export const LanguageType: {name: string, value: number}[] = [
    {name: "ENGLISH", value:1},
    {name: "AUSTRIAN", value:2}
];

export const InvoicingType: {name: string, value: number}[] = [
    {name: "DAILY", value:1},
    {name: "WEEKLY", value:2},
    {name: "MONTHLY", value: 3}
];
export class InvoiceRecipient{
    iR_ID: string;
    external_IR_ID?: string;
    companyName: string;
    responsiblePerson: string;
    address: string;
    zipCode: string;
    city: string;
    vaT_ID: string;
    ccEmail: string;
    language?: number;
    active: boolean;
    phoneNumber: string;
    email: string;
    ibaN_account: string;
    biC_account: string;
    languageType: number;
    paymentTerms: string;
    countryId?: string;
    ust: string;
    countryName: string;
    invoicingCycleId?: string;
    iatA_codeAirlineId?: string;
    iataName: string;
    invoicingCycleName: string;

    /**
     * Constructor
     * @param invoiceRecipient
     */
    constructor(invoiceRecipient?){
        invoiceRecipient = invoiceRecipient || {};
        this.iR_ID = invoiceRecipient.iR_ID;
        this.external_IR_ID = invoiceRecipient.external_IR_ID;
        this.companyName = invoiceRecipient.companyName;
        this.responsiblePerson = invoiceRecipient.responsiblePerson;
        this.address = invoiceRecipient.address;
        this.zipCode = invoiceRecipient.zipCode;
        this.city = invoiceRecipient.city;
        this.vaT_ID = invoiceRecipient.vaT_ID;
        this.ccEmail = invoiceRecipient.ccEmail;
        this.language = invoiceRecipient.LanguageType;
        this.languageType = invoiceRecipient.language;
        this.active = invoiceRecipient.active;
        this.phoneNumber = invoiceRecipient.phoneNumber;
        this.email = invoiceRecipient.email;
        this.ibaN_account = invoiceRecipient.ibaN_account;
        this.biC_account = invoiceRecipient.biC_account;
        this.paymentTerms = invoiceRecipient.paymentTerms;
        this.ust = invoiceRecipient.ust || 0;
        this.countryId = invoiceRecipient.countryId || null;
        this.countryName = invoiceRecipient.countryName;
        this.iatA_codeAirlineId = invoiceRecipient.iatA_codeAirlineId || null;
        this.iataName = invoiceRecipient.iataName;
        this.invoicingCycleId = invoiceRecipient.invoicingCycleId || null;
        this.invoicingCycleName = invoiceRecipient.invoicingCycleName;
    }
}

export class UST{
    Key:string
    Value:string
}
