import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { PriceTable } from './price-table.model';


@Injectable({ providedIn: "root" })
export class PriceTablesService implements Resolve<PriceTable[]> {

    priceTables: PriceTable[];
    onPriceTableChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onPriceTableChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPriceTables()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPriceTables(): Promise<PriceTable[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/priceTables')
                .subscribe((response: any) => {
                    this.priceTables = response;
                    this.onPriceTableChanged.next(this.priceTables);
                    resolve(response);
                }, reject);
        });
    }

    getPriceTablesByDates( beginIsoDate1: string,  endIsoDate: string): Promise<PriceTable[]>{
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.urlAddress}/api/pricetables/params?fromDate=${beginIsoDate1}&toDate=${endIsoDate}`)
                .subscribe((response: any) => {
                    this.priceTables = response;
                    this.onPriceTableChanged.next(this.priceTables);
                    resolve(response);
                }, reject);
        });
    }

    getPriceTableDetails(id: number) : Observable<PriceTable> {
        const  apiUrl: string = `${environment.urlAddress}/api/pricetables/${id}`;
        return this.http.get<PriceTable>(apiUrl);
    }

   createPriceTable(priceTable: PriceTable) : Observable<any> {
        const apiUrl: string = `${environment.urlAddress}/api/pricetables`;
        return this.http.post<any>(apiUrl, priceTable);
    }


    deletePriceTable(id: number) : Observable<PriceTable>{
        const apiUrl: string = `${environment.urlAddress}/api/pricetables/${id}`;
        return this.http.delete<PriceTable>(apiUrl);
    }

    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onPriceTableChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

}