import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Vendor } from './vendor.model';
import { Country } from '../country/country.model';


@Injectable({ providedIn: "root" })
export class VendorsService implements Resolve<Vendor[]> {

    vendors: Vendor[];
    onVendorsChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onVendorsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getVendors()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getVendors(): Promise<Vendor[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/vendors')
                .subscribe((response: any) => {
                    this.vendors = response;
                    this.onVendorsChanged.next(this.vendors);
                    resolve(response);
                }, reject);
        });
    }

    getVendorDetails(vE_ID: string) : Observable<Vendor> {
        const  apiUrl: string = `${environment.urlAddress}/api/vendors/${vE_ID}`;
        return this.http.get<Vendor>(apiUrl);
    }

   createVendor(vendor: Vendor, country: Country) : Observable<Vendor> {
    if(country){
        vendor.countryId = country.id;
    }
    if(vendor.active == null){
        vendor.active = false;
    }
        const apiUrl: string = `${environment.urlAddress}/api/vendors`;
        return this.http.post<Vendor>(apiUrl, vendor);
    }


    deleteVendor(vE_ID: string) : Observable<Vendor>{
        const apiUrl: string = `${environment.urlAddress}/api/vendors/${vE_ID}`;
        return this.http.delete<Vendor>(apiUrl);
    }
}