import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatCheckboxModule
} from '@angular/material';
import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { InvoiceCyclesComponent } from './invoice-cycles.component';
import { InvoiceCyclesService } from './invoice-cycles.service';
import { InvoiceCycleComponent } from './invoice-cycle/invoice-cycle.component';
import { InvoiceCycleService } from './invoice-cycle/invoice-cycle.service';

const routes = [
    {
        path: '',
        component: InvoiceCyclesComponent,
        resolve: {
            data: InvoiceCyclesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'invoiceCycles',
        component: InvoiceCyclesComponent,
        resolve: {
            data: InvoiceCyclesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'invoiceCycles/:id',
        component: InvoiceCycleComponent,
        resolve: {
            data: InvoiceCycleService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        InvoiceCyclesComponent,
        InvoiceCycleComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,

        
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        InvoiceCyclesService
    ],
    exports: [
        InvoiceCyclesComponent, 
        InvoiceCycleComponent
    ]
})

export class InvoiceCycleModule {
}
