import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Owner } from './owner.model';
import { Country } from '../country/country.model';


@Injectable({ providedIn: "root" })
export class OwnersService implements Resolve<Owner[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    owners: Owner[];
    onOwnersChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onOwnersChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getOwners()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getOwners(): Promise<Owner[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/owners')
                .subscribe((response: any) => {
                    this.owners = response;
                    this.onOwnersChanged.next(this.owners);
                    resolve(response);
                }, reject);
        });
    }

    getOwnerDetails(id: number) : Observable<Owner> {
        const  apiUrl: string = `${environment.urlAddress}/api/owners/${id}`;
        return this.http.get<Owner>(apiUrl);
    }

   createOwner(owner: Owner, country: Country) : Observable<Owner> {
    if(country){
        owner.countryId = country.id;
    }
        const apiUrl: string = `${environment.urlAddress}/api/owners`;
        return this.http.post<Owner>(apiUrl, owner);
    }


    deleteOwner(id: number) : Observable<Owner>{
        const apiUrl: string = `${environment.urlAddress}/api/owners/${id}`;
        return this.http.delete<Owner>(apiUrl);
    }

    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onOwnersChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);

        this.onSelectedContactsChanged.next(this.selectedContacts);
    }
}