import { LngLatLike } from 'mapbox-gl';

export class MapboxEstimation {
    public readonly placeA: LngLatLike;
    public readonly placeB: LngLatLike;
    public readonly duration: number;
    public readonly distance: number;

    constructor(placeA: LngLatLike, placeB: LngLatLike, duration: number, distance: number) {
        this.placeA = placeA;
        this.placeB = placeB;
        this.duration = duration;
        this.distance = distance;
    }
}