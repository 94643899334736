import { Component, Input } from '@angular/core';

@Component({
  selector: 'mapbox-info-panel',
  templateUrl: './mapbox-info-panel.component.html',
  styleUrls: ['./mapbox-info-panel.component.css']
})
export class MapboxInfoPanel {
   routeDuration: string;
   routeDurationUnit: string;
   routeDistance: string;
   routeDistanceUnit: string;

  @Input('duration') set duration(duration) {
    let routeDuration: number = Math.ceil(duration / 60);
    let routeDurationUnit = "minutes"; 
    if(routeDuration > 60) {
      routeDuration = parseFloat((routeDuration / 60).toFixed(2));
      routeDurationUnit = "hours"; 
    }

    this.routeDuration = routeDuration.toString();
    this.routeDurationUnit = routeDurationUnit;
  }

  @Input('distance') set distance(distance) {
    let routeDistance: number = distance;
    let routeDistanceUnit = "meters"; 
    if(routeDistance > 1000) {
      routeDistance = parseFloat((routeDistance / 1000).toFixed(2));
      routeDistanceUnit = "km"; 
    }

    this.routeDistance = routeDistance ? routeDistance.toString() : "0";
    this.routeDistanceUnit = routeDistanceUnit;
  }
}
