export class VehicleType{
    vehicleTypeName: string;
    id?: number;

    /**
     * Constructor
     * @param vehicleType
     */
    constructor(vehicleType?){
        vehicleType = vehicleType || {};
        this.id = vehicleType.id || 0;
        this.vehicleTypeName = vehicleType.vehicleTypeName || '';
    }
}