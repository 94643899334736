import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbar, MatToolbarModule, MatAutocompleteModule, MatFormFieldControl, MatCheckboxModule, MAT_CHECKBOX_CLICK_ACTION
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { DriversComponent } from './drivers.component';
import { DriverComponent } from './driver/driver.component';
import { DriversService } from './drivers.service';
import { DriverService } from './driver/driver.service';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { DriverPasswordComponent } from './driver-password/driver-password.component';
import { DriverSessionComponent } from './driver-session/driver-session.component';

const routes = [
    {
        path: '',
        component: DriversComponent,
        resolve: {
            data: DriversService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'drivers',
        component: DriversComponent,
        resolve: {
            data: DriversService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'drivers/:id',
        component: DriverComponent,
        resolve: {
            data: DriverService
        }, 
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'drivers/:id',
        component: DriverPasswordComponent,
        resolve: {
            data: DriversService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'drivers/:id',
        component: DriverSessionComponent,
        resolve: {
            data: DriversService
        },
        canActivate: [AuthenticationGuard]
    } 
];

@NgModule({
    declarations: [
        DriversComponent,
        DriverComponent,
        DriverSessionComponent,
        DriverPasswordComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatAutocompleteModule,

        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        DriversService,
        DriverService
    ],
    exports: [
        DriversComponent,
        DriverComponent,
        DriverPasswordComponent,
        DriverSessionComponent
    ]
})

export class DriversModule {
}
