export class InvoicingCycle{
    name: string;
    id?: string;

    /**
     * Constructor
     * @param country
     */
    constructor(invoiceCycle?){
        invoiceCycle = invoiceCycle || {};
        this.id = invoiceCycle.id || 0;
        this.name = invoiceCycle.name || '';
    }
}