import { Map, Marker, LngLatLike, MapboxOptions } from "mapbox-gl";
import { MapPoint } from './MapPoint';
import { MapboxClickablePointOptions } from "./MapboxClickablePointOptions";
import { MapBoxService } from '../mapbox.service';

export class MapboxMap extends Map {
    private clickablePoints: MapPoint[] = [];
    private mapboxService: MapBoxService;

    constructor(options: MapboxOptions, mapboxService: MapBoxService) {
        super(options);
        this.mapboxService = mapboxService;
    }

    public async addClickablePoint(options: MapboxClickablePointOptions) {
        const pointElement = document.createElement("div");
        pointElement.classList.add("marker");
        pointElement.classList.add("shadow-darken");

        const pointInner = document.createElement("div");
        pointInner.classList.add("marker-inner");
        pointInner.innerText = options.title;
        pointInner.style.backgroundColor = options.pointColor;

        const tooltipElement = document.createElement("div");
        tooltipElement.classList.add("marker-tooltip");

        pointElement.appendChild(pointInner);
        pointElement.appendChild(tooltipElement);

        const position = await this.mapboxService.getCoordinatesForAddress(options.address, options.zipCode, options.country);
    
        const marker: Marker = new Marker(pointElement).setLngLat(position).addTo(this);

        const mapPoint: MapPoint =  new MapPoint({
            data: options.data,
            position,
            element: pointElement,
            tooltipElement,
            clickHandler: options.onClick,
            marker,
            map: this,
            getTooltipHTML: options.getTooltipHTML,
            innerElement: pointInner
        });

        this.clickablePoints.push(mapPoint);

        return mapPoint;
    }

    public getAllClickablePoints(): MapPoint[] {
        return this.clickablePoints;
    }

    public removeMapPoint(mapPoint: MapPoint) {
        this.clickablePoints = this.clickablePoints.filter(point => point !== mapPoint);
    }

    
}