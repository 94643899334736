import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { IATA_airport } from './airport.model';


@Injectable({ providedIn: "root" })
export class AirportsService implements Resolve<IATA_airport[]> {

    airports: IATA_airport[];
    onAirportsChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onAirportsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAirports()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAirports(): Promise<IATA_airport[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/iata_airports')
                .subscribe((response: any) => {
                    this.airports = response;
                    this.onAirportsChanged.next(this.airports);
                    resolve(response);
                }, reject);
        });
    }

    getAirportDetails(id: number) : Observable<IATA_airport> {
        const  apiUrl: string = `${environment.urlAddress}/api/iata_airports/${id}`;
        return this.http.get<IATA_airport>(apiUrl);
    }

   createAirport(airport: IATA_airport) : Observable<IATA_airport> {
        const apiUrl: string = `${environment.urlAddress}/api/iata_airports`;
        return this.http.post<IATA_airport>(apiUrl, airport);
    }


    deleteAirport(id: number) : Observable<IATA_airport>{
        const apiUrl: string = `${environment.urlAddress}/api/iata_airports/${id}`;
        return this.http.delete<IATA_airport>(apiUrl);
    }
}