import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Console } from 'console';

@Component({
  selector: 'price-popup-dialog',
  templateUrl: './price-popup.component.html',
  styleUrls: ['./price-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PricePopupComponent {
  action: string;
  dialogTitle: string;
  displayedColumns: string[];
  priceDetails: any[];
  /**
   * Constructor
   *
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public _matDialog: MatDialog,
    public matDialogRef: MatDialogRef<PricePopupComponent>
  ) {
    this.dialogTitle = "Price Details";
    this.displayedColumns = ['netInvoicePrice', 'invoiceTax', 'totalInvoiceProice', 'netVendorPrice', 'vendorTax', 'totalVendorProice'];
    this.priceDetails = [_data.record];
    console.log("this.priceDetails", this.priceDetails);
  }
}