import { Component, ViewEncapsulation, Optional, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Order } from '../order.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { IATA_airline } from 'app/main/airlines/airlines.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { AirlinesService } from 'app/main/airlines/airlines.service';
import { startWith, map } from 'rxjs/operators';
import { EmailDetailsComponent } from './email-details/email-details.component';
import { DispatcherOrdersService } from '../orders.service';
import { Mail } from 'app/mail/mail.model';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'order-form-dialog',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderComponent {

  action: string;
  order: Order;
  mail: Mail;
  dialogTitle: string;
  pageType: string;
  orderForm: FormGroup;
  myControl = new FormControl();
  options: { id: number, value: string }[] = [{ id: 1, value: 'One' }, { id: 2, value: 'O444e' }, { id: 3, value: 'O222ne' }];
  filteredOptions: Observable<string[]>;
  dialogRef: any;
  iata_airlineCtrl = new FormControl();
  filteredIata_airline: Observable<IATA_airline[]>;
  iata_airlines: IATA_airline[] = [];
  allIata_airlines: IATA_airline[];

  @ViewChild('permanentAddressAutoComplete') permanentAddressAutoCompleteRef: ElementRef;
  @ViewChild('deliveryAddressAutoComplete') deliveryAddressAutoCompleteRef: ElementRef;
  @ViewChild('temporaryAddressAutoComplete') temporaryAddressAutoCompleteRef: ElementRef;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<OrderComponent>,
    private _iata_airlineService: AirlinesService,
    private _formBuilder: FormBuilder,
    private _DispatcherOrdersService: DispatcherOrdersService,
    public _matDialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.order = new Order(_data.order);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Order' : 'New Order';
    this.orderForm = this.createOrderForm();
    this._unsubscribeAll = new Subject();

    this.setupAutocompleteDataSourceIATA_airlines();
  }


  /**
   * Create driver form
   *
   * @returns {FormGroup}
   */
  createOrderForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.order.id],
      ahlName: [this.order.ahlName, [Validators.required]],
      name01: [this.order.name01],
      name02: [this.order.name02],
      name03: [this.order.name03],
      deliveryService: [this.order.deliveryService],
      permanentAddress01: [this.order.permanentAddress01],
      permanentAddress02: [this.order.permanentAddress02],
      zipCode: [this.order.zipCode],
      city: [this.order.city],
      deliveryDate: [this.order.deliveryDate || new Date(), [Validators.required]],
      deliveryTime: [this.order.deliveryTime || "00:00"],
      cellPhone01: [this.order.cellPhone01],
      cellPhone02: [this.order.cellPhone02],
      localDelivery01: [this.order.localDelivery01],
      localDelivery02: [this.order.localDelivery02],
      datePickedupForDelivery01: [this.order.datePickedupForDelivery01],
      datePickedupForDelivery02: [this.order.datePickedupForDelivery02],
      deliveryAddress01: [this.order.deliveryAddress01],
      deliveryAddress02: [this.order.deliveryAddress02],
      sendEmail: [this.order.sendEmail],
      routing: [this.order.routing],
      agent: [this.order.agent],
      permanentPhoneNumber01: [this.order.permanentPhoneNumber01],
      permanentPhoneNumber02: [this.order.permanentPhoneNumber02],
      teletypeAddress: [this.order.teletypeAddress],
      temporaryAddress01: [this.order.temporaryAddress01],
      temporaryAddress02: [this.order.temporaryAddress02],
      temporaryZipCode: [this.order.temporaryZipCode],
      numberOfBags: [this.order.numberOfBags],
      emailId: [this.order.emailId],
      emailSubject: [this.order.emailSubject],
      iatA_airlineId: [this.order.iatA_airlineId],
      iata_airlineCtrl: [this.order.codeName],
      StatusType: [this.order.StatusType],
      OriginType: [this.order.OriginType]
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.orderForm.getRawValue(),
      iata_airline: this.iata_airlines[0]
    });
  }

  public mailDetails(order): void {
    console.log('jd');

    this._DispatcherOrdersService.getOrderByEmailId(order.emailId).subscribe(email => {
      this.dialogRef = this._matDialog.open(EmailDetailsComponent, {
        panelClass: 'email-details-form-dialog',
        data: {
          email,
          order,
          action: 'edit'
        }
      });
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }

      });
  }


  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.orderForm.getRawValue(),
      iata_airline: this.iata_airlines[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.orderForm.getRawValue(),
      iata_airline: this.iata_airlines[0]
    });
  }


  private setupAutocompleteDataSourceIATA_airlines() {
    this._iata_airlineService.getAirlines().then(iata_airlines => {
      this.allIata_airlines = iata_airlines;
      this.filteredIata_airline = this.iata_airlineCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterIATA_airline(name) : this.allIata_airlines.slice()));
    });
    this.iata_airlineCtrl.setValue(this.order.codeName);
  }

  private _filterIATA_airline(codeName: string): IATA_airline[] {
    const filterValue = codeName.toLowerCase();

    this.iata_airlines = this.allIata_airlines.filter(iata_airline => iata_airline.codeName.toLowerCase().indexOf(filterValue) === 0);
    return this.iata_airlines;
  }

  ngAfterViewInit(): void {
    this.googlePlaceAutoComplete();
  }
  
  googlePlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      const options = {
        componentRestrictions: { country: ["at", "si","sk", "hu", "cz"] }
      };
    
      let permanentAddressAutocomplete = new google.maps.places.Autocomplete(this.permanentAddressAutoCompleteRef.nativeElement, options);
      let deliveryAddressAutocomplete = new google.maps.places.Autocomplete(this.deliveryAddressAutoCompleteRef.nativeElement, options);
      let temporaryAddressAutocomplete = new google.maps.places.Autocomplete(this.temporaryAddressAutoCompleteRef.nativeElement, options);
      permanentAddressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let permanentAddressPlace: google.maps.places.PlaceResult = permanentAddressAutocomplete.getPlace();
          if (permanentAddressPlace && permanentAddressPlace.name && permanentAddressPlace.formatted_address) {
            this.orderForm.patchValue({
              permanentAddress01: (permanentAddressPlace.name && !(permanentAddressPlace.formatted_address.toLowerCase()).includes(permanentAddressPlace.name.toLowerCase())) ? permanentAddressPlace.name + ", " + permanentAddressPlace.formatted_address : permanentAddressPlace.formatted_address
            });
          }
        });
      });

      deliveryAddressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let deliveryAddressPlace: google.maps.places.PlaceResult = deliveryAddressAutocomplete.getPlace();
          if (deliveryAddressPlace && deliveryAddressPlace.name && deliveryAddressPlace.formatted_address) {
            this.orderForm.patchValue({
              deliveryAddress01: (deliveryAddressPlace.name && !(deliveryAddressPlace.formatted_address.toLowerCase()).includes(deliveryAddressPlace.name.toLowerCase())) ? deliveryAddressPlace.name + ", " + deliveryAddressPlace.formatted_address : deliveryAddressPlace.formatted_address
            });
          }
        });
      });

      temporaryAddressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let temporaryAddressPlace: google.maps.places.PlaceResult = temporaryAddressAutocomplete.getPlace();
          if (temporaryAddressPlace && temporaryAddressPlace.formatted_address) {
            this.orderForm.patchValue({
              temporaryAddress01: (temporaryAddressPlace.name && !(temporaryAddressPlace.formatted_address.toLowerCase()).includes(temporaryAddressPlace.name.toLowerCase())) ? temporaryAddressPlace.name + ", " + temporaryAddressPlace.formatted_address : temporaryAddressPlace.formatted_address
            });
          }
        });
      });
    });
  }

}
