import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DispatcherScreenService } from '../../dispatcher-screen.service';
import { OrderStatusesPopupComponent } from './order-statuses-popup/order-statuses-popup.component';

@Component({
  selector: 'tour-details-popup-dialog',
  templateUrl: './tour-details-popup.component.html',
  styleUrls: ['./tour-details-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TourDetailsPopupComponent {
  action: string;
  dialogTitle: string;
  displayedColumns: string[];
  dataSource: [];
  dialogRef: any;
  tourData: any;
  orderStatusUpdateInProgress: boolean = false;
  orderStatusTypes = [
    { name: "ASSIGNED", value: 1 },
    { name: "NOT ASSIGNED", value: 2 },
    { name: "IN PROGRESS", value: 3 },
    { name: "DELIVERED", value: 4 },
    { name: "NOT DELIVERED", value: 5 },
    { name: "DELIVERED WITH DAMAGE", value: 6 }
  ];
  /**
   * Constructor
   *
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    private dispatcherScreenService: DispatcherScreenService,
    public matDialogRef: MatDialogRef<TourDetailsPopupComponent>,
    public _matDialog: MatDialog
  ) {
    this.action = _data.action;
    this.dialogTitle = "Tour with orders";
    this.displayedColumns = ['ahlName', 'statusName'];
    this.tourData = _data.tour;
    this.tourData.tour_Orders.map(item => {
      let orderStatusType = this.orderStatusTypes.find(result => result.value == item.orderInfo.statusType);
      item.orderInfo.statusName = orderStatusType ? orderStatusType.name : "";
    });
    this.dataSource = this.tourData.tour_Orders;
  }
  public orderStatusesPopup(order): void {
    this.dialogRef = this._matDialog.open(OrderStatusesPopupComponent, {
      panelClass: 'order-statuses-popup-dialog',
      data: {
        order,
        action: 'edit',
        TourTableId: this.tourData.id
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        switch (response.actionType) {
          case 'change-status':
            this.orderStatusUpdateInProgress = true;
            this.dispatcherScreenService.updateOrdersStatus(response.orderstatusData).subscribe(() => {
              this.tourData.tour_Orders.map(item => {
                this.orderStatusUpdateInProgress = false;
                //Update status & status name in grid
                if (item.orderInfo.id == response.orderstatusData.OrderId) {
                  item.orderInfo.statusType = response.orderstatusData.status;
                  item.orderInfo.statusName = this.orderStatusTypes.find(result => result.value == item.orderInfo.statusType).name;
                }
              });
              this.dataSource = this.tourData.tour_Orders;
            });
            break;
        }

      });
  }
}