import { Component, Optional, Inject } from '@angular/core';
import { Unit, UnitType } from '../unit.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FuseUtils } from '@fuse/utils';
import { UnitService } from './unit.service';

@Component({
  selector: 'units-unit-form-dialog',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent {

  unit: Unit;
  dialogRef: any;
  // mat-selecct
  untiTypes = UnitType;

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
  _matSnackBar: any;

  compareByValue(f1: any, f2: any) {
    return f1.value == f2.value && f1.viewValue == f2.viewValue;
  }
  action: string;
  dialogTitle: string;
  pageType: string;
  unitForm: FormGroup;

  private _unsubscribeAll: Subject<any>;

  /**
    * Constructor
    *
    * @param {FormBuilder} _formBuilder
    * @param {Location} _location
    * @param {MatSnackBar} _matSnackBar
    */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<UnitComponent>,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    public _unitService: UnitService
  ) {
    this.unit = new Unit(_data.unit);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Unit' : 'New Unit';
    this.unitForm = this.createUnitForm();
    this._unsubscribeAll = new Subject();
  }

  /**
     * Create user form
     *
     * @returns {FormGroup}
     */
    createUnitForm(): FormGroup {
    const selectedEnum = this.untiTypes.find(unitType => unitType.value == this.unit.unitName);
    return this._formBuilder.group({
      id: [this.unit.id],
      description: [this.unit.description, [Validators.required, Validators.maxLength(80)]],
      unitType: [this.unit.unitName, [Validators.required]],
    });
  }


    saveUnit(): void
    {
        const data = this.unitForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._unitService.saveUnit(data)
            .then(() => {
                this._unitService.onUnitChanged.next(data);

                this._matSnackBar.open('Unit saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

}
