import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { TourTable } from '../tour/tour.model';

@Component({
  selector: 'tour-date-form-dialog',
  templateUrl: './tour-date.component.html',
  styleUrls: ['./tour-date.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TourDateComponent {
  action: string;
  tour: any;
  dialogTitle: string;
  pageType: string;
  tourDateForm: FormGroup;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   * @param {MatDialogRef} matDialogRef
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<TourDateComponent>,
    private _formBuilder: FormBuilder
  ) {
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Update Tour Date Time' : '';
    console.log(_data.tour);
    this.tour = _data.tour;
    this.tourDateForm = this.createTourDateForm();
    this._unsubscribeAll = new Subject();
  }

  /**
   * Create company form
   *
   * @returns {FormGroup}
   */
  createTourDateForm(): FormGroup {
    return this._formBuilder.group({
      dateOfCreation: [this.tour.dateOfCreation, [Validators.required]],
      timeOfTheTour: [this.tour.timeOfTheTour, [Validators.required]],
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.tourDateForm.getRawValue()
    });
  }
}