import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { UsersService } from 'app/main/user-management/users/users.service';
import { User } from 'app/main/user-management/users/users.model';

const LOGIN_DETAILS_KEY = 'loginDetails';

interface LoginResponseDTO {
    id?: number;
    token: string;
    name: string;
    lastName: string;
    role: string;
    email: string;
    defaultPage?: string;
    ownerId? : number;
}

const defaulPages = {
    "Administrator": "users",
    "Driver": "drivers"
}

@Injectable({ providedIn: 'root' })
export class LoginService {

    private loginDetails: LoginResponseDTO;
    readonly onLoginDetailsChanged: BehaviorSubject<any>;

    constructor(private http: HttpClient, private _router: Router, private FuseConfig: FuseConfigService, private _usersService: UsersService) 
    {
        this.loginDetails =  JSON.parse(localStorage.getItem(LOGIN_DETAILS_KEY));
        this.onLoginDetailsChanged = new BehaviorSubject(this.loginDetails);
    }


    login(credentials): Observable<any> {
        const data = {
            username: credentials.username,
            password: credentials.password,
        };
        return this.http.post<LoginResponseDTO>(`${environment.urlAddress}/api/auth/login`, data, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)));

        function authenticateSuccess(resp: HttpResponse<LoginResponseDTO>): void {
            resp.body.role = resp.body.role || 'Driver';
            resp.body.defaultPage = defaulPages[resp.body.role];
            this.setUserDetails(resp.body);
        }
    }

    logout(): any {
        this.setUserDetails(null);
    }

    getUserDetails(): LoginResponseDTO {
        return this.loginDetails;
    }

    private setUserDetails(details) {
        this.loginDetails = details;
        details ? localStorage.setItem(LOGIN_DETAILS_KEY, JSON.stringify(details)) : localStorage.removeItem(LOGIN_DETAILS_KEY);
        this.onLoginDetailsChanged.next(details);
    }

     updateUserDetails(detailsWithoutToken) {
        Object.assign(this.loginDetails, detailsWithoutToken);
        this.setUserDetails(this.loginDetails);
    }


}
