import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { InvoicingCycle } from './invoice-cycle.model';


@Injectable({ providedIn: "root" })
export class InvoiceCyclesService implements Resolve<InvoicingCycle[]> {

    invoiceCycles: InvoicingCycle[];
    onCyclesChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onCyclesChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCycles()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCycles(): Promise<InvoicingCycle[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/invoiceCycles')
                .subscribe((response: any) => {
                    this.invoiceCycles = response;
                    this.onCyclesChanged.next(this.invoiceCycles);
                    resolve(response);
                }, reject);
        });
    }

    getCycleDetails(id: number) : Observable<InvoicingCycle> {
        const  apiUrl: string = `${environment.urlAddress}/api/invoiceCycles/${id}`;
        return this.http.get<InvoicingCycle>(apiUrl);
    }

   createCycle(cycle: InvoicingCycle) : Observable<InvoicingCycle> {
        const apiUrl: string = `${environment.urlAddress}/api/invoiceCycles`;
        return this.http.post<InvoicingCycle>(apiUrl, cycle);
    }


    deleteCycle(id: number) : Observable<InvoicingCycle>{
        const apiUrl: string = `${environment.urlAddress}/api/invoiceCycles/${id}`;
        return this.http.delete<InvoicingCycle>(apiUrl);
    }

}