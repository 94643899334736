import { NgModule } from '@angular/core';

import { LoginModule } from 'app/main/pages/authentication/login/login.module';
import { Error404Module } from 'app/main/pages/errors/404/error-404.module';
import { Error500Module } from 'app/main/pages/errors/500/error-500.module';
import { ProfileModule } from 'app/main/pages/profile/profile.module';
import { Error403Module } from './errors/403/error-403.module';
import { Error401Module } from './errors/401/error-401.module';

@NgModule({
    imports: [
        LoginModule,
        Error404Module,
        Error401Module,
        Error403Module,
        Error500Module,
        ProfileModule
    ],
    exports: [
        Error500Module,
        Error404Module,
        Error401Module,
        Error403Module
    ]
})
export class PagesModule
{

}
