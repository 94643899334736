export class PriceTablePriceItem{
    serviceTypeId?: string;
    serviceTypeName: string;
    unitId?: string;
    unitName: string;
    unitType: string;
    costSharing: boolean;
    zones: string;
    extra: boolean;
    vendorPriceValues: {table: string, price: number}[];
    irPriceValues: {table: string, price: number}[];
    name: string;
    isDeleted: boolean;
    changeKG: boolean;
    lfk: number;
    lhgroup: number;
    oshandling: number;
    vendorlfk: number;
    vendorlhgroup: number;
    vendor: number;
    id?: string;
    /**
     * Constructor
     * @param priceTablePriceItem
     */
    constructor(priceTablePriceItem?){
        priceTablePriceItem = priceTablePriceItem || {};
        this.id = priceTablePriceItem.id || 0;
        this.serviceTypeId = priceTablePriceItem.serviceTypeId || null;
        this.serviceTypeName = priceTablePriceItem.serviceTypeName || '';
        this.unitId = priceTablePriceItem.unitId || null;
        this.unitName = priceTablePriceItem.unitName || '';
        this.unitType = priceTablePriceItem.unitType || '';
        this.costSharing = priceTablePriceItem.costSharing || false;
        this.zones = priceTablePriceItem.zones || '';
        this.vendorPriceValues = priceTablePriceItem.vendorPriceValues || [];
        this.irPriceValues = priceTablePriceItem.irPriceValues || [];
        this.extra = priceTablePriceItem.extra || false;
        this.name = priceTablePriceItem.name || null;
        this.isDeleted = priceTablePriceItem.isDeleted || false;
        this.changeKG = priceTablePriceItem.changeKG || false;
        this.lfk = priceTablePriceItem.lfk || 0;
        this.lhgroup = priceTablePriceItem.lhgroup || 0;
        this.vendorlfk = priceTablePriceItem.vendorlfk || 0;
        this.vendorlhgroup = priceTablePriceItem.vendorlhgroup || 0;
        this.oshandling = priceTablePriceItem.oshandling || 0;
        this.vendor = priceTablePriceItem.vendor || 0;
    }
}