import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Beneficiary } from './beneficiary.model';


@Injectable({ providedIn: "root" })
export class BeneficiariesService implements Resolve<Beneficiary[]> {

    beneficiaries: Beneficiary[];
    onBeneficiariesChanged: BehaviorSubject<any>;
    onIataChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onBeneficiariesChanged = new BehaviorSubject({});
        this.onIataChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getBeneficiaries()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getBeneficiaries(): Promise<Beneficiary[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/beneficiaries')
                .subscribe((response: any) => {
                    this.beneficiaries = response;
                    this.onBeneficiariesChanged.next(this.beneficiaries);
                    resolve(response);
                }, reject);
        });
    }

    getBeneficiaryDetails(bE_ID: string) : Observable<Beneficiary> {
        const  apiUrl: string = `${environment.urlAddress}/api/beneficiaries/${bE_ID}`;
        return this.http.get<Beneficiary>(apiUrl);
    }

}