import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { PriceTablePriceItem } from './price-table-items.model';


@Injectable({ providedIn: "root" })
export class PriceTablePriceItemService implements Resolve<PriceTablePriceItem[]> {

    tableItems: PriceTablePriceItem[];
    onItemsChanged: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
        this.onItemsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPriceItems()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPriceItems(): Promise<PriceTablePriceItem[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/priceItems')
                .subscribe((response: any) => {
                    this.tableItems = response;
                    this.onItemsChanged.next(this.tableItems);
                    resolve(response);
                }, reject);
        });
    }

    getPriceItemsById(id): Promise<PriceTablePriceItem[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/priceItems/' + id)
                .subscribe((response: any) => {
                    this.tableItems = response;
                    this.onItemsChanged.next(this.tableItems);
                    resolve(response);
                }, reject);
        });
    }

    downloadPricing(): Observable<Blob> {
        const apiUrl: string = `${environment.urlAddress}/api/priceItems/DownloadPricing`;
        return this.http.get(apiUrl, { responseType: 'blob' });
    }


    getPriceItemsDetails(id: number): Observable<PriceTablePriceItem> {
        const apiUrl: string = `${environment.urlAddress}/api/priceItems/${id}`;
        return this.http.get<PriceTablePriceItem>(apiUrl);
    }

    createItemsTable(priceTable: PriceTablePriceItem): Observable<PriceTablePriceItem> {
        const apiUrl: string = `${environment.urlAddress}/api/priceItems`;
        return this.http.post<PriceTablePriceItem>(apiUrl, priceTable);
    }

    importPricingFile(requestPayload: any): Observable<any> {
        const apiUrl: string = `${environment.urlAddress}/api/priceItems/ImportPricing`;
        return this.http.post(apiUrl, requestPayload);
    }

    savePriceTable(priceTable): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.put(environment.urlAddress + '/api/priceItems/' + priceTable.id, priceTable)
                .subscribe((response: any) => {
                    resolve(response);
                    this.getPriceItems();
                }, reject);
        });
    }
    deleteItemsTable(id: number): Observable<PriceTablePriceItem> {
        const apiUrl: string = `${environment.urlAddress}/api/priceItems/${id}`;
        return this.http.delete<PriceTablePriceItem>(apiUrl);
    }

}