import { Component, ViewEncapsulation, Optional, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { User, RoleType } from '../users/users.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Owner } from 'app/main/owners/owner.model';
import { OwnersService } from 'app/main/owners/owners.service';
import { startWith, map } from 'rxjs/operators';
import { Country } from 'app/main/country/country.model';
import { CountriesService } from 'app/main/country/country.service';
import { ChangePasswordComponent, ChangePasswordDialogResult } from '../change-password/change-password.component';
import { UsersService } from '../users/users.service';
import { UserService } from './user.service';
import { MapsAPILoader } from '@agm/core';

export interface UserDialogResult {
  actionType: string;
  formData: any;
  owner: Owner;
  country: Country;
}

@Component({
  selector: 'user-form-dialog',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent {

  user: User;
  dialogRef: any;
  roles = RoleType;
  ownersCtrl = new FormControl();

  filteredOwners: Observable<Owner[]>;
  owners: Owner[] = [];
  allOwners: Owner[];
  countriesCtrl = new FormControl();
  filteredCountries: Observable<Country[]>;
  countries: Country[] = [];
  allCountries: Country[];
  @ViewChild('addressAutoComplete') addressAutoCompleteRef: ElementRef;

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1.value == f2.value && f1.viewValue == f2.viewValue;
  }
  action: string;
  dialogTitle: string;
  pageType: string;
  userForm: FormGroup;

  private _unsubscribeAll: Subject<any>;

  /**
    * Constructor
    *
    * @param {FormBuilder} _formBuilder
    * @param {Location} _location
    * @param {MatSnackBar} _matSnackBar
    */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<UserComponent>,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    public _ownersService: OwnersService,
    public _countryService: CountriesService,
    public _userService: UserService,
    public _usersService: UsersService,
    private _matSnackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.user = new User(_data.user);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit User' : 'New User';
    this.userForm = this.createUserForm();
    this._unsubscribeAll = new Subject();
    this.setupAutocompleteDataSource();
    this.setupAutocompleteDataSourceCountry();
  }

  /**
     * Create user form
     *
     * @returns {FormGroup}
     */
  createUserForm(): FormGroup {
    const selectedEnum = this.roles.find(role => role.value == this.user.roleType);
    return this._formBuilder.group({
      id: [this.user.id],
      name: [this.user.name, [Validators.required, Validators.maxLength(80)]],
      lastName: [this.user.lastName, [Validators.required, Validators.maxLength(80)]],
      address: [this.user.address, [Validators.required, Validators.maxLength(80)]],
      username: [this.user.username, [Validators.required, Validators.maxLength(80)]],
      password: [this.user.password, [Validators.required, Validators.maxLength(80)]],
      email: [this.user.email, [Validators.required, Validators.maxLength(80)]],
      phoneNumber: [this.user.phoneNumber],
      role: [this.user.roleType],
      active: [this.user.active],
      ownerId: [this.user.ownerId],
      ownersCtrl: [this.user.ownerName],
      countryId: [this.user.countryId],
      countriesCtrl: [this.user.countryName]
    });
  }

  changePassword(user): void {
    this.dialogRef = this._matDialog.open(ChangePasswordComponent, {
      panelClass: 'change-password-form-dialog',
      data: {
        user,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: ChangePasswordDialogResult) => {
        if (!response || !response.formData) {
          return;
        }
        this.saveUserPassword(response.user, response.formData.password);
      });

  }

  saveUserPassword(user: User, password: string) {
    user.password = password;
    this._userService.changePassword(user).then(() => {
      this._matSnackBar.open(`Password updated successfully`, 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      this.matDialogRef.close();
    });
  }


  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.userForm.getRawValue(),
      owner: this.owners[0],
      country: this.countries[0]
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.userForm.getRawValue(),
      owner: this.owners[0],
      country: this.countries[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.userForm.getRawValue(),
      owner: this.owners[0],
      country: this.countries[0]
    });
  }

  private setupAutocompleteDataSource() {
    this._ownersService.getOwners().then(owners => {
      this.allOwners = owners;
      this.filteredOwners = this.ownersCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filter(name) : this.allOwners.slice()));
    });
    this.ownersCtrl.setValue(this.user.ownerName);
  }

  private _filter(ownerName: string): Owner[] {
    const filterValue = ownerName.toLowerCase();

    this.owners = this.allOwners.filter(owner => owner.name.toLowerCase().indexOf(filterValue) === 0);
    return this.owners;
  }

  private setupAutocompleteDataSourceCountry() {
    this._countryService.getCountries().then(countries => {
      this.allCountries = countries;
      this.filteredCountries = this.countriesCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterCountry(name) : this.allCountries.slice()));
    });
    this.countriesCtrl.setValue(this.user.countryName);
  }

  private _filterCountry(name: string): Country[] {
    const filterValue = name.toLowerCase();

    this.countries = this.allCountries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
    return this.countries;
  }


  ngAfterViewInit(): void {
    this.googlePlaceAutoComplete();
  }

  googlePlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      const options = {
        componentRestrictions: { country: ["at", "si", "sk", "hu", "cz"] }
      };

      let addressAutocomplete = new google.maps.places.Autocomplete(this.addressAutoCompleteRef.nativeElement, options);
      addressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let addressPlace: google.maps.places.PlaceResult = addressAutocomplete.getPlace();
          if (addressPlace && addressPlace.name && addressPlace.formatted_address) {
            this.userForm.patchValue({
              address: (addressPlace.name && !(addressPlace.formatted_address.toLowerCase()).includes(addressPlace.name.toLowerCase())) ? addressPlace.name + ", " + addressPlace.formatted_address : addressPlace.formatted_address
            });
          }
        });
      });
    });
  }
}
