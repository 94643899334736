import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { Vehicle } from '../vehicle.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { VehicleService } from './vehicle.service';
import { Vendor } from 'app/main/vendors/vendor.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { VendorsService } from 'app/main/vendors/vendors.service';
import { startWith, map } from 'rxjs/operators';
import { DriversService } from 'app/main/drivers/drivers.service';
import { Location } from './locaton.model';
import { VehicleType } from 'app/main/vehicle-types/vehicle-type.model';
import { VehicleTypesService } from 'app/main/vehicle-types/vehicle-types.service';

export interface VehicleDialogResult {
  actionType: string;
  formData: any;
  vendor: Vendor;
  location: Location;
  vehicleType: VehicleType
}
@Component({
  selector: 'vehicles-vehicle-form-dialog',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehicleComponent  {

    vendor: Vendor;
    dialogRef: any;
    compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
    compareByValue(f1: any, f2: any) { 
        return f1.value == f2.value && f1.viewValue == f2.viewValue;
    }
    action: string;
    vehicle: Vehicle;
    dialogTitle: string;
    pageType: string;
    vehicleForm: FormGroup;
    myControl = new FormControl();
    options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
    filteredOptions: Observable<string[]>;
   
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    vendorsCtrl = new FormControl();

    filteredVendors: Observable<Vendor[]>;
    vendors: Vendor[] = [];
    allVendors: Vendor[];
 
    locationsCtrl = new FormControl();

    filteredLocations: Observable<Location[]>;
    locations: Location[] = [];
    allLocations: Location[];

    vehicleTypesCtrl = new FormControl();

    filteredTypes: Observable<VehicleType[]>;
    types: VehicleType[] = [];
    allTypes: VehicleType[];
   
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     * @param {MatSnackBar} _matSnackBar
     */
  constructor(
    @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<VehicleComponent>,
    private _vehicleService: VehicleService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private _vendoersService: VendorsService,
    private _driversService: DriversService,
    private _vehicleTypeService: VehicleTypesService
  ) { 

     this.vehicle = new Vehicle(_data.vehicle);
     this.action = _data.action;
     this.dialogTitle = this.action === 'edit' ? 'Edit Vehicle' : 'New Vehicle';
     this.vehicleForm = this.createVehicleForm();
     this._unsubscribeAll = new Subject();
     this.setupAutocompleteDataSourceVendor();
     this.setupAutocompleteDataSourceLocation();
     this.setupAutocompleteDataSourceVehicleType();
  }

    createVehicleForm(): FormGroup
    {
        return this._formBuilder.group({
            id                 : [this.vehicle.id],
            name               : [this.vehicle.name, [Validators.required, Validators.maxLength(80)]],
            luggageCapacity    : [this.vehicle.luggageCapacity, [Validators.required]],
            model              : [this.vehicle.model],
            licensePlateNumber : [this.vehicle.licensePlateNumber, [Validators.required]],
            maxPayload         : [this.vehicle.maxPayload],
            description        : [this.vehicle.description],
            parkingNumber      : [this.vehicle.parkingNumber],
            vendorId           : [this.vehicle.vendorId],
            locationId         : [this.vehicle.locationId],
            vehicleTypeId      : [this.vehicle.vehicleTypeId],
            vendorsCtrl        : [this.vehicle.vendorName],
            locationsCtrl      : [this.vehicle.locationName],
            vehicleTypesCtrl   : [this.vehicle.vehicleTypeName]
        });
    }

    onSaveClick() {
      this.matDialogRef.close({
        actionType: 'save',
        formData: this.vehicleForm.getRawValue(),
        vendor: this.vendors[0],
        location: this.locations[0],
        vehicleType: this.types[0]
      });
    }
  
    onAddClick() {
      this.matDialogRef.close({
        actionType: 'add',
        formData: this.vehicleForm.getRawValue(),
        vendor: this.vendors[0],
        location: this.locations[0],
        vehicleType: this.types[0]
      });
    }



    onDeleteClick (){
      this.matDialogRef.close({
       actionType: 'delete',
       formData: this.vehicleForm.getRawValue(),
       vendor: this.vendors[0],
       location: this.locations[0],
       vehicleType: this.types[0]
     }); 
   }
  
    private setupAutocompleteDataSourceVendor() {
      this._vendoersService.getVendors().then(vendors => {
        this.allVendors = vendors;
        this.filteredVendors = this.vendorsCtrl.valueChanges.pipe(
          startWith(null),
          map((name: string | null) => name ? this._filterVendor(name) : this.allVendors.slice()));
      });
      this.vendorsCtrl.setValue(this.vehicle.vendorName);
    }

    private _filterVendor(vendorName: string): Vendor[] {
      const filterValue = vendorName.toLowerCase();
  
      this.vendors = this.allVendors.filter(vendor => vendor.companyName.toLowerCase().indexOf(filterValue) === 0);
      return this.vendors;
    }
   
     private setupAutocompleteDataSourceLocation() {
      this._driversService.getLocations().then(locations => {
        this.allLocations = locations;
        this.filteredLocations = this.locationsCtrl.valueChanges.pipe(
          startWith(null),
          map((name: string | null) => name ? this._filterLocation(name) : this.allLocations.slice()));
      });
      this.locationsCtrl.setValue(this.vehicle.locationName);
    }

    private _filterLocation(name: string): Location[] {
      const filterValue = name.toLowerCase();
  
      this.locations = this.allLocations.filter(location => location.name.toLowerCase().indexOf(filterValue) === 0);
      return this.locations;
    } 

    private setupAutocompleteDataSourceVehicleType() {
      this._vehicleTypeService.getVehicleTypes().then(types => {
        this.allTypes = types;
        this.filteredTypes = this.vehicleTypesCtrl.valueChanges.pipe(
          startWith(null),
          map((vehicleTypeName: string | null) => vehicleTypeName ? this._filterVehicleType(vehicleTypeName) : this.allTypes.slice()));
      });
      this.vehicleTypesCtrl.setValue(this.vehicle.vehicleTypeName);
    }

    private _filterVehicleType(vehicleTypeName: string) : VehicleType[] {
      const filterValue = vehicleTypeName.toLowerCase();

      this.types = this.allTypes.filter(vehicleType => vehicleType.vehicleTypeName.toLowerCase().indexOf(filterValue) === 0);
      return this.types;
    }

    private getVehicleType (){
      return this.types.length > 0 ? this.types[0] : new VehicleType({vehicleTypeName : this.vehicleTypesCtrl.value})
    }
}
