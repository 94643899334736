
import { Component, ViewEncapsulation, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PriceItemsService } from 'app/main/price-item/price-item.service';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'extra-price-item-dialog',
    templateUrl: './extra-price-item.component.html',
    styleUrls: ['./extra-price-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExtraPriceItemComponent {
    order: any;
    action: string;
    dialogTitle: string;
    extraPriceItemForm: FormGroup;
    priceItemsCtrl = new FormControl();
    filteredPriceItems: Observable<any>;
    priceItems: any = [];
    allPriceItems: any;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CompanyServicee} _companyService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<ExtraPriceItemComponent>,
        private _formBuilder: FormBuilder,
        private _priceItemsService: PriceItemsService
    ) {
        this.order = _data.order;
        this.action = _data.action;
        this.dialogTitle = 'Extra Price Item';
        this.extraPriceItemForm = this.createExtraPriceItemForm();
        this._unsubscribeAll = new Subject();
        this.setupAutocompleteDataSourcePriceItem();
    }

    /**
     * Create company form
     *
     * @returns {FormGroup}
     */
    createExtraPriceItemForm(): FormGroup {
        return this._formBuilder.group({
            priceItemId: [this.order.priceItem, [Validators.required]]
        });
    }

    priceItemSelected(priceItemId): void {
        this.extraPriceItemForm.patchValue({
            priceItemId: (priceItemId ? ((this.priceItems && this.priceItems[0] && this.priceItems[0].id) || null) : null)
        });
    }

    private setupAutocompleteDataSourcePriceItem() {
        this._priceItemsService.getPriceItems().then(data => {
            this.allPriceItems = data.filter(item => item.extra === true);

            if(this.order.priceItem){
                //find and set price item name
                let selectedPriceItem = this.allPriceItems.find(item => item.id === this.order.priceItem);
                this.priceItemsCtrl.setValue(selectedPriceItem ? selectedPriceItem.name : null);
            }
            
            this.filteredPriceItems = this.priceItemsCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterPriceItem(name) : this._clearPriceItemSelection()));
        });
    }

    public _clearPriceItemSelection() {
        if (!this.priceItemsCtrl.value)
            this.priceItemSelected(null);
        return this.allPriceItems.slice();
    }

    private _filterPriceItem(name: string): any {
        const filterValue = name.toLowerCase();

        this.priceItems = this.allPriceItems.filter(priceItem => priceItem.name.toLowerCase().indexOf(filterValue) === 0);
        return this.priceItems;
    }
}

