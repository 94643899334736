import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";
import { Log } from './log.model';


@Injectable({ providedIn: "root" })
export class LogsService {
    logs: Log[];
    totalCount: number;

    constructor(private http: HttpClient) { }

    getLogsNew(page, pageSize, searchText): Observable<any> {
        return this.http.get(environment.urlAddress + '/api/LTSLogPagged?PageNumber=' + (page + 1) + '&PageSize=' + pageSize + '&SearchString=' + searchText).pipe();
    }
}