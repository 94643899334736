import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { UnitsService } from './units.service';
import { UnitService } from './unit/unit.service';
import { UnitComponent } from './unit/unit.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'main-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class UnitsComponent implements OnInit, OnDestroy {

  @ViewChild('dialogContent')
  dialogContent: TemplateRef<any>;

  country: any;
  dataSource: FilesDataSource | null;
  displayedColumns = ['description', 'unitName'];
  dialogRef: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('filter')
  filter: ElementRef;

  @ViewChild(MatSort)
  sort: MatSort;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CountriesService} _countriesService
   */
  constructor(
      private _unitsService: UnitsService,
      private _unitService: UnitService,
      public _matDialog: MatDialog
  )
  {
      this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void
  {
      this.dataSource = new FilesDataSource(this._unitsService, this.paginator, this.sort);

      fromEvent(this.filter.nativeElement, 'keyup')
          .pipe(
              takeUntil(this._unsubscribeAll),
              debounceTime(150),
              distinctUntilChanged()
          )
          .subscribe(() => {
              if ( !this.dataSource )
              {
                  return;
              }
              this.dataSource.filter = this.filter.nativeElement.value;
          });
  }

  ngOnDestroy(): void
  {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

   /**
     * Edit unit
     *
     * @param unit
     */
    editUnit(unit): void
    {
        this.dialogRef = this._matDialog.open(UnitComponent, {
            panelClass: 'unit-form-dialog',
            data      : {
              unit: unit,
                action : 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    case 'save':

                        this._unitService.saveUnit(formData.getRawValue());
                        break;
                    
                    case 'delete':
                        this.deleteUnit(unit);
                        break;
                }
            });
    }

    newUnit(): void
    {
        this.dialogRef = this._matDialog.open(UnitComponent, {
            panelClass: 'unit-form-dialog',
            data      : {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if ( !response )
                {
                    return;
                }

                this._unitService.createUnit(response.getRawValue()).subscribe( () => {
                    this._unitsService.getUnits();
                });
            });
    }

    deleteUnit(unit): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._unitService.deleteUnit(unit.id).subscribe( () => {
                    this._unitsService.getUnits();
                });
            }
            this.confirmDialogRef = null;
        });

    }
}



export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {CountriesService} _countriesService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
      private _unitsService: UnitsService,
      private _matPaginator: MatPaginator,
      private _matSort: MatSort
  )
  {
      super();

      this.filteredData = this._unitsService.units;
  }

  get filteredData(): any
  {
      return this._filteredDataChange.value;
  }

  set filteredData(value: any)
  {
      this._filteredDataChange.next(value);
  }

  get filter(): string
  {
      return this._filterChange.value;
  }

  set filter(filter: string)
  {
      this._filterChange.next(filter);
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
      const displayDataChanges = [
          this._unitsService.onUnitsChanged,
          this._matPaginator.page,
          this._filterChange,
          this._matSort.sortChange
      ];

      return merge(...displayDataChanges).pipe(map(() => {

              let data = this._unitsService.units.slice();

              data = this.filterData(data);

              this.filteredData = [...data];

              data = this.sortData(data);

              const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
              return data.splice(startIndex, this._matPaginator.pageSize);
          })
      );

  }

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any
  {
      if ( !this.filter )
      {
          return data;
      }
      return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[]
  {
      if ( !this._matSort.active || this._matSort.direction === '' )
      {
          return data;
      }

      return data.sort((a, b) => {
          let propertyA: number | string = '';
          let propertyB: number | string = '';

          switch ( this._matSort.active )
          {
              case 'id':
                  [propertyA, propertyB] = [a.id, b.id];
                  break;
              case 'reference':
                  [propertyA, propertyB] = [a.reference, b.reference];
                  break;
              case 'customer':
                  [propertyA, propertyB] = [a.customer.firstName, b.customer.firstName];
                  break;
              case 'total':
                  [propertyA, propertyB] = [a.total, b.total];
                  break;
              case 'payment':
                  [propertyA, propertyB] = [a.payment.method, b.payment.method];
                  break;
              case 'status':
                  [propertyA, propertyB] = [a.status[0].name, b.status[0].name];
                  break;
              case 'date':
                  [propertyA, propertyB] = [a.date, b.date];
                  break;
          }

          const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
          const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

          return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
      });
  }

  disconnect(): void
  {
  }
}


