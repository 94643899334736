import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { ImportSitaService } from './import-sita.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'import-sita-dialog',
  templateUrl: './import-sita.component.html',
  styleUrls: ['./import-sita.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class ImportSitaComponent {
  dialogTitle: string;
  dataSource: any;
  displayedColumns = ['ahlName', 'Description', 'numberOfBags', 'permanentAddress01', 'cellPhone01', 'deliveryDate', 'deliveryTime',];

  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    public matDialogRef: MatDialogRef<ImportSitaComponent>,
    public _matDialog: MatDialog,
    private _importSitaService: ImportSitaService
  ) {
    this.dialogTitle = "Import SITA";
  }

  getImportSita(): void {
    this._importSitaService.getSITAOrder().then(response => {
      if (response.status == 'Sucess') {
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.sort = this.sort;
        this.sort.disableClear = true;
      }
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
  }
}