import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatDatepickerModule, MatCheckboxModule
} from '@angular/material';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { PriceTablesComponent } from './price-tables.component';
import { PriceTablesService } from './price-tables.service';
import { TableComponent } from './table/table.component';
import { PriceTableService } from './table/table.service';

const routes = [
    {
        path: '',
        component: PriceTablesComponent,
        resolve: {
            data: PriceTablesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'pricetables',
        component: PriceTablesComponent,
        resolve: {
            data: PriceTablesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'pricetables/:id',
        component: TableComponent,
        resolve: {
            data: PriceTableService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        PriceTablesComponent,
        TableComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatDatepickerModule,
        MatChipsModule,
        MatDialogModule,
        SatDatepickerModule, 
        SatNativeDateModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        PriceTablesService
    ],
    exports: [
        PriceTablesComponent, 
        TableComponent
    ]
})

export class PriceTableModule {
}
