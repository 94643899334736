import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { AirportsComponent } from './airports.component';
import { AirportsService } from './airports.service';
import { AirportComponent } from './airport/airport.component';
import { AirportService } from './airport/airport.service';

const routes = [
    {
        path: '',
        component: AirportsComponent,
        resolve: {
            data: AirportsService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'iata_airports',
        component: AirportsComponent,
        resolve: {
            data: AirportsService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'iata_airports/:id',
        component: AirportComponent,
        resolve: {
            data: AirportService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        AirportsComponent,
        AirportComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,

        
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        AirportsService
    ],
    exports: [
        AirportsComponent, 
        AirportComponent
    ]
})

export class AirportModule {
}
