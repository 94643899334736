import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatSort } from '@angular/material';
import { Subject, fromEvent, BehaviorSubject, Observable, of } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, catchError, finalize, tap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { LogsService } from './logs.service';
import { isNumber } from 'lodash';
import { Log, logType } from './log.model';

@Component({
    selector: 'main-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LogsComponent implements OnInit, OnDestroy {
    dataSource: LogsDataSource;
    displayedColumns = ['id', 'userName', 'actionName', 'description', 'createdDate'];
    searchText: string = '';

    @ViewChild(MatPaginator)
    paginator: MatPaginator;
    @ViewChild('filter')
    filter: ElementRef;
    @ViewChild(MatSort)
    sort: MatSort;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {LogsService} _logsService
     */
    constructor(
        private _logsService: LogsService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new LogsDataSource(this._logsService);
        this.dataSource.loadLogs(this.searchText, 0, this.paginator.pageSize);
        this.sort.disableClear = true;

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.searchText = this.filter.nativeElement.value;
                this.dataSource.loadLogs(this.searchText, 0, this.paginator.pageSize);
                this.paginator.firstPage();
            });
    }

    ngAfterViewInit() {
        this.paginator.page
            .pipe(
                tap(() => this.dataSource.loadLogs(this.searchText, this.paginator.pageIndex, this.paginator.pageSize))
            )
            .subscribe();
        this.sort.sortChange.subscribe(() => {
            this.dataSource.sortData(this.sort.active, this.sort.direction);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

export class LogsDataSource implements DataSource<any> {
    private logsSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalCount: number;
    private logData: Log[] = [];

    constructor(private _logsService: LogsService) { }

    connect(): Observable<any[]> {
        return this.logsSubject.asObservable();
    }

    disconnect(): void {
        this.logsSubject.complete();
        this.loadingSubject.complete();
    }

    loadLogs(searchText = '', pageIndex = 0, pageSize = 100) {
        this.loadingSubject.next(true);
        this._logsService.getLogsNew(pageIndex, pageSize, searchText).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
            .subscribe(response => {
                this.totalCount = response.totalCount;
                this.logData = response.logs;
                this.logData.map(item => {
                    let actionItem = logType.find(aItem => aItem.value == item.action);
                    item.actionName = actionItem ? actionItem.name : '';
                    return item;
                });
                this.logsSubject.next(this.logData);
            });
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(sortColumn, direction): Log[] {
        if (!sortColumn || direction === '') {
            return;
        }

        if (direction == 'asc') {
            this.logData.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            this.logData.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
        this.logsSubject.next(this.logData);
    }
}