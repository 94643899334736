import { Component, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { User, RoleType } from '../users/users.model';
import { Owner } from 'app/main/owners/owner.model';
import { Country } from 'app/main/country/country.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OwnersService } from 'app/main/owners/owners.service';
import { CountriesService } from 'app/main/country/country.service';

export interface ChangePasswordDialogResult {
  actionType: string;
  formData: any;
  user: User;
}

@Component({
  selector: 'change-password-form-dialog',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  user: User;
  dialogRef: any;
  roles = RoleType;

  ownersCtrl = new FormControl();

  filteredOwners: Observable<Owner[]>;
  owners: Owner[] = [];
  allOwners: Owner[];

  countriesCtrl = new FormControl();

  filteredCountries: Observable<Country[]>;
  countries: Country[] = [];
  allCountries: Country[];


  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1.value == f2.value && f1.viewValue == f2.viewValue;
  }
  action: string;
  dialogTitle: string;
  pageType: string;
  userForm: FormGroup;

  private _unsubscribeAll: Subject<any>;

  /**
    * Constructor
    *
    * @param {FormBuilder} _formBuilder
    * @param {Location} _location
    * @param {MatSnackBar} _matSnackBar
    */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<ChangePasswordComponent>,
    private _formBuilder: FormBuilder,
    public _ownersService: OwnersService,
    public _countryService: CountriesService
  ) {
    this.user = new User(_data.user);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Password' : 'New Password';
    this.userForm = this.createChangePasswordForm();
    this._unsubscribeAll = new Subject();
  }

  /**
     * Create user form
     *
     * @returns {FormGroup}
     */
    createChangePasswordForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.user.id],
      password: ['', [Validators.required]]
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      user: this.user,
      formData: this.userForm.getRawValue()
    });
  }

}
