import { NgModule } from '@angular/core';
import {
    MatButtonModule, MatChipsModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatRippleModule, MatSelectModule, MatSnackBarModule,
    MatSortModule,
    MatTableModule, MatTabsModule, MatCheckboxModule
} from '@angular/material';


import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { PagesModule } from './pages/pages.module';
import { VendorsModule } from './vendors/vendors.module';
import { BeneficiariesModule } from './beneficiaries/beneficiaries.module';
import { InvoiceRecipientsModule } from './invoice-recipients/invoice-recipients.module';
import { DriversModule } from './drivers/drivers.module';
import { UserManagementUsersModule } from './user-management/users/users.module';
import { VehiclesModule } from './vehicles/vehicles.module';
import { OwnersModule } from './owners/owners.module';
import { ZonesModule } from './zones/zones.module';
import { ServiceTypesModule } from './service-types/serviceTypes.module';
import { CountryModule } from './country/country.module';
import { VehicleTypeModule } from './vehicle-types/vehicle-types.module';
import { InvoiceCycleModule } from './invoice-cycles/invoice-cycles.module';
import { AirlineModule } from './airlines/airlines.module';
import { AirportModule } from './airports/airports.module';
import { OrderModule } from './orders/orders.module';
import { TourModule } from './tours/tours.module';
import { FixedTourServiceModule } from './fixed-tours/fixed-tours.module';
import { UnitModule } from './units/units.module';
import { PriceTableModule } from './price-tables/price-tables.module';
import { PriceItemModule } from './price-item/price-item.module';
import { UserManagementVendorsModule } from './user-management/vendor-role/vendor-role.module';
import { UserManagementDispatcherModule } from './user-management/dispatcher-role/dispatcher-role.module';
import { UserManagementBeneficiaryModule } from './user-management/beneficiary-role/beneficiary-role.module';
import { UserManagementEmployeeModule } from './user-management/employee-role/employee-role.module';
import { PriceTablePriceItemModule } from './price-table-items/price-table-items.module';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from 'saturn-datepicker';
import { DistrictsModule } from './districts/districts.module';
import { InvoicingDraftsModule } from './invoicing-drafts/invoicing-drafts.module';
import { InvoicingInvoicesModule } from './invoicing-invoices/invoicing-invoices.module';
import { InvoicingDraftDetailsModule } from './invoicing-draft-details/invoicing-draft-details.module';
import { LogsModule } from './logs/logs.module';
import { InvoicingOverviewModule } from './invoicing-overview/invoicing-overview.module';
import { VendorDraftsModule } from './vendor-drafts/vendor-drafts.module';

import { InvoicingCreditNotesModule } from './invoicing-credit-notes/invoicing-credit-notes.module';
import { VendorDraftDetailsModule } from './vendor-draft-details/vendor-draft-details.module';
import { ReportsInvoicesModule } from './reports-invoices/reports-invoices.module';
import { RolesModule } from './user-management/role-module/role-module.module';

const MY_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
    },
    display: {
        // dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'numeric', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'numeric' },
    }
};

export class MyDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat == "input") {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            return this._to2digit(day) + '.' + this._to2digit(month) + '.' + year;
        } else {
            return date.toDateString();
        }
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}

@NgModule({
    imports: [
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        PagesModule,
        FuseWidgetModule,
        FuseSharedModule,
        VendorsModule,
        BeneficiariesModule,
        ServiceTypesModule,
        DriversModule,
        OwnersModule,
        ZonesModule,
        DistrictsModule,
        UserManagementUsersModule,
        VehiclesModule,
        CountryModule,
        VehicleTypeModule,
        InvoiceCycleModule,
        AirlineModule,
        AirportModule,
        OrderModule,
        TourModule,
        InvoicingOverviewModule,
        InvoicingCreditNotesModule,
        FixedTourServiceModule,
        UnitModule,
        PriceTableModule,
        PriceItemModule,
        UserManagementVendorsModule,
        UserManagementDispatcherModule,
        UserManagementBeneficiaryModule,
        UserManagementEmployeeModule,
        RolesModule,
        PriceTablePriceItemModule,
        PriceItemModule,
        InvoiceRecipientsModule,
        InvoicingDraftsModule,
        VendorDraftsModule,
        VendorDraftDetailsModule,
        InvoicingInvoicesModule,
        InvoicingDraftDetailsModule,
        LogsModule,
        ReportsInvoicesModule
    ],
    providers: [
        { provide: DateAdapter, useClass: MyDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    ]
})
export class LtsMainModule {
}
