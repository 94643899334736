import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { takeUntil } from 'rxjs/internal/operators';
import { DriversService } from './drivers.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { DriverComponent, DriverDialogResult } from './driver/driver.component';
import { DriverService } from './driver/driver.service';
import { Driver } from './drivers.model';
import { Vendor } from '../vendors/vendor.model';
import { Location } from './location.model';
import { isNumber } from 'lodash';
import { DriverSessionComponent } from './driver-session/driver-session.component';

@Component({
    selector: 'main-drivers',
    templateUrl: './drivers.component.html',
    styleUrls: ['./drivers.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DriversComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    driver: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['dR_ID', 'firstName', 'licenseNumber', 'iD_Card', 'phoneNumber', 'email', 'vendorName', 'locationName', 'active', 'sessionId', 'comment'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    list: string[];

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DriversService} _driversService
     */
    constructor(
        private _driversService: DriversService,
        private _driverService: DriverService,
        public _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._driversService, this.paginator, this.sort);
        this.sort.disableClear = true;
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Edit contact
     *
     * @param contact
     */
    editDriver(driver): void {
        this.dialogRef = this._matDialog.open(DriverComponent, {
            panelClass: 'driver-form-dialog',
            data: {
                driver,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: DriverDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                switch (response.actionType) {
                    case 'save':
                        this._driverService.saveDriver(response.formData, response.vendor, response.location);
                        break;
                    case 'delete':
                        this.deleteDriver(response.formData);
                        break;
                }
            });
    }

    newContact(): void {
        this.dialogRef = this._matDialog.open(DriverComponent, {
            panelClass: 'driver-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: DriverDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                this.addDriver(response.formData, response.vendor, response.location);

            });
    }

    addDriver(driver: Driver, vendor: Vendor, location: Location) {
        this._driverService.createDriver(driver, vendor, location).subscribe(() => {
            this._driversService.getDrivers();
            this._matSnackBar.open('Driver Created', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    saveDriver(driver: Driver, vendor: Vendor, location: Location) {
        if (vendor) {
            this.list = vendor.vE_ID.split('_');
            driver.vendorId = this.list[1];
        }
        if (location) {
            driver.locationId = location.locationId;
        }
        this._driverService.saveDriver(driver, vendor, location).then(() => {
            this._driversService.getDrivers();
            this._matSnackBar.open('Driver Updated', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    deleteDriver(driver): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this._driverService.deleteDriver(driver.id).subscribe(() => {
                this._driversService.getDrivers();
            });
            this.confirmDialogRef = null;
        });

    }

    addSession(driver): void {
        this.dialogRef = this._matDialog.open(DriverSessionComponent, {
            panelClass: 'driver-session-form-dialog',
            data: {
                action: 'new',
                driver
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: any) => {
                if (!response || !response.formData) {
                    return;
                }
                this._driversService.createDriverSession(response.formData);
            });
    }

    removeSession(driver): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete session?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this._driversService.deleteDriverSession(driver.sessionId).then(response => {});
            this.confirmDialogRef = null;
        });
    }
}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {DriversService} _driversService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _driversService: DriversService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._driversService.drivers;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }


    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._driversService.onDriversChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._driversService.drivers.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}
