import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { PriceItem } from './price-item.model';
import { ServiceType } from '../service-types/serviceType.model';
import { Unit } from '../units/unit.model';
import { PriceTable } from '../price-tables/price-table.model';


@Injectable({ providedIn: "root" })
export class PriceItemsService implements Resolve<PriceItem[]> {

    priceItems: PriceItem[];
    onPriceItemsChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onPriceItemsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPriceItems()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPriceItems(): Promise<PriceItem[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/priceItems')
                .subscribe((response: any) => {
                    this.priceItems = response;
                    this.onPriceItemsChanged.next(this.priceItems);
                    resolve(response);
                }, reject);
        });
    }

    getPriceItemDetails(id: number) : Observable<PriceItem> {
        const  apiUrl: string = `${environment.urlAddress}/api/priceItems/${id}`;
        return this.http.get<PriceItem>(apiUrl);
    }

   createPriceItem(priceItem: PriceItem, service: ServiceType, unit: Unit, priceTable:PriceTable) : Observable<PriceItem> {
    if(service){
        priceItem.serviceTypeId = service.id;
    }
    if(unit){
        priceItem.unitId = unit.id;
    }
        const apiUrl: string = `${environment.urlAddress}/api/priceItems`;
        return this.http.post<PriceItem>(apiUrl, priceItem);
    }


    deletePriceItem(id: number) : Observable<PriceItem>{
        const apiUrl: string = `${environment.urlAddress}/api/priceItems/${id}`;
        return this.http.delete<PriceItem>(apiUrl);
    }
}