import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

export interface PriceItemDialogResult {
  actionType: string;
}

@Component({
  selector: 'app-import-pricing',
  templateUrl: './import-pricing.component.html',
  styleUrls: ['./import-pricing.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class ImportPricingComponent {
  action: string;
  dialogTitle: string;

  selectedFile: any = null;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<ImportPricingComponent>,
    public _matDialog: MatDialog) {
    this.action = _data.action;
    this.dialogTitle = 'Import Pricing';

  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'upload',
      selectedFile: this.selectedFile
    });
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] ? event.target.files[0] : null;
  }

}
