import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { IATA_airline } from './airlines.model';


@Injectable({ providedIn: "root" })
export class AirlinesService implements Resolve<IATA_airline[]> {

    airlines: IATA_airline[];
    onAirlinesChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onAirlinesChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAirlines()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAirlines(): Promise<IATA_airline[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/iata_airlines')
                .subscribe((response: any) => {
                    this.airlines = response;
                    this.onAirlinesChanged.next(this.airlines);
                    resolve(response);
                }, reject);
        });
    }

    getAirlineDetails(id: number) : Observable<IATA_airline> {
        const  apiUrl: string = `${environment.urlAddress}/api/iata_airlines/${id}`;
        return this.http.get<IATA_airline>(apiUrl);
    }

   createAirline(airline: IATA_airline) : Observable<IATA_airline> {
        const apiUrl: string = `${environment.urlAddress}/api/iata_airlines`;
        return this.http.post<IATA_airline>(apiUrl, airline);
    }


    deleteAirline(id: number) : Observable<IATA_airline>{
        const apiUrl: string = `${environment.urlAddress}/api/iata_airlines/${id}`;
        return this.http.delete<IATA_airline>(apiUrl);
    }
}