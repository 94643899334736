import { Component, ViewEncapsulation, Optional, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Vendor, Language } from '../vendor.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { VendorService } from './vendor.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { VendorsService } from '../vendors.service';
import { Country } from 'app/main/country/country.model';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CountriesService } from 'app/main/country/country.service';
import { startWith, map } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';

export interface VendorDialogResult {
  actionType: string;
  formData: any;
  country: Country
}


@Component({
  selector: 'vendors-vendor-form-dialog',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorComponent {

  action: string;
  vendor: Vendor;
  country: Country;
  dialogTitle: string;
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1.value == f2.value && f1.viewValue == f2.viewValue;
  }
  pageType: string;
  vendorForm: FormGroup;
  myControl = new FormControl();
  options: { id: number, value: string }[] = [{ id: 1, value: 'One' }, { id: 2, value: 'O444e' }, { id: 3, value: 'O222ne' }];
  filteredOptions: Observable<string[]>;
  languages = Language;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  countriesCtrl = new FormControl();

  filteredCountries: Observable<Country[]>;
  countries: Country[] = [];
  allCountries: Country[];
  @ViewChild('addressAutoComplete') addressAutoCompleteRef: ElementRef;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {VendorsService} _vendorService
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<VendorComponent>,
    private _vendoorService: VendorService,
    private _vendorService: VendorsService,
    private _countryService: CountriesService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.vendor = new Vendor(_data.vendor);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Vendor' : 'New Vendor';
    this.vendorForm = this.createVendorForm();
    this._unsubscribeAll = new Subject();
    this.setupAutocompleteDataSourceCountry();
  }

  /**
   * Create company form
   *
   * @returns {FormGroup}
   */
  createVendorForm(): FormGroup {
    const selectedEnum = this.languages.find(language => language.value == this.vendor.languageType);
    return this._formBuilder.group({
      vE_ID: [this.vendor.vE_ID],
      companyName: [this.vendor.companyName, [Validators.required, Validators.maxLength(40)]],
      responsiblePerson: [this.vendor.responsiblePerson],
      address: [this.vendor.address],
      ziP_CODE: [this.vendor.ziP_CODE],
      city: [this.vendor.city],
      email: [this.vendor.email, [Validators.required]],
      vaT_ID: [this.vendor.vaT_ID],
      ccEmail: [this.vendor.ccEmail],
      biC_account: [this.vendor.biC_account, [Validators.required]],
      ibaN_account: [this.vendor.ibaN_account, [Validators.required]],
      active: [this.vendor.active],
      ust: [this.vendor.ust],
      paymentMethod: [this.vendor.paymentMethod],
      phoneNumber: [this.vendor.phoneNumber],
      language: [this.vendor.languageType || 1],
      countryId: [this.vendor.countryId],
      countriesCtrl: [this.vendor.countryName]
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.vendorForm.getRawValue(),
      country: this.countries[0]
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.vendorForm.getRawValue(),
      country: this.countries[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.vendorForm.getRawValue(),
      country: this.countries[0]
    });
  }

  private setupAutocompleteDataSourceCountry() {
    this._countryService.getCountries().then(countries => {
      this.allCountries = countries;
      this.filteredCountries = this.countriesCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterCountry(name) : this.allCountries.slice()));
    });
    this.countriesCtrl.setValue(this.vendor.countryName);
  }

  private _filterCountry(name: string): Country[] {
    const filterValue = name.toLowerCase();

    this.countries = this.allCountries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
    return this.countries;
  }


  ngAfterViewInit(): void {
    this.googlePlaceAutoComplete();
  }

  googlePlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      const options = {
        componentRestrictions: { country: ["at", "si", "sk", "hu", "cz"] }
      };

      let addressAutocomplete = new google.maps.places.Autocomplete(this.addressAutoCompleteRef.nativeElement, options);
      addressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let addressPlace: google.maps.places.PlaceResult = addressAutocomplete.getPlace();
          if (addressPlace && addressPlace.name && addressPlace.formatted_address) {
            this.vendorForm.patchValue({
              address: (addressPlace.name && !(addressPlace.formatted_address.toLowerCase()).includes(addressPlace.name.toLowerCase())) ? addressPlace.name + ", " + addressPlace.formatted_address : addressPlace.formatted_address
            });
          }
        });
      });
    });
  }
}