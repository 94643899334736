export class Driver{
    dR_ID: string;
    firstName: string;
    lastName: string;
    arrivalTime: Date;
    licenseNumber: string;
    iD_Card: string;
    phoneNumber: string;
    username: string;
    password: string;
    email: string;
    active: boolean;
    comment: string;
    vendorId?: string;
    locationId?: number;
    vendorName: string;
    locationName: string;
    id?: number;

    /**
     * Constructor
     * @param driver
     */
    constructor(driver?){
        driver = driver || {};
        this.id = driver.id || 0;
        this.dR_ID = driver.dR_ID || '';
        this.firstName = driver.firstName || '';
        this.lastName = driver.lastName || '';
        this.arrivalTime = driver.arrivalTime || Date.now;
        this.licenseNumber = driver.licenseNumber || '';
        this.iD_Card = driver.iD_Card || '';
        this.phoneNumber = driver.phoneNumber || '';
        this.username = driver.username || '';
        this.password = driver.password || '';
        this.email = driver.email || '';
        this.active = driver.active;
        this.comment = driver.comment || '';
        this.vendorId = driver.vendorId || null;
        this.locationId = driver.locationId || null;
        this.vendorName = driver.vendorName;
        this.locationName = driver.locationName;
    }
}
