import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { Country } from '../country.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CountriesService } from '../country.service';
import { CountryService } from './country-item.service';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'country-country-form-dialog',
  templateUrl: './country-item.component.html',
  styleUrls: ['./country-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountryItemComponent {

    action: string;
    country: Country;
    dialogTitle: string;
    pageType: string;
    countryForm: FormGroup;
    myControl = new FormControl();
    options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
    filteredOptions: Observable<string[]>;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<CountryItemComponent>,
        private _countriesService: CountriesService,
        private _countryService: CountryService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar
    )
    {
        this.country = new Country(_data.country);
        this.action = _data.action;
        this.dialogTitle = this.action === 'edit' ? 'Edit Country' : 'New Country';
        this.countryForm = this.createCountryForm();
        this._unsubscribeAll = new Subject();
    }

    /**
     * Create company form
     *
     * @returns {FormGroup}
     */
    createCountryForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.country.id],
            name            : [this.country.name, [Validators.required, Validators.maxLength(40)]],
        });
    }

    saveCountry(): void
    {
        const data = this.countryForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._countryService.saveCountry(data)
            .then(() => {
                this._countryService.onCountryChanged.next(data);

                this._matSnackBar.open('Country saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}

