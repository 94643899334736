import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as geoJSON from 'geojson';
import { Route, Geometry, MapboxFeature, MapboxFeatureCollection, MapboxOptions, RouteData, MapboxEstimation } from './models';
import { position } from './helpers/coordinates';
import * as turf from '@turf/turf';
import { Route as MapboxRoute, MapboxMap } from './models';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapBoxService {
  private accessToken: String;
  private defaultCenter = { lat: 48.210033, long: 16.363449 }; // Vienna
  private defaultStyle = 'mapbox://styles/mapbox/streets-v9';

  constructor() {
    this.accessToken = environment.mapbox.accessToken;
    Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(this.accessToken);
  }

  public drawMapWithoutRoute(containerSelector: string, options: MapboxOptions = {}): Promise<MapboxMap> {
    return this.drawMap(containerSelector, options);
  }

  public async drawMapWithRoute(containerSelector: string, route?: MapboxRoute, options: MapboxOptions = {}): Promise<MapboxMap> {
    const turfFeatures = turf.featureCollection(route.coordinates.map(routePoint => turf.point(position(routePoint))));
    const center = turf.center(turfFeatures);
    options.center = [center.geometry.coordinates[0], center.geometry.coordinates[1]];
    const map = await this.drawMap(containerSelector, options);
    await this.drawRoute({ map, route, ...options });
    return map;
  }

  private drawMap(containerSelector, options: MapboxOptions = {}): Promise<MapboxMap> {
    options.style = options.style || this.defaultStyle;
    const map: MapboxMap = new MapboxMap(
      {
        container: containerSelector,
        center: [this.defaultCenter.long, this.defaultCenter.lat],
        ...options
      },
      this);



    return new Promise((resolve, reject) => {
      map.on('load', event => {


        resolve(map);
      });

      map.on('error', error => {
        console.log(error);
        reject(error);
      });
    });
  }

  public drawRoute(params: { map?: any, route?: any, routeColor?: any, drawMarkers?: boolean }) {
    const { route, map, drawMarkers } = params;
    let { routeColor } = params;
    routeColor = routeColor || '#3887be';

    //
    //    const turfFeatures = turf.featureCollection(route.coordinates.map(routePoint => turf.point(position(routePoint))));
    //    const center = turf.center(/*options.center || */turfFeatures);
    //

    const geometry: Geometry = new Geometry(route.coordinates);
    const feature: MapboxFeature = new MapboxFeature(geometry);
    const featureCollection: MapboxFeatureCollection = new MapboxFeatureCollection([feature]);
    const geoJSONSource = new RouteData(featureCollection);

    map.addSource('route', geoJSONSource);

    map.addLayer({
      id: 'routeline-active',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': routeColor,
        'line-width': {
          base: 1,
          stops: [[12, 3], [22, 12]]
        }
      }
    }, 'waterway-label');


    map.addLayer({
      id: 'routearrows',
      type: 'symbol',
      source: 'route',
      layout: {
        'symbol-placement': 'line' as mapboxgl.SymbolLayout['symbol-placement'],
        'text-field': '▶',
        'text-size': {
          base: 1,
          stops: [[12, 24], [22, 60]]
        },
        'symbol-spacing': 12,
        'text-keep-upright': false
      },
      paint: {
        'text-color': routeColor,
        'text-halo-color': 'hsl(55, 11%, 96%)',
        'text-halo-width': 3
      }
    }, 'waterway-label');

    if (drawMarkers) {
      this.drawMarkers(route, map);
    }

  }

  public async getMostEfficientRoute(startPoint: mapboxgl.LngLatLike, endPoint: mapboxgl.LngLatLike, places: mapboxgl.LngLatLike[]): Promise<Route> {
    const inputCoordinates: mapboxgl.LngLatLike[] = [startPoint, ...places, endPoint];

    const coordinatesString: string = inputCoordinates.reduce((acc: string, currentCoordinate: mapboxgl.LngLatLike) => {
      const currentPosition: geoJSON.Position = position(currentCoordinate);
      return acc + `${acc.length > 0 ? ';' : ''}${currentPosition[0]},${currentPosition[1]}`;
    }, '');

    const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesString}?overview=full&steps=true&geometries=geojson&access_token=${this.accessToken}`);
    const data = await response.json();

    const coordinates = data.routes[0].geometry.coordinates;
    const waypoints = data.waypoints.map(waypoint => waypoint.location);
    const duration = data.routes[0].duration;
    const distance = data.routes[0].distance;

    return new Route(coordinates, waypoints, duration, distance);
  }

  private drawMarkers(route: MapboxRoute, map: mapboxgl.Map) {
    const startPointMarker = document.createElement('div');
    startPointMarker.classList.add('sonar-marker');

    const endPointMarker = document.createElement('div');
    endPointMarker.classList.add('marker');
    endPointMarker.classList.add('end-marker');

    new mapboxgl.Marker(startPointMarker).setLngLat(route.waypoints[0]).addTo(map);
    new mapboxgl.Marker(endPointMarker).setLngLat(route.waypoints[route.waypoints.length - 1]).addTo(map);

    route.waypoints.forEach((waypoint, index) => {
      if (index === 0 || index === route.waypoints.length - 1) return;

      const waypointMarker = document.createElement('div');
      waypointMarker.classList.add('marker');
      waypointMarker.classList.add('shadow-darken');
      waypointMarker.innerText = index.toString();

      new mapboxgl.Marker(waypointMarker).setLngLat(waypoint).addTo(map);
    });
  }

  public async getCoordinatesForAddress(address: string, zip: string, country: string): Promise<mapboxgl.LngLatLike> {
    const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(`${address} ${zip}`)}.json?limit=1&country=${country}&access_token=${this.accessToken}&types=address,postcode`);
    const data = await response.json();

    return data.features[0].geometry.coordinates;
  }

  public async getEstimation(placeA: mapboxgl.LngLatLike, placeB: mapboxgl.LngLatLike): Promise<MapboxEstimation> {
    const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${position(placeA).join(',')};${position(placeB).join(',')}?access_token=${this.accessToken}`);
    const data = await response.json();

    return new MapboxEstimation(placeA, placeB, data.routes[0].duration, data.routes[0].distance);
  }
}