import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatToolbarModule, MatAutocompleteModule, MatCheckboxModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { VendorsComponent } from './vendors.component';
import { VendorComponent } from './vendor/vendor.component';
import { VendorsService } from './vendors.service';
import { VendorService } from './vendor/vendor.service';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { AgmCoreModule } from '@agm/core';

const routes = [
    {
        path: '',
        component: VendorsComponent,
        resolve: {
            data: VendorsService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'vendors',
        component: VendorsComponent,
        resolve: {
            data: VendorsService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'vendors/:vE_ID',
        component: VendorComponent,
        resolve: {
            data: VendorService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        VendorsComponent,
        VendorComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatAutocompleteModule,
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
            libraries: ["places"]
        })
    ],
    providers: [
        VendorsService
    ],
    exports: [
        VendorsComponent, 
        VendorComponent
    ]
})

export class VendorsModule {
}
