import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Zone } from './zone.model';


@Injectable({ providedIn: "root" })
export class ZonesService implements Resolve<Zone[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    zones: Zone[];
    onZonesChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onZonesChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getZones()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getZones(): Promise<Zone[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/zones')
                .subscribe((response: any) => {
                    this.zones = response;
                    this.onZonesChanged.next(this.zones);
                    resolve(response);
                }, reject);
        });
    }

    getZoneDetails(id: number) : Observable<Zone> {
        const  apiUrl: string = `${environment.urlAddress}/api/zones/${id}`;
        return this.http.get<Zone>(apiUrl);
    }

   createZone(zone: Zone) : Observable<Zone> {
        const apiUrl: string = `${environment.urlAddress}/api/zones`;
        return this.http.post<Zone>(apiUrl, zone);
    }


    deleteZone(id: number) : Observable<Zone>{
        const apiUrl: string = `${environment.urlAddress}/api/zones/${id}`;
        return this.http.delete<Zone>(apiUrl);
    }

    


    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onZonesChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }
}