import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, MatChipsModule, MatExpansionModule, MatFormFieldModule, MatCheckboxModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatRippleModule, MatSortModule, MatSnackBarModule, MatTableModule, MatTabsModule, MatAutocompleteModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule, FuseConfirmDialogModule, FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LoginModule } from 'app/main/pages/authentication/login/login.module';

@NgModule({
    declarations: [
        ToolbarComponent,
        UserDetailsComponent
    ],
    imports     : [
        RouterModule.forChild([]),
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatToolbarModule,
        MatAutocompleteModule,

        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        LoginModule
    ],
    exports     : [
        ToolbarComponent,
        UserDetailsComponent
    ],
    entryComponents: [
        UserDetailsComponent
    ]
})
export class ToolbarModule
{
}
