import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { PriceTablesService } from '../price-tables.service';
import { PriceTable } from '../price-table.model';


@Injectable({ providedIn: "root" })
export class PriceTableService implements Resolve<any>
{
    routeParams: any;
    priceTable: any;
    onPriceTableChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _priceTablesService: PriceTablesService
    )
    {
        this.onPriceTableChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getPriceTable()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getPriceTable(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onPriceTableChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/pricetables/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.priceTable = response;
                    this.onPriceTableChanged.next(this.priceTable);
                    resolve(response);
                }, reject);
 
        });
    }

    /**
     * Save owner
     *
     * @param priceTable
     * @returns {Promise<any>}
     */
    savePriceTable(priceTable : PriceTable): Observable<any>
    {
        return this._httpClient.put<any>(environment.urlAddress + '/api/pricetables/' + priceTable.id, priceTable);
    }
}