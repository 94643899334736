import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef, ElementRef, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { MatPaginator, MatDialogRef, MatMenuTrigger, MatDialog, MatSnackBar, MatSort } from '@angular/material';
import { DispatcherDriversService } from './drivers.service';
import { Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { DriverVehicleSession } from './driver-vehicle-session.model';
import { TourTable } from '../tour/tour.model';
import { LtsDropItemType } from '../dispatcher-screen.component';
import { DispatcherDriversDataSource } from './drivers.data-source';
import { CdkDropList, CdkDragExit } from '@angular/cdk/drag-drop';

@Component({
  selector: 'dispatcher-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DriversComponent implements OnInit, OnDestroy {

    @Input() ltsConnectedDroplists: string[];
    @Input() ltsDroplist: string;
    @Input() ltsDropItemType: LtsDropItemType;

  @ViewChild('dialogContent')
  dialogContent: TemplateRef<any>;

  session: any;
  tours: TourTable[];
  dataSource: DispatcherDriversDataSource | null;
  displayedColumns = ['loggedIn', 'driverName', 'vendorName', 'vehicleName', 'driverStatus', 'arrivalTime'];
  dialogRef: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild('filter')
  filter: ElementRef;

  

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  private _unsubscribeAll: Subject<any>;
  constructor(
    private _driversService: DispatcherDriversService,
    public _matDialog: MatDialog,
    private _matSnackBar: MatSnackBar,
    private router: Router
      ) { 
        this._unsubscribeAll = new Subject();
      }

      ngOnInit(): void
      {
        this._driversService.getDrivers().then(orders => {
            this.dataSource = new DispatcherDriversDataSource(this._driversService, this.paginator, this.sort);
            this.sort.disableClear = true;
         });

         this._driversService.getTours().then(tours => {
            this.tours = tours;
        })
      }

     contextMenuPosition = { x: '0px', y: '0px' };

     onContextMenu(event: MouseEvent, session: DriverVehicleSession) {
       event.preventDefault();
       this.contextMenuPosition.x = event.clientX + 'px';
       this.contextMenuPosition.y = event.clientY + 'px';
       this.contextMenu.menuData = { 'session': session };
       this.contextMenu.openMenu();
     }

      addTourDriver(session: DriverVehicleSession, tour: TourTable) {
       this._driversService.createTourDriver(tour, session).then( () => {
           this._driversService.getDrivers();
           this._matSnackBar.open(`Driver ${session.driverName} added to tour ${tour.name}`, 'OK', {
               verticalPosition: 'top',
               duration        : 2000
           });
       });
   }
   onOrderAddedToTourViaDragAndDrop(event: CdkDragExit): void {
        
    const droppedItemData = event && event.item && event.item.data;
    if (!droppedItemData) {
        return;
    }

}
   
   ngOnDestroy(): void
   {
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }
}
