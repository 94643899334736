import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector     : 'error-401',
    templateUrl  : './error-401.component.html',
    styleUrls    : ['./error-401.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error401Component
{
    /**
     * Constructor
     */
    constructor(private _fuseConfigService: FuseConfigService)
    {
         // Configure the layout
         this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
}
