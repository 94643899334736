import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DispatcherScreenService } from '../dispatcher-screen.service';
import { TourDetailsPopupComponent } from './tour-details-popup/tour-details-popup.component';

@Component({
    selector: 'dispatcher-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    encapsulation: ViewEncapsulation.None

})


export class DispatcherMapComponent implements OnInit {
    public lat = 48.1126125;
    public lng = 16.5755139;
    tours: any = [];
    public origin: any;
    public destination: any;
    public wayPoints: any;
    public markerOptions: any;
    public orderMarkers: any;
    public renderOptions: any;
    inProgressTourForm: FormGroup;

    dialogRef: any;
    constructor(
        private dispatcherScreenService: DispatcherScreenService,
        public _matDialog: MatDialog,
        private inProgressFormBuilder: FormBuilder
    ) {

    }

    ngOnInit() {
        let defaultInProgressTourDate = sessionStorage.getItem('inprogress-tour-date') ? new Date(sessionStorage.getItem('inprogress-tour-date')) : new Date();
        this.getInitialTours(defaultInProgressTourDate);

        //Dispatcher tour date init
        this.inProgressTourForm = this.inProgressFormBuilder.group({
          inprogressTourDate: defaultInProgressTourDate
        });
    }


    onInProgressTourDateSelected(event): void {
        let selectedDate = new Date(event.value);
        sessionStorage.setItem('inprogress-tour-date', selectedDate.toString());
        this.getInitialTours(selectedDate);
    }

    getInitialTours(defaultInProgressDate) {
        this.origin = {};
        this.destination = {};
        this.wayPoints = [];
        this.markerOptions =  {};
        this.renderOptions = {};

        const offset = defaultInProgressDate.getTimezoneOffset();
        defaultInProgressDate = new Date(defaultInProgressDate.getTime() - (offset * 60 * 1000));
        let dateString = defaultInProgressDate.toISOString().split('T')[0];

        this.orderMarkers = [];
        this.dispatcherScreenService.getInProgressTours(dateString).then(tr => {
            this.tours = tr;
            this.tours.forEach(tour => {
                generateOrderString(tour);
                //set order markers
                tour.tour_Orders.forEach(order => {
                    this.orderMarkers.push({
                        lat: order.orderInfo.latitude,
                        lng: order.orderInfo.longitude,
                        address: order.temporaryAddress01 ? order.temporaryAddress01 : order.permanentAddress01
                    });
                });
            });
        });
    }

    public renderPath(tour): void {
        //lat-long of all orders
        let orderLatLongs = [];
        let isOrderPriorityNull = false;

        for (let i = 0; i < tour.tour_Orders.length; i++) {
            orderLatLongs.push({ lat: tour.tour_Orders[i].orderInfo.latitude, lng: tour.tour_Orders[i].orderInfo.longitude });
            if (!tour.tour_Orders[i].priority)
                isOrderPriorityNull = true;
        }

        //If atleast one order has priority NULL then find and set priorities for all the orders
        if (isOrderPriorityNull) {
            //set priorities null for all the orders
            for (let i = 0; i < tour.tour_Orders.length; i++) {
                tour.tour_Orders[i].priority = null;
            }

            //Find nearest order from tour base address
            this.dispatcherScreenService.distanceForMultipleDestianations({ lat: tour.latitude, lng: tour.longitude }, orderLatLongs)
                .subscribe((result: any) => {
                    if (result && result.rows[0] && result.rows[0].elements) {
                        let minDistance = Math.min.apply(Math, result.rows[0].elements.map(function (item) { return (item.distance && item.distance.value || 9999999999999); }));
                        let nearestPointIndex = result.rows[0].elements.findIndex(item => (item.distance && item.distance.value || 9999999999999) == minDistance);

                        //Update priority for order
                        tour.tour_Orders[nearestPointIndex].priority = 1;
                        tour.tour_Orders[nearestPointIndex].kilometers = ((minDistance == 9999999999999) ? 0 : (minDistance / 1000));

                        //Find and set priorities for nearest orders
                        if (tour.tour_Orders.length > 1)
                            this.findOrdersPriorities(tour);
                        else {
                            this.dispatcherScreenService.updateTourOrderWithPriority(tour.tour_Orders).subscribe();
                            //Set origin, destination and waypoints                
                            this.displayDirection(tour);
                        }
                    }
                }
                );
        } else {
            //sort orders by priority
            tour.tour_Orders.sort((a, b) => {
                return (a.priority ? parseInt(a.priority) : 999999) - (b.priority ? parseInt(b.priority) : 999999)
            });
            //Set origin, destination and waypoints                
            this.displayDirection(tour);
        }
    }

    findOrdersPrioritiesPromise = (index, tour) => {
        return new Promise(resolve => {
            //lat-long of all orders
            let orderLatLongsInternal = [];
            let mapTourOrders = [];
            let nextOrigin;

            //sort orders by priority
            tour.tour_Orders.sort((a, b) => {
                return (a.priority ? parseInt(a.priority) : 999999) - (b.priority ? parseInt(b.priority) : 999999)
            });

            for (let i = 0; i < tour.tour_Orders.length; i++) {
                if (!tour.tour_Orders[i].priority) {
                    orderLatLongsInternal.push({ lat: tour.tour_Orders[i].orderInfo.latitude, lng: tour.tour_Orders[i].orderInfo.longitude });
                    mapTourOrders.push(tour.tour_Orders[i].id);
                } else {
                    nextOrigin = { lat: tour.tour_Orders[i].orderInfo.latitude, lng: tour.tour_Orders[i].orderInfo.longitude }
                }
            }
            this.dispatcherScreenService.distanceForMultipleDestianations(nextOrigin, orderLatLongsInternal)
                .subscribe((result: any) => {
                    if (result && result.rows[0] && result.rows[0].elements) {
                        //find minimum distance
                        let minDistanceInternal = Math.min.apply(Math, result.rows[0].elements.map(function (item) { return item.distance && item.distance.value || 9999999999999; }));

                        //find index of nearest point
                        let nearestPointIndexInternal = result.rows[0].elements.findIndex(item => (item.distance && item.distance.value || 9999999999999) == minDistanceInternal);

                        //find nearest orderId
                        let tourOrderId = mapTourOrders[nearestPointIndexInternal];
                        let tourOrderIndex = tour.tour_Orders.findIndex(item => item.id == tourOrderId);

                        //Update priority for order
                        tour.tour_Orders[tourOrderIndex].priority = (index + 1);
                        tour.tour_Orders[tourOrderIndex].kilometers = ((minDistanceInternal == 9999999999999) ? 0 : (minDistanceInternal / 1000));
                        resolve();
                    } else {
                        resolve();
                    }
                }
                );
        });
    }

    findOrdersPriorities = async (tour) => {
        for (let i = 1; i < tour.tour_Orders.length; i++) {
            await this.findOrdersPrioritiesPromise(i, tour);
        }
        //sort orders by priority
        tour.tour_Orders.sort((a, b) => {
            return (a.priority ? parseInt(a.priority) : 999999) - (b.priority ? parseInt(b.priority) : 999999)
        });

        //Update order priorities and distance
        this.dispatcherScreenService.updateTourOrderWithPriority(tour.tour_Orders).subscribe();

        //Set origin, destination and waypoints                
        this.displayDirection(tour);
    }

    public displayDirection(tour): void {
        //Update order markers as per tour selected
        this.orderMarkers = [];
        this.tours.forEach(item => {
            if (item.id != tour.id) {
                item.tour_Orders.forEach(order => {
                    this.orderMarkers.push({
                        lat: order.orderInfo.latitude,
                        lng: order.orderInfo.longitude,
                        address: order.orderInfo.address
                    });
                });
            }
        });

        if (tour.tour_Orders && tour.tour_Orders.length > 0) {
            this.markerOptions = {
                origin: {
                    label: { color: 'white', text: 'O' },
                    draggable: false
                },
                waypoints: [],
                destination: {}
            };

            this.origin = { lng: tour.longitude, lat: tour.latitude };

            this.wayPoints = [];
            for (var i = 0; i < (tour.tour_Orders.length - 1); i++) {
                this.wayPoints[i] = { location: { lng: tour.tour_Orders[i].orderInfo.longitude, lat: tour.tour_Orders[i].orderInfo.latitude }, stopover: false };
                this.markerOptions.waypoints[i] = {
                    infoWindow: tour.tour_Orders[i].orderInfo.address,
                    label: { color: 'white', text: tour.tour_Orders[i].priority.toString() },
                    draggable: false
                };
            }

            this.markerOptions.destination = {
                label: { color: 'white', text: tour.tour_Orders[(tour.tour_Orders.length - 1)].priority.toString() },
                draggable: false
            };
            this.destination = { lng: tour.tour_Orders[(tour.tour_Orders.length - 1)].orderInfo.longitude, lat: tour.tour_Orders[(tour.tour_Orders.length - 1)].orderInfo.latitude };

            this.renderOptions = {
                suppressMarkers: true,
            };
        }
    }

    public orderDetails(tour): void {
        this.dialogRef = this._matDialog.open(TourDetailsPopupComponent, {
            panelClass: 'tour-details-popup-dialog',
            data: {

                tour,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                //Retrieve tours again
                let defaultInProgressTourDate = sessionStorage.getItem('inprogress-tour-date') ? new Date(sessionStorage.getItem('inprogress-tour-date')) : new Date();
                this.getInitialTours(defaultInProgressTourDate);
            });
    }

    backToDispatcher(tour): void {
        const tourStatusData = {
            "Status": 1,
            "Id": tour.id
        };
        this.dispatcherScreenService.changeTourStatus(tourStatusData).subscribe(() => {
            let defaultInProgressTourDate = sessionStorage.getItem('inprogress-tour-date') ? new Date(sessionStorage.getItem('inprogress-tour-date')) : new Date();
            this.getInitialTours(defaultInProgressTourDate);
        });
    }

}

function generateOrderString(tour) {
    tour.ordersString = tour.tour_Orders.map(order => order.orderInfo.ahlName).join(", ");
    tour.deliveredOrders = tour.tour_Orders.filter(order => order.orderInfo.statusType == 4).length;
}