import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, fromEvent, merge, Observable, BehaviorSubject } from 'rxjs';
import { InvoicingOverviewService } from './invoicing-overview.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { DataSource } from '@angular/cdk/table';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumber } from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'main-invoicing-overview',
    templateUrl: './invoicing-overview.component.html',
    styleUrls: ['./invoicing-overview.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InvoicingOverviewComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    date = new Date();
    overview: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['company', 'vendor', 'driver', 'deliveryDate', 'tourName', 'creationDate', 'creationTime', 'ahlName', 'address', 'city', 'name', 'noOfBag', 'km', 'pau', 'total', 'orderStatusName', 'tourStatusName'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;
    form: FormGroup;
    private _unsubscribeAll: Subject<any>;

    private params: any = {
        start_date : moment().format("YYYY-MM-DD"),
        end_date : moment().format("YYYY-MM-DD"),
        search_text: ''
    };

    constructor(
        private _invoicingOverviewService: InvoicingOverviewService,
        private router: Router,
        public _matDialog: MatDialog,
        private _fb: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private route: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
        
        //Get querystring params
        this.route.queryParams.subscribe(params => {
            console.log("params", params);
            if(params.start_date){
                this.params.start_date = params.start_date;
            }

            if(params.end_date){
                this.params.end_date = params.end_date;
            }

            if(params.search_text){
                this.params.search_text = params.search_text;
            }
        });
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._invoicingOverviewService, this.paginator, this.sort);
        this.sort.disableClear = true;

        /*If filter params exists then filter records*/
        this._invoicingOverviewService.getOverview().then(kek => {
            if (this.params.search_text) {
                this.dataSource.filter = this.params.search_text;
                this.filter.nativeElement.value = this.params.search_text;
            }
        });
        /*If filter params exists then filter records*/

        this.form = this._fb.group({
            dateRange: [{ begin: this.params.start_date, end: this.params.end_date }]
        });

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;

                //Set search text in querystring params
                this.router.navigate(
                    [],
                    {
                        relativeTo: this.route,
                        queryParams: {
                            search_text: this.filter.nativeElement.value
                        },
                        queryParamsHandling: 'merge', // remove to replace all query params by provided
                    });
            });
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onRangeSelected(event) {
        // const offset = event.value.begin.getTimezoneOffset();

        // let startDateTime = new Date(event.value.begin.getTime() - (offset * 60 * 1000));
        // let startDateTimeString = startDateTime.toISOString().split('T')[0];
        // //startDateTimeString = startDateTimeString + 'T00:00:00.001Z';

        // let endDateTime = new Date(event.value.end.getTime() - (offset * 60 * 1000));
        // let endDateTimeString = endDateTime.toISOString().split('T')[0];
        // //endDateTimeString = endDateTimeString + 'T23:59:59.999Z';

        // this._invoicingOverviewService.getToursByDates(startDateTimeString, endDateTimeString).then(kek => {
        //     console.log("kek")
        // });

        // //Set date range in querystring params
        // this.router.navigate(
        //     [],
        //     {
        //         relativeTo: this.route,
        //         queryParams: {
        //             start_date: startDateTimeString,
        //             end_date: endDateTimeString,
        //         },
        //         queryParamsHandling: 'merge', // remove to replace all query params by provided
        //     });
    }

}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {BeneficiaryService} _beneficiaryService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _invoicingOverviewService: InvoicingOverviewService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();
        this.filteredData = this._invoicingOverviewService.overview;
    }


    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }


    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._invoicingOverviewService.onOverviewChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._invoicingOverviewService.overview.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}



