import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { VehicleTypesService } from '../vehicle-types.service';

@Injectable({ providedIn: "root" })
export class VehicleTypeService implements Resolve<any>
{
    routeParams: any;
    vehicleType: any;
    onVehicleTypeChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _vehicleTypesService: VehicleTypesService
    )
    {
        this.onVehicleTypeChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getVehicleType()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getVehicleType(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onVehicleTypeChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/vehicleTypes/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.vehicleType = response;
                    this.onVehicleTypeChanged.next(this.vehicleType);
                    resolve(response);
                }, reject);
 
        });
    }

    /**
     * Save type
     *
     * @param vehicleType
     * @returns {Promise<any>}
     */
    saveVehicleType(vehicleType): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/vehicleTypes/' + vehicleType.id, vehicleType)
                .subscribe((response: any) => {
                    resolve(response);
                    this._vehicleTypesService.getVehicleTypes();
                }, reject);
        });
    }

}