import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { FuseUtils } from '@fuse/utils';
import { ServiceType } from '../serviceType.model';
import { ServiceTypeService } from './serviceType.service';
import { ServiceTypesService } from '../serviceTypes.service';

@Component({
  selector: 'service-types-service-type-form-dialog',
  templateUrl: './service-type.component.html',
  styleUrls: ['./service-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceTypeComponent {

  action: string;
  service: ServiceType;
  dialogTitle: string;
  pageType: string;
  serviceForm: FormGroup;
  myControl = new FormControl();
  options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
  filteredOptions: Observable<string[]>;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {ServiceTypeService} _serviceTypeService
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
      @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
      public matDialogRef: MatDialogRef<ServiceTypeComponent>,
      private _serviceTypeService: ServiceTypeService,
      private _serviceTypesService: ServiceTypesService,
      private _formBuilder: FormBuilder,
      private _matSnackBar: MatSnackBar
  )
  {
      this.service = new ServiceType(_data.serviceType);
      this.action = _data.action;

      this.dialogTitle = this.action === 'edit' ? 'Edit Service Type' : 'New Service Type';
      this.serviceForm = this.createServiceTypeForm();
      this._unsubscribeAll = new Subject();
  }

  /**
   * Create company form
   *
   * @returns {FormGroup}
   */
  createServiceTypeForm(): FormGroup
  {
      return this._formBuilder.group({
          id              : [this.service.id],
          name            : [this.service.name, [Validators.required, Validators.maxLength(40)]],
          active          : [this.service.active]
      });
  }

  saveServiceType(): void
  {
      const data = this.serviceForm.getRawValue();
      data.handle = FuseUtils.handleize(data.name);

      this._serviceTypeService.saveServiceType(data)
          .then(() => {
              this._serviceTypeService.onServiceTypeChanged.next(data);

              this._matSnackBar.open('Service type saved', 'OK', {
                  verticalPosition: 'top',
                  duration        : 2000
              });
          });
  }

 

  private _filter(value: any): any[] {
      const filterValue = value.toLowerCase();
  
      return this.options.filter(option => option.value.toLowerCase().includes(filterValue));
    }

}

