import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AirlinesService } from '../airlines.service';


@Injectable({ providedIn: "root" })
export class AirlineService implements Resolve<any>
{
    routeParams: any;
    airline: any;
    onAirlineChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _airlinesService: AirlinesService
    )
    {
        this.onAirlineChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getAirline()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get airline
     *
     * @returns {Promise<any>}
     */
    getAirline(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onAirlineChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/iata_airlines/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.airline = response;
                    this.onAirlineChanged.next(this.airline);
                    resolve(response);
                }, reject);
 
        });
    }

    /**
     * Save owner
     *
     * @param airline
     * @returns {Promise<any>}
     */
    saveAirline(airline): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/iata_airlines/' + airline.id, airline)
                .subscribe((response: any) => {
                    resolve(response);
                    this._airlinesService.getAirlines();
                }, reject);
        });
    }

}