import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { InvoiceDraft } from "./invoicing-drafts.model";


@Injectable({ providedIn: "root" })
export class InvoicingDraftsService implements Resolve<InvoiceDraft[]> {

    onSelectedContactsChanged: BehaviorSubject<any>;
    invoiceDrafts: InvoiceDraft[];
    onInvoiceDraftsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];

    constructor(private http: HttpClient) {
        this.onInvoiceDraftsChanged = new BehaviorSubject({});
        this.onSelectedContactsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getInvoiceDrafts({})
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getInvoiceDrafts(searchParams): Promise<InvoiceDraft[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/invoice/getalldraft', { params: searchParams })
                .subscribe((response: any) => {
                    this.invoiceDrafts = response;
                    this.onInvoiceDraftsChanged.next(this.invoiceDrafts);
                    resolve(response);
                }, reject);
        });
    }

    getInvoiceDraftDetails(invoiceId, type = "draft"): Promise<any> {
        return new Promise((resolve, reject) => {
            const apiUrl = environment.urlAddress + '/api/invoice/' + invoiceId + '/' + (type == "draft" ? 'draft' : 'invoice');
            this.http.get(apiUrl)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    createDraft(orders): Observable<any> {
        const apiUrl: string = `${environment.urlAddress}/api/invoice/createdraft`;
        return this.http.post<any>(apiUrl, orders);
    }

    createInvoice(invoiceIds): Observable<any> {
        let loginUserDetails = JSON.parse(localStorage.getItem('loginDetails'));
        const apiUrl: string = `${environment.urlAddress}/api/invoice/createinvoice`;
        return this.http.post<any>(apiUrl, { "Ids": invoiceIds, "User_id": loginUserDetails.id });
    }

    deleteDraft(invoiceIds): Observable<any> {
        const apiUrl: string = `${environment.urlAddress}/api/invoice/deletedraft`;
        return this.http.post<any>(apiUrl, { "Ids": invoiceIds });
    }
}