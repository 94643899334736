import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { VehicleTypesService } from './vehicle-types.service';
import { VehicleTypeService } from './vehicle-type/vehicle-type.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { VehicleTypeComponent } from './vehicle-type/vehicle-type.component';
import { FormGroup } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { isNumber } from 'lodash';

@Component({
  selector: 'main-vehicle-types',
  templateUrl: './vehicle-types.component.html',
  styleUrls: ['./vehicle-types.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class VehicleTypesComponent implements OnInit {

  @ViewChild('dialogContent')
  dialogContent: TemplateRef<any>;

  vehicleType: any;
  dataSource: FilesDataSource | null;
  displayedColumns = ['vehicleTypeName'];
  dialogRef: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('filter')
  filter: ElementRef;

  @ViewChild(MatSort)
  sort: MatSort;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CountriesService} _countriesService
   */
  constructor(
      private _vehicleTypesService: VehicleTypesService,
      private _vehicleTypeService: VehicleTypeService,
      public _matDialog: MatDialog
  )
  {
      this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void
  {
      this.dataSource = new FilesDataSource(this._vehicleTypesService, this.paginator, this.sort);
      this.sort.disableClear = true;

      fromEvent(this.filter.nativeElement, 'keyup')
          .pipe(
              takeUntil(this._unsubscribeAll),
              debounceTime(150),
              distinctUntilChanged()
          )
          .subscribe(() => {
              if ( !this.dataSource )
              {
                  return;
              }
              this.dataSource.filter = this.filter.nativeElement.value;
          });
  }

  ngOnDestroy(): void
  {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

   /**
     * Edit contact
     *
     * @param contact
     */
    editVehicleType(vehicleType): void
    {
        this.dialogRef = this._matDialog.open(VehicleTypeComponent, {
            panelClass: 'vehicle-type-form-dialog',
            data      : {
              vehicleType: vehicleType,
                action : 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    case 'save':
                        this._vehicleTypeService.saveVehicleType(formData.getRawValue());
                        break;
              
                    case 'delete':
                        this.deleteVehicleType(vehicleType);
                        break;
                }
            });
    }

    newVehicleType(): void
    {
        this.dialogRef = this._matDialog.open(VehicleTypeComponent, {
            panelClass: 'vehicle-type-form-dialog',
            data      : {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if ( !response )
                {
                    return;
                }

                this._vehicleTypesService.createVehicleType(response.getRawValue()).subscribe( () => {
                    this._vehicleTypesService.getVehicleTypes();
                });
            });
    }


    deleteVehicleType(vehicleType): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._vehicleTypesService.deleteVehicleType(vehicleType.id).subscribe( () => {
                    this._vehicleTypesService.getVehicleTypes();
                });
            }
            this.confirmDialogRef = null;
        });

    }
}



export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {CountriesService} _countriesService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
      private _vehicleTypesService: VehicleTypesService,
      private _matPaginator: MatPaginator,
      private _matSort: MatSort
  )
  {
      super();

      this.filteredData = this._vehicleTypesService.types;
  }

  get filteredData(): any
  {
      return this._filteredDataChange.value;
  }

  set filteredData(value: any)
  {
      this._filteredDataChange.next(value);
  }

  get filter(): string
  {
      return this._filterChange.value;
  }

  set filter(filter: string)
  {
      this._filterChange.next(filter);
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]>
  {
      const displayDataChanges = [
          this._vehicleTypesService.onVehicleTypesChanged,
          this._matPaginator.page,
          this._filterChange,
          this._matSort.sortChange
      ];

      return merge(...displayDataChanges).pipe(map(() => {

              let data = this._vehicleTypesService.types.slice();

              data = this.filterData(data);

              this.filteredData = [...data];

              data = this.sortData(data);

              // Grab the page's slice of data.
              const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
              return data.splice(startIndex, this._matPaginator.pageSize);
          })
      );

  }

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any
  {
      if ( !this.filter )
      {
          return data;
      }
      return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
      if (!this._matSort.active || this._matSort.direction === '') {
          return data;
      }

      let sortColumn = this._matSort.active;
      let direction = this._matSort.direction;
      if (direction == 'asc') {
          return data.sort(function (a, b) {
              var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                  vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());
                  
              return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
          });
      } else {
          return data.sort(function (a, b) {
              var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                  vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());

              return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
          });
      }
  }

  disconnect(): void
  {
  }
}
