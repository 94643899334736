import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { InvoicingOverviewComponent } from './invoicing-overview.component';
import { InvoicingOverviewService } from './invoicing-overview.service';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

const routes = [
    {
        path: '',
        component: InvoicingOverviewComponent,
        resolve: {
            data: InvoicingOverviewService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'invoicing/vendor-module/overview',
        component: InvoicingOverviewComponent,
        resolve: {
            data: InvoicingOverviewService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        InvoicingOverviewComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatDatepickerModule,
        SatDatepickerModule, 
        SatNativeDateModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatDatepickerModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCheckboxModule, 
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatCheckboxModule
    ],
    providers: [
        InvoicingOverviewService
    ],
    exports: [
        InvoicingOverviewComponent
    ]
})

export class InvoicingOverviewModule {
}
