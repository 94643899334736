import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { TourTable } from './tour.model';


@Injectable({ providedIn: "root" })
export class ToursService implements Resolve<TourTable[]> {

    tours: TourTable[];
    onToursChanged: BehaviorSubject<any>;
    
    statusTypes = [
        { name: "Created", value: 1 },
        { name: "Ready", value: 2 },
        { name: "In progress", value: 3 },
        { name: "Finished", value: 4 }
    ];

    constructor(private http: HttpClient) 
    {
        this.onToursChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getTours()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getTours(): Promise<TourTable[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/tours')
                .subscribe((response: any) => {
                    response.map(item => {
                        let tourStatusName = this.statusTypes.find(i => i.value === item.status);
                        item.statusName = tourStatusName ? tourStatusName.name : item.statusName;
                    });
                    this.tours = response;
                    this.onToursChanged.next(this.tours);
                    resolve(response);
                }, reject);
        });
    }

    getToursByDates( beginIsoDate1: string,  endIsoDate: string): Promise<TourTable[]>{
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.urlAddress}/api/tours/params?fromDate=${beginIsoDate1}&toDate=${endIsoDate}`)
                .subscribe((response: any) => {
                    response.map(item => {
                        let tourStatusName = this.statusTypes.find(i => i.value === item.status);
                        item.statusName = tourStatusName ? tourStatusName.name : item.statusName;
                    });
                    this.tours = response;
                    this.onToursChanged.next(this.tours);
                    resolve(response);
                }, reject);
        });
    }

    getTourDetails(id: number) : Observable<TourTable> {
        const  apiUrl: string = `${environment.urlAddress}/api/tours/${id}`;
        return this.http.get<TourTable>(apiUrl);
    }

}