import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { IATA_airport } from '../airport.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AirlineComponent } from 'app/main/airlines/airline/airline.component';
import { FuseUtils } from '@fuse/utils';
import { AirportsService } from '../airports.service';
import { AirportService } from './airport.service';

@Component({
  selector: 'airports-airport-form-dialog',
  templateUrl: './airport.component.html',
  styleUrls: ['./airport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AirportComponent {

  action: string;
  airport: IATA_airport;
  dialogTitle: string;
  pageType: string;
  airportForm: FormGroup;
  myControl = new FormControl();
  options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
  filteredOptions: Observable<string[]>;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
      @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
      public matDialogRef: MatDialogRef<AirlineComponent>,
      private _airportsService: AirportsService,
      private _airportService: AirportService,
      private _formBuilder: FormBuilder,
      private _matSnackBar: MatSnackBar
  )
  {
      this.airport = new IATA_airport(_data.airport);
      this.action = _data.action;
      this.dialogTitle = this.action === 'edit' ? 'Edit Airport' : 'New Airport';
      this.airportForm = this.createAirportForm();
      this._unsubscribeAll = new Subject();
  }

  createAirportForm(): FormGroup
  {
      return this._formBuilder.group({
          id              : [this.airport.id],
          airport         : [this.airport.airport, [Validators.required, Validators.maxLength(40)]],
          iatA_code       : [this.airport.iatA_code, [Validators.required, Validators.maxLength(3)]],
          city            : [this.airport.city],
          country         : [this.airport.country]
      });
  }

  saveAirport(): void
  {
      const data = this.airportForm.getRawValue();
      data.handle = FuseUtils.handleize(data.name);

      this._airportService.saveAirport(data)
          .then(() => {
              this._airportService.onAirportChanged.next(data);

              this._matSnackBar.open('Airport saved', 'OK', {
                  verticalPosition: 'top',
                  duration        : 2000
              });
          });
  }

}