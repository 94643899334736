import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { PriceTablePriceItemService } from './price-table-items.service';
import { CdkTableModule } from '@angular/cdk/table';
import { PriceTableItemsComponent } from './price-table-items.component';
import { ItmTblComponent } from './itm-tbl/itm-tbl.component';
import { ImportPricingComponent } from './import-pricing/import-pricing.component';

const routes = [
    {
        path: '',
        component: PriceTableItemsComponent,
        resolve: {
            data: PriceTablePriceItemService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'tableItems',
        component: PriceTableItemsComponent,
        resolve: {
            data: PriceTablePriceItemService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'tableItems/:id',
        component: ItmTblComponent,
        resolve: {
            data: PriceTablePriceItemService
        },
        canActivate: [AuthenticationGuard]
    }
];



@NgModule({
    declarations: [
        PriceTableItemsComponent,
        ItmTblComponent,
        ImportPricingComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        CdkTableModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatAutocompleteModule
    ],
    providers: [
        PriceTablePriceItemService
    ],
    exports: [
        PriceTableItemsComponent,
        ItmTblComponent,
        ImportPricingComponent
    ],
    entryComponents: [
        ImportPricingComponent
    ]
})

export class PriceTablePriceItemModule {
}
