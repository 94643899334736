import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    MatButtonModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';


import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';

import { MapComponent } from './map.component';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { AgmCoreModule } from '@agm/core';            
import { AgmDirectionModule } from 'agm-direction';

const routes: Routes = [
    {
        path: 'map',
        component: MapComponent,
        children: [],
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        MapComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
          }),
          AgmDirectionModule
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class LtsMapModule {
}
