export const locale = {
    lang: 'en',
    data: {

        /* LEFT MENU navigation section */

        'NAV': {
            'APPLICATIONS': 'Applications',
            'COMPANIES' : 'Companies',
            'VENDOR' : 'Vendors',
            'PROFILE' : 'Company',
            'VEHICLES.TITLE' : 'Vehicles',
            'DRIVERS.TITLE' : 'Drivers',
            'ENTITIES' : 'Entities',
            'INVOICE-RECIPIENTS.TITLE' : 'Invoice Recipients',
            'BENEFICIARIES.TITLE' : 'Beneficiaries',
            'VENDORS.TITLE' : 'Vendors',
            'KAKO-GOD.TITLE' : 'Price Items ...',
            'TABLES.TITLE' : 'Tables',
            'SERVICES' : 'Services',
            'VENDORS': 'Vendors',
            'DISPATCHERS.TITLE': 'Dispatchers',
            'BENEFICIARIESS.TITLE': 'Beneficiaries',
            'EMPLOYEE.TITLE': 'Employees',
            'ROLES.TITLE': 'ROLES',
            'ORDERS.TITLE' : 'Orders',
            'TOURS.TITLE' : 'Tours',
            'PRICE-ITEMS.TITLE' : 'Price Items',
            'FIXED-TOURS.TITLE' : 'Fixed Tours',
            'SERVICE-TYPES.TITLE' : 'Service Types',
            'PRICE-TABLES.TITLE' : 'Price Tables',
            'INVOICING' : 'Invoicing',
            'INVOICE-CYCLE.TITLE' : 'Invoice Cycle',
            'CLIENTDATA' : 'Client data',
            'AIRLINES.TITLE' : 'IATA airline',
            'AIRPORTS.TITLE' : 'IATA airport',
            'VEHICLE-TYPE.TITLE' : 'Vehicle Type',
            'ZONES.TITLE' : 'Zones',
            'LOGS.TITLE' : 'Logs',
            'COUNTRY.TITLE' : 'Country',
            'USERMANAGEMENT' : 'User management',
            'USERS.TITLE' : 'Users',
            'DISPATCHER.TITLE' : 'Dispatcher',
            'BASICDATA.TITLE' : 'Basic data',
            'VENDORS_COMPANIES.TITLE' : 'Vendor Companies',
            'DISTRICTS' : 'Districts',
            'INVOICING_MODULE.TITLE' : 'Invoicing module',
            'INVOICES.TITLE' : 'Invoices',
            'OVERVIEW.TITLE' : 'Overview',
            'DRAFTS.TITLE' : 'Drafts',
            'CREDIT_NOTES.TITLE' : 'Credit Notes',
            'REPORTS' : 'Reports',
            'INVOICE.TITLE' : 'Invoice'
        },

       /*  COMPANY - OWNER component */

        'OWNER': {

            /* HEADER SECTION */

            'COMPANIES.TITLE' : 'Companies list',
            'SEARCH.BENEFICIARY.TITLE' : 'Search for a beneficiary',
            'ADD.NEW.COMPANY' : 'ADD NEW COMPANY',

            /* TABLE SECTION */

            'TABLE.COMPANY' : 'Company',
            'TABLE.ADDRESS' : 'Address',
            'TABLE.COUNTRY' : 'Country',
            'TABLE.ZIP' : 'ZIP Code',
            'TABLE.CITY' : 'City',
            'TABLE.EMAIL' : 'Email',
            'TABLE.PHONE' : 'Phone number',

            /* MODALS SECTION */

            'FORM.COMPANY.NAME' : 'Company name',
            'FORM.COMPANY.EMAIL' : 'Email',
            'FORM.COMPANY.ADDRESS' : 'Address',
            'FORM.COMPANY.CITY' : 'City',
            'FORM.COMPANY.ZIP.CODE' : 'ZIP Code',
            'FORM.COMPANY.COUNTRY' : 'Country',
            'FORM.COMPANY.ASSIGN.COUNTRY' : 'Assign country',
            'FORM.COMPANY.PHONE.NUMBER' : 'Phone number',
            'FORM.COMPANY.FAX' : 'Fax',
            'FORM.COMPANY.WEBSITE' : 'Website',
            'FORM.COMPANY.REGISTER.NUMBER' : 'Register number',
            'FORM.COMPANY.VAT' : 'VAT',
            'FORM.COMPANY.BANK' : 'Bank',
            'FORM.COMPANY.IBAN' : 'IBAN',
            'FORM.COMPANY.BIC' : 'BIC',
            'FORM.COMPANY.NOTES' : 'Notes',
        }
    }
};
