import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { DriversService } from '../drivers.service';
import { Vendor } from 'app/main/vendors/vendor.model';
import { Driver } from '../drivers.model';
import { Location } from '../location.model';


@Injectable({ providedIn: "root" })
export class DriverService implements Resolve<any>
{
    routeParams: any;
    driver: any;
    onDriverChanged: BehaviorSubject<any>;
    list: string[];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _driversService: DriversService
    )
    {
        this.onDriverChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getDriver()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getDriver(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onDriverChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/drivers/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.driver = response;
                    this.onDriverChanged.next(this.driver);
                    resolve(response);
                }, reject);
 
        });
    }

    createDriver(driver: Driver, vendor: Vendor, location: Location) : Observable<Driver> {
        if(vendor) {
            this.list = vendor.vE_ID.split('_');
            driver.vendorId = this.list[1];
        }

        if(location){
            driver.locationId = location.locationId;
        }

        if(driver.active == null){
            driver.active = false;
        }
        const apiUrl: string = `${environment.urlAddress}/api/drivers`;
        return this._httpClient.post<Driver>(apiUrl, driver);
    }

    saveDriver(driver, vendor, location): Promise<any>
    {
        if(vendor){
            this.list = vendor.vE_ID.split('_');
            driver.vendorId = this.list[1];
        }
        if(location){
            driver.locationId = location.locationId;
        }

        if(driver.active == null){
            driver.active = false;
        }
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/drivers/' + driver.id, driver)
                .subscribe((response: any) => {
                    resolve(response);
                    this._driversService.getDrivers();
                }, reject);
        });
    }

    deleteDriver(id: number) : Observable<Driver>{
        const apiUrl: string = `${environment.urlAddress}/api/drivers/${id}`;
        return this._httpClient.delete<Driver>(apiUrl);
    }
   
}