import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Beneficiary } from "app/main/beneficiaries/beneficiary.model";

@Injectable({ providedIn: "root" })
export class InvoicingDraftService implements Resolve<[]> {

    constructor(private http: HttpClient) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getBeneficiaries(fromDate, toDate): Promise<Beneficiary[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/invoice/params?fromDate=' + fromDate + '&toDate=' + toDate)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getOrders(beIds, fromDate, toDate): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(environment.urlAddress + '/api/invoice/GetOrdersByBEIds', {
                "BEIDs": beIds,
                "fromDate": fromDate,
                "toDate": toDate
            })
            .subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
}