import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { TourTemplate } from './fixed-tour.model';


@Injectable({ providedIn: "root" })
export class FixedToursService implements Resolve<TourTemplate[]> {

    fixedTours: TourTemplate[];
    onFixedToursChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onFixedToursChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getFixedTours()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getFixedTours(): Promise<TourTemplate[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/fixedTours')
                .subscribe((response: any) => {
                    this.fixedTours = response;
                    this.onFixedToursChanged.next(this.fixedTours);
                    resolve(response);
                }, reject);
        });
    }

    getFixedTourDetails(id: number) : Observable<TourTemplate> {
        const  apiUrl: string = `${environment.urlAddress}/api/fixedTours/${id}`;
        return this.http.get<TourTemplate>(apiUrl);
    }

   createFixedTour(tour: TourTemplate) : Observable<TourTemplate> {
   
        const apiUrl: string = `${environment.urlAddress}/api/fixedTours`;
        return this.http.post<TourTemplate>(apiUrl, tour);
    }


    deleteFixedTour(id: number) : Observable<TourTemplate>{
        const apiUrl: string = `${environment.urlAddress}/api/fixedTours/${id}`;
        return this.http.delete<TourTemplate>(apiUrl);
    }

}