import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MapBoxService } from '@mapbox/mapbox.service';
import { MapService } from './map.service';
import { AgmMap } from '@agm/core';


@Component({
    selector: 'map-overview',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    encapsulation: ViewEncapsulation.None

})


export class MapComponent implements OnInit {
    constructor(
        private mapService: MapService
    ) {

    }
    public centerLocation = { "lat": 48.1126125, "lng": 16.5755139 };
    public driverMarkers = [];
    @ViewChild(AgmMap) agmMap;
    ngOnInit() {
        this.agmMap.mapReady.subscribe(map => {
            this.mapService.getActiveDrivers().then((drivers: any) => {
                if (drivers.length > 0) {
                    for (let i = 0; i < drivers.length; i++) {
                        if (drivers[i].latitude && drivers[i].longitude) {
                            if (this.driverMarkers.length == 0) {
                                this.centerLocation = { "lat": drivers[i].latitude, "lng": drivers[i].longitude };
                            }
                            this.driverMarkers.push({
                                "name": drivers[i].firstName + " " + drivers[i].lastName,
                                "lat": drivers[i].latitude,
                                "lng": drivers[i].longitude
                            });
                        }
                    }
                }
                console.log("this.centerLocation", this.centerLocation);
                console.log("this.driverMarkers", this.driverMarkers);
            });
        });
    }

}


