import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { User } from '../users/users.model';

@Injectable({ providedIn: "root" })
export class RoleModuleService implements Resolve<User[]> {

    users: User[];
    onUsersChanged: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
        this.onUsersChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([

            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getModuleByRole(roleName): Observable<User> {
        const apiUrl: string = `${environment.urlAddress}/api/userrolemodule/GetModuleByRole?roleName=` + roleName;
        return this.http.get<User>(apiUrl);
    }

    updateModuleRole(payload: any): Observable<any> {
        const apiUrl: string = `${environment.urlAddress}/api/userrolemodule/UpdateModuleRole`;
        return this.http.post<any>(apiUrl, payload);
    }
}