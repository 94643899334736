import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { PriceItemComponent } from './price-item.component';
import { PriceItemsService } from './price-item.service';
import { ItemComponent } from './item/item.component';
import { PriceItemService } from './item/item.service';
import { PriceItem } from './price-item.model';

const routes = [
    {
        path: '',
        component: PriceItemComponent,
        resolve: {
            data: PriceItemsService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'priceItems',
        component: PriceItemComponent,
        resolve: {
            data: PriceItemsService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'priceItems/:id',
        component: ItemComponent,
        resolve: {
            data: PriceItemService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        PriceItemComponent,
        ItemComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,

        
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        PriceItemsService
    ],
    exports: [
        PriceItemComponent, 
        ItemComponent
    ]
})

export class PriceItemModule {
}
