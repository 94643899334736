import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Country } from './country.model';


@Injectable({ providedIn: "root" })
export class CountriesService implements Resolve<Country[]> {

    countries: Country[];
    onCountriessChanged: BehaviorSubject<any>;
    
    constructor(private http: HttpClient) 
    {
        this.onCountriessChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCountries()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCountries(): Promise<Country[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/countries')
                .subscribe((response: any) => {
                    this.countries = response;
                    this.onCountriessChanged.next(this.countries);
                    resolve(response);
                }, reject);
        });
    }

    getCountryDetails(id: number) : Observable<Country> {
        const  apiUrl: string = `${environment.urlAddress}/api/countries/${id}`;
        return this.http.get<Country>(apiUrl);
    }

   createCountry(country: Country) : Observable<Country> {
        const apiUrl: string = `${environment.urlAddress}/api/countries`;
        return this.http.post<Country>(apiUrl, country);
    }


    deleteCountry(id: number) : Observable<Country>{
        const apiUrl: string = `${environment.urlAddress}/api/countries/${id}`;
        return this.http.delete<Country>(apiUrl);
    }

}