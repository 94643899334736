import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { User } from 'app/main/user-management/users/users.model';
import { LoginService } from 'app/main/pages/authentication/login/login.service';

@Injectable({ providedIn: "root" })
export class UserDetailService 
{
    users: User[];
    onUsersChanged: BehaviorSubject<any>;
     /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService,
    )
    {
        this.onUsersChanged = new BehaviorSubject({});
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>
    {
        const id = this._loginService.getUserDetails().id;
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.urlAddress + '/api/users/' + id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
 
        });
    }

    getUsers(): Promise<User[]>{
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.urlAddress + '/api/users')
                .subscribe((response: any) => {
                    this.users = response;
                    this.onUsersChanged.next(this.users);
                    resolve(response);
                }, reject);
        });
    }

    saveUserDetails(user): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.patch(environment.urlAddress + '/api/users/' + user.id, user)
                .subscribe((response: any) => {
                    resolve(response);
                    this._loginService.updateUserDetails(user);
                    this.getUsers();
                }, reject);
        });
    }
    
    deleteUser(id: number) : Observable<User>{
        const apiUrl: string = `${environment.urlAddress}/api/users/${id}`;
        return this._httpClient.delete<User>(apiUrl);
    }
}