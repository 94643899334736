export class PriceTable{
    name: string;
    beginDate: Date;
    endDate: Date;
    dateOfCreation: Date;
    id?: string;
    IsCopy?: boolean;

    /**
     * Constructor
     * @param priceTable
     */
    constructor(priceTable?){
        priceTable = priceTable || {};
        this.id = priceTable.id || 0;
        this.name = priceTable.name || '';
        this.beginDate = priceTable.beginDate;
        this.endDate = priceTable.endDate;
        this.dateOfCreation = priceTable.dateOfCreation || Date.now;
        this.IsCopy = priceTable.IsCopy || false;
    }
}