import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DispatcherScreenService } from '../../dispatcher-screen.service';

@Component({
  selector: 'tour-status-popup-dialog',
  templateUrl: './tour-status-popup.component.html',
  styleUrls: ['./tour-status-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TourStatusPopupComponent {
  action: string;
  dialogTitle: string;
  displayedColumns: string[];
  //dataSource: [];
  dialogRef: any;
  tourWithOrdersData: any;
  // unreadTourStatusCount: number = 0;
  form: FormGroup;
  loader: boolean = false;
  /**
   * Constructor
   *
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<TourStatusPopupComponent>,
    public _matDialog: MatDialog,
    private _fb: FormBuilder,
    private dispatcherScreenService: DispatcherScreenService
  ) {
    this.action = _data.action;
    this.dialogTitle = "Tours Status";
    this.displayedColumns = ['name', 'status', 'phoneNumber', 'deliveryDate', 'customerName', 'note'];
    this.tourWithOrdersData = _data.notDeliveredToursWithOrders;
    // this.unreadTourStatusCount = this._data.unreadTourStatusCount;
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      dateRange: this._data.dateRange
    });
  }

  onRangeSelected(event) {
    this.loader = true;
    const offset = event.value.begin.getTimezoneOffset();

    let startDateTime = new Date(event.value.begin.getTime() - (offset * 60 * 1000));
    let startDateTimeString = startDateTime.toISOString().split('T')[0];
    startDateTimeString = startDateTimeString + 'T00:00:00.001Z';

    let endDateTime = new Date(event.value.end.getTime() - (offset * 60 * 1000));
    let endDateTimeString = endDateTime.toISOString().split('T')[0];
    endDateTimeString = endDateTimeString + 'T23:59:59.999Z';

    let requestPayload = {
      "StartDate": startDateTimeString,
      "EndDate": endDateTimeString
    };

    this.dispatcherScreenService.getNotDeliveredOrdersByFilter(requestPayload).then((response: any) => {
      this.tourWithOrdersData = response.tourDetail;
      this._data.unreadTourStatusCount = response.count;
      this.loader = false;
    });
  }

  closeDialog() {
    this.matDialogRef.close({
      actionType: 'close',
      unreadTourStatusCount: this._data.unreadTourStatusCount,
      notDeliveredToursWithOrders: this.tourWithOrdersData,
      dateRange: this.form.value.dateRange
    });
  }
}