import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, 
    MatChipsModule, MatAutocompleteModule, MatCheckboxModule, MatDialogRef, MatDialogModule,
    MatDatepickerModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import {MatToolbarModule} from '@angular/material/toolbar';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { RoleModuleComponent } from './role-module.component';
import { RoleModuleService } from './role-module.service';

const routes = [
    {
        path: '',
        component: RoleModuleComponent,
        resolve: {
            data: RoleModuleService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'user-management/roles',
        component: RoleModuleComponent,
        resolve: {
            data: RoleModuleService
        },
        canActivate: [AuthenticationGuard]
    },
];

@NgModule({
    entryComponents: [
    ],
    declarations: [
        RoleModuleComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    exports: [
        RoleModuleComponent
    ],
    providers: [
        RoleModuleService
    ]
})

export class RolesModule {
}
