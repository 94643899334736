// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    //urlAddress: 'http://localhost:44386',
    urlAddress: 'https://api.aps-vie.com',
    //urlAddress: 'http://116.202.126.24',
     mapbox: {
        accessToken: 'pk.eyJ1IjoiZ2xpZ29yaWplIiwiYSI6ImNqcm01NXBtbDBmb2M0NHBueDg3dmc2ZTgifQ.byTj1ztuHuKhdng6ScCeTg'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
