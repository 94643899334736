import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef, MatAutocompleteModule
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { OwnersComponent } from './owners.component';
import { OwnersService } from './owners.service';
import { OwnerComponent } from './owner/owner.component';
import { OwnerService } from './owner/owner.service';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';


import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';

const routes = [
    {
        path: '',
        component: OwnersComponent,
        resolve: {
            data: OwnersService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'owners',
        component: OwnersComponent,
        resolve: {
            data: OwnersService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'owners/:id',
        component: OwnerComponent,
        resolve: {
            data: OwnerService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        OwnersComponent,
        OwnerComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        TranslateModule,        
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,
        AgmCoreModule.forRoot({ 
            apiKey: 'AIzaSyAOxhLUKQ3mxEkp0D_2miff1-ifA7pZjzA',
            libraries: ["places"]
        })
    ],
    providers: [
        OwnersService
    ],
    exports: [
        OwnersComponent, 
        OwnerComponent
    ]
})

export class OwnersModule {


}
