import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { RoleModuleService } from './role-module.service';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'main-role-module',
    templateUrl: './role-module.component.html',
    styleUrls: ['./role-module.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RoleModuleComponent implements OnInit, OnDestroy {
    allModules: any = [];
    private _unsubscribeAll: Subject<any>;
    roleId: Number = 0;
    selectedModuleIds: Number[] = [];
    private parentChildren: any = [];
    private childParents: any = [];
    /**
     * Constructor
     *
     * @param {RoleModuleService} _roleModuleService
     */
    constructor(
        private _roleModuleService: RoleModuleService, private _matSnackBar: MatSnackBar
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void { }

    selectRole(event): void {
        this.roleId = parseInt(event.value);
        this.allModules = [];
        this.parentChildren = [];
        this.childParents = [];
        this.selectedModuleIds = [];

        if (this.roleId) {
            this._roleModuleService.getModuleByRole(this.roleId).subscribe((response) => {
                this.allModules = response;
                this.allModules.forEach(rootElement => {
                    this.parentChildren[rootElement.id] = [];
                    if (rootElement.isSelected)
                        this.selectedModuleIds.push(rootElement.id);

                    if (rootElement.childModules.length > 0) {
                        rootElement.childModules.forEach(firstChildElement => {
                            this.parentChildren[rootElement.id].push(firstChildElement.id);
                            this.parentChildren[firstChildElement.id] = [];
                            this.childParents[firstChildElement.id] = [rootElement.id];
                            if (firstChildElement.isSelected)
                                this.selectedModuleIds.push(firstChildElement.id);
                            if (firstChildElement.childModules.length > 0) {
                                firstChildElement.childModules.forEach(secondChildElement => {
                                    this.parentChildren[rootElement.id].push(secondChildElement.id);
                                    this.parentChildren[firstChildElement.id].push(secondChildElement.id);
                                    this.childParents[secondChildElement.id] = [firstChildElement.id, rootElement.id];
                                    if (secondChildElement.isSelected)
                                        this.selectedModuleIds.push(secondChildElement.id);
                                });
                            }
                        });
                    }
                });
            });
        }
    }

    toggleMenu(menuId): void {
        this.allModules.forEach(rootElement => {
            if (rootElement.id === menuId) {
                rootElement.displayChild = !rootElement.displayChild;
            } else {
                rootElement.childModules.forEach(firstChildElement => {
                    if (firstChildElement.id === menuId) {
                        firstChildElement.displayChild = !firstChildElement.displayChild;
                    } else {
                        firstChildElement.childModules.forEach(secondChildElement => {
                            if (secondChildElement.id === menuId) {
                                secondChildElement.displayChild = !secondChildElement.displayChild;
                            }
                        });
                    }
                });
            }
        });
    }

    toggleModules(event): void {
        let moduleId = parseInt(event.target.value);
        if (event.target.checked) {
            this.selectedModuleIds.push(moduleId);
            //If parent selected then select all children
            if (this.parentChildren[moduleId] && this.parentChildren[moduleId].length > 0) {
                this.parentChildren[moduleId].forEach(elementId => {
                    this.selectedModuleIds.push(elementId);
                });
            }
            //If child selected then select all parents
            if (this.childParents[moduleId] && this.childParents[moduleId].length > 0) {
                this.childParents[moduleId].forEach(elementId => {
                    this.selectedModuleIds.push(elementId);
                });
            }
        } else {
            let moduleIdIndex = this.selectedModuleIds.indexOf(moduleId);
            if (moduleIdIndex >= 0)
                this.selectedModuleIds.splice(moduleIdIndex, 1);

            //If parent unselected then unselect all children
            if (this.parentChildren[moduleId] && this.parentChildren[moduleId].length > 0) {
                this.parentChildren[moduleId].forEach(elementId => {
                    let elementIdIndex = this.selectedModuleIds.indexOf(elementId);
                    if (elementIdIndex >= 0)
                        this.selectedModuleIds.splice(elementIdIndex, 1);
                });
            }
        }
        this.selectedModuleIds = this.selectedModuleIds.filter((item, i, ar) => ar.indexOf(item) === i);
    }

    saveRoleModules(): void {
        let roleModulePayload = {
            "Role": this.roleId,
            "ModuleList": []
        }
        for (let i = 0; i < this.selectedModuleIds.length; i++) {
            roleModulePayload.ModuleList.push({ "Id": this.selectedModuleIds[i] });
        }
        this._roleModuleService.updateModuleRole(roleModulePayload).subscribe((response) => {
            // console.log("response", response);
            this._matSnackBar.open(response.message, 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}