import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { InvoiceRecipientsService } from '../invoice-recipients.service';
import { UST } from '../invoice-recipient.model';

@Injectable({ providedIn: "root" })
export class InvoiceRecipientService implements Resolve<any>
{
    routeParams: any;
    recipient: any;
    onRecipientChanged: BehaviorSubject<any>;

     /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _invoiceRecipientsService: InvoiceRecipientsService
    )
    {
        this.onRecipientChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            
            Promise.all([
                this.getInvoiceRecipient()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

      /**
     * Get recipient
     *
     * @returns {Promise<any>}
     */
    getInvoiceRecipient(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onRecipientChanged.next(false);
                resolve(true);
                return;
            }
            this._httpClient.get(environment.urlAddress + '/api/invoicerecipients/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.recipient = response;
                    this.onRecipientChanged.next(this.recipient);
                    resolve(response);
                }, reject);
 
        });
    }

      /**
     * Save user
     *
     * @param user
     * @returns {Promise<any>}
     */
    saveRecipient(recipient, country, invoiceCycle, iataCodeAirline): Promise<any>
    {
        if(country){
            recipient.countryId = country.id;
        }
        if(invoiceCycle){
            recipient.invoicingCycleId = invoiceCycle.id;
        }
        if(iataCodeAirline){
            recipient.iatA_codeAirlineId = iataCodeAirline.id;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.urlAddress + '/api/invoicerecipients/' + recipient.iR_ID, recipient)
                .subscribe((response: any) => {
                    resolve(response);
                    this._invoiceRecipientsService.getInvoiceRecipients();
                }, reject);
        });
    }

    getUST() : Observable<UST> {
        const  apiUrl: string = `${environment.urlAddress}/api/invoicerecipients/GetUST`;
        return this._httpClient.get<UST>(apiUrl);
    }

}
