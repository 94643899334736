import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { DispatcherOrdersService } from './orders.service';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { Order } from './order.model';
import { isNumber } from 'lodash';

export class DispatcherOrdersDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {BeneficiaryService} _beneficiaryService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _DispatcherOrdersService: DispatcherOrdersService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._DispatcherOrdersService.orders;
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._DispatcherOrdersService.onOrdersChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._DispatcherOrdersService.orders.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    remove(toBeRemoved: Order): any {
        this.filteredData = this.filteredData.filter(order => order.id === toBeRemoved.id);
    }


    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;
        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());
                    
                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn]:("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn]:("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}