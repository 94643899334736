import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Mail } from 'app/mail/mail.model';
import { environment } from 'environments/environment';

@Injectable()
export class MailService implements Resolve<any>
{

    mails: Mail[] = [];
    selectedMails: Mail[];
    currentMail: Mail;
    pageNumber = 1;
    pageSize = 50;
    totalPages: number;
    totalCount: number;
    searchString: string = '';

    folders: any[] = [];
    filters: any[];
    labels: any[];
    routeParams: any;

    onMailsChanged: BehaviorSubject<any>;
    onSelectedMailsChanged: BehaviorSubject<any>;
    onCurrentMailChanged: BehaviorSubject<any>;
    onFoldersChanged: BehaviorSubject<any>;
    onFiltersChanged: BehaviorSubject<any>;
    onLabelsChanged: BehaviorSubject<any>;
    onSearchTextChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        this.selectedMails = [];
        this.onMailsChanged = new BehaviorSubject([]);
        this.onSelectedMailsChanged = new BehaviorSubject([]);
        this.onCurrentMailChanged = new BehaviorSubject([]);
        this.onFoldersChanged = new BehaviorSubject([]);
        this.onFiltersChanged = new BehaviorSubject([]);
        this.onLabelsChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new BehaviorSubject('');
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([
                this.getMails({ pageNumber: this.pageNumber, pageSize: this.pageSize, searchString: this.searchString })
            ]).then(mails => {
                //on search
                this.onSearchTextChanged.subscribe(searchText => {
                    this.searchString = searchText;
                    this.getMails({ pageNumber: this.pageNumber, pageSize: this.pageSize, searchString: this.searchString })
                });

                resolve(this.mails);
            });
        });
    }

    /**
     * Get all folders
     *
     * @returns {Promise<any>}
     */
    getFolders(): Promise<any> {
        return new Promise((resolve, reject) => {

        });
    }

    /**
     * Get all filters
     *
     * @returns {Promise<any>}
     */
    getFilters(): Promise<any> {
        return new Promise((resolve, reject) => {

            resolve([]);
        });
    }

    /**
     * Get all labels
     *
     * @returns {Promise<any>}
     */
    getLabels(): Promise<any> {
        return new Promise((resolve, reject) => {

            resolve([]);
        });
    }

    /**
     * Get all mails
     *
     * @returns {Promise<Mail[]>}
     */
    getMails(params: { pageNumber: number, pageSize: number, searchString: string }): Promise<Mail[]> {
        const { pageSize, pageNumber, searchString } = params;

        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.urlAddress}/api/inbox?pageNumber=${pageNumber}&pageSize=${pageSize}&pageSize=${pageSize}&searchString=${searchString}`)
                .subscribe((response: any) => {
                    this.mails = response.emails;
                    this.totalCount = response.totalCount;
                    this.totalPages = response.totalPages;
                    this.onMailsChanged.next({ totalCount: this.totalCount, totalPages: this.totalPages, mails: this.mails });
                    this.onCurrentMailChanged.next(null);
                    resolve(this.mails);
                });



        });
    }

    /**
     * Get mails by folder
     *
     * @param handle
     * @returns {Promise<Mail[]>}
     */
    getMailsByFolder(handle): Promise<Mail[]> {
        return new Promise((resolve, reject) => {
            resolve([]);

        });
    }

    /**
     * Get mails by filter
     *
     * @param handle
     * @returns {Promise<Mail[]>}
     */
    getMailsByFilter(handle): Promise<Mail[]> {
        return new Promise((resolve, reject) => {
            resolve([]);

        });
    }

    /**
     * Get mails by label
     *
     * @param handle
     * @returns {Promise<Mail[]>}
     */
    getMailsByLabel(handle): Promise<Mail[]> {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }

    /**
     * Toggle selected mail by id
     *
     * @param id
     */
    toggleSelectedMail(id): void {
        if (this.selectedMails.length > 0) {
            for (const mail of this.selectedMails) {
                if (mail.id === id) {
                    const index = this.selectedMails.indexOf(mail);

                    if (index !== -1) {
                        this.selectedMails.splice(index, 1);

                        this.onSelectedMailsChanged.next(this.selectedMails);

                        return;
                    }
                }
            }
        }

        this.selectedMails.push(
            this.mails.find(mail => {
                return mail.id === id;
            })
        );

        this.onSelectedMailsChanged.next(this.selectedMails);
    }


    toggleSelectAll(): void {
        if (this.selectedMails.length > 0) {
            this.deselectMails();
        }
        else {
            this.selectMails();
        }

    }

    /**
     * Select mails
     *
     * @param filterParameter
     * @param filterValue
     */
    selectMails(filterParameter?, filterValue?): void {
        this.selectedMails = [];

        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedMails = this.mails;
        }
        else {
            this.selectedMails.push(...
                this.mails.filter(mail => {
                    return mail[filterParameter] === filterValue;
                })
            );
        }

        this.onSelectedMailsChanged.next(this.selectedMails);
    }


    deselectMails(): void {
        this.selectedMails = [];

        this.onSelectedMailsChanged.next(this.selectedMails);
    }

    /**
     * Set current mail by id
     *
     * @param id
     */
    setCurrentMail(id): void {
        this.currentMail = this.mails.find(mail => {
            return mail.id === id;
        });

        this.onCurrentMailChanged.next(this.currentMail);
    }

    /**
     * Toggle label on selected mails
     *
     * @param labelId
     */
    toggleLabelOnSelectedMails(labelId): void {
        this.selectedMails.map(mail => {

            const index = mail.labels.indexOf(labelId);

            if (index !== -1) {
                mail.labels.splice(index, 1);
            }
            else {
                mail.labels.push(labelId);
            }

            this.updateMail(mail);
        });
    }

    /**
     * Set folder on selected mails
     *
     * @param folderId
     */
    setFolderOnSelectedMails(folderId): void {
        this.selectedMails.map(mail => {
            mail.folder = folderId;

            this.updateMail(mail);
        });

        this.deselectMails();
    }

    /**
     * Update the mail
     *
     * @param mail
     * @returns {Promise<any>}
     */
    updateMail(mail): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve([]);

        });
    }

    onPageChanged(params): any {
        this.pageNumber = params.pageNumber;
        this.pageSize = params.pageSize;

        params.searchString = this.searchString;
        this.getMails(params);
    }
}
