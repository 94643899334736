import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        let loginUserDetails = JSON.parse(localStorage.getItem('loginDetails'));
        let modules = loginUserDetails ? loginUserDetails.modules : [];

        this.navigation = [{
            id: 'applications',
            title: '',
            translate: 'NAV.APPLICATIONS',
            type: 'group',
            icon: 'apps',
            children: this.renderMenu(modules)
        }];
        
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = [{
                    id: 'applications',
                    title: '',
                    translate: 'NAV.APPLICATIONS',
                    type: 'group',
                    icon: 'apps',
                    children: this.renderMenu(modules)
                }];

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    renderMenu(modules): any {
        let navigationModules = [];
        if (modules) {
            modules.forEach((rootElement, rootIndex) => {
                let rootModuleItem = {
                    id: rootElement.moduleName.replace(/ /g, ''),
                    title: rootElement.moduleName,
                    type: rootElement.childModules.length > 0 ? 'collapsable' : 'item',
                    icon: rootElement.icon,
                    children: this.renderMenu(rootElement.childModules)
                };
                if (rootElement.childModules.length === 0) {
                    rootModuleItem['url'] = rootElement.url;
                }
                navigationModules.push(rootModuleItem);
            });
        }
        return navigationModules;
    }
}
