import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Console } from 'console';

@Component({
  selector: 'sita-popup-dialog',
  templateUrl: './sita-popup.component.html',
  styleUrls: ['./sita-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SitaPopupComponent {
  action: string;
  dialogTitle: string;
  jsonData: any = {};
  /**
   * Constructor
   *
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public _matDialog: MatDialog,
    public matDialogRef: MatDialogRef<SitaPopupComponent>
  ) {

    if (_data.type == "sitaODRequest") {
      this.dialogTitle = "SITA OD Request";
    } else if (_data.type == "sitaODResponse") {
      this.dialogTitle = "SITA OD Response";
    } else if (_data.type == "sitaOFRequest") {
      this.dialogTitle = "SITA OF Request";
    } else if (_data.type == "sitaOFResponse") {
      this.dialogTitle = "SITA OF Response";
    } else if (_data.type == "sitaUNRequest") {
      this.dialogTitle = "SITA UN Request";
    } else if (_data.type == "sitaUNResponse") {
      this.dialogTitle = "SITA UN Response";
    } else if (_data.type == "sitaUPRequest") {
      this.dialogTitle = "SITA UP Request";
    } else if (_data.type == "sitaUPResponse") {
      this.dialogTitle = "SITA UP Response";
    }
    
    this.jsonData = JSON.parse(_data.record[_data.type] || '{}');
  }
}