import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Driver } from 'app/main/drivers/drivers.model';
import { DriversService } from 'app/main/drivers/drivers.service';
import { TourService } from 'app/main/tours/tour/tour.service';
import { Observable, Subject } from 'rxjs';
import { DispatcherScreenService } from '../../dispatcher-screen.service';
import { startWith, map } from 'rxjs/operators';
import { MatTable, MatSnackBar, MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ExtraPriceItemComponent } from './extra-price-item/extra-price-item.component';

@Component({
    selector: 'tour-with-orders-form-dialog',
    templateUrl: './tour-with-orders.component.html',
    styleUrls: ['./tour-with-orders.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TourWithOrdersComponent {
    displayedColumns: string[];
    dataSource: any;
    tourDetails: any;
    tourForm: FormGroup;
    statusTypes = [
        { name: "Created", value: 1 },
        { name: "Ready", value: 2 },
        { name: "In progress", value: 3 },
        { name: "Finished", value: 4 }
    ];
    driverCtrl = new FormControl();
    filteredDrivers: Observable<Driver[]>;
    drivers: Driver[] = [];
    allDrivers: Driver[];

    @ViewChild('table1') table1: MatTable<any>;

    public lat = 48.1126125;
    public lng = 16.5755139;
    public origin: any;
    public destination: any;
    public wayPoints: any;
    public markerOptions: any;;
    public renderOptions: any;
    private _unsubscribeAll: Subject<any>;
    public directionPoints: any;
    public directionRenderPoints: any;
    tourConfirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: any;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _dispatcherScreenService: DispatcherScreenService,
        private _driversService: DriversService,
        private _tourService: TourService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private _matSnackBar: MatSnackBar,
        public _matDialog: MatDialog,
        private router: Router
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._dispatcherScreenService.getTourDetails(this.route.snapshot.params.id).then(tourDetailsResponse => {
            this.tourDetails = tourDetailsResponse;
            if (this.tourDetails.status === 4) {
                this.displayedColumns = ['ahlName', 'temporaryAddress01', 'city', 'zipCode', 'numberOfBags', 'kilometers', 'priority'];
            } else {
                this.displayedColumns = ['ahlName', 'temporaryAddress01', 'city', 'zipCode', 'numberOfBags', 'kilometers', 'priority', 'action'];
            }
            this.tourForm = this.createTourForm();
            this.setupAutocompleteDataSourceDrivers();

            //Check if any order's priority is NULL
            let isOrderPriorityNull = false;

            for (let i = 0; i < this.tourDetails.tour_Orders.length; i++) {
                if (!this.tourDetails.tour_Orders[i].priority)
                    isOrderPriorityNull = true;
            }

            //If atleast one order has priority NULL then find and set priorities for all the orders
            if (isOrderPriorityNull) {
                this.setOptimizedOrderPriorities();
            } else {
                //sort orders by priority
                this.tourDetails.tour_Orders.sort((a, b) => parseInt(a.priority) - parseInt(b.priority));

                //Set order grid data
                this.dataSource = new MatTableDataSource(this.tourDetails.tour_Orders);

                //Set origin, destination and waypoints                
                this.setDirectionData(this.tourDetails);
            }
        });
    }

    setOptimizedOrderPriorities = () => {
        //set priorities null for all the orders
        let orderLatLongs = [];
        for (let i = 0; i < this.tourDetails.tour_Orders.length; i++) {
            orderLatLongs.push({ lat: this.tourDetails.tour_Orders[i].orderInfo.latitude, lng: this.tourDetails.tour_Orders[i].orderInfo.longitude });
            this.tourDetails.tour_Orders[i].priority = null;
            this.tourDetails.tour_Orders[i].kilometers = 0;
        }

        //Find nearest order from tour base address
        this._dispatcherScreenService.distanceForMultipleDestianations({ lat: this.tourDetails.latitude, lng: this.tourDetails.longitude }, orderLatLongs)
            .subscribe((result: any) => {
                if (result && result.rows[0] && result.rows[0].elements) {
                    let minDistance = Math.min.apply(Math, result.rows[0].elements.map(function (item) { return (item.distance && item.distance.value || 9999999999999); }));
                    let nearestPointIndex = result.rows[0].elements.findIndex(item => (item.distance && item.distance.value || 9999999999999) == minDistance);

                    //Update priority for order
                    this.tourDetails.tour_Orders[nearestPointIndex].priority = 1;
                    this.tourDetails.tour_Orders[nearestPointIndex].kilometers = ((minDistance == 9999999999999) ? 0 : (minDistance / 1000));

                    //Find and set priorities for nearest orders
                    if (this.tourDetails.tour_Orders.length > 1)
                        this.findOrdersPriorities();
                    else {
                        this._dispatcherScreenService.updateTourOrderWithPriority(this.tourDetails.tour_Orders).subscribe();

                        //Set order grid data
                        this.dataSource = new MatTableDataSource(this.tourDetails.tour_Orders);

                        //Set origin, destination and waypoints                
                        this.setDirectionData(this.tourDetails);
                    }
                }
            }
            );
    }

    findOrdersPrioritiesPromise = (index) => {
        return new Promise(resolve => {
            //lat-long of all orders
            let orderLatLongsInternal = [];
            let mapTourOrders = [];
            let nextOrigin;

            //sort orders by priority
            this.tourDetails.tour_Orders.sort((a, b) => {
                return (a.priority ? parseInt(a.priority) : 999999) - (b.priority ? parseInt(b.priority) : 999999)
            });

            for (let i = 0; i < this.tourDetails.tour_Orders.length; i++) {
                if (!this.tourDetails.tour_Orders[i].priority) {
                    orderLatLongsInternal.push({ lat: this.tourDetails.tour_Orders[i].orderInfo.latitude, lng: this.tourDetails.tour_Orders[i].orderInfo.longitude });
                    mapTourOrders.push(this.tourDetails.tour_Orders[i].id);
                } else {
                    nextOrigin = { lat: this.tourDetails.tour_Orders[i].orderInfo.latitude, lng: this.tourDetails.tour_Orders[i].orderInfo.longitude }
                }
            }

            this._dispatcherScreenService.distanceForMultipleDestianations(nextOrigin, orderLatLongsInternal)
                .subscribe((result: any) => {
                    if (result && result.rows[0] && result.rows[0].elements) {
                        //find minimum distance
                        let minDistanceInternal = Math.min.apply(Math, result.rows[0].elements.map(function (item) { return item.distance && item.distance.value || 9999999999999; }));

                        //find index of nearest point
                        let nearestPointIndexInternal = result.rows[0].elements.findIndex(item => (item.distance && item.distance.value || 9999999999999) == minDistanceInternal);

                        //find nearest orderId
                        let tourOrderId = mapTourOrders[nearestPointIndexInternal];
                        let tourOrderIndex = this.tourDetails.tour_Orders.findIndex(item => item.id == tourOrderId);

                        //Update priority for order
                        this.tourDetails.tour_Orders[tourOrderIndex].priority = (index + 1);
                        this.tourDetails.tour_Orders[tourOrderIndex].kilometers = (minDistanceInternal == 9999999999999) ? 0 : (minDistanceInternal / 1000);
                        resolve();
                    } else {
                        resolve();
                    }
                }
                );
        });
    }

    findOrdersPriorities = async () => {
        for (let i = 1; i < this.tourDetails.tour_Orders.length; i++) {
            await this.findOrdersPrioritiesPromise(i);
        }

        //sort orders by priority
        this.tourDetails.tour_Orders.sort((a, b) => {
            return (a.priority ? parseInt(a.priority) : 999999) - (b.priority ? parseInt(b.priority) : 999999)
        });

        //Set order grid data
        this.dataSource = new MatTableDataSource(this.tourDetails.tour_Orders);

        //Set origin, destination and waypoints                
        this.setDirectionData(this.tourDetails);

        //Update order priorities and distance
        this._dispatcherScreenService.updateTourOrderWithPriority(this.tourDetails.tour_Orders).subscribe();
    }

    findDistances(tourDetails) {
        if (tourDetails.tour_Orders) {
            let kilometersFlag = false;
            Promise.all(
                tourDetails.tour_Orders.map((item, index) => {
                    return new Promise((resolve) => {
                        let originAddress = index === 0 ? { lat: this.tourDetails.latitude, lng: this.tourDetails.longitude } : { lat: this.tourDetails.tour_Orders[(index - 1)].orderInfo.latitude, lng: this.tourDetails.tour_Orders[(index - 1)].orderInfo.longitude };

                        if (originAddress && item.orderInfo.address) {
                            this._dispatcherScreenService.distanceMatrixFromAddress(originAddress, { lat: item.orderInfo.latitude, lng: item.orderInfo.longitude })
                                .subscribe((result: any) => {
                                    if (result && result.rows && result.rows[0] && result.rows[0].elements && result.rows[0].elements[0] && result.rows[0].elements[0].distance) {
                                        item.kilometers = (result.rows[0].elements[0].distance.value / 1000);
                                        kilometersFlag = true;
                                    }
                                    resolve();
                                }
                                );
                        } else {
                            resolve();
                        }
                    });
                })
            ).then(() => {
                //Update kms
                if (kilometersFlag) {
                    this._dispatcherScreenService.updateTourOrderWithPriority(tourDetails.tour_Orders).subscribe();
                }

                //Set order grid data
                this.dataSource = new MatTableDataSource(tourDetails.tour_Orders);

                //Set origin, destination and waypoints                
                this.setDirectionData(tourDetails);
            });
        }
    }

    setDirectionData(tourDetails) {
        //Find Lat & Long of Tour base address
        if (tourDetails.baseAddress && tourDetails.tour_Orders && tourDetails.tour_Orders.length > 0) {
            this.directionPoints = [];
            for (var i = 0; i < tourDetails.tour_Orders.length; i++) {
                if (i == 0) {
                    this.directionPoints.push({
                        "points": {
                            "origin": { lng: tourDetails.longitude, lat: tourDetails.latitude },
                            "destination": { lng: tourDetails.tour_Orders[i].orderInfo.longitude, lat: tourDetails.tour_Orders[i].orderInfo.latitude }
                        },
                        "markerOptions": {
                            origin: {
                                label: { color: 'white', text: 'O' },
                                draggable: false
                            },
                            destination: {
                                label: { color: 'white', text: tourDetails.tour_Orders[i].priority.toString() },
                                draggable: false
                            }
                        },
                    }
                    );
                } else {
                    this.directionPoints.push({
                        "points": {
                            "origin": { lng: tourDetails.tour_Orders[i - 1].orderInfo.longitude, lat: tourDetails.tour_Orders[i - 1].orderInfo.latitude },
                            "destination": { lng: tourDetails.tour_Orders[i].orderInfo.longitude, lat: tourDetails.tour_Orders[i].orderInfo.latitude }
                        },
                        "markerOptions": {
                            origin: {
                                label: { color: 'white', text: tourDetails.tour_Orders[i - 1].priority.toString() },
                                draggable: false
                            },
                            destination: {
                                label: { color: 'white', text: tourDetails.tour_Orders[i].priority.toString() },
                                draggable: false
                            }
                        },
                    }
                    );
                }
            }

            this.directionRenderPoints = [];
            for (let i = 0; i < this.directionPoints.length; i++) {
                setTimeout(() => {
                    this.directionRenderPoints.push(this.directionPoints[i]);
                }, 500 * (i + 1));
            }
            this.renderOptions = {
                suppressMarkers: true,
            };
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        // updates moved data and table, but not dynamic if more dropzones
        this.dataSource.data = cloneDeep(this.dataSource.data);
        this.dataSource.data.map((item, index) => {
            item.priority = (index + 1);
        });
    }

    createTourForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.tourDetails.id],
            name: [this.tourDetails.name, [Validators.required]],
            dateOfCreation: [this.tourDetails.dateOfCreation, [Validators.required]],
            timeOfTheTour: [this.tourDetails.timeOfTheTour, [Validators.required]],
            driverId: [this.tourDetails.driverId],
            driverCtrl: [this.tourDetails.driverName],
            status: [this.tourDetails.status],
            baseAddress: [this.tourDetails.baseAddress],
            longitude: [this.tourDetails.longitude],
            latitude: [this.tourDetails.latitude]
        });
    }

    private setupAutocompleteDataSourceDrivers() {
        this._driversService.getDrivers().then(drivers => {
            this.allDrivers = drivers;
            this.filteredDrivers = this.driverCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterDrivers(name) : this.allDrivers.slice()));
        });
        this.driverCtrl.setValue(this.tourDetails.driverName);
    }

    private _filterDrivers(driverName: string): Driver[] {
        const filterValue = driverName.toLowerCase();

        this.drivers = this.allDrivers.filter(driver => driver.firstName.toLowerCase().indexOf(filterValue) === 0);
        return this.drivers;
    }

    onSaveClick() {
        //To pass selected date(Avoid timezone issue)
        let updateTourData = this.tourForm.getRawValue();
        let tourDate = new Date(updateTourData.dateOfCreation);
        const offset = tourDate.getTimezoneOffset();
        tourDate = new Date(tourDate.getTime() - (offset * 60 * 1000));
        updateTourData.dateOfCreation = tourDate.toISOString().split('T')[0];

        this._tourService.saveTour(updateTourData, this.drivers[0]).then((res) => {
           
            if(!res.status){
                this._matSnackBar.open(`${res.message}`, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
                return
            }
            this.tourDetails.status = updateTourData.status;
            if (updateTourData.status === 4) {
                this.displayedColumns = ['ahlName', 'temporaryAddress01', 'city', 'zipCode', 'numberOfBags', 'kilometers', 'priority'];
            } else {
                this.displayedColumns = ['ahlName', 'temporaryAddress01', 'city', 'zipCode', 'numberOfBags', 'kilometers', 'priority', 'action'];
            }

            this._matSnackBar.open('Tour Updated', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    removeOrderFromTour(tourOrderId, orderId): void {
        this.tourConfirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.tourConfirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.tourConfirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                var removeOrderRequestParams = {
                    "Id": tourOrderId,
                    "OrderId": orderId,
                };
                this._dispatcherScreenService.removeOrderFromTour(removeOrderRequestParams).subscribe((res) => {

                    if(!res.status){
                        this._matSnackBar.open(`${res.message}`, 'OK', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                        return
                    }
                    this._dispatcherScreenService.getTourDetails(this.route.snapshot.params.id).then(tourDetailsResponse => {
                        this.tourDetails = tourDetailsResponse;
                        this.dataSource = new MatTableDataSource(tourDetailsResponse.tour_Orders);
                    });
                });
            }
            this.tourConfirmDialogRef = null;
        });
    }

    calculateTotalKms() {
        return this.tourDetails.tour_Orders.reduce((accum, curr) => accum + curr.kilometers, 0);
    }

    updatePriority() {
        this.tourDetails.tour_Orders = this.dataSource.data;
        this.findDistances(this.tourDetails);
    }

    addExtraPriceItem(element): void {
        this.dialogRef = this._matDialog.open(ExtraPriceItemComponent, {
            panelClass: 'extra-price-item-dialog',
            data: {
                action: 'edit',
                order: element
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }

                let selectedPriceItemId = (response.extraPriceItemForm && response.extraPriceItemForm.getRawValue() && response.extraPriceItemForm.getRawValue().priceItemId) || null;
                
                let request = {
                    "id": element.id,
                    "orderId": element.orderInfo.id,
                    "extraPriceId": selectedPriceItemId
                };

                this._dispatcherScreenService.addExtraPriceIdInOrder(request).subscribe(() => {
                    element.priceItem = selectedPriceItemId;
                    this._matSnackBar.open('Extra price item saved.', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                });
            });
    }
}