import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Console } from 'console';
import { environment } from 'environments/environment';

@Component({
  selector: 'customer-signature-popup-dialog',
  templateUrl: './customer-signature-popup.component.html',
  styleUrls: ['./customer-signature-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerSignaturePopupComponent {
  action: string;
  dialogTitle: string;
  displayedColumns: string[];
  signatureImage: string;
  /**
   * Constructor
   *
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public _matDialog: MatDialog,
    public matDialogRef: MatDialogRef<CustomerSignaturePopupComponent>
  ) {
    this.dialogTitle = "Customer Signature";
    this.signatureImage = environment.urlAddress + '/upload/' + _data.order.customeSignature;
  }
}