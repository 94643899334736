import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatSort, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { UsersService } from './users.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FuseUtils } from '@fuse/utils';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { UserService } from '../user/user.service';
import { UserComponent, UserDialogResult } from '../user/user.component';
import { User } from './users.model';
import { Owner } from 'app/main/owners/owner.model';
import { Country } from 'app/main/country/country.model';
import { isNumber } from 'lodash';

@Component({
    selector: 'main-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, OnDestroy {

    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    user: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['name', 'lastName', 'address', 'countryName', 'username', 'email', 'phoneNumber', 'ownerName', 'active'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild('filter')
    filter: ElementRef;

    @ViewChild(MatSort)
    sort: MatSort;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UserService} _userService
     */
    constructor(
        private _usersService: UsersService,
        private _userService: UserService,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._usersService, this.paginator, this.sort);
        this.sort.disableClear = true;

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    editUser(user): void {
        this.dialogRef = this._matDialog.open(UserComponent, {
            panelClass: 'user-form-dialog',
            data: {
                user,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: UserDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                switch (response.actionType) {
                    case 'save':
                        this.saveUser(response.formData, response.owner, response.country);
                        break;
                    case 'delete':
                        this.deleteUser(response.formData);
                        break;
                }
            });
    }

    newContact(): void {
        this.dialogRef = this._matDialog.open(UserComponent, {
            panelClass: 'user-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: UserDialogResult) => {
                if (!response || !response.formData) {
                    return;
                }
                this.addUser(response.formData, response.owner, response.country);
            });
    }

    addUser(user: User, owner: Owner, country: Country) {
        this._userService.createUser(user, owner, country).subscribe(() => {
            this._usersService.getUsers();

            this._matSnackBar.open('User Created', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }



    saveUser(user: User, owner: Owner, country: Country) {
        if (owner) {
            user.ownerId = owner.id;
        }

        if (country) {
            user.countryId = country.id;
        }

        this._userService.saveUser(user, owner, country).then(() => {
            this._usersService.getUsers();

            this._matSnackBar.open('User Updated', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    deleteUser(user): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this._userService.deleteUser(user.id).subscribe(() => {
                this._usersService.getUsers();
            });
            this.confirmDialogRef = null;
        });

    }

}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {UserService} _userService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private _usersService: UsersService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();

        this.filteredData = this._usersService.users;
    }


    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._usersService.onUsersChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

            let data = this._usersService.users.slice();

            data = this.filterData(data);

            this.filteredData = [...data];

            data = this.sortData(data);

            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        let sortColumn = this._matSort.active;
        let direction = this._matSort.direction;

        if (direction == 'asc') {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (va > vb ? 1 : (va === vb ? 0 : -1)));
            });
        } else {
            return data.sort(function (a, b) {
                var va = (a[sortColumn] === null || a[sortColumn] === "") ? Infinity : (isNumber(a[sortColumn]) ? a[sortColumn] : ("" + a[sortColumn]).toLowerCase()),
                    vb = (b[sortColumn] === null || b[sortColumn] === "") ? Infinity : (isNumber(b[sortColumn]) ? b[sortColumn] : ("" + b[sortColumn]).toLowerCase());

                return va === Infinity ? 1 : (vb === Infinity ? -1 : (vb > va ? 1 : (va === vb ? 0 : -1)));
            });
        }
    }

    disconnect(): void {
    }
}

