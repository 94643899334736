

export class TourTemplate {
    name: string;
    tourTime: Date;
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    zoneId: string;
    zoneName?: string;
    priceItemId?: string;
    extraPriceItemId?: string;
    id?: string;
    baseAddress?: string;

    /**
     * Constructor
     * @param fixedTour
     */
    constructor(fixedTour?) {
        fixedTour = fixedTour || {};
        this.id = fixedTour.id || 0;
        this.name = fixedTour.name || '';
        this.tourTime = fixedTour.tourTime || null;
        this.isActive = fixedTour.isActive || false;
        this.monday = fixedTour.monday || false;
        this.tuesday = fixedTour.tuesday || false;
        this.wednesday = fixedTour.wednesday || false;
        this.thursday = fixedTour.thursday || false;
        this.friday = fixedTour.friday || false;
        this.saturday = fixedTour.saturday || false;
        this.sunday = fixedTour.sunday || false;
        this.baseAddress = fixedTour.baseAddress;
        this.zoneId = fixedTour.zoneId || '';
        this.priceItemId = fixedTour.priceItemId || '';
        this.extraPriceItemId = fixedTour.extraPriceItemId || '';
        this.zoneName = fixedTour.zoneName || '';
    }
}