
import { Component, ViewEncapsulation, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { District } from '../District.model';
import { ZonesService } from 'app/main/zones/zones.service';
import { Zone } from 'app/main/zones/zone.model';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'Districts-district-form-dialog',
    templateUrl: './district.component.html',
    styleUrls: ['./district.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DistrictComponent {
    action: string;
    district: District;
    dialogTitle: string;
    districtForm: FormGroup;
    private _unsubscribeAll: Subject<any>;
    zonesCtrl = new FormControl();
    filteredZones: Observable<Zone[]>;
    zones: Zone[] = [];
    allZones: Zone[];

    /**
     * Constructor
     *
     * @param {CompanyServicee} _companyService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<DistrictComponent>,
        private _zonesService: ZonesService,
        private _formBuilder: FormBuilder
    ) {
        this.district = new District(_data.district);
        this.action = _data.action;
        this.dialogTitle = this.action === 'edit' ? 'Edit District' : 'New District';
        this.districtForm = this.createDistrictForm();
        this._unsubscribeAll = new Subject();
        this.setupAutocompleteDataSourceZone();
    }

    /**
     * Create company form
     *
     * @returns {FormGroup}
     */
    createDistrictForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.district.id],
            name: [this.district.name, [Validators.required, Validators.maxLength(40)]],
            zoneId: [this.district.zoneId, [Validators.required]],
            zonesCtrl: [this.district.zoneName],
            postalCode: [this.district.postalCode, [Validators.required]]
        });
    }

    saveDistrict(): void {
        this.matDialogRef.close({
            actionType: 'new',
            formData: this.districtForm.getRawValue(),
            zoneId: (this.zones && this.zones[0] && this.zones[0].id) || null
        });
    }

    updateDistrict(): void {
        this.matDialogRef.close({
            actionType: 'save',
            formData: this.districtForm.getRawValue(),
            zoneId: (this.zones && this.zones[0] && this.zones[0].id) || this._data.district.zoneId
        });
    }

    deleteDistrict(): void {
        this.matDialogRef.close({
            actionType: 'delete'
        });
    }

    zoneSelected(zoneId): void {
        this.districtForm.patchValue({
            zoneId
        });
    }

    private setupAutocompleteDataSourceZone() {
        this._zonesService.getZones().then(zones => {
            this.allZones = zones;
            this.filteredZones = this.zonesCtrl.valueChanges.pipe(
                startWith(null),
                map((name: string | null) => name ? this._filterZone(name) : this._clearZoneSelection()));
        });
        this.zonesCtrl.setValue(this.district.zoneName);
    }

    public _clearZoneSelection() {
        if (!this.zonesCtrl.value)
            this.zoneSelected(null);
        return this.allZones.slice();
    }

    private _filterZone(name: string): Zone[] {
        const filterValue = name.toLowerCase();

        this.zones = this.allZones.filter(zone => zone.name.toLowerCase().indexOf(filterValue) === 0);
        return this.zones;
    }
}