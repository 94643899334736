import { Component, ViewEncapsulation, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Email } from './email.model';

@Component({
  selector: 'email-details-form-dialog',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailDetailsComponent {

  action: string;
  email: Email;
  dialogTitle: string;
  pageType: string;
  mailForm: FormGroup;
  myControl = new FormControl();
  options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
  filteredOptions: Observable<string[]>;

 private _unsubscribeAll: Subject<any>;

 /**
  * Constructor
  *
  * @param {FormBuilder} _formBuilder
  * @param {MatSnackBar} _matSnackBar
  */
 constructor(
     @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
     public matDialogRef: MatDialogRef<EmailDetailsComponent>,
     private _formBuilder: FormBuilder,
     public _matDialog: MatDialog
 )
 {
     this.email = _data.email;
     this.action = _data.action;
     this.dialogTitle = this.action === 'edit' && 'Email';
     this._unsubscribeAll = new Subject();

 }
}