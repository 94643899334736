
import { Component, ViewEncapsulation, Inject, Optional } from '@angular/core';
import { FuseUtils } from '@fuse/utils';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Zone } from '../zone.model';
import { ZonesService } from '../zones.service';
import { ZoneService } from './zone.service';

@Component({
  selector: 'zones-zone-form-dialog',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoneComponent  {


    action: string;
    zone: Zone;
    dialogTitle: string;
    pageType: string;
    zoneForm: FormGroup;
    myControl = new FormControl();
    options: {id: number, value: string}[] = [{id:1, value:'One'},{id:2, value:'O444e'},{id:3, value:'O222ne'}];
    filteredOptions: Observable<string[]>;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CompanyServicee} _companyService
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        @Optional()  @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<ZoneComponent>,
        private _zonesService: ZonesService,
        private _zoneService: ZoneService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar
    )
    {
        this.zone = new Zone(_data.zone);
        this.action = _data.action;
        this.dialogTitle = this.action === 'edit' ? 'Edit Zone' : 'New Zone';
        this.zoneForm = this.createZoneForm();
        this._unsubscribeAll = new Subject();
    }

    /**
     * Create company form
     *
     * @returns {FormGroup}
     */
    createZoneForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.zone.id],
            name            : [this.zone.name, [Validators.required, Validators.maxLength(40)]],
            description     : [this.zone.description]
        });
    }

    saveZone(): void
    {
        const data = this.zoneForm.getRawValue();
        data.handle = FuseUtils.handleize(data.name);

        this._zoneService.saveZone(data)
            .then(() => {
                this._zoneService.onZoneChanged.next(data);

                this._matSnackBar.open('Zone saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

   

    private _filter(value: any): any[] {
        const filterValue = value.toLowerCase();
    
        return this.options.filter(option => option.value.toLowerCase().includes(filterValue));
      }

}

