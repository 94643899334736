import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import {
    MatIconModule, MatTableModule, MatPaginatorModule, MatSortModule,
    MatSnackBarModule, MatTabsModule, MatSelectModule, MatRippleModule,
    MatInputModule, MatFormFieldModule, MatExpansionModule, MatButtonModule, MatChipsModule, MatDialogModule, MatToolbarModule, MatDialogRef
} from '@angular/material';


import { FuseWidgetModule, FuseConfirmDialogModule } from '@fuse/components';
import { AuthenticationGuard } from 'app/blocks/guards/authentication.guards';
import { CountryComponent } from './country.component';
import { CountriesService } from './country.service';
import { CountryItemComponent } from './country-item/country-item.component';
import { CountryService } from './country-item/country-item.service';

const routes = [
    {
        path: '',
        component: CountryComponent,
        resolve: {
            data: CountriesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'countries',
        component: CountryComponent,
        resolve: {
            data: CountriesService
        },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'countries/:id',
        component: CountryItemComponent,
        resolve: {
            data: CountryService
        },
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    declarations: [
        CountryComponent,
        CountryItemComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,

        
        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers: [
        CountriesService
    ],
    exports: [
        CountryComponent, 
        CountryItemComponent
    ]
})

export class CountryModule {
}
