import { Injectable } from '@angular/core';
import { TourTable } from 'app/main/tours/tour.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TourDetails } from './tour-screen/tour-order.model';
import { Driver } from 'app/main/drivers/drivers.model';

@Injectable({ providedIn: "root" })
export class DispatcherToursService {

    tours: TourTable[];
    selectedTour: TourDetails;
    onToursChanged: BehaviorSubject<any>;
    onSelectedTourChanged: BehaviorSubject<TourDetails>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    selectedContacts: string[] = [];
    
    constructor(private http: HttpClient) 
    {
        this.onSelectedTourChanged = new BehaviorSubject(null);
        this.onToursChanged = new BehaviorSubject({});
    }
    // getTours(): Promise<TourTable[]>{
    //     return new Promise((resolve, reject) => {
    //         this.http.get(environment.urlAddress + '/api/tours/gettodaytour')
    //             .subscribe((response: any) => {
    //                 this.tours = response;
    //                 this.onToursChanged.next(this.tours);
    //                 resolve(response);
    //             }, reject);
    //     });
    // }


    getTours(): Promise<TourTable[]>{
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlAddress + '/api/dispatcher-screen/tour-templates-for-today')
                .subscribe((response: any) => {
                    this.tours = [...response.fixedTours, ...response.customTours];
                    this.onToursChanged.next(this.tours);
                    resolve(response);
                }, reject);
        });
    }

    getOrdersByTourId(id: number): Promise<TourDetails>{
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.urlAddress}/api/tours/${id}/orders`)
                .subscribe((response: any) => {
                    this.selectedTour = response;
                    this.onSelectedTourChanged.next(this.selectedTour);
                    resolve(response);
                }, reject);
        });
    }

    createTour(tour: TourTable, driver: Driver) : Observable<TourTable> {
        if(driver){
            tour.driverId = driver.id;
        }
        const apiUrl: string = `${environment.urlAddress}/api/tours/tour_order`;
        return this.http.post<TourTable>(apiUrl, tour);
    }

    getTourDetails(id: number) : Observable<TourTable> {
        const  apiUrl: string = `${environment.urlAddress}/api/tours/${id}`;
        return this.http.get<TourTable>(apiUrl);
    }

    toggleSelectedContact(id): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);
                this.onToursChanged.next(this.selectedContacts);
                return;
            }
        }

        this.selectedContacts.push(id);
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    saveFixedTour(tour): Promise<any>
    {
      
        return new Promise((resolve, reject) => {
            this.http.post(environment.urlAddress + '/api/fixedTours/', tour)
                .subscribe((response: any) => {
                    resolve(response);
                    this.getTours();
                }, reject);
        });
    }
}
