export class IATA_airport{
    airport: string;
    iatA_code: string;
    city: string;
    country: string;
    id?: string;

    /**
     * Constructor
     * @param airport
     */
    constructor(airport?){
        airport = airport || {};
        this.id = airport.id || 0;
        this.airport = airport.airport || '';
        this.iatA_code = airport.iatA_code || '';
        this.city = airport.city || '';
        this.country = airport.country || '';
    }
}