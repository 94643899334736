import { Component, ViewEncapsulation, Optional, Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Beneficiary } from '../beneficiary.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { BeneficiariesService } from '../beneficiaries.service';
import { BeneficiaryService } from './beneficiary.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { InvoiceRecipient } from 'app/main/invoice-recipients/invoice-recipient.model';
import { InvoiceRecipientsService } from 'app/main/invoice-recipients/invoice-recipients.service';
import { startWith, map } from 'rxjs/operators';
import { InvoicingCycle } from 'app/main/invoice-cycles/invoice-cycle.model';
import { Country } from 'app/main/country/country.model';
import { InvoiceCyclesService } from 'app/main/invoice-cycles/invoice-cycles.service';
import { CountriesService } from 'app/main/country/country.service';
import { AirlinesService } from 'app/main/airlines/airlines.service';
import { IATA_airline } from 'app/main/airlines/airlines.model';
import { MapsAPILoader } from '@agm/core';

export interface BeneficiaryDialogResult {
  actionType: string;
  formData: any;
  invoiceRecipient: InvoiceRecipient;
  invoicingCycle: InvoicingCycle;
  country: Country;
  iata: IATA_airline;
}

@Component({
  selector: 'beneficiaries-beneficiary-form-dialog',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeneficiaryComponent {

  action: string;
  beneficiary: Beneficiary;
  dialogTitle: string;
  pageType: string;
  beneficiaryForm: FormGroup;
  myControl = new FormControl();
  options: { id: number, value: string }[] = [{ id: 1, value: 'One' }, { id: 2, value: 'O444e' }, { id: 3, value: 'O222ne' }];
  filteredOptions: Observable<string[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  iatasCtrl = new FormControl();

  filteredIata: Observable<IATA_airline[]>;
  iatas: IATA_airline[] = [];
  allIatas: IATA_airline[];
  list: string[];

  invoiceRecipientCtrl = new FormControl();

  filteredInvoiceRecipient: Observable<InvoiceRecipient[]>;
  invoiceRecipients: InvoiceRecipient[] = [];
  allInvoiceRecipients: InvoiceRecipient[];
  invoicingCycletCtrl = new FormControl();

  filteredInvoicingCycle: Observable<InvoicingCycle[]>;
  invoiceCycles: InvoicingCycle[] = [];
  allInvoicinCycle: InvoicingCycle[];
  countryCtrl = new FormControl();

  filteredCountry: Observable<Country[]>;
  countries: Country[] = [];
  allCountries: Country[];
  ShowRec: boolean = true;

  @ViewChild('addressAutoComplete') addressAutoCompleteRef: ElementRef;

  private _unsubscribeAll: Subject<any>;

  showOptions(event) {
    console.log(event.checked);
    if (event.checked)
      this.ShowRec = false;
    else
      this.ShowRec = true;
  }
  /**
   * Constructor
   *
   * @param {DriverService} _driverService
   * @param {FormBuilder} _formBuilder
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public _data: any,
    public matDialogRef: MatDialogRef<BeneficiaryComponent>,
    private _beneficiariesService: BeneficiariesService,
    private _invoicingCycles: InvoiceCyclesService,
    private _countriesService: CountriesService,
    private _iataService: AirlinesService,
    private _beneficiaryService: BeneficiaryService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private _invoiceRecipientsService: InvoiceRecipientsService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.beneficiary = new Beneficiary(_data.beneficiary);
    this.action = _data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Beneficiary' : 'New Beneficiary';
    this.beneficiaryForm = this.createBeneficiaryForm();
    this.ShowRec = !this.beneficiary.isRecipient;
    this._unsubscribeAll = new Subject();

    this.setupAutocompleteDataSourceIATA();

    this.setupAutocompleteDataSourceInvoiceRecipient();

    this.setupAutocompleteDataSourceInvoicingCycle();

    this.setupAutocompleteDataSourceCountry();
  }

  /**
   * Create driver form
   *
   * @returns {FormGroup}
   */
  createBeneficiaryForm(): FormGroup {
    return this._formBuilder.group({
      bE_ID: [this.beneficiary.bE_ID],
      externaL_BE_ID: [this.beneficiary.externaL_BE_ID],
      companyName: [this.beneficiary.companyName, [Validators.required]],
      responsiblePerson: [this.beneficiary.responsiblePerson],
      address: [this.beneficiary.address],
      zipCode: [this.beneficiary.zipCode],
      city: [this.beneficiary.city],
      email: [this.beneficiary.email],
      vaT_ID: [this.beneficiary.vaT_ID],
      ccEmail: [this.beneficiary.ccEmail],
      phoneNumber: [this.beneficiary.phoneNumber],
      active: [this.beneficiary.active],
      iatA_airlineId: [this.beneficiary.iatA_airlineId],
      iatasCtrl: [this.beneficiary.iatA_airlineName],
      iR_ID: [this.beneficiary.iR_ID],
      invoiceRecipientCtrl: [this.beneficiary.invoiceRecipientName],
      invoicingCycleId: [this.beneficiary.invoicingCycleId],
      invoicingCycletCtrl: [this.beneficiary.invoicingCycleName],
      countryId: [this.beneficiary.countryId],
      countryCtrl: [this.beneficiary.countryName],
      isRecipient: [this.beneficiary.isRecipient],
      colliSize: [this.beneficiary.colliSize]
    });
  }

  onSaveClick() {
    this.matDialogRef.close({
      actionType: 'save',
      formData: this.beneficiaryForm.getRawValue(),
      invoiceRecipient: this.invoiceRecipients[0],
      iata: this.iatas[0],
      country: this.countries[0],
      invoicingCycle: this.invoiceCycles[0]
    });
  }

  onAddClick() {
    this.matDialogRef.close({
      actionType: 'add',
      formData: this.beneficiaryForm.getRawValue(),
      invoiceRecipient: this.invoiceRecipients[0],
      iata: this.iatas[0],
      country: this.countries[0],
      invoicingCycle: this.invoiceCycles[0]
    });
  }

  onDeleteClick() {
    this.matDialogRef.close({
      actionType: 'delete',
      formData: this.beneficiaryForm.getRawValue(),
      invoiceRecipient: this.invoiceRecipients[0],
      iata: this.iatas[0],
      country: this.countries[0],
      invoicingCycle: this.invoiceCycles[0]
    });
  }

  private setupAutocompleteDataSourceIATA() {
    this._iataService.getAirlines().then(iatas => {
      this.allIatas = iatas;
      this.filteredIata = this.iatasCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterIata(name) : this.allIatas.slice()));
    });
    this.iatasCtrl.setValue(this.beneficiary.iatA_airlineName);
  }

  private _filterIata(iatA_airlineName: string): IATA_airline[] {
    const filterValue = iatA_airlineName.toLowerCase();

    this.iatas = this.allIatas.filter(iata => iata.airlineName.toLowerCase().indexOf(filterValue) === 0);
    return this.iatas;
  }

  private setupAutocompleteDataSourceInvoicingCycle() {
    this._invoicingCycles.getCycles().then(invoiceCycles => {
      this.allInvoicinCycle = invoiceCycles;
      this.filteredInvoicingCycle = this.invoicingCycletCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterInvoicingCycl(name) : this.allInvoicinCycle.slice()));
    });
    this.invoicingCycletCtrl.setValue(this.beneficiary.invoicingCycleName);
  }

  private _filterInvoicingCycl(invoicingCycleName: string): InvoicingCycle[] {
    const filterValue = invoicingCycleName.toLowerCase();

    this.invoiceCycles = this.allInvoicinCycle.filter(invoicingCycle => invoicingCycle.name.toLowerCase().indexOf(filterValue) === 0);
    return this.invoiceCycles;
  }

  private setupAutocompleteDataSourceCountry() {
    this._countriesService.getCountries().then(countries => {
      this.allCountries = countries;
      this.filteredCountry = this.countryCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this._filterCountry(name) : this.allCountries.slice()));
    });
    this.countryCtrl.setValue(this.beneficiary.countryName);
  }

  private _filterCountry(countryName: string): Country[] {
    const filterValue = countryName.toLowerCase();

    this.countries = this.allCountries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
    return this.countries;
  }

  private setupAutocompleteDataSourceInvoiceRecipient() {
    this._invoiceRecipientsService.getInvoiceRecipients().then(recipients => {
      this.allInvoiceRecipients = recipients;
      this.filteredInvoiceRecipient = this.invoiceRecipientCtrl.valueChanges.pipe(
        startWith(null),
        map((name: string | null) => name ? this.filteredRecipients(name) : this.allInvoiceRecipients.slice()));
    });
    this.invoiceRecipientCtrl.setValue(this.beneficiary.invoiceRecipientName);
  }

  private filteredRecipients(invoiceRecipientName: string): InvoiceRecipient[] {
    const filterValue = invoiceRecipientName.toLowerCase();

    this.invoiceRecipients = this.allInvoiceRecipients.filter(invoiceRecipient => invoiceRecipient.companyName.toLowerCase().indexOf(filterValue) === 0);
    return this.invoiceRecipients;
  }

  ngAfterViewInit(): void {
    this.googlePlaceAutoComplete();
  }

  googlePlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      const options = {
        componentRestrictions: { country: ["at", "si", "sk", "hu", "cz"] }
      };

      let addressAutocomplete = new google.maps.places.Autocomplete(this.addressAutoCompleteRef.nativeElement, options);
      addressAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let addressPlace: google.maps.places.PlaceResult = addressAutocomplete.getPlace();
          if (addressPlace && addressPlace.name && addressPlace.formatted_address) {
            this.beneficiaryForm.patchValue({
              address: (addressPlace.name && !(addressPlace.formatted_address.toLowerCase()).includes(addressPlace.name.toLowerCase())) ? addressPlace.name + ", " + addressPlace.formatted_address : addressPlace.formatted_address
            });
          }
        });
      });
    });
  }
}
