export const Language: {name: string, value: number}[] = [
    {name: "ENGLISH", value:1},
    {name: "AUSTRIAN", value:2}
];

export class Vendor{
    vE_ID: string;
    companyName: string;
    responsiblePerson: string;
    address: string;
    ziP_CODE: string;
    city: string;
    email: string;
    vaT_ID: string;
    ccEmail: string;
    biC_account?: string;
    ibaN_account?: string;
    active: boolean;
    ust: number;
    paymentMethod?: string;
    phoneNumber: string;
    language?: number;
    languageType: number;
    countryId?: string;
    countryName: string;

    /**
     * Constructor
     * @param vendor
     */
    constructor(vendor?){
        vendor = vendor || {};
        this.vE_ID = vendor.vE_ID || '';
        this.companyName = vendor.companyName || '';
        this.responsiblePerson = vendor.responsiblePerson || '';
        this.address = vendor.address || '';
        this.ziP_CODE = vendor.ziP_CODE || '';
        this.city = vendor.city || '';
        this.email = vendor.email || '';
        this.vaT_ID = vendor.vaT_ID || '';
        this.ccEmail = vendor.ccEmail || '';
        this.biC_account = vendor.biC_account || '';
        this.active = vendor.active;
        this.ust = vendor.ust || 0;
    this.ibaN_account = vendor.ibaN_account || '';
        this.paymentMethod = vendor.paymentMethod || '';
        this.phoneNumber = vendor.phoneNumber || '';
        this.language = vendor.Language;
        this.languageType = vendor.language;
        this.countryId = vendor.countryId || null;
        this.countryName = vendor.countryName;
    }
}
